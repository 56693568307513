import TooltipIconButton from '@paypr/mui5-common-components/dist/components/actions/TooltipIconButton';
import React from 'react';
import ExplodeCloseIcon from './ExplodeCloseIcon';
import ExplodeOpenIcon from './ExplodeOpenIcon';

export interface ExplodeIconButtonProps extends ExplodingComponentProps {}

export interface ExplodingComponentProps {
  exploded?: boolean;
  onExplodeChanged?: (exploded: boolean) => void;
}

const ExplodeIconButton = ({ exploded, onExplodeChanged }: ExplodeIconButtonProps) => {
  if (!onExplodeChanged) {
    return null;
  }

  const handleExplodeOpen = (event) => {
    event.stopPropagation();
    onExplodeChanged(true);
  };

  const handleExplodeClose = (event) => {
    event.stopPropagation();
    onExplodeChanged(false);
  };

  return exploded ? (
    <TooltipIconButton title="Close Full Screen" onClick={handleExplodeClose}>
      <ExplodeCloseIcon />
    </TooltipIconButton>
  ) : (
    <TooltipIconButton title="Open Full Screen" onClick={handleExplodeOpen}>
      <ExplodeOpenIcon />
    </TooltipIconButton>
  );
};
export default ExplodeIconButton;
