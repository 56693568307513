import { gql } from '@apollo/client';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import AlternatingTableRow from '@paypr/mui5-common-components/dist/components/tables/AlternatingTableRow';
import EmptyTableRow from '@paypr/mui5-common-components/dist/components/tables/EmptyTableRow';
import {
  SimpleSortingTableHeadCell,
  SimpleSortingTableHeadProps,
} from '@paypr/mui5-common-components/dist/components/tables/SortingTableHead';
import { getComparator, stableSort, useSorting } from '@paypr/mui5-common-components/dist/components/tables/sortUtils';
import TooltipItem from '@paypr/mui5-common-components/dist/components/typography/TooltipItem';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { OrdersTableData_OrderConnection, OrdersTableRow_Order } from '../../generated/graphql';
import { buildTimeframeDisplay } from '../common/model/timeframe';
import HeightLimitedTableContainer from '../common/tables/HeightLimitedTableContainer';
import SimpleSortingTableHead from '../common/tables/SortingTableHead';
import UsdFormat from '../common/typography/UsdFormat';
import { buildOrderStatusDisplay } from './OrderDetail';

interface OrdersTableProps {
  orderConnection: OrdersTableData_OrderConnection;
  onClickOrderRow?: OrderClickEventHandler;
  fullHeight?: boolean;
}

export type OrderClickEventHandler = (orderId: string, newTab: boolean) => void;

interface OrdersTableRowData extends OrdersTableRow_Order {
  portfolioName: string;
  assetsText: string;
  allAssetsText: string;
}

const OrdersTable = ({ orderConnection, onClickOrderRow, fullHeight }: OrdersTableProps) => {
  const { order, orderBy, handleRequestSort } = useSorting<OrdersTableRowData>('createdAt', 'desc');

  const createClickHandler = (orderId: string) => {
    if (!onClickOrderRow) {
      return undefined;
    }
    return (event) => onClickOrderRow(orderId, event.ctrlKey || event.metaKey);
  };

  const orders: OrdersTableRowData[] = orderConnection.edges
    .map(({ node }) => node)
    .map((order) => {
      const assets = order.assets.map((asset) => asset);
      assets.sort((a, b) => {
        if (a.desiredCurrencyAmount !== null && b.desiredCurrencyAmount !== null) {
          return b.desiredCurrencyAmount - a.desiredCurrencyAmount;
        }

        if (a.desiredCurrencyAmount !== null) {
          return -1;
        }

        if (b.desiredCurrencyAmount != null) {
          return 1;
        }

        if (a.asset < b.asset) {
          return -1;
        }

        if (a.asset > b.asset) {
          return 1;
        }

        return 0;
      });

      const maxAssetsToShow = 3;

      const allAssets = assets.map(({ asset }) => asset);

      return {
        ...order,
        portfolioName: order.portfolio.name,
        allAssetsText: allAssets.join(', '),
        assetsText:
          allAssets.filter((_, index) => index < maxAssetsToShow).join(', ') +
            (assets.length > maxAssetsToShow ? '...' : '') || 'None',
      };
    });

  return (
    <HeightLimitedTableContainer fullHeight={fullHeight}>
      <Table stickyHeader>
        <OrdersTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
        <TableBody>
          {orders.length > 0 ? (
            stableSort(orders, getComparator(order, orderBy)).map((order) => (
              <OrderTableRow key={order.id} order={order} onRowClick={createClickHandler(order.id)} />
            ))
          ) : (
            <EmptyTableRow columnCount={columnHeadings.length}>No orders were found.</EmptyTableRow>
          )}
        </TableBody>
      </Table>
    </HeightLimitedTableContainer>
  );
};
export default OrdersTable;

type OrdersTableHeadProps = Omit<SimpleSortingTableHeadProps<OrdersTableRowData>, 'headings'>;

const columnHeadings: SimpleSortingTableHeadCell<OrdersTableRowData>[] = [
  { key: 'portfolioName', label: 'Portfolio' },
  { key: 'status', label: 'Status' },
  { key: 'allAssetsText', label: 'Assets' },
  { key: 'timeframe', label: 'Timeframe' },
  { key: 'desiredCurrencyAmount', label: 'Desired', align: 'right' },
  { key: 'filledCurrencyAmount', label: 'Filled', align: 'right' },
  { key: 'unfilledCurrencyAmount', label: 'Unfilled', align: 'right' },
  { key: 'feesPaid', label: 'Fees', align: 'right' },
  { key: 'dueAt', label: 'Due', align: 'right' },
  { key: 'updatedAt', label: 'Last Update', align: 'right' },
  { key: 'createdAt', label: 'Created', align: 'right' },
];

const OrdersTableHead = (props: OrdersTableHeadProps) => (
  <SimpleSortingTableHead {...props} headings={columnHeadings} />
);

type OrderTableRowProps = {
  order: OrdersTableRowData;
  onRowClick?: React.MouseEventHandler;
};

export const OrderTableRow = ({ order, onRowClick }: OrderTableRowProps) => {
  const { formatShortDateTime } = useDateTime();

  return (
    <AlternatingTableRow hover={Boolean(onRowClick)} onClick={onRowClick}>
      <TableCell>{order.portfolioName}&nbsp;</TableCell>
      <TableCell>{buildOrderStatusDisplay(order.status)}</TableCell>
      <TableCell>
        <TooltipItem title={order.allAssetsText}>
          <span>{order.assetsText}</span>
        </TooltipItem>
      </TableCell>
      <TableCell>{buildTimeframeDisplay(order.timeframe)}</TableCell>
      <TableCell align="right">
        <UsdFormat amount={order.desiredCurrencyAmount} />
      </TableCell>
      <TableCell align="right">
        <UsdFormat amount={order.filledCurrencyAmount} />
      </TableCell>
      <TableCell align="right">
        <UsdFormat amount={order.unfilledCurrencyAmount} />
      </TableCell>
      <TableCell align="right">
        <UsdFormat amount={order.feesPaid} />
      </TableCell>
      <TableCell align="right">{formatShortDateTime(order.dueAt)}</TableCell>
      <TableCell align="right">{formatShortDateTime(order.updatedAt)}</TableCell>
      <TableCell align="right">{formatShortDateTime(order.createdAt)}</TableCell>
    </AlternatingTableRow>
  );
};

export const ordersTableRowFragment = gql`
  fragment OrdersTableData_OrderConnection on OrderConnection {
    edges {
      node {
        id
        ...OrdersTableRow_Order
      }
    }
  }

  fragment OrdersTableRow_Order on Order {
    id
    portfolio {
      id
      name
    }
    assets {
      id
      asset
      desiredCurrencyAmount
    }
    status
    desiredCurrencyAmount
    filledCurrencyAmount
    unfilledCurrencyAmount
    feesPaid
    timeframe
    dueAt
    createdAt
    updatedAt
  }
`;
