import NavItem from '@paypr/mui5-common-components/dist/components/page/nav/NavItem';
import React from 'react';
import { useFavoriteReports } from './favorites';
import ReportIcon from '../common/icons/ReportIcon';
import { buildFavoriteReportPath, latestReportId } from './reportPaths';

export interface FavoriteReportNavItemsProps {
  disabled?: boolean;
}

const FavoriteReportNavItems = ({ disabled }: FavoriteReportNavItemsProps) => {
  const { favoriteReports } = useFavoriteReports();
  return (
    <>
      {favoriteReports.map((report) =>
        report.id != latestReportId ? (
          <NavItem
            key={report.id}
            title={report.name}
            icon={ReportIcon}
            path={buildFavoriteReportPath(report.id)}
            disabled={disabled}
            child
          />
        ) : null,
      )}
    </>
  );
};
export default FavoriteReportNavItems;
