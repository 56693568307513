import { gql } from '@apollo/client';
import PageContainer from '@paypr/mui5-common-components/dist/components/page/PageContainer';
import { useSnackbar } from 'notistack';
import React from 'react';
import AddClientForm, { addClientFormFundDetailsFragment } from '../../components/clients/AddClientForm';
import { buildLpPath } from '../../components/clients/clientPaths';
import AppPage from '../../components/common/page/AppPage';
import {
  CreateClientInput,
  useAddClientPageCreateClientMutation,
  useAddClientPageQuery,
} from '../../generated/graphql';
import { useErrors } from '../../hooks/errors';
import { usePageTitle } from '../../hooks/page';
import { usePaths } from '../../hooks/paths';

const ClientPage = () => {
  const { navigateTo } = usePaths();
  const { enqueueSnackbar } = useSnackbar();
  const { showError } = useErrors();

  usePageTitle('Add New LP');

  const { data, loading } = useAddClientPageQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [addClient, { loading: updating }] = useAddClientPageCreateClientMutation();

  const handleAddClient = async (newClient: CreateClientInput) => {
    try {
      const { data } = await addClient({
        variables: {
          input: newClient,
        },
      });

      if (!data) {
        showError(new Error('No data returned'));
        return;
      }

      enqueueSnackbar('LP added successfully.', { variant: 'success' });

      navigateTo(buildLpPath(data.createClient.id));
    } catch (e) {
      showError(e);
    }
  };

  return (
    <AppPage>
      <PageContainer>
        <AddClientForm onAddClient={handleAddClient} funds={data?.funds} loading={loading || updating} />
      </PageContainer>
    </AppPage>
  );
};
export default ClientPage;

gql`
  query AddClientPageQuery {
    funds {
      id
      ...AddClientForm_FundDetails
    }
  }

  mutation AddClientPageCreateClientMutation($input: CreateClientInput!) {
    createClient(input: $input) {
      id
    }
  }

  ${addClientFormFundDetailsFragment}
`;
