import { ClientEntityType } from '../../generated/graphql';
import { snakeOrKebabToDisplay } from '../../utils/strings';

export const buildClientTitle = (fullName?: string) => fullName || 'LP';

export const buildClientEntityTypeDisplay = (entityType: ClientEntityType) => {
  switch (entityType) {
    case ClientEntityType.Individual:
      return 'Individual';
    case ClientEntityType.Company:
      return 'LLC, LP, Trust, etc.';
    default:
      console.warn(`Unknown entity type: ${entityType}`);
      return snakeOrKebabToDisplay(entityType);
  }
};
