import { gql } from '@apollo/client';
import { css } from '@emotion/react';
import Grid from '@mui/material/Grid';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailText from '@paypr/mui5-common-components/dist/components/typography/DetailText';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import DetailValue from '@paypr/mui5-common-components/dist/components/typography/DetailValue';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { LedgerAccountEventType, LedgerEventDetail_LedgerAccountEvent } from '../../generated/graphql';
import { limitLength, snakeOrKebabToDisplay } from '../../utils/strings';
import DetailGridItem from '../common/containers/DetailGridItem';
import LedgerEventDeletedNote from './LedgerEventDeletedNote';

interface LedgerEventDetailProps {
  ledgerEvent: LedgerEventDetail_LedgerAccountEvent;
}

const LedgerEventDetail = ({ ledgerEvent }: LedgerEventDetailProps) => {
  const { formatDateTime } = useDateTime();

  return (
    <>
      <DetailContainer>
        <LedgerEventDeletedNote ledgerEvent={ledgerEvent} />

        <Grid container justifyContent="left" spacing={2}>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Type</DetailTitle>
              <DetailValue>{buildLedgerAccountEventTypeDisplay(ledgerEvent.type)}</DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Value</DetailTitle>
              <DetailValue>{ledgerEvent.value}</DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Notes</DetailTitle>
              <DetailValue
                css={css`
                  white-space: pre-line;
                `}
              >
                {ledgerEvent.notes}
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Occurred on</DetailTitle>
              <DetailValue>{formatDateTime(ledgerEvent.occurredAt)}</DetailValue>
            </DetailField>
          </DetailGridItem>
        </Grid>
      </DetailContainer>
    </>
  );
};
export default LedgerEventDetail;

export interface LedgerEventNotesProps {
  notes: string | undefined | null;
}

export const LedgerEventNotes = ({ notes }: LedgerEventNotesProps) => (
  <DetailText
    css={css`
      white-space: pre-line;
    `}
  >
    {notes}
  </DetailText>
);

export const buildLedgerEventNotesSummary = (notes: string | undefined | null, len: number = 120) =>
  limitLength(
    notes
      ?.split('\n')
      ?.map((s) => s.replace(/^\s*[-–*]+/g, ''))
      ?.join('; '),
    len,
  );

export const buildLedgerAccountEventTypeDisplay = (type: LedgerAccountEventType) => snakeOrKebabToDisplay(type);

export const ledgerEventDetailFragment = gql`
  fragment LedgerEventDetail_LedgerAccountEvent on LedgerAccountEvent {
    id
    type
    value
    occurredAt
    notes
    deletedAt
  }
`;
