import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DefaultTextField from '@paypr/mui5-common-components/dist/components/forms/DefaultTextField';
import SecondaryButton from '@paypr/mui5-common-components/dist/components/forms/SecondaryButton';
import React from 'react';
import { useFormFields } from '../../hooks/forms';

export interface FavoriteReportDialogProps extends Omit<DialogProps, 'onClose'> {
  defaultReportName?: string;
  onFavoriteReport: (reportName: string) => void;
  onClose: (
    event: React.MouseEvent<HTMLButtonElement> | {},
    reason: 'backdropClick' | 'escapeKeyDown' | 'cancelClicked',
  ) => void;
}

interface FavoriteReportFields {
  reportName: string;
}

const FavoriteReportDialog = ({
  defaultReportName,
  onFavoriteReport,
  onClose,
  ...props
}: FavoriteReportDialogProps) => {
  const { fields, handleFieldChange } = useFormFields<FavoriteReportFields>({
    reportName: defaultReportName || '',
  });

  const handleFavoriteReport = () => {
    onFavoriteReport(fields.reportName);
  };

  return (
    <Dialog {...props} onClose={onClose}>
      <DialogTitle>Favorite Report</DialogTitle>
      <DialogContent>
        <DefaultTextField
          autoFocus
          name="reportName"
          label="Report Name"
          value={fields.reportName}
          required
          onChange={handleFieldChange}
        />
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={(event) => onClose(event, 'cancelClicked')}>Cancel</SecondaryButton>
        <Button onClick={handleFavoriteReport}>Favorite</Button>
      </DialogActions>
    </Dialog>
  );
};
export default FavoriteReportDialog;
