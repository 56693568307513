import { gql, NetworkStatus } from '@apollo/client';
import React, { useEffect } from 'react';
import { useDateTime } from '../../data/dates';
import { LedgerReportGroupField, useLedgerReportIntegrationQuery } from '../../generated/graphql';
import LedgerReportDisplay from './LedgerReportDisplay';
import {
  determineEndAtFromStandardTimeframe,
  determineStartAtFromStandardTimeframe,
  determineTimeFromTimeResolution,
} from './ledgerReportHelpers';
import { ledgerReportDetailsFragment, LedgerReportSettings } from './ledgerReportModel';

export interface LedgerReportIntegrationProps {
  reportSettings: LedgerReportSettings;
  onLoading?: (loading: boolean) => void;
  defaultAutoRefresh?: boolean;
}

const LedgerReportIntegration = ({ reportSettings, onLoading, defaultAutoRefresh }: LedgerReportIntegrationProps) => {
  const { now, toDateTime } = useDateTime();

  const buildReportDefinition = () => {
    const definition = { ...reportSettings.definition };
    if (definition.standardTimeframe) {
      definition.startAt = determineStartAtFromStandardTimeframe(definition.standardTimeframe, {
        now,
        toDateTime,
      });
      definition.startAt = determineTimeFromTimeResolution(definition.startAt, definition.timeResolution);
      definition.endAt = determineEndAtFromStandardTimeframe(definition.standardTimeframe, {
        now,
      });
      if (definition.endAt) {
        definition.endAt = determineTimeFromTimeResolution(definition.endAt, definition.timeResolution);
      }
    }

    return {
      startAt: definition.startAt.toISO(),
      endAt: definition.endAt?.toISO(),
      timeResolution: definition.timeResolution,
      timeZone: definition.startAt.zoneName,
      groupBy: definition.groupBy,
      groupFilters: Object.entries(definition.groupFilters || {}).map(([group, values]) => ({
        group: group as LedgerReportGroupField,
        values,
      })),
      values: definition.values,
      cryptoMarketAssets: definition.marketAssets,
      includeTotals: definition.includeTotals,
      includeAccountEvents: definition.includeAccountEvents,
      accountEventTypes: definition.accountEventTypes,
    };
  };

  const { data, networkStatus, loading, refetch } = useLedgerReportIntegrationQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      reportDefinition: buildReportDefinition(),
    },
  });

  const report = data?.ledgerReport || null;

  useEffect(() => {
    onLoading?.(loading);
  }, [loading, onLoading]);

  return (
    <LedgerReportDisplay
      report={report}
      reportSettings={reportSettings}
      onRefresh={refetch}
      loading={loading}
      refreshing={networkStatus === NetworkStatus.refetch}
      autoRefresh={defaultAutoRefresh}
    />
  );
};
export default LedgerReportIntegration;

gql`
  query LedgerReportIntegrationQuery($reportDefinition: LedgerReportDefinitionInput!) {
    ledgerReport(definition: $reportDefinition) {
      ...LedgerReportDetailsDetails
    }
  }

  ${ledgerReportDetailsFragment}
`;
