import { gql } from '@apollo/client';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import * as React from 'react';
import { PortfolioSection_Portfolio } from '../../generated/graphql';
import { AddLedgerEventClickEventHandler } from '../ledgerEvents/LedgerEventsAccordion';
import { LedgerEventClickEventHandler } from '../ledgerEvents/LedgerEventsTable';
import { OrderClickEventHandler } from '../orders/OrdersTable';
import { TradeClickEventHandler } from '../trades/TradesTable';
import { PortfolioAssetClickEventHandler } from './PortfolioAssetsTable';
import PortfolioDetail, { buildPortfolioTitle, portfolioDetailFragment } from './PortfolioDetail';
import PortfolioIcon from './PortfolioIcon';
import { SubmitPortfolioManagedCurrencyUpdateEventHandler } from './managedCurrency/UpdatePortfolioManagedCurrencyDialog';

interface PortfolioSectionProps extends RefreshingComponentProps {
  portfolio?: PortfolioSection_Portfolio;
  onClickPortfolioAsset?: PortfolioAssetClickEventHandler;
  onClickOrder?: OrderClickEventHandler;
  onClickTrade?: TradeClickEventHandler;
  onClickLedgerEvent?: LedgerEventClickEventHandler;
  onClickAddLedgerEvent?: AddLedgerEventClickEventHandler;
  onUpdatePortfolioManagedCurrency?: SubmitPortfolioManagedCurrencyUpdateEventHandler;
  updatingPortfolioManagedCurrency?: boolean;
  includeDeletedAssets?: boolean;
  onUpdateIncludeDeletedAssets?: (includeDeletedAssets: boolean) => void;
}

const PortfolioSection = ({
  portfolio,
  loading,
  refreshing,
  onRefresh,
  autoRefresh,
  onClickPortfolioAsset,
  onClickOrder,
  onClickTrade,
  onClickLedgerEvent,
  onClickAddLedgerEvent,
  onUpdatePortfolioManagedCurrency,
  updatingPortfolioManagedCurrency,
  includeDeletedAssets,
  onUpdateIncludeDeletedAssets,
}: PortfolioSectionProps) => (
  <Paper>
    <Toolbar>
      <PortfolioIcon />
      &nbsp;
      <SectionTitle>{buildPortfolioTitle(portfolio?.name)}</SectionTitle>
      <HorizontalAutoSpacer />
      <RefreshIconButton
        refreshing={refreshing}
        loading={loading}
        onRefresh={onRefresh}
        includeRefreshText
        autoRefresh={autoRefresh}
      />
    </Toolbar>

    {portfolio ? (
      <PortfolioDetail
        portfolio={portfolio}
        onClickPortfolioAsset={onClickPortfolioAsset}
        onClickOrder={onClickOrder}
        onClickTrade={onClickTrade}
        onClickLedgerEvent={onClickLedgerEvent}
        onClickAddLedgerEvent={onClickAddLedgerEvent}
        onSubmitPortfolioManagedCurrencyUpdate={onUpdatePortfolioManagedCurrency}
        submittingPortfolioManagedCurrencyUpdate={updatingPortfolioManagedCurrency}
        includeDeletedAssets={includeDeletedAssets}
        onUpdateIncludeDeletedAssets={onUpdateIncludeDeletedAssets}
        autoRefresh={autoRefresh}
      />
    ) : null}
  </Paper>
);
export default PortfolioSection;

export const portfolioSectionFragment = gql`
  fragment PortfolioSection_Portfolio on Portfolio {
    id
    name
    ...PortfolioDetail_Portfolio
  }
  ${portfolioDetailFragment}
`;
