import { gql } from '@apollo/client';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { FinanceStatsDetail_Stats } from '../../generated/graphql';
import FloatFormat from '../common/typography/FloatFormat';
import PercentFormat from '../common/typography/PercentFormat';

interface FinanceStatsDetailProps {
  stats: FinanceStatsDetail_Stats;
}

const FinanceStatsDetail = ({ stats }: FinanceStatsDetailProps) => {
  if (
    stats.beta.allTime === null &&
    stats.beta.yearToDate === null &&
    stats.maxDrawdown.allTime === null &&
    stats.maxDrawdown.yearToDate === null &&
    stats.sharpeRatio.allTime === null &&
    stats.sharpeRatio.yearToDate === null
  ) {
    return null;
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <th align="left">Stats</th>
          <th>All Time</th>
          <th>Year-to-Date</th>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <th align="left">Sharpe Ratio</th>
          <td align="right">
            {stats.sharpeRatio.allTime !== null ? (
              <FloatFormat amount={stats.sharpeRatio.allTime} decimalScale={2} />
            ) : (
              'n/a'
            )}
          </td>
          <td align="right">
            {stats.sharpeRatio.yearToDate !== null ? (
              <FloatFormat amount={stats.sharpeRatio.yearToDate} decimalScale={2} />
            ) : (
              'n/a'
            )}
          </td>
        </TableRow>
        <TableRow>
          <th align="left">Beta</th>
          <td align="right">
            {stats.beta.allTime !== null ? <FloatFormat amount={stats.beta.allTime} decimalScale={2} /> : 'n/a'}
          </td>
          <td align="right">
            {stats.beta.yearToDate !== null ? <FloatFormat amount={stats.beta.yearToDate} decimalScale={2} /> : 'n/a'}
          </td>
        </TableRow>
        <TableRow>
          <th align="left">Max Drawdown</th>
          <td align="right">
            {stats.maxDrawdown.allTime !== null ? <PercentFormat amount={stats.maxDrawdown.allTime} /> : 'n/a'}
          </td>
          <td align="right">
            {stats.maxDrawdown.yearToDate !== null ? <PercentFormat amount={stats.maxDrawdown.yearToDate} /> : 'n/a'}
          </td>
        </TableRow>
      </TableBody>
    </Table>
  );
};
export default FinanceStatsDetail;

export const financeStatsDetailFragment = gql`
  fragment FinanceStatsDetail_Stats on FinanceStats {
    beta {
      allTime
      yearToDate
    }
    maxDrawdown {
      allTime
      yearToDate
    }
    sharpeRatio {
      allTime
      yearToDate
    }
  }
`;
