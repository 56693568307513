import { gql, NetworkStatus } from '@apollo/client';
import React from 'react';
import { useStrategyLedgerEventsSectionQueryLazyQuery } from '../../generated/graphql';
import ContentError from '../common/content/ContentError';
import { ExplodingComponentProps } from '../common/icons/ExplodeIconButton';
import LedgerEventsAccordion, { AddLedgerEventClickEventHandler } from '../ledgerEvents/LedgerEventsAccordion';
import { LedgerEventClickEventHandler, ledgerEventsTableRowFragment } from '../ledgerEvents/LedgerEventsTable';

export interface StrategyLedgerEventsSectionIntegrationProps extends ExplodingComponentProps {
  strategyId: string;
  onClickLedgerEvent?: LedgerEventClickEventHandler;
  onClickAddLedgerEvent?: AddLedgerEventClickEventHandler;
  defaultExpanded?: boolean;
  autoRefresh?: boolean | number;
}

const StrategyLedgerEventsSectionIntegration = ({
  strategyId,
  onClickLedgerEvent,
  onClickAddLedgerEvent,
  defaultExpanded,
  autoRefresh,
  exploded,
  onExplodeChanged,
}: StrategyLedgerEventsSectionIntegrationProps) => {
  const [fetchStrategyLedgerEvents, { data, error, refetch, networkStatus, loading }] =
    useStrategyLedgerEventsSectionQueryLazyQuery({
      fetchPolicy: 'cache-and-network',
      variables: { strategyId },
    });

  if (error) {
    return <ContentError message="Error loading strategy events" error={error} />;
  }

  const strategy = data?.strategy;

  const handleRefresh = async () => {
    if (strategy) {
      await refetch();
    } else {
      await fetchStrategyLedgerEvents();
    }
  };

  return (
    <LedgerEventsAccordion
      ledgerEvents={strategy?.ledgerEvents}
      onClickLedgerEvent={onClickLedgerEvent}
      onClickAddLedgerEvent={onClickAddLedgerEvent}
      defaultExpanded={defaultExpanded}
      exploded={exploded}
      onExplodeChanged={onExplodeChanged}
      refreshing={networkStatus === NetworkStatus.refetch}
      loading={loading}
      onRefresh={handleRefresh}
      autoRefresh={autoRefresh}
    />
  );
};
export default StrategyLedgerEventsSectionIntegration;

gql`
  query StrategyLedgerEventsSectionQuery($strategyId: ID!) {
    strategy(id: $strategyId) {
      id

      ledgerEvents {
        ...LedgerEventsTableRow_LedgerAccountEvent
      }
    }
  }

  ${ledgerEventsTableRowFragment}
`;
