import { ChangeEvent, useState } from 'react';

export type FormFieldTextChangeEvent = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;

export interface FormFieldsHook<S> {
  fields: S;
  setFieldValue: FormFieldValueSetter<S>;
  handleFieldChange: FormFieldChangeEventHandler;
  resetFields: FormFieldsResetter<S>;
}

export type FormFieldValueSetter<S> = <K extends keyof S>(name: K, value: S[K]) => void;

export type FormFieldChangeEventHandler = (event: FormFieldTextChangeEvent) => FormFieldsHookFieldChangeResult;

export type FormFieldsResetter<S> = (newState?: S) => void;

export interface FormFieldsHookFieldChangeResult {
  name: string;
  file?: File;
  value: string;
}

export const useFormFields = <S>(initialState: S): FormFieldsHook<S> => {
  const [fields, setValues] = useState<S>(initialState);

  const setFieldValue = (name: keyof S, value: any) => {
    setValues((currentFields) => ({
      ...currentFields,
      [name]: value,
    }));
  };

  const handleFieldChange = (event: FormFieldTextChangeEvent) => {
    const { name, value } = event.target;
    const file =
      'files' in event.target && event.target.files && event.target.files.length > 0
        ? event.target.files[0]
        : undefined;

    setFieldValue(name as keyof S, file || value);
    return { name, file, value };
  };

  const resetFields = (newState?: S) => {
    setValues(newState || initialState);
  };

  return { fields, handleFieldChange, setFieldValue, resetFields };
};
