import { gql } from '@apollo/client';
import NoteBlock from '@paypr/mui5-common-components/dist/components/typography/NoteBlock';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { FundDeletedNote_Fund } from '../../generated/graphql';

interface FundDeletedNoteProps {
  fund: FundDeletedNote_Fund;
}

const FundDeletedNote = ({ fund: { deletedAt } }: FundDeletedNoteProps) => {
  const { formatDateTime } = useDateTime();

  if (!deletedAt) {
    return null;
  }

  return (
    <NoteBlock>
      <em>This fund was deleted on {formatDateTime(deletedAt)}</em>
    </NoteBlock>
  );
};
export default FundDeletedNote;

export const fundDeletedNoteFragment = gql`
  fragment FundDeletedNote_Fund on Fund {
    deletedAt
  }
`;
