import { gql, NetworkStatus } from '@apollo/client';
import React from 'react';
import { buildAuditActionTitle } from '../../components/auditActions/AuditActionDetail';
import { AuditActionPageParams } from '../../components/auditActions/auditActionPaths';
import AuditActionSection, { auditActionSectionFragment } from '../../components/auditActions/AuditActionSection';
import AppPage from '../../components/common/page/AppPage';
import AppPageError from '../../components/common/page/AppPageError';
import AppPageMessage from '../../components/common/page/AppPageMessage';
import WiderPageContainer from '../../components/common/page/WiderPageContainer';
import { useAuditActionPageQuery } from '../../generated/graphql';
import { usePageParams, usePageTitle } from '../../hooks/page';

const AuditActionPage = () => {
  const { auditActionId } = usePageParams<keyof AuditActionPageParams>();

  const { data, error, refetch, networkStatus, loading } = useAuditActionPageQuery({
    fetchPolicy: 'cache-and-network',
    variables: { auditActionId },
  });

  usePageTitle(`Activity - ${buildAuditActionTitle(data?.auditAction?.subsystem?.label, data?.auditAction?.type)}`);

  if (error) {
    return <AppPageError error={error} />;
  }

  const auditAction = data?.auditAction;

  if (auditAction === null) {
    return <AppPageMessage text={`Activity ${auditActionId} does not exist`} />;
  }

  return (
    <AppPage>
      <WiderPageContainer>
        <AuditActionSection
          auditAction={auditAction}
          loading={loading}
          refreshing={networkStatus === NetworkStatus.refetch}
          onRefresh={() => refetch()}
        />
      </WiderPageContainer>
    </AppPage>
  );
};
export default AuditActionPage;

gql`
  query AuditActionPageQuery($auditActionId: ID!) {
    auditAction(id: $auditActionId) {
      id
      subsystem {
        id
        label
      }
      type
      ...AuditActionSection_AuditAction
    }
  }

  ${auditActionSectionFragment}
`;
