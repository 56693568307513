import { gql, NetworkStatus } from '@apollo/client';
import React from 'react';
import AppPage from '../../components/common/page/AppPage';
import AppPageError from '../../components/common/page/AppPageError';
import WiderPageContainer from '../../components/common/page/WiderPageContainer';
import { useQuery } from '../../components/common/routes/paths';
import {
  addSubsystemPath,
  buildSubsystemPath,
  SubsystemsPageQueryParams,
} from '../../components/subsystems/subsystemPaths';
import SubsystemsSection from '../../components/subsystems/SubsystemsSection';
import { subsystemsTableRowFragment } from '../../components/subsystems/SubsystemsTable';
import { useSubsystemsPageQuery } from '../../generated/graphql';
import { usePageTitle } from '../../hooks/page';
import { usePaths } from '../../hooks/paths';

const SubsystemPage = () => {
  usePageTitle('Subsystems');
  const { navigateTo } = usePaths();

  const { params, updateParam } = useQuery<SubsystemsPageQueryParams>();

  const includeDeletedFlag = params.get('includeDeleted');
  const includeDeleted = includeDeletedFlag === 'true';

  const { data, error, refetch, networkStatus, loading } = useSubsystemsPageQuery({
    fetchPolicy: 'cache-and-network',
    variables: { includeDeleted: includeDeleted || false },
  });

  if (error) {
    return <AppPageError error={error} />;
  }

  const handleAddSubsystem = () => {
    navigateTo(addSubsystemPath);
  };

  const handleSubsystemClicked = (subsystemId: string, newTab: boolean) => {
    navigateTo(buildSubsystemPath(subsystemId), newTab);
  };

  const handleIncludeDeletedSubsystemsChanged = (includeDeletedSubsystems: boolean) => {
    updateParam('includeDeleted', includeDeletedSubsystems ? 'true' : 'false');
  };

  return (
    <AppPage>
      <WiderPageContainer>
        <SubsystemsSection
          subsystems={data?.subsystems}
          onAddSubsystem={handleAddSubsystem}
          onClickSubsystem={handleSubsystemClicked}
          includeDeletedSubsystems={includeDeleted}
          onUpdateIncludeDeletedSubsystems={
            includeDeletedFlag !== null ? handleIncludeDeletedSubsystemsChanged : undefined
          }
          loading={loading}
          refreshing={networkStatus === NetworkStatus.refetch}
          onRefresh={() => refetch()}
          autoRefresh
        />
      </WiderPageContainer>
    </AppPage>
  );
};
export default SubsystemPage;

gql`
  query SubsystemsPageQuery($includeDeleted: Boolean!) {
    subsystems(includeDeleted: $includeDeleted) {
      id
      ...SubsystemsTableRow_Subsystem
    }
  }

  ${subsystemsTableRowFragment}
`;
