import { gql, NetworkStatus } from '@apollo/client';
import { useSnackbar } from 'notistack';
import React from 'react';
import {
  DshAllocationsUpdateInput,
  useDshStrategyAllocationsIntegrationQuery,
  useDshStrategyAllocationsIntegrationUpdateAllocationsMutation,
} from '../../../generated/graphql';
import { useErrors } from '../../../hooks/errors';
import { dshStrategyAllocationsDetailDshVersionDetailsFragment } from './DshStrategyAllocationsDetail';
import DshStrategyAllocationsSection from './DshStrategyAllocationsSection';

export interface DshStrategyAllocationsIntegrationProps {
  version: string;
}

const DshStrategyAllocationsIntegration = ({ version }: DshStrategyAllocationsIntegrationProps) => {
  const { data, refetch, networkStatus, loading } = useDshStrategyAllocationsIntegrationQuery({
    fetchPolicy: 'cache-and-network',
    variables: { version },
  });

  const { enqueueSnackbar } = useSnackbar();
  const { showError, clearError } = useErrors();

  const [updateDshAllocations, { loading: updating }] = useDshStrategyAllocationsIntegrationUpdateAllocationsMutation();

  const handleUpdateDshAllocations = async (newDshAllocations: DshAllocationsUpdateInput) => {
    clearError();

    try {
      await updateDshAllocations({
        variables: {
          input: newDshAllocations,
        },
      });
      enqueueSnackbar('Allocations updated successfully.', { variant: 'success' });
    } catch (e) {
      showError(e);
    }
  };

  return (
    <DshStrategyAllocationsSection
      version={version}
      dshVersion={data?.dshVersion}
      onRefresh={refetch}
      onSubmitDshAllocations={handleUpdateDshAllocations}
      submittingDshAllocations={updating}
      loading={loading || updating}
      refreshing={networkStatus === NetworkStatus.refetch}
    />
  );
};
export default DshStrategyAllocationsIntegration;

gql`
  query DshStrategyAllocationsIntegrationQuery($version: ID!) {
    dshVersion(id: $version) {
      id
      ...DshStrategyAllocationsSectionDshVersionDetails
    }
  }

  mutation DshStrategyAllocationsIntegrationUpdateAllocationsMutation($input: DshAllocationsUpdateInput!) {
    updateDshAllocations(input: $input)
  }

  ${dshStrategyAllocationsDetailDshVersionDetailsFragment}
`;
