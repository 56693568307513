import * as React from 'react';
import AppPage from '../../components/common/page/AppPage';
import WidePageContainer from '../../components/common/page/WidePageContainer';
import { DshStrategyAllocationsPageParams } from '../../components/dsh/dshPaths';
import DshStrategyAllocationsIntegration from '../../components/dsh/allocations/DshStrategyAllocationsIntegration';
import { useDshStrategyVersionPageTitle } from '../../components/dsh/DshStrategyVersionTitle';
import { usePageParams } from '../../hooks/page';

const DshStrategyAllocationsPage = () => {
  const { version } = usePageParams<keyof DshStrategyAllocationsPageParams>();

  useDshStrategyVersionPageTitle(version, 'Allocations');

  return (
    <AppPage>
      <WidePageContainer>
        <DshStrategyAllocationsIntegration version={version} />
      </WidePageContainer>
    </AppPage>
  );
};
export default DshStrategyAllocationsPage;
