import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import TooltipIconButton from '@paypr/mui5-common-components/dist/components/actions/TooltipIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import React from 'react';
import { InternalUsersTableRow_InternalUser } from '../../generated/graphql';
import AddIcon from '../common/icons/AddIcon';
import NoDataError from '../common/page/NoDataError';
import InternalUsersTable, { InternalUserClickEventHandler } from './InternalUsersTable';

export interface InternalUsersSectionProps extends RefreshingComponentProps {
  internalUsers?: readonly InternalUsersTableRow_InternalUser[];
  onClickInternalUser?: InternalUserClickEventHandler;
  onClickAddInternalUser?: (newTab: boolean) => void;
  includeDeletedInternalUsers?: boolean;
  onUpdateIncludeDeletedInternalUsers?: (includeDeletedInternalUsers: boolean) => void;
}

const InternalUsersSection = ({
  internalUsers,
  loading,
  refreshing,
  onRefresh,
  autoRefresh,
  onClickInternalUser,
  onClickAddInternalUser,
  includeDeletedInternalUsers,
  onUpdateIncludeDeletedInternalUsers,
}: InternalUsersSectionProps) => {
  const handleAddInternalUser = (event) => {
    if (!onClickAddInternalUser) {
      return;
    }

    event.stopPropagation();
    onClickAddInternalUser(event.ctrlKey || event.metaKey);
  };

  return (
    <Paper>
      <Toolbar>
        <SectionTitle>Admin Users</SectionTitle>
        <HorizontalAutoSpacer />
        {onClickAddInternalUser && (
          <TooltipIconButton title="Add Admin User" onClick={handleAddInternalUser} disabled={refreshing || loading}>
            <AddIcon />
          </TooltipIconButton>
        )}
        <RefreshIconButton
          refreshing={refreshing}
          loading={loading}
          onRefresh={onRefresh}
          autoRefresh={autoRefresh}
          includeRefreshText
        />
      </Toolbar>
      {onUpdateIncludeDeletedInternalUsers ? (
        <DetailContainer>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={includeDeletedInternalUsers}
                onChange={(_, value) => onUpdateIncludeDeletedInternalUsers(value)}
              />
            }
            label="Include Deleted"
          />
        </DetailContainer>
      ) : null}

      {internalUsers ? (
        <InternalUsersTable internalUsers={internalUsers} onClickInternalUserRow={onClickInternalUser} />
      ) : !(loading || refreshing) ? (
        <NoDataError />
      ) : null}
    </Paper>
  );
};
export default InternalUsersSection;
