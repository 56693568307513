import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailInlineTitle from '@paypr/mui5-common-components/dist/components/typography/DetailInlineTitle';
import DetailInlineValue from '@paypr/mui5-common-components/dist/components/typography/DetailInlineValue';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import React from 'react';
import { useThemeHelpers } from '../../../style/theme';
import DetailGridItem from '../../common/containers/DetailGridItem';
import PercentFormat from '../../common/typography/PercentFormat';
import { UpdateClientFundDetails } from './UpdateClientFundEditor';

export interface UpdateClientFundCurrencyChangesProps {
  oldValues: UpdateClientFundDetails;
  newValues: UpdateClientFundDetails;
  fundManagementFeePercent: number;
  fundPerformanceFeePercent: number;
}

const UpdateClientFundChanges = ({
  oldValues,
  newValues,
  fundManagementFeePercent,
  fundPerformanceFeePercent,
}: UpdateClientFundCurrencyChangesProps) => {
  const { buildChangeCss } = useThemeHelpers();

  return (
    <DetailContainer>
      <Grid container justifyContent="left" spacing={2}>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Management Fee %</DetailTitle>
            <Box>
              <DetailInlineTitle>Fund</DetailInlineTitle>
              <DetailInlineValue>
                <PercentFormat amount={fundManagementFeePercent} />
              </DetailInlineValue>
            </Box>
            <Box>
              <DetailInlineTitle>Old</DetailInlineTitle>
              <DetailInlineValue>
                <PercentFormat amount={oldValues.managementFeePercent} />
              </DetailInlineValue>
            </Box>
            <Box>
              <DetailInlineTitle>New</DetailInlineTitle>
              <DetailInlineValue css={buildChangeCss(oldValues.managementFeePercent, newValues.managementFeePercent)}>
                <PercentFormat amount={newValues.managementFeePercent} />
              </DetailInlineValue>
            </Box>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Performance Fee %</DetailTitle>
            <Box>
              <DetailInlineTitle>Fund</DetailInlineTitle>
              <DetailInlineValue>
                <PercentFormat amount={fundPerformanceFeePercent} />
              </DetailInlineValue>
            </Box>
            <Box>
              <DetailInlineTitle>Old</DetailInlineTitle>
              <DetailInlineValue>
                <PercentFormat amount={oldValues.performanceFeePercent} />
              </DetailInlineValue>
            </Box>
            <Box>
              <DetailInlineTitle>New</DetailInlineTitle>
              <DetailInlineValue css={buildChangeCss(oldValues.performanceFeePercent, newValues.performanceFeePercent)}>
                <PercentFormat amount={newValues.performanceFeePercent} />
              </DetailInlineValue>
            </Box>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>NAV ID</DetailTitle>
            <Box>
              <DetailInlineTitle>Current</DetailInlineTitle>
              <DetailInlineValue>{oldValues.navId}</DetailInlineValue>
            </Box>
            <Box>
              <DetailInlineTitle>New</DetailInlineTitle>
              <DetailInlineValue css={buildChangeCss(oldValues.navId, newValues.navId)}>
                {newValues.navId}
              </DetailInlineValue>
            </Box>
          </DetailField>
        </DetailGridItem>
      </Grid>
    </DetailContainer>
  );
};
export default UpdateClientFundChanges;
