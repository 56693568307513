import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTimeZone } from '../../data/dates';
import { getDshAssetReportState } from '../../data/reports';
import { fetchDshAssetReport } from '../../services/dshReports';
import DshTimeSeriesReport from './reports/DshTimeSeriesReport';

export interface DshAssetReportIntegrationProps {
  version: string;
  reportName: string;
  asset: string;
}

const DshAssetReportIntegration = ({ version, reportName, asset }: DshAssetReportIntegrationProps) => {
  const [{ report }, setDshAssetReportState] = useRecoilState(getDshAssetReportState(version, reportName, asset));
  const [loading, setLoading] = useState<boolean>(false);
  const timeZone = useTimeZone();

  useEffect(() => {
    if (report) {
      return;
    }

    // noinspection JSIgnoredPromiseFromCall
    loadReportData();
  }, [report, reportName]);

  const loadReportData = async () => {
    try {
      setLoading(true);
      const report = await fetchDshAssetReport(version, reportName, asset, timeZone);
      setDshAssetReportState((currentState) => ({ ...currentState, report }));
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DshTimeSeriesReport
      report={report}
      onRefresh={loadReportData}
      loading={loading}
      refreshing={Boolean(loading && report)}
    />
  );
};
export default DshAssetReportIntegration;
