import FullPage from '@paypr/mui5-common-components/dist/components/containers/FullPage';
import PageContent from '@paypr/mui5-common-components/dist/components/page/PageContent';
import React from 'react';
import PageBar from './bar/PageBar';
import AppNav from './nav/AppNav';
import PageFooter from './PageFooter';

interface AppPageProps {
  children?: React.ReactNode;
}

const AppPage = ({ children }: AppPageProps) => (
  <FullPage>
    <PageBar />
    <AppNav />
    <PageContent>
      {children}
      <PageFooter />
    </PageContent>
  </FullPage>
);
export default AppPage;
