import { gql } from '@apollo/client';
import Grid from '@mui/material/Grid';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import DetailValue from '@paypr/mui5-common-components/dist/components/typography/DetailValue';
import * as React from 'react';
import { FundDetail_Fund } from '../../generated/graphql';
import ClientFundsSection from '../clientFunds/ClientFundsSection';
import { ClientFundClickEventHandler, clientFundsTableRowFragment } from '../clientFunds/ClientFundsTable';
import { ClientTransactionClickEventHandler } from '../clients/transactions/ClientTransactionsTable';
import TransferNoticesSection from '../clients/transfers/TransferNoticesSection';
import {
  TransferNoticeClickEventHandler,
  transferNoticesTableRowFragment,
} from '../clients/transfers/TransferNoticesTable';
import DetailGridItem from '../common/containers/DetailGridItem';
import PercentFormat from '../common/typography/PercentFormat';
import UsdFormat from '../common/typography/UsdFormat';
import PortfoliosSection from '../portfolios/PortfoliosSection';
import { PortfolioClickEventHandler, portfoliosTableRowFragment } from '../portfolios/PortfoliosTable';
import FinanceStatsDetail, { financeStatsDetailFragment } from '../stats/FinanceStatsDetail';
import FundClientTransactionsSectionIntegration from './FundClientTransactionsSectionIntegration';
import FundDeletedNote, { fundDeletedNoteFragment } from './FundDeletedNote';
import FundReport from './FundReport';

export type FundDetailData = FundDetail_Fund;

interface FundDetailProps {
  fund: FundDetailData;
  onClickPortfolio?: PortfolioClickEventHandler;
  onClickClientFund?: ClientFundClickEventHandler;
  onClickClientTransaction?: ClientTransactionClickEventHandler;
  onClickTransferNotice?: TransferNoticeClickEventHandler;
}

const FundDetail = ({
  fund,
  onClickPortfolio,
  onClickClientFund,
  onClickTransferNotice,
  onClickClientTransaction,
}: FundDetailProps) => (
  <>
    <DetailContainer>
      <FundDeletedNote fund={fund} />
      <Grid container justifyContent="left" spacing={2}>
        <DetailGridItem size="full">
          <DetailField>
            <DetailValue>{fund.description}</DetailValue>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Legal Name</DetailTitle>
            <DetailValue>{fund.legalName}</DetailValue>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Management Fee %</DetailTitle>
            <DetailValue>
              <PercentFormat amount={fund.managementFeePercent} />
            </DetailValue>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Performance Fee %</DetailTitle>
            <DetailValue>
              <PercentFormat amount={fund.performanceFeePercent} />
            </DetailValue>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem size="full">
          <DetailField>
            <DetailTitle>Performance</DetailTitle>
            <FundReport fundId={fund.id} />
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Allocated Funds</DetailTitle>
            <DetailValue>
              <UsdFormat amount={fund.stats.allocatedFunds} />
            </DetailValue>
            <DetailValue variant="body2">
              <UsdFormat amount={fund.stats.unallocatedFunds} /> unallocated
            </DetailValue>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Deposits & Withdrawals</DetailTitle>
            <DetailValue>
              <UsdFormat amount={fund.stats.clientDeposits} />
              <br />
              <UsdFormat amount={fund.stats.clientWithdrawals} />
            </DetailValue>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Investment Basis</DetailTitle>
            <DetailValue>
              <UsdFormat amount={fund.stats.clientInvestment} />
            </DetailValue>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Current Value</DetailTitle>
            <DetailValue>
              <UsdFormat amount={fund.stats.currentValue} />
            </DetailValue>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>PnL</DetailTitle>
            <DetailValue>
              <UsdFormat amount={fund.stats.pnlCumulativeValue} />
              <br />
              <PercentFormat amount={fund.stats.pnlCumulativeRoi} />
            </DetailValue>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Realized Gains/Losses</DetailTitle>
            <DetailValue>
              <UsdFormat amount={fund.stats.realizedGainsCumulativeValue} />
              <br />
              <PercentFormat amount={fund.stats.realizedGainsCumulativeRoi} />
            </DetailValue>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Unrealized Gains/Losses</DetailTitle>
            <DetailValue>
              <UsdFormat amount={fund.stats.unrealizedGainsCumulativeValue} />
              <br />
              <PercentFormat amount={fund.stats.unrealizedGainsCumulativeRoi} />
            </DetailValue>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem size="double">
          <FinanceStatsDetail stats={fund.stats} />
        </DetailGridItem>
      </Grid>
    </DetailContainer>
    <br />
    <TransferNoticesSection
      title="Unprocessed Transfers"
      transferNotices={fund?.transferNotices}
      hideFund
      onClickTransferNotice={onClickTransferNotice}
    />
    <br />
    <PortfoliosSection portfolios={fund?.portfolios} onClickPortfolio={onClickPortfolio} hideFund />
    <br />
    <ClientFundsSection clientFunds={fund?.clients} hideFund onClickClientFund={onClickClientFund} />
    <br />
    <FundClientTransactionsSectionIntegration fundId={fund?.id} onClickClientTransaction={onClickClientTransaction} />
  </>
);
export default FundDetail;

export const fundDetailFragment = gql`
  fragment FundDetail_Fund on Fund {
    id
    name
    legalName
    managementFeePercent
    performanceFeePercent
    description

    stats {
      clientDeposits
      clientWithdrawals
      clientInvestment
      allocatedFunds
      unallocatedFunds
      currentValue
      pnlCumulativeRoi
      pnlCumulativeValue
      realizedGainsCumulativeRoi
      realizedGainsCumulativeValue
      unrealizedGainsCumulativeRoi
      unrealizedGainsCumulativeValue
      ...FinanceStatsDetail_Stats
    }

    transferNotices(processed: false) {
      id
      ...TransferNoticesTableRow_TransferNotice
    }

    portfolios {
      id
      ...PortfoliosTableRow_Portfolio
    }

    clients {
      id
      ...ClientFundsTableRow_ClientFund
    }

    createdAt

    ...FundDeletedNote_Fund
  }

  ${fundDeletedNoteFragment}
  ${financeStatsDetailFragment}
  ${transferNoticesTableRowFragment}
  ${portfoliosTableRowFragment}
  ${clientFundsTableRowFragment}
`;
