import { gql, NetworkStatus } from '@apollo/client';
import React from 'react';
import { useInternalUserAuditActionsSectionQueryLazyQuery } from '../../generated/graphql';
import ContentError from '../common/content/ContentError';
import { ExplodingComponentProps } from '../common/icons/ExplodeIconButton';
import AuditActionsAccordion from '../auditActions/AuditActionsAccordion';
import { AuditActionClickEventHandler } from '../auditActions/AuditActionsTable';

export interface InternalUserAuditActionsSectionIntegrationProps extends ExplodingComponentProps {
  internalUserId: string;
  onClickAuditAction?: AuditActionClickEventHandler;
  defaultExpanded?: boolean;
  autoRefresh?: boolean | number;
}

const InternalUserAuditActionsSectionIntegration = ({
  internalUserId,
  onClickAuditAction,
  defaultExpanded,
  autoRefresh,
  exploded,
  onExplodeChanged,
}: InternalUserAuditActionsSectionIntegrationProps) => {
  const [fetchInternalUserAuditActions, { data, error, refetch, networkStatus, loading }] =
    useInternalUserAuditActionsSectionQueryLazyQuery({
      fetchPolicy: 'cache-and-network',
      variables: { internalUserId },
    });

  if (error) {
    return <ContentError message="Error loading internalUser activity" error={error} />;
  }

  const internalUser = data?.internalUser;

  const handleRefresh = async () => {
    if (internalUser) {
      await refetch();
    } else {
      await fetchInternalUserAuditActions();
    }
  };

  return (
    <AuditActionsAccordion
      auditActions={internalUser?.actions}
      onClickAuditAction={onClickAuditAction}
      defaultExpanded={defaultExpanded}
      exploded={exploded}
      onExplodeChanged={onExplodeChanged}
      refreshing={networkStatus === NetworkStatus.refetch}
      loading={loading}
      onRefresh={handleRefresh}
      autoRefresh={autoRefresh}
    />
  );
};
export default InternalUserAuditActionsSectionIntegration;

gql`
  query InternalUserAuditActionsSectionQuery($internalUserId: ID!) {
    internalUser(id: $internalUserId) {
      id

      actions {
        ...AuditActionsTableData_AuditActionConnection
      }
    }
  }
`;
