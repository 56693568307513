import { gql, NetworkStatus } from '@apollo/client';
import React from 'react';
import { buildAuditActionPath } from '../../components/auditActions/auditActionPaths';
import { buildSubsystemTitle } from '../../components/subsystems/SubsystemDetail';
import { SubsystemPageParams } from '../../components/subsystems/subsystemPaths';
import SubsystemSection, { subsystemSectionFragment } from '../../components/subsystems/SubsystemSection';
import AppPage from '../../components/common/page/AppPage';
import AppPageError from '../../components/common/page/AppPageError';
import AppPageMessage from '../../components/common/page/AppPageMessage';
import WiderPageContainer from '../../components/common/page/WiderPageContainer';
import { useSubsystemPageQuery } from '../../generated/graphql';
import { usePageParams, usePageTitle } from '../../hooks/page';
import { usePaths } from '../../hooks/paths';

const SubsystemPage = () => {
  const { subsystemId } = usePageParams<keyof SubsystemPageParams>();
  const { navigateTo } = usePaths();

  const { data, error, refetch, networkStatus, loading } = useSubsystemPageQuery({
    fetchPolicy: 'cache-and-network',
    variables: { subsystemId },
  });

  usePageTitle(`Subsystem - ${buildSubsystemTitle(data?.subsystem?.label)}`);

  if (error) {
    return <AppPageError error={error} />;
  }

  const subsystem = data?.subsystem;

  if (subsystem === null) {
    return <AppPageMessage text={`Subsystem ${subsystemId} does not exist`} />;
  }

  const handleAuditActionClicked = (auditActionId: string, newTab: boolean) => {
    navigateTo(buildAuditActionPath(auditActionId), newTab);
  };

  return (
    <AppPage>
      <WiderPageContainer>
        <SubsystemSection
          subsystem={subsystem}
          onClickAuditAction={handleAuditActionClicked}
          loading={loading}
          refreshing={networkStatus === NetworkStatus.refetch}
          onRefresh={() => refetch()}
          autoRefresh
        />
      </WiderPageContainer>
    </AppPage>
  );
};
export default SubsystemPage;

gql`
  query SubsystemPageQuery($subsystemId: ID!) {
    subsystem(id: $subsystemId) {
      id
      label
      ...SubsystemSection_Subsystem
    }
  }

  ${subsystemSectionFragment}
`;
