import { gql } from '@apollo/client';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import AlternatingTableRow from '@paypr/mui5-common-components/dist/components/tables/AlternatingTableRow';
import EmptyTableRow from '@paypr/mui5-common-components/dist/components/tables/EmptyTableRow';
import {
  SimpleSortingTableHeadCell,
  SimpleSortingTableHeadProps,
} from '@paypr/mui5-common-components/dist/components/tables/SortingTableHead';
import { getComparator, stableSort, useSorting } from '@paypr/mui5-common-components/dist/components/tables/sortUtils';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { ApprovalsTableData_ApprovalConnection, ApprovalsTableRow_Approval } from '../../generated/graphql';
import HeightLimitedTableContainer from '../common/tables/HeightLimitedTableContainer';
import SimpleSortingTableHead from '../common/tables/SortingTableHead';
import { buildWorkflowReferenceTitle } from '../workflows/workflowUtils';
import { buildApprovalStatusDisplay, buildApprovalTypeDisplay } from './approvalUtils';

interface ApprovalsTableProps {
  approvalConnection?: ApprovalsTableData_ApprovalConnection;
  approvals?: readonly ApprovalsTableRow_Approval[];
  onClickApprovalRow?: ApprovalClickEventHandler;
  fullHeight?: boolean;
  sortByApprover?: boolean;
}

export type ApprovalClickEventHandler = (approvalId: string, newTab: boolean) => void;

export interface ApprovalsTableRowData extends ApprovalsTableRow_Approval {
  workflowTitle: string;
  userFullName: string;
}

const ApprovalsTable = ({
  approvals,
  approvalConnection,
  onClickApprovalRow,
  fullHeight,
  sortByApprover,
}: ApprovalsTableProps) => {
  const { order, orderBy, handleRequestSort } = useSorting<ApprovalsTableRowData>(
    sortByApprover ? 'userFullName' : 'createdAt',
    sortByApprover ? 'asc' : 'desc',
  );

  const createClickHandler = (approvalId: string) => {
    if (!onClickApprovalRow) {
      return undefined;
    }
    return (event) => onClickApprovalRow(approvalId, event.ctrlKey || event.metaKey);
  };

  const approvalList: ApprovalsTableRowData[] = buildApprovals({ approvalConnection, approvals });

  return (
    <HeightLimitedTableContainer fullHeight={fullHeight}>
      <Table stickyHeader>
        <ApprovalsTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
        <TableBody>
          {approvalList.length > 0 ? (
            stableSort(approvalList, getComparator(order, orderBy)).map((approval) => (
              <ApprovalTableRow key={approval.id} approval={approval} onRowClick={createClickHandler(approval.id)} />
            ))
          ) : (
            <EmptyTableRow columnCount={columnHeadings.length}>No approvals were found.</EmptyTableRow>
          )}
        </TableBody>
      </Table>
    </HeightLimitedTableContainer>
  );
};
export default ApprovalsTable;

export interface BuildApprovalsOptions {
  approvalConnection?: ApprovalsTableData_ApprovalConnection;
  approvals?: readonly ApprovalsTableRow_Approval[];
}

export const buildApprovals = ({ approvalConnection, approvals }: BuildApprovalsOptions) =>
  approvalConnection
    ? approvalConnection.edges.map(({ node }) => node).map(buildApprovalRowData)
    : approvals
    ? approvals.map(buildApprovalRowData)
    : [];

const buildApprovalRowData = (approval: ApprovalsTableRow_Approval) => ({
  ...approval,
  workflowTitle: buildWorkflowReferenceTitle(approval.workflow),
  userFullName: approval.user.fullName,
});

type ApprovalsTableHeadProps = Omit<SimpleSortingTableHeadProps<ApprovalsTableRowData>, 'headings'>;

const columnHeadings: SimpleSortingTableHeadCell<ApprovalsTableRowData>[] = [
  { key: 'workflowTitle', label: 'Workflow' },
  { key: 'userFullName', label: 'Approver' },
  { key: 'type', label: 'Type' },
  { key: 'status', label: 'Status' },
  { key: 'updatedAt', label: 'Last Update', align: 'right' },
  { key: 'createdAt', label: 'Sent', align: 'right' },
];

const ApprovalsTableHead = (props: ApprovalsTableHeadProps) => (
  <SimpleSortingTableHead {...props} headings={columnHeadings} />
);

type ApprovalTableRowProps = {
  approval: ApprovalsTableRowData;
  onRowClick?: React.MouseEventHandler;
};

export const ApprovalTableRow = ({ approval, onRowClick }: ApprovalTableRowProps) => {
  const { formatShortDateTime } = useDateTime();

  return (
    <AlternatingTableRow hover={Boolean(onRowClick)} onClick={onRowClick}>
      <TableCell>{approval.workflowTitle}</TableCell>
      <TableCell>{approval.userFullName}</TableCell>
      <TableCell>{buildApprovalTypeDisplay(approval.type)} </TableCell>
      <TableCell>{buildApprovalStatusDisplay(approval.status)} </TableCell>
      <TableCell align="right">{formatShortDateTime(approval.updatedAt)}</TableCell>
      <TableCell align="right">{formatShortDateTime(approval.createdAt)}</TableCell>
    </AlternatingTableRow>
  );
};

export const approvalsTableRowFragment = gql`
  fragment ApprovalsTableData_ApprovalConnection on ApprovalConnection {
    edges {
      node {
        id
        ...ApprovalsTableRow_Approval
      }
    }
  }

  fragment ApprovalsTableRow_Approval on Approval {
    id
    workflow {
      id
      title
    }
    user {
      id
      fullName
    }
    type
    status
    createdAt
    updatedAt
  }
`;
