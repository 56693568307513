import { gql } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import AlternatingTableRow from '@paypr/mui5-common-components/dist/components/tables/AlternatingTableRow';
import EmptyTableRow from '@paypr/mui5-common-components/dist/components/tables/EmptyTableRow';
import HeightLimitedTableContainer from '@paypr/mui5-common-components/dist/components/tables/HeightLimitedTableContainer';
import {
  SimpleSortingTableHeadCell,
  SimpleSortingTableHeadProps,
} from '@paypr/mui5-common-components/dist/components/tables/SortingTableHead';
import { getComparator, stableSort, useSorting } from '@paypr/mui5-common-components/dist/components/tables/sortUtils';
import TooltipItem from '@paypr/mui5-common-components/dist/components/typography/TooltipItem';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { InternalUsersTableRow_InternalUser } from '../../generated/graphql';
import DeletedIcon from '../common/icons/DeletedIcon';
import SimpleSortingTableHead from '../common/tables/SortingTableHead';
import { buildInternalUserTitle } from './InternalUserDetail';

interface InternalUsersTableProps {
  internalUsers: readonly InternalUsersTableRow_InternalUser[];
  onClickInternalUserRow?: InternalUserClickEventHandler;
}

interface InternalUsersTableRowData extends InternalUsersTableRow_InternalUser {
  sortKey: string;
}

export type InternalUserClickEventHandler = (internalUserId: string, newTab: boolean) => void;

const InternalUsersTable = ({ internalUsers, onClickInternalUserRow }: InternalUsersTableProps) => {
  const { order, orderBy, handleRequestSort } = useSorting<InternalUsersTableRowData>('sortKey', 'asc');

  const createClickHandler = (internalUserId: string) => {
    if (!onClickInternalUserRow) {
      return undefined;
    }
    return (event) => onClickInternalUserRow(internalUserId, event.ctrlKey || event.metaKey);
  };

  const internalUserData = internalUsers.map((internalUser) => ({
    ...internalUser,
    sortKey: `${internalUser.lastName}, ${internalUser.firstName}`,
  }));

  return (
    <HeightLimitedTableContainer>
      <Table stickyHeader>
        <InternalUsersTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
        <TableBody>
          {internalUserData.length > 0 ? (
            stableSort(internalUserData, getComparator(order, orderBy)).map((internalUser) => (
              <InternalUserTableRow
                key={internalUser.id}
                internalUser={internalUser}
                onRowClick={createClickHandler(internalUser.id)}
              />
            ))
          ) : (
            <EmptyTableRow columnCount={columnHeadings.length}>No admin users were found.</EmptyTableRow>
          )}
        </TableBody>
      </Table>
    </HeightLimitedTableContainer>
  );
};
export default InternalUsersTable;

type InternalUsersTableHeadProps = Omit<SimpleSortingTableHeadProps<InternalUsersTableRowData>, 'headings'>;

const columnHeadings: SimpleSortingTableHeadCell<InternalUsersTableRowData>[] = [
  { key: 'fullName', label: 'Name' },
  { key: 'createdAt', label: 'Created', align: 'right' },
];

const InternalUsersTableHead = (props: InternalUsersTableHeadProps) => (
  <SimpleSortingTableHead {...props} headings={columnHeadings} />
);

type InternalUserTableRowProps = {
  internalUser: InternalUsersTableRowData;
  onRowClick?: React.MouseEventHandler;
};

export const InternalUserTableRow = ({ internalUser, onRowClick }: InternalUserTableRowProps) => {
  const theme = useTheme();
  const { formatDateTime, formatShortDateTime } = useDateTime();

  return (
    <AlternatingTableRow hover={Boolean(onRowClick)} onClick={onRowClick}>
      <TableCell>
        {buildInternalUserTitle(internalUser.fullName)}&nbsp;
        {internalUser.deletedAt ? (
          <TooltipItem title={`Deleted on ${formatDateTime(internalUser.deletedAt)}`}>
            <DeletedIcon style={{ fontSize: theme.typography.body2.fontSize }} />
          </TooltipItem>
        ) : null}
      </TableCell>
      <TableCell align="right">{formatShortDateTime(internalUser.createdAt)}</TableCell>
    </AlternatingTableRow>
  );
};

export const internalUsersTableRowFragment = gql`
  fragment InternalUsersTableRow_InternalUser on InternalUser {
    id
    firstName
    lastName
    fullName
    createdAt
    deletedAt
  }
`;
