import { gql } from '@apollo/client';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import LoaderButton from '@paypr/mui5-common-components/dist/components/forms/LoaderButton';
import SecondaryButton from '@paypr/mui5-common-components/dist/components/forms/SecondaryButton';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import SubSectionTitle from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import React, { useEffect } from 'react';
import {
  DshAllocationsUpdateInput,
  DshStrategyAllocationsDetailDshVersionDetails,
  UpdateDshStrategyAllocations_DshAllocationEstimate,
  UpdateDshStrategyAllocations_WorkflowDetails,
  useDshStrategyAllocationsDataEstimateQuery,
} from '../../../generated/graphql';
import { useDebounce } from '../../../hooks/debounce';
import { useFormFields } from '../../../hooks/forms';
import { isTextPositiveFloat, isTextPositiveFloatOrZero, isTextPositiveInt } from '../../../utils/strings';
import DshStrategyVersionTitle from '../DshStrategyVersionTitle';
import UpdateDshStrategyAllocationsChanges from './UpdateDshStrategyAllocationsChanges';
import UpdateDshStrategyAllocationsEditor, {
  dshStrategyAllocationsEditorDshVersionFragment,
  UpdateDshStrategyAllocationsAssetFields,
  UpdateDshStrategyAllocationsForm,
} from './UpdateDshStrategyAllocationsEditor';

export type SubmitDshAllocationsUpdateEventHandler = (
  dshAllocationsUpdateInput: DshAllocationsUpdateInput,
) => Promise<void>;

export interface UpdateDshStrategyAllocationsDialogProps extends Omit<DialogProps, 'onClose' | 'maxWidth'> {
  dshVersion: DshStrategyAllocationsDetailDshVersionDetails;
  onSubmitDshAllocations: SubmitDshAllocationsUpdateEventHandler;
  submitting?: boolean;
  onClose: (
    event: React.MouseEvent<HTMLButtonElement> | {},
    reason: 'backdropClick' | 'escapeKeyDown' | 'cancelClicked',
  ) => void;
}

const UpdateDshStrategyAllocationsDialog = ({
  dshVersion,
  onSubmitDshAllocations,
  submitting,
  onClose,
  open,
  ...props
}: UpdateDshStrategyAllocationsDialogProps) => {
  const startingPage = 1;
  const maxPages = 2;
  const [page, setPage] = React.useState(startingPage);

  const { fields, handleFieldChange, resetFields } = useFormFields<UpdateDshStrategyAllocationsForm>({
    totalAmount: dshVersion.portfolio.managedCurrencyAmount.toString(),
  });
  const [formCounter, setFormCounter] = React.useState(0);

  const initialAssets = dshVersion.assets.map((asset) =>
    asset.config
      ? {
          asset: asset.asset,
          assetPercent:
            dshVersion.portfolio.managedCurrencyAmount > 0
              ? ((100 * asset.config.allocationAmount) / dshVersion.portfolio.managedCurrencyAmount).toFixed(2)
              : '',
          maxSlots: asset.config.maxSlots.toString(),
          slotInvestmentSize: asset.config.slotInvestmentSize.toString(),
        }
      : { asset: asset.asset, assetPercent: '', maxSlots: '', slotInvestmentSize: '' },
  );

  const [assetFields, setAssetFields] = React.useState<UpdateDshStrategyAllocationsAssetFields[]>(initialAssets);

  function incrementFormCounter() {
    setFormCounter((c) => c + 1);
  }

  const resetForm = () => {
    setAssetFields(initialAssets);
    resetFields();
    incrementFormCounter();
  };

  useEffect(() => {
    if (!open) {
      return;
    }

    resetForm();
    setPage(startingPage);
  }, [open]);

  const totalAmount = isTextPositiveFloatOrZero(fields.totalAmount)
    ? parseFloat(fields.totalAmount)
    : dshVersion.portfolio.managedCurrencyAmount;

  const totalPercent = assetFields.reduce(
    (total, asset) =>
      total + (isTextPositiveFloatOrZero(asset.assetPercent) ? parseFloat(asset.assetPercent) / 100 : 0),
    0,
  );

  const formFieldsValid =
    isTextPositiveFloat(fields.totalAmount) &&
    totalPercent >= 0.99 &&
    totalPercent <= 1.01 &&
    assetFields.every(
      (asset) =>
        isTextPositiveFloatOrZero(asset.assetPercent) &&
        parseFloat(asset.assetPercent) <= 100 &&
        isTextPositiveInt(asset.maxSlots) &&
        parseInt(asset.maxSlots, 10) > 2 &&
        isTextPositiveFloat(asset.slotInvestmentSize),
    );
  const formValid = formFieldsValid;

  const estimatesInput = {
    totalAmount,
    assets: assetFields.map((asset) => ({
      asset: asset.asset,
      assetPercent: parseFloat(asset.assetPercent) / 100,
      maxSlots: parseInt(asset.maxSlots, 0),
      slotInvestmentSize: parseFloat(asset.slotInvestmentSize),
    })),
  };

  const debouncedEstimatesInput = useDebounce(formFieldsValid ? estimatesInput : undefined, 500);
  const [estimate, setEstimate] = React.useState<UpdateDshStrategyAllocations_DshAllocationEstimate | undefined>();

  const { loading: estimating } = useDshStrategyAllocationsDataEstimateQuery({
    fetchPolicy: 'cache-and-network',
    skip: !debouncedEstimatesInput,
    variables: { input: debouncedEstimatesInput! },
    onCompleted: (data) => {
      setEstimate(data?.dshAllocationsEstimate);
    },
  });

  const handleSubmitChange = async () => {
    await onSubmitDshAllocations({
      version: dshVersion.id,
      desiredTotalAmount: totalAmount,
      assets: assetFields.map((asset) => ({
        asset: asset.asset,
        desiredPercent: parseFloat(asset.assetPercent) / 100,
        maxSlots: parseInt(asset.maxSlots, 0),
        slotInvestmentSize: parseFloat(asset.slotInvestmentSize),
      })),
    });
  };

  const updateAssetFields = (asset: string, newAssetFields: UpdateDshStrategyAllocationsAssetFields) => {
    const newAssets = assetFields.map((a) => {
      if (a.asset === asset) {
        return newAssetFields;
      }

      return a;
    });

    setAssetFields(newAssets);
  };

  const buildAllocations = (): UpdateDshStrategyAllocations_WorkflowDetails | undefined => {
    if (!estimate || !formFieldsValid) {
      return undefined;
    }

    const oldTotalAmount = dshVersion.assets.reduce(
      (total, asset) => total + (asset.config ? asset.config.allocationAmount : 0),
      0,
    );

    return {
      ...estimate,
      __typename: 'UpdateDshAllocationsWorkflowDetails',
      oldTotalAmount,
      oldTotalPercent: 1,
      assets: estimate.assets.map((asset) => {
        const dshAsset = dshVersion.assets.find((a) => a.asset === asset.asset);
        if (!dshAsset) {
          throw new Error(`Asset ${asset.asset} not found in dshVersion ${dshVersion.id}`);
        }

        if (!dshAsset.config) {
          return {
            ...asset,
            __typename: 'UpdateDshAllocationsAssetWorkflowDetails',
            oldAmount: 0,
            oldPercent: 0,
            oldMaxSlots: 0,
            oldSlotInvestmentSize: 0,
          };
        }

        return {
          ...asset,
          __typename: 'UpdateDshAllocationsAssetWorkflowDetails',
          oldAmount: dshAsset.config.allocationAmount,
          oldPercent: dshAsset.config.allocationAmount / oldTotalAmount,
          oldMaxSlots: dshAsset.config.maxSlots,
          oldSlotInvestmentSize: dshAsset.config.slotInvestmentSize,
        };
      }),
    };
  };

  const allocations = buildAllocations();

  const determineAllocationsChanged = () => {
    if (!allocations) {
      return false;
    }

    const totalAmountChanged = allocations.oldTotalAmount !== allocations.desiredTotalAmount;
    const assetsChanged = allocations.assets.some((asset) => {
      const oldAsset = dshVersion.assets.find((a) => a.asset === asset.asset);
      if (!oldAsset) {
        return true;
      }

      if (!oldAsset.config) {
        return true;
      }

      return (
        oldAsset.config.allocationAmount !== asset.estimatedAmount ||
        oldAsset.config.maxSlots !== asset.desiredMaxSlots ||
        oldAsset.config.slotInvestmentSize !== asset.desiredSlotInvestmentSize
      );
    });

    return totalAmountChanged || assetsChanged;
  };

  const canSubmit = formValid && !estimating && !submitting && determineAllocationsChanged();

  const nextPage = () => {
    setPage((page) => page + 1);
  };

  const prevPage = () => {
    setPage((page) => page - 1);
  };

  return (
    <Dialog {...props} open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>
        <DshStrategyVersionTitle version={dshVersion.id} pageName="Allocations" />
      </DialogTitle>
      <DialogContent>
        {page == 1 && (
          <UpdateDshStrategyAllocationsEditor
            key={`editor-${formCounter}`}
            dshVersion={dshVersion}
            assetFields={assetFields}
            estimate={estimate}
            fields={fields}
            handleFieldChange={handleFieldChange}
            onUpdateAssetFields={updateAssetFields}
          />
        )}
        {page == 2 && allocations && (
          <>
            <SubSectionTitle>Verify Changes</SubSectionTitle>
            <UpdateDshStrategyAllocationsChanges allocations={allocations} />
          </>
        )}
      </DialogContent>

      <DialogActions>
        <DetailField>
          <Stack direction="row" spacing={1}>
            <SecondaryButton onClick={(event) => onClose(event, 'cancelClicked')} disabled={submitting}>
              Cancel
            </SecondaryButton>

            {page < maxPages && (
              <SecondaryButton onClick={resetForm} disabled={submitting}>
                Reset
              </SecondaryButton>
            )}

            {page > 1 && (
              <SecondaryButton onClick={prevPage} disabled={submitting}>
                Back
              </SecondaryButton>
            )}

            {page < maxPages && (
              <Button color="inherit" onClick={nextPage} disabled={!canSubmit}>
                Next
              </Button>
            )}

            {page == maxPages && (
              <LoaderButton loading={submitting} disabled={!canSubmit} onClick={handleSubmitChange}>
                Submit Updates
              </LoaderButton>
            )}
          </Stack>
        </DetailField>
      </DialogActions>
    </Dialog>
  );
};
export default UpdateDshStrategyAllocationsDialog;

export const updateDshStrategyAllocationsDialogFragment = gql`
  fragment UpdateDshStrategyAllocationsDialog_DshVersion on DshVersion {
    id
    assets {
      id
      asset
    }
    active

    ...DshStrategyAllocationsEditor_DshVersion
  }

  ${dshStrategyAllocationsEditorDshVersionFragment}
`;
