import { gql } from '@apollo/client';
import Grid from '@mui/material/Grid';
import LoaderButton from '@paypr/mui5-common-components/dist/components/forms/LoaderButton';
import RouteLink from '@paypr/mui5-common-components/dist/components/routes/RouteLink';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import DetailValue from '@paypr/mui5-common-components/dist/components/typography/DetailValue';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { ApprovalDetail_Approval, ApprovalStatus } from '../../generated/graphql';
import DetailGridItem from '../common/containers/DetailGridItem';
import { buildInternalUserPath } from '../internalUsers/internalUserPaths';
import { buildWorkflowPath } from '../workflows/workflowPaths';
import { buildWorkflowReferenceTitle, workflowReferenceFragment } from '../workflows/workflowUtils';
import { buildApprovalDecisionPath } from './approvalPaths';
import { buildApprovalStatusDisplay, buildApprovalTypeDisplay } from './approvalUtils';

export type ApprovalDetailData = ApprovalDetail_Approval;

interface ApprovalDetailProps {
  approval: ApprovalDetailData;
  disabled?: boolean;
}

const ApprovalDetail = ({ approval, disabled }: ApprovalDetailProps) => {
  const { formatDateTime } = useDateTime();

  return (
    <>
      <DetailContainer>
        <Grid container justifyContent="left" spacing={2}>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Workflow</DetailTitle>
              <DetailValue>
                <RouteLink path={buildWorkflowPath(approval.workflow.id)}>
                  {buildWorkflowReferenceTitle(approval.workflow)}
                </RouteLink>
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Type</DetailTitle>
              <DetailValue>{buildApprovalTypeDisplay(approval.type)}</DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Status</DetailTitle>
              <DetailValue>{buildApprovalStatusDisplay(approval.status)}</DetailValue>
              {approval.user.isMe && approval.status === ApprovalStatus.Pending && (
                <DetailValue>
                  <LoaderButton size="small" href={buildApprovalDecisionPath(approval.id)} disabled={disabled}>
                    Make Decision
                  </LoaderButton>
                </DetailValue>
              )}
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Approver</DetailTitle>
              <DetailValue>
                <RouteLink path={buildInternalUserPath(approval.user.id)}>{approval.user.fullName}</RouteLink>
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Last Update</DetailTitle>
              <DetailValue>{formatDateTime(approval.updatedAt)}</DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Sent</DetailTitle>
              <DetailValue>{formatDateTime(approval.createdAt)}</DetailValue>
            </DetailField>
          </DetailGridItem>
        </Grid>
      </DetailContainer>
    </>
  );
};
export default ApprovalDetail;

export const approvalDetailFragment = gql`
  fragment ApprovalDetail_Approval on Approval {
    id
    type
    status

    workflow {
      id
      ...WorkflowReference_Workflow
    }

    user {
      id
      fullName
      isMe
    }

    createdAt
    updatedAt
  }

  ${workflowReferenceFragment}
`;
