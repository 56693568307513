import { gql } from '@apollo/client';
import Grid from '@mui/material/Grid';
import RouteLink from '@paypr/mui5-common-components/dist/components/routes/RouteLink';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import DetailValue from '@paypr/mui5-common-components/dist/components/typography/DetailValue';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import {
  TransactionDetail_Transaction,
  TransactionSide,
  TransactionStatus,
  TransactionType,
} from '../../generated/graphql';
import DetailGridItem from '../common/containers/DetailGridItem';
import { buildExchangeDisplay } from '../common/model/exchange';
import FloatFormat from '../common/typography/FloatFormat';
import IntegerFormat from '../common/typography/IntegerFormat';
import UsdFormat from '../common/typography/UsdFormat';
import { buildTradePath } from '../trades/tradePaths';
import { buildPortfolioPath } from '../portfolios/portfolioPaths';

interface TransactionDetailProps {
  transaction: TransactionDetail_Transaction;
}

const TransactionDetail = ({ transaction }: TransactionDetailProps) => {
  const { formatDateTime } = useDateTime();

  return (
    <>
      <DetailContainer>
        {transaction.error ? (
          <DetailField>
            <DetailValue color="error">{transaction.error}</DetailValue>
          </DetailField>
        ) : null}
        <Grid container justifyContent="left" spacing={2}>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Transaction</DetailTitle>
              <DetailValue>
                <RouteLink path={buildPortfolioPath(transaction.trade.order.portfolio.id)}>
                  {transaction.trade.order.portfolio.name}
                </RouteLink>{' '}
                <RouteLink path={buildTradePath(transaction.trade.id)}>Trade</RouteLink>
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Exchange</DetailTitle>
              <DetailValue>{buildExchangeDisplay(transaction.exchange)}</DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Status</DetailTitle>
              <DetailValue>{buildTransactionStatusDisplay(transaction.status)}</DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Asset</DetailTitle>
              <DetailValue>{transaction.asset}</DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Type</DetailTitle>
              <DetailValue>{buildTransactionTypeDisplay(transaction.type)}</DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Side</DetailTitle>
              <DetailValue>{buildTransactionSideDisplay(transaction.side)}</DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Average Price</DetailTitle>
              <DetailValue>
                <UsdFormat amount={transaction.averagePrice} />
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Filled Amount</DetailTitle>
              <DetailValue>
                <UsdFormat amount={transaction.filledCurrencyAmount} />
              </DetailValue>
              <DetailValue>
                <FloatFormat amount={transaction.filledAssetAmount} asset={transaction.asset} />
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Fees Paid</DetailTitle>
              <DetailValue>
                <UsdFormat amount={transaction.feesPaid} />
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Num Fills</DetailTitle>
              <DetailValue>
                <IntegerFormat amount={transaction.numFills} />
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Last Update</DetailTitle>
              <DetailValue>{formatDateTime(transaction.updatedAt)}</DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Created</DetailTitle>
              <DetailValue>{formatDateTime(transaction.createdAt)}</DetailValue>
            </DetailField>
          </DetailGridItem>
        </Grid>
      </DetailContainer>
    </>
  );
};
export default TransactionDetail;

export const buildTransactionTitle = (transactionId?: string, portfolioName?: string) =>
  portfolioName ? `${portfolioName} Transaction` : transactionId ? `Transaction ${transactionId}` : 'Transaction';

export const buildTransactionStatusDisplay = (status: TransactionStatus) => {
  switch (status) {
    case TransactionStatus.Open:
      return 'Open';
    case TransactionStatus.Canceling:
      return 'Canceling';
    case TransactionStatus.Canceled:
      return 'Canceled';
    case TransactionStatus.Done:
      return 'Done';
    case TransactionStatus.Error:
      return 'Error';
  }
  return status;
};

export const buildTransactionSideDisplay = (side: TransactionSide) => {
  switch (side) {
    case TransactionSide.Buy:
      return 'Buy';
    case TransactionSide.Sell:
      return 'Sell';
  }
  return side;
};

export const buildTransactionTypeDisplay = (type: TransactionType) => {
  switch (type) {
    case TransactionType.Market:
      return 'Market';
    case TransactionType.Limit:
      return 'Limit';
    case TransactionType.PostOnlyLimit:
      return 'Post-Only';
  }
  return type;
};

export const transactionDetailFragment = gql`
  fragment TransactionDetail_Transaction on Transaction {
    id
    trade {
      id
      order {
        id
        portfolio {
          id
          name
        }
      }
    }
    exchange
    status
    asset
    type
    side
    filledAssetAmount
    filledCurrencyAmount
    averagePrice
    feesPaid
    numFills
    error
    createdAt
    updatedAt
  }
`;
