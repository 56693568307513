import { gql, NetworkStatus } from '@apollo/client';
import React from 'react';
import { useClientDocumentsSectionIntegrationQueryLazyQuery } from '../../generated/graphql';
import { ExplodingComponentProps } from '../common/icons/ExplodeIconButton';
import ClientDocumentsAccordion from './documents/ClientDocumentsAccordion';
import { ClientDocumentClickEventHandler, clientDocumentsTableRowFragment } from './documents/ClientDocumentsTable';

export interface ClientDocumentsSectionIntegrationProps extends ExplodingComponentProps {
  clientId: string;
  onClickClientDocument?: ClientDocumentClickEventHandler;
  defaultExpanded?: boolean;
  autoRefresh?: boolean | number;
}

const ClientDocumentsSectionIntegration = ({
  clientId,
  onClickClientDocument,
  defaultExpanded,
  autoRefresh,
  exploded,
  onExplodeChanged,
}: ClientDocumentsSectionIntegrationProps) => {
  const [fetchDocuments, { data, error, refetch, networkStatus, loading }] =
    useClientDocumentsSectionIntegrationQueryLazyQuery({
      fetchPolicy: 'cache-and-network',
      variables: { clientId },
    });

  const client = data?.client;

  const handleRefresh = async () => {
    if (client) {
      await refetch();
    } else {
      await fetchDocuments();
    }
  };

  return (
    <ClientDocumentsAccordion
      clientDocuments={client?.documents}
      hideClient
      onClickClientDocument={onClickClientDocument}
      defaultExpanded={defaultExpanded}
      exploded={exploded}
      onExplodeChanged={onExplodeChanged}
      refreshing={networkStatus === NetworkStatus.refetch}
      loading={loading}
      onRefresh={handleRefresh}
      autoRefresh={autoRefresh}
      error={error}
    />
  );
};
export default ClientDocumentsSectionIntegration;

gql`
  query ClientDocumentsSectionIntegrationQuery($clientId: ID!) {
    client(id: $clientId) {
      id

      documents {
        id
        ...ClientDocumentsTableRow_ClientDocument
      }
    }
  }

  ${clientDocumentsTableRowFragment}
`;
