import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import * as React from 'react';
import AppPage from '../../components/common/page/AppPage';
import WidePageContainer from '../../components/common/page/WidePageContainer';
import { DshStrategyReportsPageParams } from '../../components/dsh/dshPaths';
import DshStrategyVersionTitle, { useDshStrategyVersionPageTitle } from '../../components/dsh/DshStrategyVersionTitle';
import { usePageParams } from '../../hooks/page';
import DshStrategyReportsSection from '../../components/dsh/DshStrategyReportsSection';

const DshStrategyReportsPage = () => {
  const { version } = usePageParams<keyof DshStrategyReportsPageParams>();

  useDshStrategyVersionPageTitle(version, 'Reports');

  return (
    <AppPage>
      <WidePageContainer>
        <SectionTitle>
          <DshStrategyVersionTitle version={version} pageName="Reports" />
        </SectionTitle>
        <DshStrategyReportsSection version={version} />
      </WidePageContainer>
    </AppPage>
  );
};
export default DshStrategyReportsPage;
