import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React from 'react';

export interface ExplodingContainerProps {
  renderComponent: (exploded: boolean, onExplodeChanged: (exploded: boolean) => void) => React.ReactElement;
}

const ExplodingContainer = ({ renderComponent }: ExplodingContainerProps) => {
  const [exploded, setExploded] = React.useState(false);

  return (
    <>
      {renderComponent(exploded, setExploded)}
      <Dialog fullScreen open={exploded} onClose={() => setExploded(false)}>
        <DialogContent>{renderComponent(exploded, setExploded)}</DialogContent>
      </Dialog>
    </>
  );
};
export default ExplodingContainer;
