import { gql, NetworkStatus } from '@apollo/client';
import React from 'react';
import { useSubsystemAuditActionsSectionQueryLazyQuery } from '../../generated/graphql';
import ContentError from '../common/content/ContentError';
import { ExplodingComponentProps } from '../common/icons/ExplodeIconButton';
import AuditActionsAccordion from '../auditActions/AuditActionsAccordion';
import { AuditActionClickEventHandler } from '../auditActions/AuditActionsTable';

export interface SubsystemAuditActionsSectionIntegrationProps extends ExplodingComponentProps {
  subsystemId: string;
  onClickAuditAction?: AuditActionClickEventHandler;
  defaultExpanded?: boolean;
  autoRefresh?: boolean | number;
}

const SubsystemAuditActionsSectionIntegration = ({
  subsystemId,
  onClickAuditAction,
  defaultExpanded,
  autoRefresh,
  exploded,
  onExplodeChanged,
}: SubsystemAuditActionsSectionIntegrationProps) => {
  const [fetchSubsystemAuditActions, { data, error, refetch, networkStatus, loading }] =
    useSubsystemAuditActionsSectionQueryLazyQuery({
      fetchPolicy: 'cache-and-network',
      variables: { subsystemId },
    });

  if (error) {
    return <ContentError message="Error loading subsystem activity" error={error} />;
  }

  const subsystem = data?.subsystem;

  const handleRefresh = async () => {
    if (subsystem) {
      await refetch();
    } else {
      await fetchSubsystemAuditActions();
    }
  };

  return (
    <AuditActionsAccordion
      auditActions={subsystem?.actions}
      onClickAuditAction={onClickAuditAction}
      defaultExpanded={defaultExpanded}
      exploded={exploded}
      onExplodeChanged={onExplodeChanged}
      refreshing={networkStatus === NetworkStatus.refetch}
      loading={loading}
      onRefresh={handleRefresh}
      autoRefresh={autoRefresh}
    />
  );
};
export default SubsystemAuditActionsSectionIntegration;

gql`
  query SubsystemAuditActionsSectionQuery($subsystemId: ID!) {
    subsystem(id: $subsystemId) {
      id

      actions {
        ...AuditActionsTableData_AuditActionConnection
      }
    }
  }
`;
