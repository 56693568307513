import { gql } from '@apollo/client';
import Grid from '@mui/material/Grid';
import RouteLink from '@paypr/mui5-common-components/dist/components/routes/RouteLink';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import DetailValue from '@paypr/mui5-common-components/dist/components/typography/DetailValue';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { OrderDetail_Order, OrderStatus } from '../../generated/graphql';
import { snakeOrKebabToDisplay } from '../../utils/strings';
import DetailGridItem from '../common/containers/DetailGridItem';
import { buildExchangeDisplay } from '../common/model/exchange';
import { buildPriorityDisplay, buildTimeframeDisplay } from '../common/model/timeframe';
import UsdFormat from '../common/typography/UsdFormat';
import { buildPortfolioPath } from '../portfolios/portfolioPaths';
import TradesAccordion from '../trades/TradesAccordion';
import { TradeClickEventHandler } from '../trades/TradesTable';
import OrderAssetsSection from './OrderAssetsSection';
import { OrderAssetClickEventHandler, orderAssetsTableRowFragment } from './OrderAssetsTable';

export type OrderDetailData = OrderDetail_Order;

interface OrderDetailProps {
  order: OrderDetailData;
  onClickOrderAsset?: OrderAssetClickEventHandler;
  onClickTrade?: TradeClickEventHandler;
}

const OrderDetail = ({ order, onClickOrderAsset, onClickTrade }: OrderDetailProps) => {
  const { formatDateTime } = useDateTime();

  return (
    <>
      <DetailContainer>
        <Grid container justifyContent="left" spacing={2}>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Portfolio</DetailTitle>
              <DetailValue>
                <RouteLink path={buildPortfolioPath(order.portfolio.id)}>{order.portfolio.name}</RouteLink>
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Status</DetailTitle>
              <DetailValue>{buildOrderStatusDisplay(order.status)}</DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Fees Paid</DetailTitle>
              <DetailValue>
                <UsdFormat amount={order.feesPaid} />
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Desired Amount</DetailTitle>
              <DetailValue>
                <UsdFormat amount={order.desiredCurrencyAmount} />
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Filled Amount</DetailTitle>
              <DetailValue>
                <UsdFormat amount={order.filledCurrencyAmount} />
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Unfilled Amount</DetailTitle>
              <DetailValue>
                <UsdFormat amount={order.unfilledCurrencyAmount} />
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Exchanges</DetailTitle>
              {order.exchanges?.map((exchange) => (
                <DetailValue key={exchange}>{buildExchangeDisplay(exchange)}</DetailValue>
              ))}
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Timeframe</DetailTitle>
              <DetailValue>{buildTimeframeDisplay(order.timeframe)}</DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Priority</DetailTitle>
              <DetailValue>{buildPriorityDisplay(order.priority)}</DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Due</DetailTitle>
              <DetailValue>{formatDateTime(order.dueAt)}</DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Last Update</DetailTitle>
              <DetailValue>{formatDateTime(order.updatedAt)}</DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Created</DetailTitle>
              <DetailValue>{formatDateTime(order.createdAt)}</DetailValue>
            </DetailField>
          </DetailGridItem>
        </Grid>
      </DetailContainer>

      <OrderAssetsSection orderAssets={order.assets} onClickOrderAsset={onClickOrderAsset} />
      <TradesAccordion trades={order.trades} onClickTrade={onClickTrade} />
    </>
  );
};
export default OrderDetail;

export const buildOrderTitle = (orderId?: string, portfolioName?: string) =>
  portfolioName ? `${portfolioName} Order` : orderId ? `Order ${orderId}` : 'Order';

export const buildOrderStatusDisplay = (status: OrderStatus) => snakeOrKebabToDisplay(status);

export const orderDetailFragment = gql`
  fragment OrderDetail_Order on Order {
    id
    portfolio {
      id
      name
    }
    status
    desiredCurrencyAmount
    filledCurrencyAmount
    unfilledCurrencyAmount
    feesPaid
    timeframe
    exchanges
    priority
    dueAt
    createdAt
    updatedAt

    assets {
      id
      ...OrderAssetsTableRow_OrderAsset
    }

    trades {
      id
      ...TradesTableRow_Trade
    }
  }

  ${orderAssetsTableRowFragment}
`;
