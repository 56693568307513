import { gql, NetworkStatus } from '@apollo/client';
import React, { useState } from 'react';
import { clientFilterChoicesFundFragment } from '../../components/clients/ClientFilterChoices';
import {
  addLpPath,
  buildClientsUrlWithFilters,
  buildLpPath,
  ClientFilters,
  ClientsPageQueryParams,
  extractClientFiltersFromUrl,
} from '../../components/clients/clientPaths';
import ClientsSection from '../../components/clients/ClientsSection';
import { clientsTableRowFragment } from '../../components/clients/ClientsTable';
import AppPage from '../../components/common/page/AppPage';
import AppPageError from '../../components/common/page/AppPageError';
import WidePageContainer from '../../components/common/page/WidePageContainer';
import { useQuery } from '../../components/common/routes/paths';
import { useClientsPageFundsQuery, useClientsPageQuery } from '../../generated/graphql';
import { usePageTitle } from '../../hooks/page';
import { usePaths } from '../../hooks/paths';

const ClientsPage = () => {
  usePageTitle('LPs');
  const { navigateTo } = usePaths();

  const { params } = useQuery<ClientsPageQueryParams>();
  const [filters, setFilters] = useState(extractClientFiltersFromUrl(params));
  const [optionsExpanded, setOptionsExpanded] = useState(params.get('optionsExpanded') === 'true');

  const { data, error, refetch, networkStatus, loading } = useClientsPageQuery({
    fetchPolicy: 'cache-and-network',
    variables: { fundIds: filters.fundIds, includeDeleted: filters.includeDeleted === true },
  });

  const { data: fundsData, loading: fundsLoading } = useClientsPageFundsQuery({ fetchPolicy: 'cache-and-network' });

  if (error) {
    return <AppPageError error={error} />;
  }

  const handleClientClicked = (clientId: string, newTab: boolean) => {
    navigateTo(buildLpPath(clientId), newTab);
  };

  const handleFiltersChanged = (newFilters: ClientFilters) => {
    navigateTo(buildClientsUrlWithFilters(newFilters, optionsExpanded));
    setFilters(newFilters);
  };

  const handleOptionsExpandedChanged = (newOptionsExpanded: boolean) => {
    navigateTo(buildClientsUrlWithFilters(filters, newOptionsExpanded));
    setOptionsExpanded(newOptionsExpanded);
  };

  const handleClickAddClient = (newTab: boolean) => {
    navigateTo(addLpPath, newTab);
  };

  return (
    <AppPage>
      <WidePageContainer>
        <ClientsSection
          clients={data?.clients}
          loading={loading || fundsLoading}
          refreshing={networkStatus === NetworkStatus.refetch}
          onRefresh={() => refetch()}
          autoRefresh
          onClickClient={handleClientClicked}
          onClickAddClient={handleClickAddClient}
          defaultOptionsExpanded={optionsExpanded}
          onOptionsExpandedChanged={handleOptionsExpandedChanged}
          funds={fundsData?.funds}
          defaultFilters={filters}
          onChangeFilters={handleFiltersChanged}
          showDeletedOption={filters.includeDeleted !== null}
        />
      </WidePageContainer>
    </AppPage>
  );
};
export default ClientsPage;

gql`
  query ClientsPageQuery($fundIds: [ID!], $includeDeleted: Boolean!) {
    clients(fundIds: $fundIds, includeDeleted: $includeDeleted) {
      id
      ...ClientsTableRow_Client
    }
  }

  query ClientsPageFundsQuery {
    funds {
      ...ClientFilterChoices_Fund
    }
  }

  ${clientsTableRowFragment}
  ${clientFilterChoicesFundFragment}
`;
