import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import DefaultTextField from '@paypr/mui5-common-components/dist/components/forms/DefaultTextField';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailError from '@paypr/mui5-common-components/dist/components/typography/DetailError';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import React from 'react';
import { AddTransferNoticeDialog_Client } from '../../../../generated/graphql';
import { FormFieldChangeEventHandler } from '../../../../hooks/forms';
import { isTextPositiveFloat } from '../../../../utils/strings';
import DetailGridItem from '../../../common/containers/DetailGridItem';

export interface AddTransferNoticeEditorProps {
  client: AddTransferNoticeDialog_Client;
  fields: AddTransferNoticeFields;
  handleFieldChange: FormFieldChangeEventHandler;
}

export interface AddTransferNoticeFields {
  fundId: string;
  originatingAccountNumber: string;
  amount: string;
  markProcessed: string;
}

const AddTransferNoticeEditor = ({ client, fields, handleFieldChange }: AddTransferNoticeEditorProps) => {
  const validAmount = isTextPositiveFloat(fields.amount);

  return (
    <DetailContainer>
      <Grid container justifyContent="left" spacing={2}>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Fund</DetailTitle>
            <DefaultTextField name="fundId" label="Fund" value={fields.fundId} select onChange={handleFieldChange}>
              {client.funds.map((fund) => (
                <MenuItem value={fund.fund.id}>{fund.fund.name}</MenuItem>
              ))}
            </DefaultTextField>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Originating Account Number</DetailTitle>
            <DefaultTextField
              name="originatingAccountNumber"
              label="Originating Account Number"
              autoComplete="off"
              value={fields.originatingAccountNumber}
              required
              onChange={handleFieldChange}
            />
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Amount</DetailTitle>
            <DefaultTextField
              name="amount"
              label="Amount $"
              type="number"
              autoComplete="off"
              value={fields.amount}
              required
              onChange={handleFieldChange}
            />
            {!validAmount ? <DetailError>Amount must be a positive number.</DetailError> : null}
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Mark Processed Immediately</DetailTitle>
            <RadioGroup name="markProcessed" row onChange={handleFieldChange} value={fields.markProcessed}>
              <FormControlLabel radioGroup="markProcessed" value="true" control={<Radio />} label="Yes" />
              <FormControlLabel radioGroup="markProcessed" value="false" control={<Radio />} label="No" />
            </RadioGroup>
          </DetailField>
        </DetailGridItem>
      </Grid>
    </DetailContainer>
  );
};
export default AddTransferNoticeEditor;
