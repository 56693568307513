import { atom, useRecoilState } from 'recoil';
import { DateTimeHook, getDateTimeWithZone } from '../../data/dates';
import {
  LedgerReportAugmentedDefinition,
  LedgerReportSettings,
  ReportTableOrientation,
  ReportView,
} from './ledgerReportModel';

export interface FavoriteReportsHook {
  favoriteReports: FavoriteLedgerReport[];
  getFavoriteReport: (reportId: string) => FavoriteLedgerReport | undefined;
  addFavoriteReport: (newReport: FavoriteLedgerReport) => void;
  removeFavoriteReport: (reportId: string) => void;
}

export interface FavoriteLedgerReport {
  id: string;
  name: string;
  reportSettings?: LedgerReportSettings;

  /** @deprecated use reportSettings instead */
  reportDefinition: LedgerReportAugmentedDefinition;

  /** @deprecated use reportSettings instead */
  reportView: ReportView;
}

export const favoriteLedgerReportToSettings = (report: FavoriteLedgerReport): LedgerReportSettings => ({
  definition: convertFromOldReportDefinition(report.reportSettings?.definition || report.reportDefinition),
  view: report.reportSettings?.view || report.reportView,
  chartSettings: report.reportSettings?.chartSettings || {},
  tableSettings: report.reportSettings?.tableSettings || { orientation: ReportTableOrientation.Vertical },
});

const convertFromOldReportDefinition = (reportDefinition: LedgerReportAugmentedDefinition) => {
  const { timeResolution, groupBy, groupFilters, values, accountEventTypes, ...rest } = reportDefinition;
  return {
    timeResolution: convertOldReportDefinitionValue(timeResolution),
    groupBy: groupBy.map(convertOldReportDefinitionValue),
    groupFilters: Object.entries(groupFilters || {}).reduce((previousValue, [group, values]) => {
      previousValue[convertOldReportDefinitionValue(group)] = values;
      return previousValue;
    }, {}),
    values: values.map(convertOldReportDefinitionValue),
    accountEventTypes: accountEventTypes?.map(convertOldReportDefinitionValue),
    ...rest,
  };
};

const convertOldReportDefinitionValue = <T extends string>(value: T): T => value.toUpperCase().replace(/-/g, '_') as T;

export const favoriteLedgerReportFromSettings = (
  id: string,
  name: string,
  reportSettings: LedgerReportSettings,
): FavoriteLedgerReport => ({
  id,
  name,
  reportSettings,
  reportDefinition: reportSettings.definition,
  reportView: reportSettings.view,
});

export const useFavoriteReports = (): FavoriteReportsHook => {
  const [favoriteLedgerReports, setFavoriteLedgerReports] = useRecoilState(favoriteLedgerReportsState);

  const getFavoriteReport = (reportId: string) => favoriteLedgerReports.reports.find((r) => r.id == reportId);

  const buildFavoriteLedgerReports = (currentReports: FavoriteLedgerReport[], newReport: FavoriteLedgerReport) => {
    const existing = currentReports.find((r) => r.id === newReport.id);
    if (existing) {
      return currentReports.map((r) => (r.id === newReport.id ? newReport : r));
    }
    return [...currentReports, newReport];
  };

  const addFavoriteReport = (newReport: FavoriteLedgerReport) => {
    setFavoriteLedgerReports(({ reports }): { reports: FavoriteLedgerReport[] } => ({
      reports: buildFavoriteLedgerReports(reports, newReport).sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }

        if (a.name < b.name) {
          return -1;
        }

        return 0;
      }),
    }));
  };

  const removeFavoriteReport = (reportId: string) => {
    setFavoriteLedgerReports((old) => ({
      ...old,
      reports: old.reports.filter((r) => r.id != reportId),
    }));
  };

  return { favoriteReports: favoriteLedgerReports.reports, getFavoriteReport, addFavoriteReport, removeFavoriteReport };
};

// state
interface FavoriteLedgerReportsState {
  reports: FavoriteLedgerReport[];
}

const favoriteLedgerReportsStorageKey = 'favoriteLedgerReports';

const loadFavoriteLedgerReports = ({ toDateTime }: Pick<DateTimeHook, 'toDateTime'>) => {
  const favoriteReportsJson = localStorage.getItem(favoriteLedgerReportsStorageKey);
  if (!favoriteReportsJson) {
    return [];
  }

  const favoriteReports = JSON.parse(favoriteReportsJson);
  return favoriteReports.map(
    ({ reportSettings, reportDefinition: oldReportDefinition, ...rest }: FavoriteLedgerReport) => {
      const reportDefinition = reportSettings?.definition || oldReportDefinition;
      const fixedReportDefinition = {
        ...reportDefinition,
        startAt: toDateTime(reportDefinition.startAt),
        endAt: reportDefinition.endAt ? toDateTime(reportDefinition.endAt) : undefined,
      };
      return {
        ...rest,
        reportSettings: {
          ...reportSettings,
          definition: fixedReportDefinition,
        },
        reportDefinition: fixedReportDefinition,
      };
    },
  );
};

const favoriteLedgerReports = (reports: FavoriteLedgerReport[]) => {
  localStorage.setItem(favoriteLedgerReportsStorageKey, JSON.stringify(reports));
};

const favoriteLedgerReportsState = atom<FavoriteLedgerReportsState>({
  key: 'favoriteLedgerReports',
  default: {
    reports: loadFavoriteLedgerReports(getDateTimeWithZone()),
  },
  effects: [({ onSet }) => onSet((newValue) => favoriteLedgerReports(newValue.reports))],
});
