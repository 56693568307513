import { gql, NetworkStatus } from '@apollo/client';
import { useSnackbar } from 'notistack';
import React from 'react';
import { buildLpFundPath } from '../../components/clientFunds/clientFundPaths';
import { ClientPageParams } from '../../components/clients/clientPaths';
import ClientSection, { clientSectionFragment } from '../../components/clients/ClientSection';
import { buildClientTitle } from '../../components/clients/clientUtils';
import { buildTransferNoticePath } from '../../components/clients/transfers/transferNoticePaths';
import AppPage from '../../components/common/page/AppPage';
import AppPageError from '../../components/common/page/AppPageError';
import AppPageMessage from '../../components/common/page/AppPageMessage';
import WidePageContainer from '../../components/common/page/WidePageContainer';
import {
  useClientPageDisableClientMutation,
  useClientPageEnableClientMutation,
  useClientPageQuery,
} from '../../generated/graphql';
import { useErrors } from '../../hooks/errors';
import { usePageParams, usePageTitle } from '../../hooks/page';
import { usePaths } from '../../hooks/paths';

const ClientPage = () => {
  const { clientId } = usePageParams<keyof ClientPageParams>();
  const { navigateTo } = usePaths();
  const { enqueueSnackbar } = useSnackbar();
  const { showError } = useErrors();

  const { data, error, refetch, networkStatus, loading } = useClientPageQuery({
    fetchPolicy: 'cache-and-network',
    variables: { clientId },
  });

  usePageTitle(`LP - ${buildClientTitle(data?.client?.fullName)}`);

  const [enableClient, { loading: enabling }] = useClientPageEnableClientMutation({
    variables: {
      clientId,
    },
    onCompleted: async () => {
      await refetch();
    },
  });

  const [disableClient, { loading: disabling }] = useClientPageDisableClientMutation({
    variables: {
      clientId,
    },
    onCompleted: async () => {
      await refetch();
    },
  });

  if (error) {
    return <AppPageError error={error} />;
  }

  const client = data?.client;

  if (client === null) {
    return <AppPageMessage text={`LP ${clientId} does not exist`} />;
  }

  const handleEnableClient = async () => {
    if (!confirm(`Are you sure you want to enable the LP Portal Login ${buildClientTitle(client?.fullName)}?`)) {
      return;
    }

    try {
      await enableClient();

      enqueueSnackbar('LP Portal Login enabled successfully.', { variant: 'success' });
    } catch (e) {
      showError(e);
    }
  };

  const handleDisableClient = async () => {
    if (!confirm(`Are you sure you want to disable the LP Portal Login for ${buildClientTitle(client?.fullName)}?`)) {
      return;
    }

    try {
      await disableClient();

      enqueueSnackbar('LP Portal Login disabled successfully.', { variant: 'success' });
    } catch (e) {
      showError(e);
    }
  };

  const handleClientFundClicked = (clientId: string, fundId: string, newTab: boolean) => {
    navigateTo(buildLpFundPath(clientId, fundId), newTab);
  };

  const handleTransferNoticeClicked = (transferNoticeId: string, newTab: boolean) => {
    navigateTo(buildTransferNoticePath(transferNoticeId), newTab);
  };

  return (
    <AppPage>
      <WidePageContainer>
        <ClientSection
          client={client}
          onEnableClient={handleEnableClient}
          onDisableClient={handleDisableClient}
          onClickClientFund={handleClientFundClicked}
          onClickTransferNotice={handleTransferNoticeClicked}
          loading={loading || enabling || disabling}
          refreshing={networkStatus === NetworkStatus.refetch}
          onRefresh={() => refetch()}
          autoRefresh
        />
      </WidePageContainer>
    </AppPage>
  );
};
export default ClientPage;

gql`
  query ClientPageQuery($clientId: ID!) {
    client(id: $clientId) {
      id
      fullName
      ...ClientSection_Client
    }
  }

  mutation ClientPageEnableClientMutation($clientId: ID!) {
    enableClient(clientId: $clientId)
  }

  mutation ClientPageDisableClientMutation($clientId: ID!) {
    disableClient(clientId: $clientId)
  }

  ${clientSectionFragment}
`;
