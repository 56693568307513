import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useDateTime, useTimeZone } from '../../../data/dates';
import { DshReportData, fetchDshCustomReport, ReportType } from '../../../services/dshReports';
import DshGainsOverTimeReport from './DshGainsOverTimeReport';

export interface DshRealizedGainsOverTimeReportIntegrationProps {
  version: string;
  reportType: ReportType;
  endDate?: DateTime;
  numPeriods: number;
}

const DshRealizedGainsOverTimeReportIntegration = ({
  version,
  reportType,
  endDate,
  numPeriods,
}: DshRealizedGainsOverTimeReportIntegrationProps) => {
  const { now } = useDateTime();
  const reportEndDate = useMemo(() => endDate || now(), [endDate]);

  const [reports, setReports] = useState<DshReportData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const timeZone = useTimeZone();

  useEffect(() => {
    console.log('Fetching for reportType:', reportType, ', reportEndDate:', reportEndDate, ', numPeriods:', numPeriods);

    // noinspection JSIgnoredPromiseFromCall
    loadReportData();
  }, [reportType, reportEndDate, numPeriods]);

  const loadReportData = async () => {
    try {
      setLoading(true);

      const reportDates = calcReportDates(reportType, reportEndDate, numPeriods);
      setReports(
        await Promise.all(
          reportDates.map((reportDate) => fetchDshCustomReport(version, reportType, reportDate, timeZone)),
        ),
      );
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DshGainsOverTimeReport
      reports={reports}
      onRefresh={loadReportData}
      loading={loading}
      refreshing={Boolean(loading && reports.length > 0)}
      autoRefresh
    />
  );
};
export default DshRealizedGainsOverTimeReportIntegration;

const calcReportDates = (reportType: ReportType, reportEndDate: DateTime, numPeriods: number): DateTime[] =>
  Array(numPeriods)
    .fill(0)
    .map((_value, period) => calcReportDate(reportType, reportEndDate, period));

const calcReportDate = (reportType: ReportType, reportEndDate: DateTime, period: number) => {
  switch (reportType) {
    case ReportType.Daily:
      return reportEndDate.plus({ days: -period });

    case ReportType.Monthly:
      return reportEndDate.plus({ months: -period });

    default:
      console.warn('Unknown reportType:', reportType);
      return reportEndDate;
  }
};
