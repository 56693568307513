import { gql, NetworkStatus } from '@apollo/client';
import { useSnackbar } from 'notistack';
import React from 'react';
import {
  DshConfigUpdatesInput,
  useDshStrategyAssetConfigIntegrationMutation,
  useDshStrategyAssetConfigIntegrationQuery,
} from '../../../generated/graphql';
import { useErrors } from '../../../hooks/errors';
import { dshStrategyAssetConfigDetailsFragment } from './DshStrategyAssetConfig';
import DshStrategyAssetConfigAccordion from './DshStrategyAssetConfigAccordion';

export interface DshStrategyAssetConfigIntegrationProps {
  version: string;
  asset: string;
}

const DshStrategyAssetConfigIntegration = ({ version, asset }: DshStrategyAssetConfigIntegrationProps) => {
  const { data, refetch, networkStatus, loading } = useDshStrategyAssetConfigIntegrationQuery({
    fetchPolicy: 'cache-and-network',
    variables: { version, asset },
  });
  const { enqueueSnackbar } = useSnackbar();
  const { showError, clearError } = useErrors();

  const [updateDshConfig, { loading: updating }] = useDshStrategyAssetConfigIntegrationMutation();

  const handleUpdateDshConfig = async (newDshConfig: DshConfigUpdatesInput) => {
    clearError();

    try {
      await updateDshConfig({
        variables: {
          input: newDshConfig,
        },
      });
      enqueueSnackbar('Submitted DSH Config update successfully.', { variant: 'success' });
    } catch (e) {
      showError(e);
    }
  };

  const dshVersion = data?.dshVersion;

  return (
    <DshStrategyAssetConfigAccordion
      version={version}
      asset={asset}
      dshConfig={dshVersion?.asset?.config}
      active={dshVersion?.active}
      onRefresh={refetch}
      onSubmitDshConfigUpdate={handleUpdateDshConfig}
      submittingDshConfigUpdate={updating}
      loading={loading}
      refreshing={networkStatus === NetworkStatus.refetch}
    />
  );
};
export default DshStrategyAssetConfigIntegration;

gql`
  query DshStrategyAssetConfigIntegrationQuery($version: ID!, $asset: String!) {
    dshVersion(id: $version) {
      asset(asset: $asset) {
        id
        config {
          id
          ...DshStrategyAssetConfigDetails
        }
      }
      active
    }
  }

  mutation DshStrategyAssetConfigIntegrationMutation($input: DshConfigUpdatesInput!) {
    updateDshConfig(input: $input)
  }

  ${dshStrategyAssetConfigDetailsFragment}
`;
