import Typography from '@mui/material/Typography';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import * as React from 'react';

const NoDataError = () => (
  <DetailContainer>
    <Typography variant="h6" color="error">
      No data returned
    </Typography>
  </DetailContainer>
);
export default NoDataError;
