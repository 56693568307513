import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import SubSectionTitle from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import React, { useEffect } from 'react';
import { useFormFields } from '../../hooks/forms';
import { ReportTableOrientation, ReportTableSettings } from './ledgerReportModel';

export interface LedgerReportTableChoicesProps {
  tableSettings: ReportTableSettings;

  onChangeTableSettings: (tableSettings: ReportTableSettings) => void;
}

interface LedgerReportTableChoicesFields {
  orientation: ReportTableOrientation;
}

const LedgerReportTableChoices = ({ tableSettings, onChangeTableSettings }: LedgerReportTableChoicesProps) => {
  const { fields, handleFieldChange } = useFormFields<LedgerReportTableChoicesFields>({
    orientation: tableSettings.orientation,
  });

  useEffect(() => {
    updateTableSettings();
  }, [fields]);

  const updateTableSettings = () => {
    onChangeTableSettings({
      ...tableSettings,
      orientation: fields.orientation,
    });
  };

  return (
    <>
      <SubSectionTitle>Table View</SubSectionTitle>
      <DetailField>
        <DetailTitle>Orientation</DetailTitle>
        <RadioGroup name="orientation" row onChange={handleFieldChange} value={fields.orientation}>
          <FormControlLabel
            radioGroup="orientation"
            value={ReportTableOrientation.Horizontal}
            control={<Radio />}
            label="Horizontal"
          />
          <FormControlLabel
            radioGroup="orientation"
            value={ReportTableOrientation.Vertical}
            control={<Radio />}
            label="Vertical"
          />
        </RadioGroup>
      </DetailField>
    </>
  );
};
export default LedgerReportTableChoices;
