import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import SubSectionTitle from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import React, { useState } from 'react';
import ExpandIcon from '../common/icons/ExpandIcon';
import DshAssetReportIntegration from './DshAssetReportIntegration';

export interface DshStrategyAssetReportsProps {
  version: string;
  asset: string;
}

const DshStrategyAssetReports = ({ version, asset }: DshStrategyAssetReportsProps) => {
  const startExpanded = false;
  const [reportName, setReportName] = useState<string>('ledger');
  const [expanded, setExpanded] = useState<boolean>(startExpanded);

  const handleSelectReport = (event, newReportName: string) => {
    setReportName(newReportName);
  };

  const handleExpandCollapse = (_event: React.SyntheticEvent, expanded: boolean) => {
    setExpanded(expanded);
  };

  return (
    <Accordion defaultExpanded={startExpanded} onChange={handleExpandCollapse}>
      <AccordionSummary expandIcon={<ExpandIcon />}>
        <Toolbar>
          <SubSectionTitle>{asset} Tables</SubSectionTitle>
        </Toolbar>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={reportName}
            onChange={handleSelectReport}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <Tab label="Ledger" value="ledger" />
          </Tabs>
        </Box>
        {expanded ? <DshAssetReportIntegration reportName={reportName} version={version} asset={asset} /> : null}
      </AccordionDetails>
    </Accordion>
  );
};
export default DshStrategyAssetReports;
