import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import React from 'react';
import { FundsTableRow_Fund } from '../../generated/graphql';
import NoDataError from '../common/page/NoDataError';
import FundsTable, { FundClickEventHandler } from './FundsTable';

export interface FundsSectionProps extends RefreshingComponentProps {
  funds?: readonly FundsTableRow_Fund[];
  onClickFund?: FundClickEventHandler;
  includeDeletedFunds?: boolean;
  onUpdateIncludeDeletedFunds?: (includeDeletedFunds: boolean) => void;
}

const FundsSection = ({
  funds,
  loading,
  refreshing,
  onRefresh,
  autoRefresh,
  onClickFund,
  includeDeletedFunds,
  onUpdateIncludeDeletedFunds,
}: FundsSectionProps) => (
  <Paper>
    <Toolbar>
      <SectionTitle>Funds</SectionTitle>
      <HorizontalAutoSpacer />
      <RefreshIconButton
        refreshing={refreshing}
        loading={loading}
        onRefresh={onRefresh}
        autoRefresh={autoRefresh}
        includeRefreshText
      />
    </Toolbar>
    {onUpdateIncludeDeletedFunds ? (
      <DetailContainer>
        <FormControlLabel
          control={
            <Switch defaultChecked={includeDeletedFunds} onChange={(_, value) => onUpdateIncludeDeletedFunds(value)} />
          }
          label="Include Deleted"
        />
      </DetailContainer>
    ) : null}

    {funds ? (
      <FundsTable funds={funds} onClickFundRow={onClickFund} />
    ) : !(loading || refreshing) ? (
      <NoDataError />
    ) : null}
  </Paper>
);
export default FundsSection;
