import { ApprovalStatus, ApprovalType, WorkflowReference_Workflow } from '../../generated/graphql';
import { snakeOrKebabToDisplay } from '../../utils/strings';
import { buildWorkflowReferenceTitle } from '../workflows/workflowUtils';

export const buildApprovalTitle = (approvalId?: string, workflow?: WorkflowReference_Workflow) =>
  workflow ? `${buildWorkflowReferenceTitle(workflow)} Approval` : approvalId ? `Approval ${approvalId}` : 'Approval';

export const buildApprovalTypeDisplay = (type: ApprovalType) => snakeOrKebabToDisplay(type);

export const buildApprovalStatusDisplay = (status: ApprovalStatus) => snakeOrKebabToDisplay(status);
