import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import { DateTimePicker } from '@mui/x-date-pickers';
import DefaultTextField from '@paypr/mui5-common-components/dist/components/forms/DefaultTextField';
import LoaderButton from '@paypr/mui5-common-components/dist/components/forms/LoaderButton';
import RouteButton from '@paypr/mui5-common-components/dist/components/routes/RouteButton';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import { DateTime } from 'luxon';
import React from 'react';
import { useDateTime } from '../../../data/dates';
import { LedgerAccountEventType, PortfolioLedgerEventInput } from '../../../generated/graphql';
import { useFormFields } from '../../../hooks/forms';
import { buildPortfolioTitle } from '../PortfolioDetail';
import PortfolioIcon from '../PortfolioIcon';
import { buildPortfolioPath } from '../portfolioPaths';

export interface AddPortfolioLedgerEventFormProps {
  portfolioId: string;
  portfolioName: string;

  onAddPortfolioLedgerEvent: (portfolioLedgerEventInput: PortfolioLedgerEventInput) => Promise<void>;

  loading?: boolean;
  disabled?: boolean;
}

interface PortfolioLedgerEventFormFields {
  type: LedgerAccountEventType;
  value: string;
  occurredAt: DateTime;
  occurredAtNow: string;
  notes: string;
}

const AddPortfolioLedgerEventForm = ({
  portfolioId,
  portfolioName,
  onAddPortfolioLedgerEvent,
  loading,
  disabled,
}: AddPortfolioLedgerEventFormProps) => {
  const { now } = useDateTime();
  const { fields, handleFieldChange, setFieldValue } = useFormFields<PortfolioLedgerEventFormFields>({
    type: LedgerAccountEventType.Version,
    value: '',
    occurredAt: now(),
    occurredAtNow: 'true',
    notes: '',
  });

  const handleOccurredAtChange = (value: DateTime) => {
    setFieldValue('occurredAt', value);
  };

  const formValid = fields.value !== '' && fields.occurredAt.isValid;

  const handleAddPortfolioLedgerEvent = async () => {
    onAddPortfolioLedgerEvent({
      portfolioId,
      type: fields.type,
      value: fields.value,
      occurredAt: fields.occurredAt.toISO(),
      notes: fields.notes,
    });
  };

  return (
    <Paper>
      <Toolbar>
        <PortfolioIcon />
        &nbsp;
        <SectionTitle>Add {buildPortfolioTitle(portfolioName)} Event</SectionTitle>
      </Toolbar>
      <DetailContainer>
        <DetailField>
          <DetailTitle>Occurred on</DetailTitle>
          <RadioGroup name="occurredAtNow" row onChange={handleFieldChange} value={fields.occurredAtNow}>
            <FormControlLabel
              radioGroup="occurredAtNow"
              value="true"
              control={<Radio />}
              label="Occurred Just Now"
              disabled={disabled}
            />
            <FormControlLabel
              radioGroup="occurredAtNow"
              value="false"
              control={<Radio />}
              label="Choose When Occurred"
              disabled={disabled}
            />
          </RadioGroup>
          {fields.occurredAtNow !== 'true' ? (
            <DetailField>
              <DateTimePicker
                label="Occurred At"
                value={fields.occurredAt}
                onChange={handleOccurredAtChange}
                disabled={disabled}
              />
            </DetailField>
          ) : null}
        </DetailField>
        <DetailField>
          <DefaultTextField
            name="value"
            label="Version"
            autoComplete="off"
            value={fields.value}
            required
            onChange={handleFieldChange}
          />
        </DetailField>
        <DetailField>
          <DefaultTextField
            name="notes"
            label="Notes"
            autoComplete="off"
            value={fields.notes}
            multiline
            rows={4}
            onChange={handleFieldChange}
          />
        </DetailField>
        <DetailField textAlign="right">
          <Stack direction="row" spacing={2}>
            <RouteButton path={buildPortfolioPath(portfolioId)} disabled={loading} color="inherit" variant="text">
              Back to Portfolio
            </RouteButton>
            <LoaderButton loading={loading} disabled={!formValid} onClick={handleAddPortfolioLedgerEvent}>
              Add Event
            </LoaderButton>
          </Stack>
        </DetailField>
      </DetailContainer>
    </Paper>
  );
};
export default AddPortfolioLedgerEventForm;
