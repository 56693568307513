import { gql } from '@apollo/client';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import AlternatingTableRow from '@paypr/mui5-common-components/dist/components/tables/AlternatingTableRow';
import EmptyTableRow from '@paypr/mui5-common-components/dist/components/tables/EmptyTableRow';
import {
  SimpleSortingTableHeadCell,
  SimpleSortingTableHeadProps,
} from '@paypr/mui5-common-components/dist/components/tables/SortingTableHead';
import { getComparator, stableSort, useSorting } from '@paypr/mui5-common-components/dist/components/tables/sortUtils';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { WorkflowsTableData_WorkflowConnection, WorkflowsTableRow_Workflow } from '../../generated/graphql';
import HeightLimitedTableContainer from '../common/tables/HeightLimitedTableContainer';
import SimpleSortingTableHead from '../common/tables/SortingTableHead';
import { buildWorkflowReferenceTitle, buildWorkflowStatusDisplay, buildWorkflowTypeDisplay } from './workflowUtils';

interface WorkflowsTableProps {
  workflowConnection?: WorkflowsTableData_WorkflowConnection;
  workflows?: readonly WorkflowsTableRow_Workflow[];
  onClickWorkflowRow?: WorkflowClickEventHandler;
  fullHeight?: boolean;
}

export type WorkflowClickEventHandler = (workflowId: string, newTab: boolean) => void;

interface WorkflowsTableRowData extends WorkflowsTableRow_Workflow {
  userFullName: string;
}

const WorkflowsTable = ({ workflows, workflowConnection, onClickWorkflowRow, fullHeight }: WorkflowsTableProps) => {
  const { order, orderBy, handleRequestSort } = useSorting<WorkflowsTableRowData>('createdAt', 'desc');

  const createClickHandler = (workflowId: string) => {
    if (!onClickWorkflowRow) {
      return undefined;
    }
    return (event) => onClickWorkflowRow(workflowId, event.ctrlKey || event.metaKey);
  };

  const workflowList: WorkflowsTableRowData[] = buildWorkflows({ workflowConnection, workflows });

  return (
    <HeightLimitedTableContainer fullHeight={fullHeight}>
      <Table stickyHeader>
        <WorkflowsTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
        <TableBody>
          {workflowList.length > 0 ? (
            stableSort(workflowList, getComparator(order, orderBy)).map((workflow) => (
              <WorkflowTableRow key={workflow.id} workflow={workflow} onRowClick={createClickHandler(workflow.id)} />
            ))
          ) : (
            <EmptyTableRow columnCount={columnHeadings.length}>No workflows were found.</EmptyTableRow>
          )}
        </TableBody>
      </Table>
    </HeightLimitedTableContainer>
  );
};
export default WorkflowsTable;

export interface BuildWorkflowsOptions {
  workflowConnection?: WorkflowsTableData_WorkflowConnection;
  workflows?: readonly WorkflowsTableRow_Workflow[];
}

export const buildWorkflows = ({ workflowConnection, workflows }: BuildWorkflowsOptions) =>
  workflowConnection
    ? workflowConnection.edges.map(({ node }) => node).map(buildWorkflowRowData)
    : workflows
    ? workflows.map(buildWorkflowRowData)
    : [];

const buildWorkflowRowData = (workflow: WorkflowsTableRow_Workflow) => ({
  ...workflow,
  userFullName: workflow.user.fullName,
});

type WorkflowsTableHeadProps = Omit<SimpleSortingTableHeadProps<WorkflowsTableRowData>, 'headings'>;

const columnHeadings: SimpleSortingTableHeadCell<WorkflowsTableRowData>[] = [
  { key: 'title', label: 'Title' },
  { key: 'userFullName', label: 'Initiator' },
  { key: 'type', label: 'Type' },
  { key: 'status', label: 'Status' },
  { key: 'updatedAt', label: 'Last Update', align: 'right' },
  { key: 'createdAt', label: 'Started', align: 'right' },
];

const WorkflowsTableHead = (props: WorkflowsTableHeadProps) => (
  <SimpleSortingTableHead {...props} headings={columnHeadings} />
);

type WorkflowTableRowProps = {
  workflow: WorkflowsTableRowData;
  onRowClick?: React.MouseEventHandler;
};

export const WorkflowTableRow = ({ workflow, onRowClick }: WorkflowTableRowProps) => {
  const { formatShortDateTime } = useDateTime();

  return (
    <AlternatingTableRow hover={Boolean(onRowClick)} onClick={onRowClick}>
      <TableCell>{buildWorkflowReferenceTitle(workflow)}</TableCell>
      <TableCell>{workflow.userFullName}</TableCell>
      <TableCell>{buildWorkflowTypeDisplay(workflow.type)} </TableCell>
      <TableCell>{buildWorkflowStatusDisplay(workflow.status)} </TableCell>
      <TableCell align="right">{formatShortDateTime(workflow.updatedAt)}</TableCell>
      <TableCell align="right">{formatShortDateTime(workflow.createdAt)}</TableCell>
    </AlternatingTableRow>
  );
};

export const workflowsTableRowFragment = gql`
  fragment WorkflowsTableData_WorkflowConnection on WorkflowConnection {
    edges {
      node {
        id
        ...WorkflowsTableRow_Workflow
      }
    }
  }

  fragment WorkflowsTableRow_Workflow on Workflow {
    id
    title
    type
    status

    user {
      id
      fullName
    }

    createdAt
    updatedAt
  }
`;
