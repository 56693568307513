import BigNumberFormat, {
  BigNumberFormatProps,
} from '@paypr/mui5-common-components/dist/components/typography/BigNumberFormat';
import React from 'react';

export interface FloatFormatProps extends Omit<BigNumberFormatProps, 'prefix' | 'suffix' | ' fixedDecimalScale'> {
  asset?: string;
}

const FloatFormat = ({ asset, decimalScale, ...props }: FloatFormatProps) => (
  <BigNumberFormat
    {...props}
    decimalScale={decimalScale !== undefined ? decimalScale : 4}
    suffix={asset ? `\u00A0${asset}` : undefined}
    fixedDecimalScale
  />
);
export default FloatFormat;
