import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import React from 'react';
import { TransactionsTableData_TransactionConnection } from '../../generated/graphql';
import NoDataError from '../common/page/NoDataError';
import TransactionsTable, { TransactionClickEventHandler } from './TransactionsTable';

export interface TransactionsSectionProps extends RefreshingComponentProps {
  transactions?: TransactionsTableData_TransactionConnection;
  onClickTransaction?: TransactionClickEventHandler;
}

const TransactionsSection = ({
  transactions,
  loading,
  refreshing,
  onRefresh,
  autoRefresh,
  onClickTransaction,
}: TransactionsSectionProps) => (
  <Paper>
    <Toolbar>
      <SectionTitle>Transactions</SectionTitle>
      <HorizontalAutoSpacer />
      <RefreshIconButton refreshing={refreshing} loading={loading} onRefresh={onRefresh} autoRefresh={autoRefresh} />
    </Toolbar>

    {transactions ? (
      <TransactionsTable transactionConnection={transactions} onClickTransactionRow={onClickTransaction} />
    ) : !(loading || refreshing) ? (
      <NoDataError />
    ) : null}
  </Paper>
);
export default TransactionsSection;
