import { Priority, Timeframe } from '../../../generated/graphql';

export const buildTimeframeDisplay = (timeframe: Timeframe): string => {
  switch (timeframe) {
    case Timeframe.Immediate:
      return 'Immediate';
    case Timeframe.Seconds:
      return 'Seconds';
    case Timeframe.Minutes:
      return 'Minutes';
    case Timeframe.Hours:
      return 'Hours';
    case Timeframe.Anytime:
      return 'Anytime';
  }

  return timeframe;
};

export const buildPriorityDisplay = (priority: Priority): string => {
  switch (priority) {
    case Priority.High:
      return 'High';
    case Priority.Normal:
      return 'Normal';
    case Priority.Low:
      return 'Low';
  }

  return priority;
};
