import { gql } from '@apollo/client';
import PageContainer from '@paypr/mui5-common-components/dist/components/page/PageContainer';
import { useSnackbar } from 'notistack';
import React from 'react';
import AppPage from '../../components/common/page/AppPage';
import AppPageError from '../../components/common/page/AppPageError';
import AppPageMessage from '../../components/common/page/AppPageMessage';
import AddPortfolioLedgerEventForm from '../../components/portfolios/ledgerEvents/AddPortfolioLedgerEventForm';
import { buildPortfolioTitle } from '../../components/portfolios/PortfolioDetail';
import { buildPortfolioPath, PortfolioLedgerEventPageParams } from '../../components/portfolios/portfolioPaths';
import {
  PortfolioLedgerEventInput,
  useAddPortfolioLedgerEventPageMutation,
  useAddPortfolioLedgerEventPageQuery,
} from '../../generated/graphql';
import { useErrors } from '../../hooks/errors';
import { usePageParams, usePageTitle } from '../../hooks/page';
import { usePaths } from '../../hooks/paths';

const AddPortfolioLedgerEventPage = () => {
  const { portfolioId } = usePageParams<keyof PortfolioLedgerEventPageParams>();
  const { navigateTo } = usePaths();
  const { enqueueSnackbar } = useSnackbar();
  const { showError } = useErrors();

  const { data, error, loading } = useAddPortfolioLedgerEventPageQuery({
    fetchPolicy: 'cache-and-network',
    variables: { portfolioId },
  });

  const [addPortfolioLedgerEvent, { loading: updating }] = useAddPortfolioLedgerEventPageMutation();

  usePageTitle(`Add Portfolio Event - ${buildPortfolioTitle(data?.portfolio?.name)}`);

  if (error) {
    return <AppPageError error={error} />;
  }

  const portfolio = data?.portfolio;

  if (!portfolio) {
    return <AppPageMessage text={`Portfolio ${portfolioId} does not exist`} />;
  }

  const handleAddPortfolioLedgerEvent = async (newPortfolioLedgerEvent: PortfolioLedgerEventInput) => {
    if (!confirm(`Are you sure you want to add the event to ${buildPortfolioTitle(portfolio.name)}?`)) {
      return;
    }

    try {
      await addPortfolioLedgerEvent({
        variables: {
          input: newPortfolioLedgerEvent,
        },
      });
      enqueueSnackbar('Event added successfully.', { variant: 'success' });

      navigateTo(buildPortfolioPath(portfolioId));
    } catch (e) {
      showError(e);
    }
  };

  return (
    <AppPage>
      <PageContainer>
        <AddPortfolioLedgerEventForm
          portfolioId={portfolio.id}
          portfolioName={portfolio.name}
          onAddPortfolioLedgerEvent={handleAddPortfolioLedgerEvent}
          loading={loading || updating}
        />
      </PageContainer>
    </AppPage>
  );
};
export default AddPortfolioLedgerEventPage;

gql`
  query AddPortfolioLedgerEventPageQuery($portfolioId: ID!) {
    portfolio(id: $portfolioId) {
      id
      name
    }
  }

  mutation AddPortfolioLedgerEventPageMutation($input: PortfolioLedgerEventInput!) {
    createPortfolioLedgerEvent(input: $input) {
      id
    }
  }
`;
