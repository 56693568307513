import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DefaultTextField from '@paypr/mui5-common-components/dist/components/forms/DefaultTextField';
import LoaderButton from '@paypr/mui5-common-components/dist/components/forms/LoaderButton';
import SecondaryButton from '@paypr/mui5-common-components/dist/components/forms/SecondaryButton';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import React, { FormEvent } from 'react';
import { CreateSubsystemInput } from '../../generated/graphql';
import { useFormFields } from '../../hooks/forms';

export interface CreateSubsystemFormProps {
  onCreateSubsystem: (createSubsystemInput: CreateSubsystemInput) => void;
  onCancel?: () => void;
  creating?: boolean;
}

interface CreateSubsystemFields {
  name: string;
  label: string;
}

const CreateSubsystemForm = ({ onCreateSubsystem, onCancel, creating }: CreateSubsystemFormProps) => {
  const { fields, handleFieldChange } = useFormFields<CreateSubsystemFields>({
    name: '',
    label: '',
  });

  const formValid = fields.name.trim().length > 0;

  const handleCreateSubsystem = (event: FormEvent) => {
    event.preventDefault();

    onCreateSubsystem({
      name: fields.name.trim(),
      label: fields.label.trim().length > 0 ? fields.label.trim() : undefined,
    });
  };

  return (
    <form onSubmit={handleCreateSubsystem}>
      <DialogContent>
        <DefaultTextField
          name="name"
          label="Name"
          value={fields.name}
          onChange={handleFieldChange}
          required
          autoFocus
        />
        <DefaultTextField name="label" label="Label" value={fields.label} onChange={handleFieldChange} />
      </DialogContent>
      <DialogActions>
        <DetailField>
          {onCancel ? <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton> : null}
          &nbsp;
          <LoaderButton type="submit" loading={creating} disabled={!formValid} color="primary">
            Create
          </LoaderButton>
        </DetailField>
      </DialogActions>
    </form>
  );
};
export default CreateSubsystemForm;
