import { gql } from '@apollo/client';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import LoaderButton from '@paypr/mui5-common-components/dist/components/forms/LoaderButton';
import SecondaryButton from '@paypr/mui5-common-components/dist/components/forms/SecondaryButton';
import SubSectionTitle from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import React, { useEffect } from 'react';
import { UpdateClientFundDialog_ClientFund, UpdateClientFundInput } from '../../../generated/graphql';
import { useFormFields } from '../../../hooks/forms';
import { isTextPositiveFloatOrZero, trimToNull } from '../../../utils/strings';
import { buildClientFundTitle } from '../clientFundUtils';
import UpdateClientFundChanges from './UpdateClientFundChanges';
import UpdateClientFundEditor, { UpdateClientFundFields } from './UpdateClientFundEditor';

export interface UpdateClientFundDialogProps extends Omit<DialogProps, 'onClose' | 'maxWidth'> {
  clientFund: UpdateClientFundDialog_ClientFund;
  onSubmitClientFundUpdate: SubmitClientFundUpdateEventHandler;
  submitting?: boolean;
  onClose: (
    event: React.MouseEvent<HTMLButtonElement> | {},
    reason: 'backdropClick' | 'escapeKeyDown' | 'cancelClicked',
  ) => void;
}

export type SubmitClientFundUpdateEventHandler = (input: UpdateClientFundInput) => void | Promise<void>;

const UpdateClientFundDialog = ({
  clientFund,
  onSubmitClientFundUpdate,
  onClose,
  submitting,
  open,
  ...props
}: UpdateClientFundDialogProps) => {
  const startingPage = 1;
  const maxPages = 2;
  const [page, setPage] = React.useState(startingPage);

  const initialState = {
    managementFeePercent: (clientFund.managementFeePercent * 100).toString(),
    performanceFeePercent: (clientFund.performanceFeePercent * 100).toString(),
    navId: clientFund.navId || '',
  };
  const { fields, handleFieldChange, setFieldValue, resetFields } = useFormFields<UpdateClientFundFields>(initialState);

  useEffect(() => {
    if (!open) {
      return;
    }

    resetFields();
    setPage(startingPage);
  }, [open]);

  const newManagementFeePercent = isTextPositiveFloatOrZero(fields.managementFeePercent)
    ? parseFloat(fields.managementFeePercent) / 100
    : clientFund.performanceFeePercent;

  const newPerformanceFeePercent = isTextPositiveFloatOrZero(fields.performanceFeePercent)
    ? parseFloat(fields.performanceFeePercent) / 100
    : clientFund.performanceFeePercent;

  const hasChanges = JSON.stringify(initialState) !== JSON.stringify(fields);

  const formValid =
    hasChanges &&
    isTextPositiveFloatOrZero(fields.managementFeePercent) &&
    parseFloat(fields.managementFeePercent) <= 100 &&
    isTextPositiveFloatOrZero(fields.performanceFeePercent) &&
    parseFloat(fields.performanceFeePercent) <= 100;

  const updates = {
    clientId: clientFund.client.id,
    fundId: clientFund.fund.id,
    managementFeePercent: newManagementFeePercent,
    performanceFeePercent: newPerformanceFeePercent,
    navId: trimToNull(fields.navId),
  };

  const handleSubmitChange = async () => await onSubmitClientFundUpdate(updates);

  const nextPage = () => {
    setPage((page) => page + 1);
  };

  const prevPage = () => {
    setPage((page) => page - 1);
  };

  return (
    <Dialog {...props} open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>Update {buildClientFundTitle(clientFund.client.fullName, clientFund.fund.name)}</DialogTitle>
      <DialogContent>
        {page == 1 && (
          <UpdateClientFundEditor
            values={clientFund}
            fundManagementFeePercent={clientFund.fund.managementFeePercent}
            fundPerformanceFeePercent={clientFund.fund.performanceFeePercent}
            fields={fields}
            handleFieldChange={handleFieldChange}
            setFieldValue={setFieldValue}
          />
        )}
        {page == 2 && (
          <>
            <SubSectionTitle>Verify Changes</SubSectionTitle>
            <UpdateClientFundChanges
              oldValues={clientFund}
              fundManagementFeePercent={clientFund.fund.managementFeePercent}
              fundPerformanceFeePercent={clientFund.fund.performanceFeePercent}
              newValues={updates}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={1}>
          <SecondaryButton onClick={(event) => onClose(event, 'cancelClicked')} disabled={submitting}>
            Cancel
          </SecondaryButton>

          {page < maxPages && (
            <SecondaryButton onClick={() => resetFields()} disabled={submitting}>
              Reset
            </SecondaryButton>
          )}

          {page > startingPage && (
            <SecondaryButton onClick={prevPage} disabled={submitting}>
              Back
            </SecondaryButton>
          )}

          {page < maxPages && (
            <Button color="inherit" onClick={nextPage} disabled={!formValid || submitting}>
              Next
            </Button>
          )}

          {page == maxPages && (
            <LoaderButton loading={submitting} disabled={!formValid} onClick={handleSubmitChange}>
              Submit Update
            </LoaderButton>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
export default UpdateClientFundDialog;

export const updateClientFundDialogFragment = gql`
  fragment UpdateClientFundDialog_ClientFund on ClientFund {
    id
    client {
      id
      fullName
    }
    fund {
      id
      name
      managementFeePercent
      performanceFeePercent
    }
    managementFeePercent
    performanceFeePercent
    navId
  }
`;
