import { gql } from '@apollo/client';
import Grid from '@mui/material/Grid';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import DetailValue from '@paypr/mui5-common-components/dist/components/typography/DetailValue';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { SubsystemDetail_Subsystem } from '../../generated/graphql';
import { AuditActionClickEventHandler } from '../auditActions/AuditActionsTable';
import DetailGridItem from '../common/containers/DetailGridItem';
import ExplodingContainer from '../common/containers/ExplodingContainer';
import SubsystemAuditActionsSectionIntegration from './SubsystemAuditActionsSectionIntegration';
import SubsystemDeletedNote, { subsystemDeletedNoteFragment } from './SubsystemDeletedNote';

export type SubsystemDetailData = SubsystemDetail_Subsystem;

interface SubsystemDetailProps {
  subsystem: SubsystemDetailData;
  onClickAuditAction?: AuditActionClickEventHandler;
  autoRefresh?: boolean | number;
}

const SubsystemDetail = ({ subsystem, onClickAuditAction, autoRefresh }: SubsystemDetailProps) => {
  const { formatDateTime } = useDateTime();

  return (
    <>
      <DetailContainer>
        <SubsystemDeletedNote subsystem={subsystem} />

        <Grid container justifyContent="left" spacing={2}>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Label</DetailTitle>
              <DetailValue>{subsystem.label}</DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Name</DetailTitle>
              <DetailValue>{subsystem.name}</DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Created</DetailTitle>
              <DetailValue>{formatDateTime(subsystem.createdAt)}</DetailValue>
            </DetailField>
          </DetailGridItem>
        </Grid>
      </DetailContainer>
      <ExplodingContainer
        renderComponent={(exploded, onExplodeChanged) => (
          <SubsystemAuditActionsSectionIntegration
            subsystemId={subsystem.id}
            onClickAuditAction={onClickAuditAction}
            exploded={exploded}
            onExplodeChanged={onExplodeChanged}
            autoRefresh={autoRefresh}
          />
        )}
      />
    </>
  );
};
export default SubsystemDetail;

export const buildSubsystemTitle = (label?: string) => label || 'Subsystem';

export const subsystemDetailFragment = gql`
  fragment SubsystemDetail_Subsystem on Subsystem {
    id
    name
    label
    createdAt

    ...SubsystemDeletedNote_Subsystem
  }

  ${subsystemDeletedNoteFragment}
`;
