import { gql } from '@apollo/client';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import React, { useState } from 'react';
import AppPage from '../../components/common/page/AppPage';
import WiderPageContainer from '../../components/common/page/WiderPageContainer';
import { useQuery } from '../../components/common/routes/paths';
import FavoriteReportDialog from '../../components/reports/FavoriteReportDialog';
import {
  favoriteLedgerReportFromSettings,
  favoriteLedgerReportToSettings,
  useFavoriteReports,
} from '../../components/reports/favorites';
import LedgerReportChoicesContainer from '../../components/reports/LedgerReportChoicesContainer';
import {
  ledgerReportChoiceClientFragment,
  ledgerReportChoiceFundFragment,
  ledgerReportChoicePortfolioFragment,
  ledgerReportChoiceStrategyFragment,
} from '../../components/reports/LedgerReportDataChoices';
import { determineStartAtFromStandardTimeframe, possibleAssets } from '../../components/reports/ledgerReportHelpers';
import LedgerReportIntegration from '../../components/reports/LedgerReportIntegration';
import {
  LedgerReportSettings,
  LedgerReportStandardTimeframe,
  ReportTableOrientation,
  ReportView,
} from '../../components/reports/ledgerReportModel';
import {
  buildReportUrlWithDefinition,
  extractReportSettingsFromUrl,
  ReportPageQueryParams,
} from '../../components/reports/ledgerReportPaths';
import { buildFavoriteReportPath, latestReportId } from '../../components/reports/reportPaths';
import { useDateTime } from '../../data/dates';
import {
  LedgerReportGroupField,
  LedgerReportTimeResolution,
  LedgerReportValueField,
  useReportsPageQuery,
} from '../../generated/graphql';
import { usePageTitle } from '../../hooks/page';
import { usePaths } from '../../hooks/paths';

const latestReportName = 'Latest';

const ReportsPage = () => {
  usePageTitle('Reports');

  const { getFavoriteReport, addFavoriteReport } = useFavoriteReports();

  const [favoriteDialogOpen, setFavoriteDialogOpen] = useState<boolean>(false);
  const { now, toDateTime } = useDateTime();

  const { data } = useReportsPageQuery({ fetchPolicy: 'cache-and-network' });

  const { navigateTo } = usePaths();
  const { params } = useQuery<ReportPageQueryParams>();

  const editFavorite = params.get('editFavorite') || undefined;

  const [loading, setLoading] = useState<boolean>(false);
  const [refreshCount, setRefreshCount] = useState<number>(0);

  const latestReport = getFavoriteReport(latestReportId);

  const defaultReportSettings: LedgerReportSettings = {
    definition: {
      standardTimeframe: LedgerReportStandardTimeframe.Month,
      startAt: determineStartAtFromStandardTimeframe(LedgerReportStandardTimeframe.Month, { now, toDateTime }),
      timeResolution: LedgerReportTimeResolution.Day,
      groupBy: [LedgerReportGroupField.Strategy],
      includeTotals: false,
      values: [
        LedgerReportValueField.PnlCumulativeRoi,
        LedgerReportValueField.CryptoMarketPnlCumulativeRoi,
        LedgerReportValueField.EquityMarketPnlCumulativeRoi,
      ],
      marketAssets: ['BTC', 'ETH'],
    },
    view: ReportView.Chart,
    chartSettings: {},
    tableSettings: { orientation: ReportTableOrientation.Horizontal },
  };

  const startingReportSettings = extractReportSettingsFromUrl(
    params,
    latestReport ? favoriteLedgerReportToSettings(latestReport) : defaultReportSettings,
    { toDateTime },
  );

  const [reportSettings, setReportSettings] = useState<LedgerReportSettings>(startingReportSettings);
  const [optionsExpanded, setOptionsExpanded] = useState<boolean>(params.get('optionsExpanded') === 'true');

  const handleLedgerReportSettingsChange = (newReportSettings: LedgerReportSettings) => {
    updateReportSettings(newReportSettings, optionsExpanded);
  };

  const handleExpandOptionsChanged = (expanded: boolean) => {
    updateReportSettings(reportSettings, expanded);
  };

  const handleResetReportDefinitionAndView = () => {
    updateReportSettings(defaultReportSettings, optionsExpanded);
    setRefreshCount(refreshCount + 1);
  };

  const updateReportSettings = (newReportSettings: LedgerReportSettings, newOptionsExpanded: boolean) => {
    setReportSettings(newReportSettings);
    setOptionsExpanded(newOptionsExpanded);

    addFavoriteReport(favoriteLedgerReportFromSettings(latestReportId, latestReportName, newReportSettings));
    navigateTo(buildReportUrlWithDefinition(newReportSettings, newOptionsExpanded, editFavorite));
  };

  const openFavoriteDialog = () => {
    setFavoriteDialogOpen(true);
  };

  const handleFavoriteReport = (reportName) => {
    const reportId = reportName
      .replace(/[^A-Za-z0-9_-]/g, '-')
      .replace(/-+/g, '-')
      .toLowerCase();
    addFavoriteReport(favoriteLedgerReportFromSettings(reportId, reportName, reportSettings));

    closeFavoriteDialog();

    navigateTo(buildFavoriteReportPath(reportId));
  };

  const closeFavoriteDialog = () => {
    setFavoriteDialogOpen(false);
  };

  const handleLoading = (newLoading: boolean) => {
    setLoading(newLoading);
  };

  return (
    <AppPage>
      <WiderPageContainer>
        <SectionTitle>Reports</SectionTitle>
        <LedgerReportChoicesContainer
          key={`ledgerReportChoices-${refreshCount}`}
          reportSettings={startingReportSettings}
          defaultExpanded={optionsExpanded}
          assets={possibleAssets}
          clients={data?.clients}
          funds={data?.funds}
          portfolios={data?.portfolios}
          strategies={data?.strategies}
          onResetReportSettings={handleResetReportDefinitionAndView}
          onFavoriteCurrentReportSettings={openFavoriteDialog}
          onChangeReportSettings={handleLedgerReportSettingsChange}
          onOptionsExpandedChanged={handleExpandOptionsChanged}
          loading={loading}
        />

        <LedgerReportIntegration reportSettings={reportSettings} onLoading={handleLoading} defaultAutoRefresh />

        <FavoriteReportDialog
          defaultReportName={editFavorite || undefined}
          open={favoriteDialogOpen}
          onFavoriteReport={handleFavoriteReport}
          onClose={closeFavoriteDialog}
        />
      </WiderPageContainer>
    </AppPage>
  );
};
export default ReportsPage;

gql`
  query ReportsPageQuery {
    clients {
      id
      ...LedgerReportChoiceClient
    }

    funds {
      id
      ...LedgerReportChoiceFund
    }

    portfolios {
      id
      ...LedgerReportChoicePortfolio
    }

    strategies {
      id
      ...LedgerReportChoiceStrategy
    }
  }

  ${ledgerReportChoiceClientFragment}
  ${ledgerReportChoiceFundFragment}
  ${ledgerReportChoicePortfolioFragment}
  ${ledgerReportChoiceStrategyFragment}
`;
