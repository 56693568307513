import { DateTime } from 'luxon';
import { DateTimeHook } from '../../data/dates';
import {
  LedgerReportDetailsDetails,
  LedgerReportGroupField,
  LedgerReportResultDetails,
  LedgerReportValueField,
} from '../../generated/graphql';
import {
  determineFieldName,
  findLedgerReportFieldValue,
  findLedgerReportGroupName,
  formatDateTime,
  sortReportValueFields,
} from './ledgerReportHelpers';
import { LedgerReportSettings } from './ledgerReportModel';

export interface ReportGroupValueCombo {
  groupValues: ReportGroupValue[];
}

export interface ReportGroupValue {
  group: LedgerReportGroupField;
  value: string | null;
}

export const extractAllGroupValueCombos = (report: LedgerReportDetailsDetails): ReportGroupValueCombo[] => {
  const allGroupValuesCombos: ReportGroupValueCombo[] = [];

  report.results.forEach((result) => {
    const periodStartAtStr = findLedgerReportFieldValue(result.values, LedgerReportValueField.PeriodStartAt);
    if (!periodStartAtStr) {
      console.warn('No period start at for result:', result);
      return;
    }

    if (allGroupValuesCombos.find((combo) => resultGroupsSameAsCombo(result, combo))) {
      // already have this combo
      return;
    }

    const groupValues = report.groupBy.map((group) => ({
      group,
      value: findLedgerReportGroupName(result.groups, group),
    })) as ReportGroupValue[];

    allGroupValuesCombos.push({
      groupValues,
    });
  });

  allGroupValuesCombos.sort((a, b) => {
    for (let i = 0; i < report.groupBy.length; i += 1) {
      if (a.groupValues[i].value && !b.groupValues[i].value) {
        return 1;
      }
      if (!a.groupValues[i].value && b.groupValues[i].value) {
        return -1;
      }
      if (!a.groupValues[i].value || !b.groupValues[i].value) {
        return 0;
      }

      if (a.groupValues[i].value!! < b.groupValues[i].value!!) {
        return -1;
      }
      if (a.groupValues[i].value!! > b.groupValues[i].value!!) {
        return 1;
      }
    }

    return 0;
  });

  return allGroupValuesCombos;
};

export const resultGroupsSameAsCombo = (result: LedgerReportResultDetails, combo: ReportGroupValueCombo) =>
  result.groups.every(
    ({ field, name }) => name === (combo.groupValues.find((gv) => gv.group === field)?.value || null),
  );

export const extractAllResultsByPeriodStartAt = (
  report: LedgerReportDetailsDetails,
  { toDateTime }: Pick<DateTimeHook, 'toDateTime'>,
): Record<string, LedgerReportResultDetails[]> => {
  const allResultsByPeriodStartAt: Record<DateTime, LedgerReportResultDetails[]> = {};

  report.results.forEach((result) => {
    const periodStartAtStr = findLedgerReportFieldValue(result.values, LedgerReportValueField.PeriodStartAt);
    if (!periodStartAtStr) {
      console.warn('No period start at for result:', result);
      return;
    }
    const periodStartAt = toDateTime(periodStartAtStr);

    if (!allResultsByPeriodStartAt[periodStartAt]) {
      allResultsByPeriodStartAt[periodStartAt] = [];
    }

    allResultsByPeriodStartAt[periodStartAt].push(result);
  });

  return allResultsByPeriodStartAt;
};

export const buildReportCsvData = (
  settings: LedgerReportSettings,
  report: LedgerReportDetailsDetails,
  { toDateTime }: Pick<DateTimeHook, 'toDateTime'>,
): (string | number | null)[][] => {
  const allGroupValuesCombos = extractAllGroupValueCombos(report);
  const allResultsByPeriodStartAt: Record<DateTime, LedgerReportResultDetails[]> = extractAllResultsByPeriodStartAt(
    report,
    { toDateTime },
  );
  const allPeriodStartAts: DateTime[] = Object.keys(allResultsByPeriodStartAt)
    .map((periodStartAtStr) => toDateTime(periodStartAtStr))
    .sort()
    .slice(1);

  const valueFields = sortReportValueFields(
    report.values.filter(
      (field) =>
        field !== LedgerReportValueField.PeriodStartAt &&
        field != LedgerReportValueField.PeriodEndAt &&
        field != LedgerReportValueField.PeriodNumber,
    ),
  );

  const getValueAtPeriod = (periodStartAt, combo: ReportGroupValueCombo, valueField: LedgerReportValueField) => {
    const value = findLedgerReportFieldValue(
      allResultsByPeriodStartAt[periodStartAt].find((result) => resultGroupsSameAsCombo(result, combo))?.values ?? [],
      valueField,
    );
    if (value === null || value === undefined) {
      return null;
    }
    return value;
  };

  const groupHeaderRows = report.groupBy.map((group, groupIndex) => [
    '',
    ...allGroupValuesCombos.flatMap((combo) =>
      valueFields.map(() => combo.groupValues[groupIndex].value || (groupIndex == 0 ? 'Total' : '')),
    ),
  ]);

  const valueHeaderRow = [
    '',
    ...allGroupValuesCombos.flatMap(() => valueFields.map((valueField) => determineFieldName(valueField))),
  ];

  const valueRows = allPeriodStartAts.map((periodStartAt) => [
    formatDateTime(periodStartAt, report.timeResolution),
    ...allGroupValuesCombos.flatMap((combo) =>
      valueFields.map((valueField) => getValueAtPeriod(periodStartAt, combo, valueField)),
    ),
  ]);

  return [...groupHeaderRows, valueHeaderRow, ...valueRows];
};
