// internal users
export const internalUsersPath = '/admin-users';

export interface InternalUsersPageQueryParams {
  includeDeleted: string;
}

export const buildInternalUserPath = (internalUserId: string) => `${internalUsersPath}/${internalUserId}`;

export const addInternalUserPath = buildInternalUserPath('add');

export const parameterizedInternalUserPath = buildInternalUserPath(':internalUserId');
export interface InternalUserPageParams {
  internalUserId: string;
}
