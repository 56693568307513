import { gql } from '@apollo/client';
import NavItem, { NavItemProps } from '@paypr/mui5-common-components/dist/components/page/nav/NavItem';
import * as React from 'react';
import { useMatch } from 'react-router-dom';
import { useInternalUserNavItemQuery } from '../../generated/graphql';
import { buildInternalUserTitle } from './InternalUserDetail';
import { InternalUserPageParams } from './internalUserPaths';

type InternalUserNavItemProps = Omit<NavItemProps, 'title'>;

const InternalUserNavItem = ({ path, ...rest }: InternalUserNavItemProps) => {
  const match = useMatch<keyof InternalUserPageParams, typeof path>(path);

  const extractInternalUserId = (): string | null => {
    return match?.params?.internalUserId || null;
  };

  const internalUserId = extractInternalUserId();

  const { data } = useInternalUserNavItemQuery({
    variables: internalUserId ? { internalUserId } : undefined,
    skip: !internalUserId || !match,
  });

  const title = buildInternalUserTitle(data?.internalUser?.fullName);

  return <NavItem {...rest} title={title} path={path} />;
};
export default InternalUserNavItem;

gql`
  query InternalUserNavItemQuery($internalUserId: ID!) {
    internalUser(id: $internalUserId) {
      id
      fullName
    }
  }
`;
