import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import LoaderButton from '@paypr/mui5-common-components/dist/components/forms/LoaderButton';
import SecondaryButton from '@paypr/mui5-common-components/dist/components/forms/SecondaryButton';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import SubSectionTitle from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import React, { useEffect } from 'react';
import { DshConfigUpdatesInput, DshStrategyAssetConfigDetails } from '../../../generated/graphql';
import { useFormFields } from '../../../hooks/forms';
import { isTextNumber } from '../../../utils/strings';
import DshStrategyVersionTitle from '../DshStrategyVersionTitle';
import UpdateDshStrategyAssetConfigChanges from './UpdateDshStrategyAssetConfigChanges';
import UpdateDshStrategyAssetConfigEditor, { UpdateDshStrategyForm } from './UpdateDshStrategyAssetConfigEditor';

export type SubmitDshConfigUpdateEventHandler = (dshConfig: DshConfigUpdatesInput) => Promise<void>;

export interface UpdateDshStrategyAssetConfigDialogProps extends Omit<DialogProps, 'onClose' | 'maxWidth'> {
  version: string;
  asset: string;
  dshConfig: DshStrategyAssetConfigDetails;
  onSubmitDshConfigUpdate: SubmitDshConfigUpdateEventHandler;
  submitting?: boolean;
  onClose: (
    event: React.MouseEvent<HTMLButtonElement> | {},
    reason: 'backdropClick' | 'escapeKeyDown' | 'cancelClicked',
  ) => void;
}

const UpdateDshStrategyAssetConfigDialog = ({
  version,
  asset,
  dshConfig,
  onSubmitDshConfigUpdate,
  submitting,
  onClose,
  open,
  ...props
}: UpdateDshStrategyAssetConfigDialogProps) => {
  const startingPage = 1;
  const maxPages = 2;
  const [page, setPage] = React.useState(startingPage);

  const convertDshConfigToFields = (dshConfig: DshStrategyAssetConfigDetails) => ({
    startFromSavedState: dshConfig.startFromSavedState.toString(),
    startMode: dshConfig.startMode,
    daysSinceSale: dshConfig.daysSinceSale.toString(),
    forceAggressive: dshConfig.forceAggressive,
    forceAggressiveMaxSold: dshConfig.forceAggressiveMaxSold.toString(),
    stopWhenLedgerIsEmpty: dshConfig.stopWhenLedgerIsEmpty.toString(),
    maxInvestmentRisk: dshConfig.maxInvestmentRisk.toString(),
    riskManagement: dshConfig.riskManagement.toString(),
  });

  const initialState = convertDshConfigToFields(dshConfig);

  const { fields, handleFieldChange, resetFields } = useFormFields<UpdateDshStrategyForm>(initialState);

  useEffect(() => {
    if (!open) {
      return;
    }

    resetFields();
    setPage(startingPage);
  }, [open]);

  const hasChanges = JSON.stringify(initialState) !== JSON.stringify(fields);

  const formValid = hasChanges && isTextNumber(fields.maxInvestmentRisk);

  const convertFieldsToDshConfig = (fields: UpdateDshStrategyForm) => ({
    startFromSavedState: fields.startFromSavedState === 'true',
    startMode: fields.startMode,
    daysSinceSale: 0,
    forceAggressive: fields.forceAggressive,
    forceAggressiveMaxSold: 0,
    stopWhenLedgerIsEmpty: fields.stopWhenLedgerIsEmpty === 'true',
    maxInvestmentRisk: parseInt(fields.maxInvestmentRisk, 10),
    riskManagement: fields.riskManagement === 'true',
  });

  const handleSubmitChange = async () => {
    await onSubmitDshConfigUpdate({
      version,
      asset,
      ...convertFieldsToDshConfig(fields),
    });
  };

  const nextPage = () => {
    setPage((page) => page + 1);
  };

  const prevPage = () => {
    setPage((page) => page - 1);
  };

  return (
    <Dialog {...props} open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>
        <DshStrategyVersionTitle version={version} asset={asset} pageName="Execution Config Updates" />
      </DialogTitle>
      <DialogContent>
        {page == 1 && (
          <UpdateDshStrategyAssetConfigEditor
            dshConfig={dshConfig}
            fields={fields}
            handleFieldChange={handleFieldChange}
          />
        )}
        {page == 2 && (
          <>
            <SubSectionTitle>Verify Changes</SubSectionTitle>
            <UpdateDshStrategyAssetConfigChanges
              oldDshConfig={dshConfig}
              newDshConfig={{ __typename: 'DshConfig', ...convertFieldsToDshConfig(fields) }}
            />
          </>
        )}
      </DialogContent>

      <DialogActions>
        <DetailField>
          <Stack direction="row" spacing={1}>
            <SecondaryButton onClick={(event) => onClose(event, 'cancelClicked')} disabled={submitting}>
              Cancel
            </SecondaryButton>

            {page < maxPages && (
              <SecondaryButton onClick={() => resetFields()} disabled={submitting}>
                Reset
              </SecondaryButton>
            )}

            {page > 1 && (
              <SecondaryButton onClick={prevPage} disabled={submitting}>
                Back
              </SecondaryButton>
            )}

            {page < maxPages && (
              <Button color="inherit" onClick={nextPage} disabled={!formValid || submitting}>
                Next
              </Button>
            )}

            {page == maxPages && (
              <LoaderButton loading={submitting} disabled={!formValid} onClick={handleSubmitChange}>
                Submit Updates
              </LoaderButton>
            )}
          </Stack>
        </DetailField>
      </DialogActions>
    </Dialog>
  );
};
export default UpdateDshStrategyAssetConfigDialog;
