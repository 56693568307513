import { gql } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import AlternatingTableRow from '@paypr/mui5-common-components/dist/components/tables/AlternatingTableRow';
import EmptyTableRow from '@paypr/mui5-common-components/dist/components/tables/EmptyTableRow';
import HeightLimitedTableContainer from '@paypr/mui5-common-components/dist/components/tables/HeightLimitedTableContainer';
import {
  SimpleSortingTableHeadCell,
  SimpleSortingTableHeadProps,
} from '@paypr/mui5-common-components/dist/components/tables/SortingTableHead';
import { getComparator, stableSort, useSorting } from '@paypr/mui5-common-components/dist/components/tables/sortUtils';
import TooltipItem from '@paypr/mui5-common-components/dist/components/typography/TooltipItem';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { SubsystemsTableRow_Subsystem } from '../../generated/graphql';
import DeletedIcon from '../common/icons/DeletedIcon';
import SimpleSortingTableHead from '../common/tables/SortingTableHead';
import { buildSubsystemTitle } from './SubsystemDetail';

interface SubsystemsTableProps {
  subsystems: readonly SubsystemsTableRow_Subsystem[];
  onClickSubsystemRow?: SubsystemClickEventHandler;
}

interface SubsystemsTableRowData extends SubsystemsTableRow_Subsystem {}

export type SubsystemClickEventHandler = (subsystemId: string, newTab: boolean) => void;

const SubsystemsTable = ({ subsystems, onClickSubsystemRow }: SubsystemsTableProps) => {
  const { order, orderBy, handleRequestSort } = useSorting<SubsystemsTableRowData>('label', 'asc');

  const createClickHandler = (subsystemId: string) => {
    if (!onClickSubsystemRow) {
      return undefined;
    }
    return (event) => onClickSubsystemRow(subsystemId, event.ctrlKey || event.metaKey);
  };

  return (
    <HeightLimitedTableContainer>
      <Table stickyHeader>
        <SubsystemsTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
        <TableBody>
          {subsystems.length > 0 ? (
            stableSort(subsystems, getComparator(order, orderBy)).map((subsystem) => (
              <SubsystemTableRow
                key={subsystem.id}
                subsystem={subsystem}
                onRowClick={createClickHandler(subsystem.id)}
              />
            ))
          ) : (
            <EmptyTableRow columnCount={columnHeadings.length}>No subsystems were found.</EmptyTableRow>
          )}
        </TableBody>
      </Table>
    </HeightLimitedTableContainer>
  );
};
export default SubsystemsTable;

type SubsystemsTableHeadProps = Omit<SimpleSortingTableHeadProps<SubsystemsTableRowData>, 'headings'>;

const columnHeadings: SimpleSortingTableHeadCell<SubsystemsTableRowData>[] = [
  { key: 'label', label: 'Label' },
  { key: 'name', label: 'Name' },
  { key: 'createdAt', label: 'Created', align: 'right' },
];

const SubsystemsTableHead = (props: SubsystemsTableHeadProps) => (
  <SimpleSortingTableHead {...props} headings={columnHeadings} />
);

type SubsystemTableRowProps = {
  subsystem: SubsystemsTableRowData;
  onRowClick?: React.MouseEventHandler;
};

export const SubsystemTableRow = ({ subsystem, onRowClick }: SubsystemTableRowProps) => {
  const theme = useTheme();
  const { formatDateTime, formatShortDateTime } = useDateTime();

  return (
    <AlternatingTableRow hover={Boolean(onRowClick)} onClick={onRowClick}>
      <TableCell>
        {buildSubsystemTitle(subsystem.label)}&nbsp;
        {subsystem.deletedAt ? (
          <TooltipItem title={`Deleted on ${formatDateTime(subsystem.deletedAt)}`}>
            <DeletedIcon style={{ fontSize: theme.typography.body2.fontSize }} />
          </TooltipItem>
        ) : null}
      </TableCell>
      <TableCell>{subsystem.name}</TableCell>
      <TableCell align="right">{formatShortDateTime(subsystem.createdAt)}</TableCell>
    </AlternatingTableRow>
  );
};

export const subsystemsTableRowFragment = gql`
  fragment SubsystemsTableRow_Subsystem on Subsystem {
    id
    name
    label
    createdAt
    deletedAt
  }
`;
