import { css } from '@emotion/react';
import Box from '@mui/material/Box';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import React from 'react';
import { useDateTime } from '../../data/dates';
import { LedgerReportDetailsDetails } from '../../generated/graphql';
import DownloadCsvButton from '../common/tables/DownloadCsvButton';
import { buildReportCsvData } from './ledgerDataTableHelpers';
import LedgerReportChart from './LedgerReportChart';
import LedgerReportDataTable from './LedgerReportDataTable';
import { LedgerReportSettings, ReportView } from './ledgerReportModel';

export interface LedgerReportDisplayProps extends RefreshingComponentProps {
  reportSettings: LedgerReportSettings;
  report: LedgerReportDetailsDetails | null;
  autoRefresh?: boolean;
}

const LedgerReportDisplay = ({ reportSettings, report, onRefresh, loading, autoRefresh }: LedgerReportDisplayProps) => {
  const { toDateTime } = useDateTime();
  const refreshing = loading && Boolean(report);

  return (
    <>
      <Box
        css={css`
          text-align: right;
        `}
      >
        <RefreshIconButton
          refreshing={refreshing}
          loading={loading}
          onRefresh={onRefresh}
          autoRefresh={autoRefresh}
          includeRefreshText
        />
        <DownloadCsvButton
          filenamePrefix="report-data"
          dataProvider={report ? () => buildReportCsvData(reportSettings, report, { toDateTime }) : undefined}
        />
      </Box>
      {report ? (
        reportSettings.view === ReportView.Table ? (
          <LedgerReportDataTable tableSettings={reportSettings.tableSettings} report={report} />
        ) : (
          <LedgerReportChart report={report} />
        )
      ) : null}
    </>
  );
};
export default LedgerReportDisplay;
