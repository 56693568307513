import React from 'react';
import FillContentMessage from '@paypr/mui5-common-components/dist/components/containers/FillContentMessage';
import AppPage from './AppPage';

export interface AppPageMessageProps {
  text?: string;
  children?: React.ReactNode;
}

const AppPageMessage = (props: AppPageMessageProps) => (
  <AppPage>
    <FillContentMessage {...props} />
  </AppPage>
);
export default AppPageMessage;
