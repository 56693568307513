import React from 'react';
import { useDateTime } from '../../data/dates';
import { LedgerReportGroupField, LedgerReportTimeResolution, LedgerReportValueField } from '../../generated/graphql';
import { determineStartAtFromStandardTimeframe } from '../reports/ledgerReportHelpers';
import LedgerReportIntegration from '../reports/LedgerReportIntegration';
import {
  LedgerReportSettings,
  LedgerReportStandardTimeframe,
  ReportTableOrientation,
  ReportView,
} from '../reports/ledgerReportModel';

export interface FundReportProps {
  fundId: string;
}

const FundReport = ({ fundId }: FundReportProps) => {
  const { now, toDateTime } = useDateTime();

  const reportSettings: LedgerReportSettings = {
    definition: {
      standardTimeframe: LedgerReportStandardTimeframe.AllTime,
      startAt: determineStartAtFromStandardTimeframe(LedgerReportStandardTimeframe.AllTime, { now, toDateTime }),
      timeResolution: LedgerReportTimeResolution.Day,
      groupBy: [LedgerReportGroupField.Fund],
      groupFilters: { [LedgerReportGroupField.Fund]: [fundId] },
      includeTotals: false,
      includeAccountEvents: true,
      values: [
        LedgerReportValueField.PnlCumulativeInvestmentRoi,
        LedgerReportValueField.CryptoMarketPnlCumulativeRoi,
        LedgerReportValueField.EquityMarketPnlCumulativeRoi,
      ],
    },
    view: ReportView.Chart,
    chartSettings: {},
    tableSettings: { orientation: ReportTableOrientation.Horizontal },
  };

  return <LedgerReportIntegration reportSettings={reportSettings} defaultAutoRefresh />;
};
export default FundReport;
