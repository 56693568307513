import { gql } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import AlternatingTableRow from '@paypr/mui5-common-components/dist/components/tables/AlternatingTableRow';
import EmptyTableRow from '@paypr/mui5-common-components/dist/components/tables/EmptyTableRow';
import HeightLimitedTableContainer from '@paypr/mui5-common-components/dist/components/tables/HeightLimitedTableContainer';
import {
  SimpleSortingTableHeadCell,
  SimpleSortingTableHeadProps,
} from '@paypr/mui5-common-components/dist/components/tables/SortingTableHead';
import { getComparator, stableSort, useSorting } from '@paypr/mui5-common-components/dist/components/tables/sortUtils';
import TooltipItem from '@paypr/mui5-common-components/dist/components/typography/TooltipItem';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { ClientsTableRow_Client } from '../../generated/graphql';
import DeletedIcon from '../common/icons/DeletedIcon';
import SimpleSortingTableHead from '../common/tables/SortingTableHead';
import PercentFormat from '../common/typography/PercentFormat';
import UsdFormat from '../common/typography/UsdFormat';
import { buildClientTitle } from './clientUtils';

interface ClientsTableProps {
  clients: readonly ClientsTableRow_Client[];
  onClickClientRow?: ClientClickEventHandler;
}

interface ClientsTableRowData
  extends ClientsTableRow_Client,
    Pick<
      ClientsTableRow_Client['stats'],
      | 'deposits'
      | 'withdrawals'
      | 'investment'
      | 'currentValue'
      | 'fundPercent'
      | 'pnlCumulativeRoi'
      | 'pnlCumulativeValue'
      | 'realizedGainsCumulativeRoi'
      | 'realizedGainsCumulativeValue'
      | 'unrealizedGainsCumulativeRoi'
      | 'unrealizedGainsCumulativeValue'
    > {
  fundNames: string;
}

export type ClientClickEventHandler = (clientId: string, newTab: boolean) => void;

const ClientsTable = ({ clients, onClickClientRow }: ClientsTableProps) => {
  const { order, orderBy, handleRequestSort } = useSorting<ClientsTableRowData>('fullName', 'asc');

  const createClickHandler = (clientId: string) => {
    if (!onClickClientRow) {
      return undefined;
    }
    return (event) => onClickClientRow(clientId, event.ctrlKey || event.metaKey);
  };

  const clientData = clients.map((client): ClientsTableRowData => {
    const { __typename, ...stats } = client.stats;
    return {
      ...client,
      ...stats,
      fundNames: client.funds
        .map(({ fund: { name } }) => name)
        .sort()
        .join(', '),
    };
  });

  return (
    <HeightLimitedTableContainer>
      <Table stickyHeader>
        <ClientsTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
        <TableBody>
          {clientData.length > 0 ? (
            stableSort(clientData, getComparator(order, orderBy)).map((client) => (
              <ClientTableRow key={client.id} client={client} onRowClick={createClickHandler(client.id)} />
            ))
          ) : (
            <EmptyTableRow columnCount={columnHeadings.length}>No LPs were found.</EmptyTableRow>
          )}
        </TableBody>
      </Table>
    </HeightLimitedTableContainer>
  );
};
export default ClientsTable;

type ClientsTableHeadProps = Omit<SimpleSortingTableHeadProps<ClientsTableRowData>, 'headings'>;

const columnHeadings: SimpleSortingTableHeadCell<ClientsTableRowData>[] = [
  { key: 'fullName', label: 'Name' },
  { key: 'fundNames', label: 'Funds' },
  { key: 'investment', label: 'Investment Basis', align: 'right' },
  { key: 'currentValue', label: 'Current Value', align: 'right' },
  { key: 'pnlCumulativeRoi', label: 'PnL', align: 'right' },
  { key: 'realizedGainsCumulativeRoi', label: 'Realized Gains/Losses', align: 'right' },
  { key: 'unrealizedGainsCumulativeRoi', label: 'Unrealized Gains/Losses', align: 'right' },
  { key: 'fundPercent', label: 'Percent of Funds', align: 'right' },
];

const ClientsTableHead = (props: ClientsTableHeadProps) => (
  <SimpleSortingTableHead {...props} headings={columnHeadings} />
);

type ClientTableRowProps = {
  client: ClientsTableRowData;
  onRowClick?: React.MouseEventHandler;
};

export const ClientTableRow = ({ client, onRowClick }: ClientTableRowProps) => {
  const theme = useTheme();
  const { formatDateTime } = useDateTime();

  return (
    <AlternatingTableRow hover={Boolean(onRowClick)} onClick={onRowClick}>
      <TableCell>
        {buildClientTitle(client.fullName)}&nbsp;
        {client.deletedAt ? (
          <TooltipItem title={`Deleted on ${formatDateTime(client.deletedAt)}`}>
            <DeletedIcon style={{ fontSize: theme.typography.body2.fontSize }} />
          </TooltipItem>
        ) : null}
      </TableCell>
      <TableCell>{client.fundNames}</TableCell>
      <TableCell align="right">
        <UsdFormat amount={client.stats.investment} />
      </TableCell>
      <TableCell align="right">
        <UsdFormat amount={client.stats.currentValue} />
      </TableCell>
      <TableCell align="right">
        <PercentFormat amount={client.stats.pnlCumulativeRoi} />
        <br />
        <UsdFormat amount={client.stats.pnlCumulativeValue} />
      </TableCell>
      <TableCell align="right">
        <PercentFormat amount={client.stats.realizedGainsCumulativeRoi} />
        <br />
        <UsdFormat amount={client.stats.realizedGainsCumulativeValue} />
      </TableCell>
      <TableCell align="right">
        <PercentFormat amount={client.stats.unrealizedGainsCumulativeRoi} />
        <br />
        <UsdFormat amount={client.stats.unrealizedGainsCumulativeValue} />
      </TableCell>
      <TableCell align="right">
        <PercentFormat amount={client.stats.fundPercent} />
      </TableCell>
    </AlternatingTableRow>
  );
};

export const clientsTableRowFragment = gql`
  fragment ClientsTableRow_Client on Client {
    id
    fullName
    funds {
      id
      fund {
        id
        name
      }
    }
    stats {
      deposits
      withdrawals
      investment
      currentValue
      fundPercent
      pnlCumulativeRoi
      pnlCumulativeValue
      realizedGainsCumulativeRoi
      realizedGainsCumulativeValue
      unrealizedGainsCumulativeRoi
      unrealizedGainsCumulativeValue
    }
    createdAt
    deletedAt
  }
`;
