import { gql } from '@apollo/client';
import Grid from '@mui/material/Grid';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import DetailValue from '@paypr/mui5-common-components/dist/components/typography/DetailValue';
import React from 'react';
import { DshStrategyAssetConfigDetails } from '../../../generated/graphql';
import DetailGridItem from '../../common/containers/DetailGridItem';
import BooleanFormat from '../../common/typography/BooleanFormat';
import IntegerFormat from '../../common/typography/IntegerFormat';
import UsdFormat from '../../common/typography/UsdFormat';
import { buildDshForceAggressiveModeDisplay, buildDshStartModeDisplay } from '../dshUtils';

export interface DshStrategyAssetConfigProps {
  dshConfig: DshStrategyAssetConfigDetails;
}

const DshStrategyAssetConfig = ({ dshConfig }: DshStrategyAssetConfigProps) => {
  return (
    <DetailContainer>
      <Grid container justifyContent="left" spacing={2}>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Start From Saved State</DetailTitle>
            <DetailValue>
              <BooleanFormat value={dshConfig.startFromSavedState} type="on-off" />
            </DetailValue>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Start Mode</DetailTitle>
            <DetailValue>{buildDshStartModeDisplay(dshConfig.startMode)}</DetailValue>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Stop When Ledger is Empty</DetailTitle>
            <DetailValue>
              <BooleanFormat value={dshConfig.stopWhenLedgerIsEmpty} />
            </DetailValue>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Force Aggressive</DetailTitle>
            <DetailValue>{buildDshForceAggressiveModeDisplay(dshConfig.forceAggressive)}</DetailValue>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Max Investment Risk</DetailTitle>
            <DetailValue>
              <UsdFormat amount={dshConfig.maxInvestmentRisk} />
            </DetailValue>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Risk Management</DetailTitle>
            <DetailValue>
              <BooleanFormat value={dshConfig.riskManagement} type="on-off" />
            </DetailValue>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Max Slots</DetailTitle>
            <DetailValue>
              <IntegerFormat amount={dshConfig.maxSlots} />{' '}
            </DetailValue>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Slot Investment Size</DetailTitle>
            <DetailValue>
              <UsdFormat amount={dshConfig.slotInvestmentSize} />
            </DetailValue>
          </DetailField>
        </DetailGridItem>
      </Grid>
    </DetailContainer>
  );
};
export default DshStrategyAssetConfig;

export const dshStrategyAssetConfigDetailsFragment = gql`
  fragment DshStrategyAssetConfigDetails on DshConfig {
    id
    ...DshStrategyAssetConfigUpdateDetails
    maxSlots
    slotInvestmentSize
  }

  fragment DshStrategyAssetConfigUpdateDetails on DshConfig {
    startFromSavedState
    startMode
    daysSinceSale
    forceAggressive
    forceAggressiveMaxSold
    stopWhenLedgerIsEmpty
    maxInvestmentRisk
    riskManagement
  }
`;
