import { gql, NetworkStatus } from '@apollo/client';
import React, { useState } from 'react';
import {
  WorkflowFilters,
  WorkflowsPageQueryParams,
  buildWorkflowPath,
  buildWorkflowsUrlWithFilters,
  extractWorkflowFiltersFromUrl,
} from '../../components/workflows/workflowPaths';
import WorkflowsSection from '../../components/workflows/WorkflowsSection';
import { workflowsTableRowFragment } from '../../components/workflows/WorkflowsTable';
import AppPage from '../../components/common/page/AppPage';
import AppPageError from '../../components/common/page/AppPageError';
import WidePageContainer from '../../components/common/page/WidePageContainer';
import { useQuery } from '../../components/common/routes/paths';
import { useWorkflowsPageQuery } from '../../generated/graphql';
import { usePageTitle } from '../../hooks/page';
import { usePaths } from '../../hooks/paths';

const WorkflowsPage = () => {
  usePageTitle('Workflows');
  const { navigateTo } = usePaths();

  const { params } = useQuery<WorkflowsPageQueryParams>();
  const [filters, setFilters] = useState(extractWorkflowFiltersFromUrl(params));
  const [optionsExpanded, setOptionsExpanded] = useState(params.get('optionsExpanded') === 'true');

  const { data, error, refetch, networkStatus, loading } = useWorkflowsPageQuery({
    fetchPolicy: 'cache-and-network',
    variables: { ...filters },
  });

  if (error) {
    return <AppPageError error={error} />;
  }

  const handleWorkflowClicked = (workflowId: string, newTab: boolean) => {
    navigateTo(buildWorkflowPath(workflowId), newTab);
  };

  const handleFiltersChanged = (newFilters: WorkflowFilters) => {
    navigateTo(buildWorkflowsUrlWithFilters(newFilters, optionsExpanded));
    setFilters(newFilters);
  };

  const handleOptionsExpandedChanged = (newOptionsExpanded: boolean) => {
    navigateTo(buildWorkflowsUrlWithFilters(filters, newOptionsExpanded));
    setOptionsExpanded(newOptionsExpanded);
  };

  return (
    <AppPage>
      <WidePageContainer>
        <WorkflowsSection
          workflows={data?.workflows}
          loading={loading}
          refreshing={networkStatus === NetworkStatus.refetch}
          onRefresh={() => refetch()}
          autoRefresh
          onClickWorkflow={handleWorkflowClicked}
          defaultOptionsExpanded={optionsExpanded}
          onOptionsExpandedChanged={handleOptionsExpandedChanged}
          defaultFilters={filters}
          onChangeFilters={handleFiltersChanged}
        />
      </WidePageContainer>
    </AppPage>
  );
};
export default WorkflowsPage;

gql`
  query WorkflowsPageQuery($inProgress: Boolean, $status: WorkflowStatus) {
    workflows(inProgress: $inProgress, status: $status) {
      ...WorkflowsTableData_WorkflowConnection
    }
  }

  ${workflowsTableRowFragment}
`;
