import { gql } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import AlternatingTableRow from '@paypr/mui5-common-components/dist/components/tables/AlternatingTableRow';
import EmptyTableRow from '@paypr/mui5-common-components/dist/components/tables/EmptyTableRow';
import {
  SimpleSortingTableHeadCell,
  SimpleSortingTableHeadProps,
} from '@paypr/mui5-common-components/dist/components/tables/SortingTableHead';
import { getComparator, stableSort, useSorting } from '@paypr/mui5-common-components/dist/components/tables/sortUtils';
import TooltipItem from '@paypr/mui5-common-components/dist/components/typography/TooltipItem';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { LedgerEventsTableRow_LedgerAccountEvent } from '../../generated/graphql';
import DeletedIcon from '../common/icons/DeletedIcon';
import InfoIcon from '../common/icons/InfoIcon';
import HeightLimitedTableContainer from '../common/tables/HeightLimitedTableContainer';
import SimpleSortingTableHead from '../common/tables/SortingTableHead';
import {
  buildLedgerAccountEventTypeDisplay,
  buildLedgerEventNotesSummary,
  LedgerEventNotes,
} from './LedgerEventDetail';

interface LedgerEventsTableProps {
  ledgerEvents: readonly LedgerEventsTableRow_LedgerAccountEvent[];
  onClickLedgerEventRow?: LedgerEventClickEventHandler;
  fullHeight?: boolean;
}

export type LedgerEventClickEventHandler = (ledgerEventId: string, newTab: boolean) => void;

const LedgerEventsTable = ({ ledgerEvents, onClickLedgerEventRow, fullHeight }: LedgerEventsTableProps) => {
  const { order, orderBy, handleRequestSort } = useSorting<LedgerEventsTableRow_LedgerAccountEvent>(
    'occurredAt',
    'desc',
  );

  const createClickHandler = (ledgerEventId: string) => {
    if (!onClickLedgerEventRow) {
      return undefined;
    }
    return (event) => onClickLedgerEventRow(ledgerEventId, event.ctrlKey || event.metaKey);
  };

  return (
    <HeightLimitedTableContainer fullHeight={fullHeight}>
      <Table stickyHeader>
        <LedgerEventsTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
        <TableBody>
          {ledgerEvents.length > 0 ? (
            stableSort(ledgerEvents, getComparator(order, orderBy)).map((ledgerEvent) => (
              <LedgerEventTableRow
                key={ledgerEvent.id}
                ledgerEvent={ledgerEvent}
                onRowClick={createClickHandler(ledgerEvent.id)}
              />
            ))
          ) : (
            <EmptyTableRow columnCount={columnHeadings.length}>No events were found.</EmptyTableRow>
          )}
        </TableBody>
      </Table>
    </HeightLimitedTableContainer>
  );
};
export default LedgerEventsTable;

type LedgerEventsTableHeadProps = Omit<
  SimpleSortingTableHeadProps<LedgerEventsTableRow_LedgerAccountEvent>,
  'headings'
>;

const columnHeadings: SimpleSortingTableHeadCell<LedgerEventsTableRow_LedgerAccountEvent>[] = [
  { key: 'type', label: 'Type' },
  { key: 'value', label: 'Value' },
  { key: 'notes', label: 'Notes' },
  { key: 'occurredAt', label: 'Occurred on', align: 'right' },
];

const LedgerEventsTableHead = (props: LedgerEventsTableHeadProps) => (
  <SimpleSortingTableHead {...props} headings={columnHeadings} />
);

type LedgerEventTableRowProps = {
  ledgerEvent: LedgerEventsTableRow_LedgerAccountEvent;
  onRowClick?: React.MouseEventHandler;
};

export const LedgerEventTableRow = ({ ledgerEvent, onRowClick }: LedgerEventTableRowProps) => {
  const theme = useTheme();
  const { formatDateTime, formatShortDateTime } = useDateTime();

  return (
    <AlternatingTableRow hover={Boolean(onRowClick)} onClick={onRowClick}>
      <TableCell>
        {buildLedgerAccountEventTypeDisplay(ledgerEvent.type)}&nbsp;
        {ledgerEvent.deletedAt ? (
          <TooltipItem title={`Deleted on ${formatDateTime(ledgerEvent.deletedAt)}`}>
            <DeletedIcon style={{ fontSize: theme.typography.body2.fontSize }} />
          </TooltipItem>
        ) : null}
      </TableCell>
      <TableCell>{ledgerEvent.value}</TableCell>
      <TableCell>
        {buildLedgerEventNotesSummary(ledgerEvent.notes)}
        {!onRowClick ? (
          <>
            &nbsp;
            <TooltipItem title={<LedgerEventNotes notes={ledgerEvent.notes} />}>
              <InfoIcon style={{ fontSize: theme.typography.body2.fontSize }} />
            </TooltipItem>
          </>
        ) : null}
      </TableCell>
      <TableCell align="right">{formatShortDateTime(ledgerEvent.occurredAt)}</TableCell>
    </AlternatingTableRow>
  );
};

export const ledgerEventsTableRowFragment = gql`
  fragment LedgerEventsTableRow_LedgerAccountEvent on LedgerAccountEvent {
    id
    type
    value
    occurredAt
    notes
    deletedAt
  }
`;
