// clients

import { QueryParams } from '../common/routes/paths';

export const lpsPath = '/lps';

/** @deprecated use lpsPath */
export const clientsPath = '/clients';

export interface ClientsPageQueryParams {
  fundIds: string;
  includeDeleted: string;
  optionsExpanded: string;
}

export interface ClientFilters {
  fundIds: string[] | null;
  includeDeleted: boolean | null;
}

export const extractClientFiltersFromUrl = (params: QueryParams<ClientsPageQueryParams>): ClientFilters => {
  const fundIdsText = params.get('fundIds');
  const fundIds = fundIdsText ? fundIdsText.split(',') : null;

  const includeDeletedText = params.get('includeDeleted');
  const includeDeleted = includeDeletedText
    ? includeDeletedText === 'true'
      ? true
      : includeDeletedText === 'false'
        ? false
        : null
    : null;

  return {
    fundIds,
    includeDeleted,
  };
};

export const buildClientsUrlWithFilters = (filters: ClientFilters, optionsExpanded: boolean): string => {
  const { fundIds, includeDeleted } = filters;

  const params = {
    fundIds: fundIds ? fundIds.join(',') : '',
    optionsExpanded: optionsExpanded ? 'true' : 'false',
  };

  const deletedParam = { ...params, includeDeleted: includeDeleted ? includeDeleted.toString() : '' };

  const allParams = filters.includeDeleted ? { ...params, ...deletedParam } : params;

  return `${lpsPath}?${new URLSearchParams(allParams).toString()}`;
};

export const buildLpPath = (clientId: string) => `${lpsPath}/${clientId}`;

export const addLpPath = buildLpPath('add');

export const parameterizedLpPath = buildLpPath(':clientId');

export interface ClientPageParams {
  clientId: string;
}
