import { css } from '@emotion/react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import DetailText from '@paypr/mui5-common-components/dist/components/typography/DetailText';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import { DateTime } from 'luxon';
import React from 'react';
import { Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useDateTime } from '../../data/dates';
import {
  LedgerAccountEventDetails,
  LedgerReportDetailsDetails,
  LedgerReportGroupField,
  LedgerReportValueField,
} from '../../generated/graphql';
import { BreakpointsHook, useBreakpoints } from '../../style/theme';
import { useChartColors } from './chartHooks';
import {
  buildAccountEventText,
  buildGroupValueDisplay,
  determineFieldName,
  determineFieldType,
  determineTimeFromTimeResolution,
  extractLedgerReportFieldValue,
  formatDateTime,
} from './ledgerReportHelpers';
import ReportFormattedValue from './ReportFormattedValue';
import { ReportPropertyType } from './reportHelpers';

export interface LedgerReportChartProps {
  report: LedgerReportDetailsDetails;
}

interface ChartEvent extends LedgerAccountEventDetails {
  periodTimestamp: number;
  periodDateTime: DateTime;
}

interface SeriesInfo {
  name: string;
  fieldType: ReportPropertyType;
  valueField: LedgerReportValueField;
  groups: Partial<Record<LedgerReportGroupField, string>>;
}

interface ChartValues extends Record<string, number> {
  periodDateTime: DateTime;
  periodTimestamp: number;
  periodNumber: number;
}

const LedgerReportChart = ({ report }: LedgerReportChartProps) => {
  const theme = useTheme();
  const { fromSeconds, toDateTime } = useDateTime();
  const breakpoints = useBreakpoints();
  const [highlightedSeries, setHighlightedSeries] = React.useState<string | null>(null);

  const { getChartColor, eventColor } = useChartColors();

  const valueFields = report.values.filter(
    (field) =>
      field !== LedgerReportValueField.PeriodStartAt &&
      field !== LedgerReportValueField.PeriodEndAt &&
      field !== LedgerReportValueField.PeriodNumber,
  );
  const seriesInfo: Record<string, SeriesInfo> = {};

  const valuesByPeriod: Record<number, ChartValues> = {};
  report.results.forEach((result) => {
    const resultGroups = result.groups.reduce((previous, { field, name }) => {
      previous[field] = name;
      return previous;
    }, {});

    const resultValues = result.values.reduce((previous, valueField) => {
      previous[valueField.field] = extractLedgerReportFieldValue(valueField);
      return previous;
    }, {});

    const periodDateTime = toDateTime(resultValues[LedgerReportValueField.PeriodStartAt]);
    const periodTimestamp = periodDateTime.toUnixInteger();
    const periodNumber = resultValues[LedgerReportValueField.PeriodNumber] as number;

    if (!(periodDateTime in valuesByPeriod)) {
      valuesByPeriod[periodDateTime] = { periodDateTime, periodTimestamp, periodNumber };
    }

    const resultData: ChartValues = valuesByPeriod[periodDateTime];

    valueFields.forEach((valueField) => {
      const value = resultValues[valueField];
      if (typeof value !== 'number') {
        return;
      }

      const seriesName = determineSeriesName(report.groupBy, resultGroups, valueField);
      seriesInfo[seriesName] = {
        name: seriesName,
        fieldType: determineFieldType(valueField),
        valueField,
        groups: resultGroups,
      };

      resultData[seriesName] = value;
    });
  });

  const events =
    report.accountEvents?.map((event) => {
      const periodDateTime = determineTimeFromTimeResolution(toDateTime(event.occurredAt), report.timeResolution);
      const periodTimestamp = periodDateTime.toUnixInteger();
      return {
        ...event,
        periodTimestamp,
        periodDateTime,
      };
    }) || [];

  const tickFormatter = (fieldType: ReportPropertyType, value: number): string => {
    switch (fieldType) {
      case ReportPropertyType.Usd:
        return `$${value.toFixed(2)}`;
      case ReportPropertyType.Percent:
        return `${(100 * value).toFixed(2)}%`;
      case ReportPropertyType.Number:
        return value.toFixed(4);
      case ReportPropertyType.Integer:
        return value.toFixed(0);
      case ReportPropertyType.Bips:
        return (10000 * value).toFixed(0);
    }

    return value.toString();
  };

  const chartData = Object.values(valuesByPeriod).sort((a, b) => a.periodNumber - b.periodNumber);

  const numTicks = determineNumTicks(breakpoints);
  const tickStep = Math.floor(chartData.length / numTicks);

  if (chartData.length === 0) {
    return <DetailText>No data to display</DetailText>;
  }

  replaceDuplicateSeries(report.groupBy, seriesInfo, chartData);

  const fieldTypes = Array.from(new Set(Object.values(seriesInfo).map(({ fieldType }) => fieldType)));
  const seriesNames = Object.keys(seriesInfo).sort();

  const xAxisFormatter = (value: number) => formatDateTime(fromSeconds(value), report.timeResolution);

  const activeDotMouseEnter = (event, data) => {
    setHighlightedSeries(data.dataKey);
  };
  const activeDotMouseLeave = () => {
    setHighlightedSeries(null);
  };

  return (
    <Box width="100%" height="600px">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 50,
          }}
        >
          <XAxis
            style={{ fontSize: theme.typography.body2.fontSize, fontFamily: theme.typography.body2.fontFamily }}
            dataKey="periodTimestamp"
            angle={-45}
            minTickGap={1}
            padding={{ left: 20, right: 20 }}
            interval={tickStep}
            tickFormatter={xAxisFormatter}
          />
          {fieldTypes.map((fieldType, index) => (
            <YAxis
              style={{ fontSize: theme.typography.body2.fontSize, fontFamily: theme.typography.body2.fontFamily }}
              key={`yAxis-${index}`}
              yAxisId={fieldType}
              orientation={index === 0 ? 'left' : 'right'}
              tickFormatter={(value) => tickFormatter(fieldType, value)}
            />
          ))}

          <Tooltip
            content={
              <CustomTooltip
                seriesInfo={seriesInfo}
                highlightedSeries={highlightedSeries}
                report={report}
                events={events}
              />
            }
          />
          <Legend verticalAlign="top" />
          {seriesNames.map((series, index) => (
            <Line
              key={`line-${index}`}
              connectNulls
              type="monotone"
              dataKey={series}
              stroke={getChartColor(index)}
              strokeWidth={2}
              yAxisId={seriesInfo[series].fieldType}
              dot={false}
              activeDot={{ onMouseEnter: activeDotMouseEnter, onMouseLeave: activeDotMouseLeave }}
            />
          ))}
          {events.map((event, index) => (
            <ReferenceLine
              key={`referenceLine-${index}`}
              x={event.periodTimestamp}
              stroke={eventColor}
              label={{
                value: `${event.accountName}: ${buildAccountEventText(event.type, event.value)}`,
                angle: 90,
                position: 'right',
                offset: 10,
              }}
              yAxisId={fieldTypes[0]}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};
export default LedgerReportChart;

const replaceDuplicateSeries = (
  reportGroupBy: readonly LedgerReportGroupField[],
  series: Record<string, SeriesInfo>,
  chartData: ChartValues[],
) => {
  const similarSeries: Partial<Record<LedgerReportValueField, SeriesInfo[]>> = {};
  Object.values(series).forEach((seriesInfo) => {
    if (!(seriesInfo.valueField in similarSeries)) {
      similarSeries[seriesInfo.valueField] = [];
    }

    similarSeries[seriesInfo.valueField]!!.push(seriesInfo);
  });

  Object.values(similarSeries).forEach((seriesInfos) => {
    if (seriesInfos.length === 1) {
      return;
    }

    const firstSeriesInfo = seriesInfos[0];

    const allSame = chartData.every((chartValue) =>
      seriesInfos.every((seriesInfo) => chartValue[seriesInfo.name] === chartValue[firstSeriesInfo.name]),
    );

    if (!allSame) {
      return;
    }

    const seriesName = determineSeriesName([], {}, firstSeriesInfo.valueField);
    seriesInfos.forEach((seriesInfo) => {
      delete series[seriesInfo.name];
    });

    series[seriesName] = {
      ...firstSeriesInfo,
      name: seriesName,
    };

    chartData.forEach((chartValue) => {
      chartValue[seriesName] = chartValue[firstSeriesInfo.name];
    });
  });
};

const determineSeriesName = (
  groupBy: readonly LedgerReportGroupField[],
  groups: Partial<Record<LedgerReportGroupField, string>>,
  valueField: LedgerReportValueField,
) => {
  const groupPrefix = groupBy
    .map((group) => buildGroupValueDisplay(groups[group]))
    .filter((groupValue) => Boolean(groupValue))
    .join(' - ');

  return groupPrefix ? `${groupPrefix} - ${determineFieldName(valueField)}` : determineFieldName(valueField);
};

const determineNumTicks = ({ smallDown, mediumDown, largeDown }: BreakpointsHook) => {
  if (smallDown) {
    return 10;
  }

  if (mediumDown) {
    return 15;
  }

  if (largeDown) {
    return 20;
  }

  return 25;
};

interface CustomTooltipProps {
  seriesInfo: Record<string, SeriesInfo>;
  highlightedSeries: string | null;
  report: LedgerReportDetailsDetails;
  events: ChartEvent[];
  active?: boolean;
  label?: number;
  payload?: { dataKey: string; value: number }[];
}

const CustomTooltip = ({ seriesInfo, highlightedSeries, report, events, ...props }: CustomTooltipProps) => {
  const theme = useTheme();
  const { fromSeconds } = useDateTime();

  const { active, label: periodTimestamp, payload } = props;
  if (!active || !payload) {
    return null;
  }

  const periodDateTime = periodTimestamp ? fromSeconds(periodTimestamp) : undefined;
  const periodText = formatDateTime(periodDateTime, report.timeResolution);
  const periodEvents = events.filter((event) => event.periodTimestamp === periodTimestamp);

  return (
    <Box
      css={css`
        background-color: ${theme.palette.background.paper};
        border: 1px solid ${theme.palette.divider};
        padding: ${theme.spacing(1)};
      `}
    >
      <DetailTitle>{periodText}</DetailTitle>
      {payload.map((data, index) => (
        <DetailText
          key={`payload-${index}`}
          css={css`
            font-weight: ${highlightedSeries === data.dataKey ? '700' : '400'};
          `}
        >
          {data.dataKey}: <ReportFormattedValue propertyType={seriesInfo[data.dataKey].fieldType} value={data.value} />
        </DetailText>
      ))}
      {periodEvents.map((event, index) => (
        <React.Fragment key={`events-${index}`}>
          <DetailText>
            {event.accountName}: {buildAccountEventText(event.type, event.value)}
          </DetailText>
          {event.notes ? (
            <DetailText
              css={css`
                white-space: pre-line;
              `}
              variant="caption"
            >
              {event.notes}
            </DetailText>
          ) : null}
        </React.Fragment>
      ))}
    </Box>
  );
};
