import { alpha, useTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import { useDarkMode } from '../../style/theme';

export interface ChartColorsHooks {
  chartColors: string[];
  greenColor: string;
  redColor: string;
  getChartColor: (index: number) => string;
  getChangeAmountColor: (changeAmount: number) => string;
  getPercentChangeColor: (percentChange: number, badChangeAmount?: number) => string;
  eventColor: string;
}

export const useChartColors = (): ChartColorsHooks => {
  const theme = useTheme();
  const darkMode = useDarkMode();
  const chartColors = useMemo(() => getChartColors(darkMode), [darkMode]);
  const eventColor = alpha(theme.palette.text.primary, 0.8);

  const colorChangeAmount = darkMode ? 0.7 : 0.7;

  const getChartColor = (index: number) => {
    const color = chartColors[index % chartColors.length];
    if (index < chartColors.length) {
      return color;
    }

    return alpha(color, colorChangeAmount / Math.floor(index / chartColors.length));
  };

  const getChangeAmountColor = (changeAmount: number) => {
    if (changeAmount > 0) {
      return greenColor;
    }

    if (changeAmount < 0) {
      return redColor;
    }

    return theme.palette.text.primary;
  };

  const getPercentChangeColor = (percentChange: number, badChangeAmount: number = 0.01) => {
    if (Math.abs(percentChange) >= badChangeAmount) {
      return redColor;
    }

    return theme.palette.text.primary;
  };

  const greenColor = darkMode ? '#2a9822' : '#147c0b';
  const redColor = darkMode ? '#d03535' : '#930f0f';

  return { chartColors, getChartColor, getChangeAmountColor, getPercentChangeColor, eventColor, greenColor, redColor };
};

const darkBackgroundChartColors = [
  '#36a2eb',
  '#ff6384',
  '#ffce56',
  '#4bc0c0',
  '#9966ff',
  '#ff9f40',
  '#87c04b',
  '#e1a06e',
];

const lightBackgroundChartColors = [
  '#1e3a8a',
  '#98223c',
  '#8c6d00',
  '#4d1f7f',
  '#8a1e85',
  '#7e7979',
  '#6e8de1',
  '#2a9822',
];

const getChartColors = (isDarkMode: boolean) => (isDarkMode ? darkBackgroundChartColors : lightBackgroundChartColors);
