import { gql } from '@apollo/client';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import AlternatingTableRow from '@paypr/mui5-common-components/dist/components/tables/AlternatingTableRow';
import EmptyTableRow from '@paypr/mui5-common-components/dist/components/tables/EmptyTableRow';
import {
  SimpleSortingTableHeadCell,
  SimpleSortingTableHeadProps,
} from '@paypr/mui5-common-components/dist/components/tables/SortingTableHead';
import { getComparator, stableSort, useSorting } from '@paypr/mui5-common-components/dist/components/tables/sortUtils';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { AuditActionsTableData_AuditActionConnection, AuditActionsTableRow_AuditAction } from '../../generated/graphql';
import HeightLimitedTableContainer from '../common/tables/HeightLimitedTableContainer';
import SimpleSortingTableHead from '../common/tables/SortingTableHead';
import { buildAuditActionTypeDisplay } from './AuditActionDetail';

interface AuditActionsTableProps {
  auditActionConnection: AuditActionsTableData_AuditActionConnection;
  onClickAuditActionRow?: AuditActionClickEventHandler;
  fullHeight?: boolean;
}

interface AuditActionsTableRowData extends AuditActionsTableRow_AuditAction {
  subsystemLabel: string;
  userFullName: string;
}

export type AuditActionClickEventHandler = (auditActionId: string, newTab: boolean) => void;

const AuditActionsTable = ({ auditActionConnection, onClickAuditActionRow, fullHeight }: AuditActionsTableProps) => {
  const { order, orderBy, handleRequestSort } = useSorting<AuditActionsTableRowData>('createdAt', 'desc');

  const createClickHandler = (auditActionId: string) => {
    if (!onClickAuditActionRow) {
      return undefined;
    }
    return (event) => onClickAuditActionRow(auditActionId, event.ctrlKey || event.metaKey);
  };

  const auditActionData = auditActionConnection.edges
    .map(({ node }) => node)
    .map((auditAction) => ({
      ...auditAction,
      subsystemLabel: auditAction.subsystem.label,
      userFullName: auditAction.user.fullName,
    }));

  return (
    <HeightLimitedTableContainer fullHeight={fullHeight}>
      <Table stickyHeader>
        <AuditActionsTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
        <TableBody>
          {auditActionData.length > 0 ? (
            stableSort(auditActionData, getComparator(order, orderBy)).map((auditAction) => (
              <AuditActionTableRow
                key={auditAction.id}
                auditAction={auditAction}
                onRowClick={createClickHandler(auditAction.id)}
              />
            ))
          ) : (
            <EmptyTableRow columnCount={columnHeadings.length}>No activity was found.</EmptyTableRow>
          )}
        </TableBody>
      </Table>
    </HeightLimitedTableContainer>
  );
};
export default AuditActionsTable;

type AuditActionsTableHeadProps = Omit<SimpleSortingTableHeadProps<AuditActionsTableRowData>, 'headings'>;

const columnHeadings: SimpleSortingTableHeadCell<AuditActionsTableRowData>[] = [
  { key: 'subsystemLabel', label: 'Subsystem' },
  { key: 'type', label: 'Type' },
  { key: 'userFullName', label: 'User' },
  { key: 'createdAt', label: 'Created', align: 'right' },
];

const AuditActionsTableHead = (props: AuditActionsTableHeadProps) => (
  <SimpleSortingTableHead {...props} headings={columnHeadings} />
);

type AuditActionTableRowProps = {
  auditAction: AuditActionsTableRowData;
  onRowClick?: React.MouseEventHandler;
};

export const AuditActionTableRow = ({ auditAction, onRowClick }: AuditActionTableRowProps) => {
  const { formatShortDateTime } = useDateTime();

  return (
    <AlternatingTableRow hover={Boolean(onRowClick)} onClick={onRowClick}>
      <TableCell>{auditAction.subsystemLabel}</TableCell>
      <TableCell>{buildAuditActionTypeDisplay(auditAction.type)}</TableCell>
      <TableCell>{auditAction.userFullName}</TableCell>
      <TableCell align="right">{formatShortDateTime(auditAction.createdAt)}</TableCell>
    </AlternatingTableRow>
  );
};

export const auditActionsTableRowFragment = gql`
  fragment AuditActionsTableData_AuditActionConnection on AuditActionConnection {
    edges {
      node {
        id
        ...AuditActionsTableRow_AuditAction
      }
    }
  }

  fragment AuditActionsTableRow_AuditAction on AuditAction {
    id
    subsystem {
      id
      label
    }
    user {
      id
      fullName
    }
    type
    createdAt
  }
`;
