import { gql } from '@apollo/client';
import Grid from '@mui/material/Grid';
import RouteLink from '@paypr/mui5-common-components/dist/components/routes/RouteLink';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import DetailValue from '@paypr/mui5-common-components/dist/components/typography/DetailValue';
import SubSectionTitle from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import React from 'react';
import {
  DshStrategyAllocationsDetailDshAssetDetails,
  DshStrategyAllocationsDetailDshVersionDetails,
} from '../../../generated/graphql';
import DetailGridItem from '../../common/containers/DetailGridItem';
import PercentFormat from '../../common/typography/PercentFormat';
import UsdFormat from '../../common/typography/UsdFormat';
import { buildPortfolioPath } from '../../portfolios/portfolioPaths';

export interface DshStrategyAllocationsDetailProps {
  dshVersion: DshStrategyAllocationsDetailDshVersionDetails;
}

const DshStrategyAllocationsDetail = ({ dshVersion }: DshStrategyAllocationsDetailProps) => {
  const totalAmount = dshVersion.assets.reduce(
    (total, asset) => total + (asset.config ? asset.config.allocationAmount : 0),
    0,
  );

  return (
    <DetailContainer>
      <Grid container justifyContent="left" spacing={2}>
        <DetailGridItem size="half">
          <DetailField>
            <DetailTitle>
              <RouteLink path={buildPortfolioPath(dshVersion.portfolio.id)}>Portfolio Allocated Funds</RouteLink>
            </DetailTitle>
            <DetailValue>
              <UsdFormat amount={dshVersion.portfolio.managedCurrencyAmount} />
            </DetailValue>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem size="half">
          <DetailTitle>Total Allocation</DetailTitle>
          <DetailValue>
            <UsdFormat amount={totalAmount} />
          </DetailValue>
        </DetailGridItem>
        {dshVersion.assets.map((asset) => (
          <AssetAllocationDetail
            key={asset.asset}
            asset={asset}
            managedCurrencyAmount={dshVersion.portfolio.managedCurrencyAmount}
          />
        ))}
      </Grid>
    </DetailContainer>
  );
};
export default DshStrategyAllocationsDetail;

interface AssetAllocationDetailProps {
  asset: DshStrategyAllocationsDetailDshAssetDetails;
  managedCurrencyAmount: number;
}

const AssetAllocationDetail = ({ asset, managedCurrencyAmount }: AssetAllocationDetailProps) => {
  if (!asset.config) {
    return null;
  }

  return (
    <DetailGridItem key={asset.asset}>
      <SubSectionTitle>{asset.asset}</SubSectionTitle>
      <DetailField>
        <DetailTitle>Asset Percent</DetailTitle>
        <DetailValue>
          {managedCurrencyAmount > 0 ? (
            <PercentFormat amount={asset.config.allocationAmount / managedCurrencyAmount} />
          ) : (
            'n/a'
          )}
        </DetailValue>
      </DetailField>
      <DetailField>
        <DetailTitle>Max Slots</DetailTitle>
        <DetailValue>{asset.config.maxSlots}</DetailValue>
      </DetailField>
      <DetailField>
        <DetailTitle>Slot Investment Size</DetailTitle>
        <DetailValue>
          <UsdFormat amount={asset.config.slotInvestmentSize} />
        </DetailValue>
      </DetailField>
      <DetailField>
        <DetailTitle>Allocation Amount</DetailTitle>
        <DetailValue>
          <UsdFormat amount={asset.config.allocationAmount} />
        </DetailValue>
      </DetailField>
    </DetailGridItem>
  );
};

export const dshStrategyAllocationsDetailDshVersionDetailsFragment = gql`
  fragment DshStrategyAllocationsDetailDshVersionDetails on DshVersion {
    id
    portfolio {
      id
      name
      managedCurrencyAmount
    }
    assets {
      ...DshStrategyAllocationsDetailDshAssetDetails
    }
    active
  }

  fragment DshStrategyAllocationsDetailDshAssetDetails on DshAsset {
    asset
    config {
      maxSlots
      slotInvestmentSize
      allocationAmount
    }
  }
`;
