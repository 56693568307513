import { gql, NetworkStatus } from '@apollo/client';
import React from 'react';
import AppPage from '../../components/common/page/AppPage';
import AppPageError from '../../components/common/page/AppPageError';
import AppPageMessage from '../../components/common/page/AppPageMessage';
import WidePageContainer from '../../components/common/page/WidePageContainer';
import { buildPortfolioPath } from '../../components/portfolios/portfolioPaths';
import { buildStrategyTitle } from '../../components/strategies/StrategyDetail';
import { buildAddStrategyLedgerEventPath, StrategyPageParams } from '../../components/strategies/strategyPaths';
import StrategySection, { strategySectionFragment } from '../../components/strategies/StrategySection';
import { useStrategyPageQuery } from '../../generated/graphql';
import { usePageParams, usePageTitle } from '../../hooks/page';
import { usePaths } from '../../hooks/paths';

const StrategyPage = () => {
  const { strategyId } = usePageParams<keyof StrategyPageParams>();
  const { navigateTo } = usePaths();

  const { data, error, refetch, networkStatus, loading } = useStrategyPageQuery({
    fetchPolicy: 'cache-and-network',
    variables: { strategyId },
  });

  usePageTitle(`Strategy - ${buildStrategyTitle(data?.strategy?.name)}`);

  if (error) {
    return <AppPageError error={error} />;
  }

  const strategy = data?.strategy;

  if (strategy === null) {
    return <AppPageMessage text={`Strategy ${strategyId} does not exist`} />;
  }

  const handlePortfolioClicked = (portfolioId: string, newTab: boolean) => {
    navigateTo(buildPortfolioPath(portfolioId), newTab);
  };

  const handleClickAddLedgerEvent = (newTab: boolean) => {
    navigateTo(buildAddStrategyLedgerEventPath(strategyId), newTab);
  };

  return (
    <AppPage>
      <WidePageContainer>
        <StrategySection
          strategy={strategy}
          loading={loading}
          refreshing={networkStatus === NetworkStatus.refetch}
          onRefresh={() => refetch()}
          autoRefresh
          onClickPortfolio={handlePortfolioClicked}
          onClickAddLedgerEvent={handleClickAddLedgerEvent}
        />
      </WidePageContainer>
    </AppPage>
  );
};
export default StrategyPage;

gql`
  query StrategyPageQuery($strategyId: ID!) {
    strategy(id: $strategyId) {
      id
      name
      ...StrategySection_Strategy
    }
  }

  ${strategySectionFragment}
`;
