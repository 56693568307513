import AlternatingTableRow from '@paypr/mui5-common-components/dist/components/tables/AlternatingTableRow';
import TooltipItem from '@paypr/mui5-common-components/dist/components/typography/TooltipItem';
import React from 'react';
import { LedgerReportValueDetails, LedgerReportValueField } from '../../generated/graphql';
import HeaderColumnTableCell from '../common/tables/HeaderColumnTableCell';
import {
  determineFieldDescription,
  determineFieldName,
  determineFieldType,
  extractLedgerReportFieldValue,
} from './ledgerReportHelpers';
import ReportTableCell from './ReportTableCell';

export interface LedgerReportTableFieldRowProps {
  valueField: LedgerReportValueField;
  values: (LedgerReportValueDetails | null)[];
  fieldOverride?: string;
  rowIndex?: number;
}

const LedgerReportTableFieldRow = ({ valueField, values, fieldOverride, rowIndex }: LedgerReportTableFieldRowProps) => (
  <AlternatingTableRow rowIndex={rowIndex}>
    <HeaderColumnTableCell>
      {fieldOverride ? (
        <strong>{fieldOverride}</strong>
      ) : (
        <TooltipItem title={determineFieldDescription(valueField)}>
          <strong>{determineFieldName(valueField)}</strong>
        </TooltipItem>
      )}
    </HeaderColumnTableCell>
    {values.map((value, index) => (
      <ReportTableCell
        key={index}
        propertyType={determineFieldType(valueField)}
        value={value !== null ? extractLedgerReportFieldValue(value) : null}
      />
    ))}
  </AlternatingTableRow>
);
export default LedgerReportTableFieldRow;
