import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TooltipIconButton from '@paypr/mui5-common-components/dist/components/actions/TooltipIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SubSectionTitle from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import React from 'react';
import {
  LedgerReportChoiceClient,
  LedgerReportChoiceFund,
  LedgerReportChoicePortfolio,
  LedgerReportChoiceStrategy,
} from '../../generated/graphql';
import ExpandIcon from '../common/icons/ExpandIcon';
import FavoriteIcon from '../common/icons/FavoriteIcon';
import ResetIcon from '../common/icons/ResetIcon';
import LedgerReportDataChoices from './LedgerReportDataChoices';
import {
  LedgerReportAugmentedDefinition,
  LedgerReportSettings,
  ReportTableSettings,
  ReportView,
} from './ledgerReportModel';
import LedgerReportTableChoices from './LedgerReportTableChoices';

export interface LedgerReportChoicesContainerProps {
  defaultExpanded?: boolean;
  reportSettings: LedgerReportSettings;
  assets?: readonly string[];
  clients?: readonly LedgerReportChoiceClient[];
  funds?: readonly LedgerReportChoiceFund[];
  portfolios?: readonly LedgerReportChoicePortfolio[];
  strategies?: readonly LedgerReportChoiceStrategy[];
  onResetReportSettings?: () => void;
  onFavoriteCurrentReportSettings?: () => void;
  onChangeReportSettings: (reportSettings: LedgerReportSettings) => void;
  onOptionsExpandedChanged?: (expanded: boolean) => void;
  loading?: boolean;
}

const LedgerReportChoicesContainer = ({
  reportSettings,
  defaultExpanded,
  assets,
  clients,
  funds,
  portfolios,
  strategies,
  onResetReportSettings,
  onFavoriteCurrentReportSettings,
  onChangeReportSettings,
  onOptionsExpandedChanged,
  loading,
}: LedgerReportChoicesContainerProps) => {
  const handleResetReportSettings = (event) => {
    event.stopPropagation();
    onResetReportSettings?.();
  };

  const handleFavoriteCurrentReportSettings = (event) => {
    event.stopPropagation();
    onFavoriteCurrentReportSettings?.();
  };

  const handleReportDefinitionChanged = (definition: LedgerReportAugmentedDefinition) => {
    onChangeReportSettings({ ...reportSettings, definition });
  };

  const handleChangeView = (event, view: ReportView) => {
    onChangeReportSettings({ ...reportSettings, view });
  };

  const handleTableSettingsChanged = (tableSettings: ReportTableSettings) => {
    onChangeReportSettings({ ...reportSettings, tableSettings });
  };

  const handleOptionsExpandedChanged = (event, expanded) => {
    onOptionsExpandedChanged?.(expanded);
  };

  return (
    <>
      <Accordion defaultExpanded={defaultExpanded} onChange={handleOptionsExpandedChanged}>
        <AccordionSummary expandIcon={<ExpandIcon />}>
          <SubSectionTitle>Options</SubSectionTitle>
          <HorizontalAutoSpacer />
          {onResetReportSettings ? (
            <TooltipIconButton title="Reset to Defaults" onClick={handleResetReportSettings} disabled={loading}>
              <ResetIcon />
            </TooltipIconButton>
          ) : null}
          {onFavoriteCurrentReportSettings ? (
            <TooltipIconButton title="Favorite Report" onClick={handleFavoriteCurrentReportSettings} disabled={loading}>
              <FavoriteIcon />
            </TooltipIconButton>
          ) : null}
        </AccordionSummary>
        <AccordionDetails>
          <LedgerReportDataChoices
            defaultReportDefinition={reportSettings.definition}
            assets={assets}
            clients={clients}
            funds={funds}
            portfolios={portfolios}
            strategies={strategies}
            onChangeReportDefinition={handleReportDefinitionChanged}
            loading={loading}
          />
          {reportSettings.view === ReportView.Table ? (
            <LedgerReportTableChoices
              tableSettings={reportSettings.tableSettings}
              onChangeTableSettings={handleTableSettingsChanged}
            />
          ) : null}
        </AccordionDetails>
      </Accordion>

      <Tabs value={reportSettings.view} onChange={handleChangeView}>
        <Tab label="Chart" value={ReportView.Chart} />
        <Tab label="Table" value={ReportView.Table} />
      </Tabs>
    </>
  );
};
export default LedgerReportChoicesContainer;
