import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailInlineTitle from '@paypr/mui5-common-components/dist/components/typography/DetailInlineTitle';
import DetailInlineValue from '@paypr/mui5-common-components/dist/components/typography/DetailInlineValue';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import React from 'react';
import { DshStrategyAssetConfigUpdateDetails } from '../../../generated/graphql';
import { useThemeHelpers } from '../../../style/theme';
import DetailGridItem from '../../common/containers/DetailGridItem';
import BooleanFormat from '../../common/typography/BooleanFormat';
import IntegerFormat from '../../common/typography/IntegerFormat';
import UsdFormat from '../../common/typography/UsdFormat';
import { buildDshForceAggressiveModeDisplay, buildDshStartModeDisplay } from '../dshUtils';

export interface UpdateDshStrategyAssetConfigChangesProps {
  oldDshConfig: DshStrategyAssetConfigUpdateDetails;
  newDshConfig: DshStrategyAssetConfigUpdateDetails;
}

const UpdateDshStrategyAssetConfigChanges = ({
  oldDshConfig,
  newDshConfig,
}: UpdateDshStrategyAssetConfigChangesProps) => {
  const { buildChangeCss } = useThemeHelpers();

  return (
    <DetailContainer>
      <Grid container justifyContent="left" spacing={2}>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Start from Saved State</DetailTitle>
            <Box>
              <DetailInlineTitle>Old</DetailInlineTitle>
              <DetailInlineValue>
                <BooleanFormat value={oldDshConfig.startFromSavedState} type="on-off" />
              </DetailInlineValue>
            </Box>
            <Box>
              <DetailInlineTitle>New</DetailInlineTitle>
              <DetailInlineValue
                css={buildChangeCss(oldDshConfig.startFromSavedState, newDshConfig.startFromSavedState)}
              >
                <BooleanFormat value={newDshConfig.startFromSavedState} type="on-off" />
              </DetailInlineValue>
            </Box>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Start Mode</DetailTitle>
            <Box>
              <DetailInlineTitle>Old</DetailInlineTitle>
              <DetailInlineValue>{buildDshStartModeDisplay(oldDshConfig.startMode)}</DetailInlineValue>
            </Box>
            <Box>
              <DetailInlineTitle>New</DetailInlineTitle>
              <DetailInlineValue css={buildChangeCss(oldDshConfig.startMode, newDshConfig.startMode)}>
                {buildDshStartModeDisplay(newDshConfig.startMode)}
              </DetailInlineValue>
            </Box>
          </DetailField>
        </DetailGridItem>
        {oldDshConfig.daysSinceSale != newDshConfig.daysSinceSale && (
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Days Since Sale</DetailTitle>
              <Box>
                <DetailInlineTitle>Old</DetailInlineTitle>
                <DetailInlineValue>
                  <IntegerFormat amount={oldDshConfig.daysSinceSale} />
                </DetailInlineValue>
              </Box>
              <Box>
                <DetailInlineTitle>New</DetailInlineTitle>
                <DetailInlineValue css={buildChangeCss(oldDshConfig.daysSinceSale, newDshConfig.daysSinceSale)}>
                  <IntegerFormat amount={newDshConfig.daysSinceSale} />
                </DetailInlineValue>
              </Box>
            </DetailField>
          </DetailGridItem>
        )}
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Stop When Ledger is Empty</DetailTitle>
            <Box>
              <DetailInlineTitle>Old</DetailInlineTitle>
              <DetailInlineValue>
                <BooleanFormat value={oldDshConfig.stopWhenLedgerIsEmpty} />
              </DetailInlineValue>
            </Box>
            <Box>
              <DetailInlineTitle>New</DetailInlineTitle>
              <DetailInlineValue
                css={buildChangeCss(oldDshConfig.stopWhenLedgerIsEmpty, newDshConfig.stopWhenLedgerIsEmpty)}
              >
                <BooleanFormat value={newDshConfig.stopWhenLedgerIsEmpty} />
              </DetailInlineValue>
            </Box>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Force Aggressive</DetailTitle>
            <Box>
              <DetailInlineTitle>Old</DetailInlineTitle>
              <DetailInlineValue>{buildDshForceAggressiveModeDisplay(oldDshConfig.forceAggressive)}</DetailInlineValue>
            </Box>
            <Box>
              <DetailInlineTitle>New</DetailInlineTitle>
              <DetailInlineValue css={buildChangeCss(oldDshConfig.forceAggressive, newDshConfig.forceAggressive)}>
                {buildDshForceAggressiveModeDisplay(newDshConfig.forceAggressive)}
              </DetailInlineValue>
            </Box>
          </DetailField>
        </DetailGridItem>
        {oldDshConfig.forceAggressiveMaxSold != newDshConfig.forceAggressiveMaxSold && (
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Force Aggressive Max Sold</DetailTitle>
              <Box>
                <DetailInlineTitle>Old</DetailInlineTitle>
                <DetailInlineValue>
                  <IntegerFormat amount={oldDshConfig.forceAggressiveMaxSold} />
                </DetailInlineValue>
              </Box>
              <Box>
                <DetailInlineTitle>New</DetailInlineTitle>
                <DetailInlineValue
                  css={buildChangeCss(oldDshConfig.forceAggressiveMaxSold, newDshConfig.forceAggressiveMaxSold)}
                >
                  <IntegerFormat amount={newDshConfig.forceAggressiveMaxSold} />
                </DetailInlineValue>
              </Box>
            </DetailField>
          </DetailGridItem>
        )}
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Max Investment Risk</DetailTitle>
            <Box>
              <DetailInlineTitle>Old</DetailInlineTitle>
              <DetailInlineValue>
                <UsdFormat amount={oldDshConfig.maxInvestmentRisk} />
              </DetailInlineValue>
            </Box>
            <Box>
              <DetailInlineTitle>New</DetailInlineTitle>
              <DetailInlineValue css={buildChangeCss(oldDshConfig.maxInvestmentRisk, newDshConfig.maxInvestmentRisk)}>
                <UsdFormat amount={newDshConfig.maxInvestmentRisk} />
              </DetailInlineValue>
            </Box>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Risk Management</DetailTitle>
            <Box>
              <DetailInlineTitle>Old</DetailInlineTitle>
              <DetailInlineValue>
                <BooleanFormat value={oldDshConfig.riskManagement} type="on-off" />
              </DetailInlineValue>
            </Box>
            <Box>
              <DetailInlineTitle>New</DetailInlineTitle>
              <DetailInlineValue css={buildChangeCss(oldDshConfig.riskManagement, newDshConfig.riskManagement)}>
                <BooleanFormat value={newDshConfig.riskManagement} type="on-off" />
              </DetailInlineValue>
            </Box>
          </DetailField>
        </DetailGridItem>
      </Grid>
    </DetailContainer>
  );
};
export default UpdateDshStrategyAssetConfigChanges;
