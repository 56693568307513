import { gql } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import AlternatingTableRow from '@paypr/mui5-common-components/dist/components/tables/AlternatingTableRow';
import EmptyTableRow from '@paypr/mui5-common-components/dist/components/tables/EmptyTableRow';
import HeightLimitedTableContainer from '@paypr/mui5-common-components/dist/components/tables/HeightLimitedTableContainer';
import {
  SimpleSortingTableHeadCell,
  SimpleSortingTableHeadProps,
} from '@paypr/mui5-common-components/dist/components/tables/SortingTableHead';
import { getComparator, stableSort, useSorting } from '@paypr/mui5-common-components/dist/components/tables/sortUtils';
import TooltipItem from '@paypr/mui5-common-components/dist/components/typography/TooltipItem';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { PortfoliosTableRow_Portfolio } from '../../generated/graphql';
import DeletedIcon from '../common/icons/DeletedIcon';
import SimpleSortingTableHead from '../common/tables/SortingTableHead';
import FloatFormat from '../common/typography/FloatFormat';
import PercentFormat from '../common/typography/PercentFormat';
import UsdFormat from '../common/typography/UsdFormat';
import { buildPortfolioTitle } from './PortfolioDetail';

interface PortfoliosTableProps {
  portfolios: readonly PortfoliosTableRow_Portfolio[];
  hideStrategy?: boolean;
  hideFund?: boolean;
  onClickPortfolioRow?: PortfolioClickEventHandler;
}

export type PortfolioClickEventHandler = (portfolioId: string, newTab: boolean) => void;

interface PortfolioTableRowData
  extends PortfoliosTableRow_Portfolio,
    Pick<
      PortfoliosTableRow_Portfolio['stats'],
      | 'pnlCumulativeRoi'
      | 'pnlCumulativeValue'
      | 'realizedGainsCumulativeRoi'
      | 'realizedGainsCumulativeValue'
      | 'unrealizedGainsCumulativeRoi'
      | 'unrealizedGainsCumulativeValue'
    > {
  fundName: string;
  strategyName: string;
  beta: number | null;
  maxDrawdown: number | null;
  sharpeRatio: number | null;
}

const PortfoliosTable = ({ portfolios, hideStrategy, hideFund, onClickPortfolioRow }: PortfoliosTableProps) => {
  const { order, orderBy, handleRequestSort } = useSorting<PortfolioTableRowData>('name', 'asc');

  const showStrategy = !hideStrategy;
  const showFund = !hideFund;

  const createClickHandler = (portfolioId: string) => {
    if (!onClickPortfolioRow) {
      return undefined;
    }
    return (event) => onClickPortfolioRow(portfolioId, event.ctrlKey || event.metaKey);
  };

  const portfoliosData = portfolios.map((portfolio) => {
    const { __typename, beta, maxDrawdown, sharpeRatio, ...stats } = portfolio.stats;
    return {
      ...portfolio,
      ...stats,
      fundName: portfolio.fund.name,
      strategyName: portfolio.strategy.name,
      beta: beta.allTime,
      maxDrawdown: maxDrawdown.allTime,
      sharpeRatio: sharpeRatio.allTime,
    };
  });

  return (
    <HeightLimitedTableContainer>
      <Table stickyHeader>
        <PortfoliosTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          showStrategy={showStrategy}
          showFund={showFund}
        />
        <TableBody>
          {portfoliosData.length > 0 ? (
            stableSort(portfoliosData, getComparator(order, orderBy)).map((portfolio) => (
              <PortfolioTableRow
                key={portfolio.id}
                portfolio={portfolio}
                showStrategy={showStrategy}
                showFund={showFund}
                onRowClick={createClickHandler(portfolio.id)}
              />
            ))
          ) : (
            <EmptyTableRow columnCount={columnHeadings.length}>No portfolios were found.</EmptyTableRow>
          )}
        </TableBody>
      </Table>
    </HeightLimitedTableContainer>
  );
};
export default PortfoliosTable;

interface PortfoliosTableHeadProps extends Omit<SimpleSortingTableHeadProps<PortfolioTableRowData>, 'headings'> {
  showStrategy: boolean;
  showFund: boolean;
}

const columnHeadings: SimpleSortingTableHeadCell<PortfolioTableRowData>[] = [
  { key: 'name', label: 'Name' },
  { key: 'strategyName', label: 'Strategy', align: 'right' },
  { key: 'fundName', label: 'Fund', align: 'right' },
  { key: 'managedCurrencyAmount', label: 'Allocated Funds', align: 'right' },
  { key: 'pnlCumulativeRoi', label: 'All Time PnL', align: 'right' },
  { key: 'realizedGainsCumulativeRoi', label: 'Realized Gains/Losses', align: 'right' },
  { key: 'unrealizedGainsCumulativeRoi', label: 'Unrealized Gains/Losses', align: 'right' },
  { key: 'sharpeRatio', label: 'Sharpe Ratio', align: 'right' },
  { key: 'beta', label: 'Beta', align: 'right' },
  { key: 'maxDrawdown', label: 'Max Drawdown', align: 'right' },
];

const columnHeadingsWithoutStrategy = columnHeadings.filter(({ key }) => key !== 'strategyName');
const columnHeadingsWithoutFund = columnHeadings.filter(({ key }) => key !== 'fundName');
const columnHeadingsWithoutFundOrStrategy = columnHeadings.filter(
  ({ key }) => key !== 'fundName' && key !== 'strategyName',
);

const PortfoliosTableHead = ({ showStrategy, showFund, ...props }: PortfoliosTableHeadProps) => {
  const buildColumnHeadings = () => {
    if (showStrategy && showFund) {
      return columnHeadings;
    }
    if (showStrategy) {
      return columnHeadingsWithoutFund;
    }
    if (showFund) {
      return columnHeadingsWithoutStrategy;
    }
    return columnHeadingsWithoutFundOrStrategy;
  };

  return <SimpleSortingTableHead {...props} headings={buildColumnHeadings()} />;
};

type PortfolioTableRowProps = {
  portfolio: PortfolioTableRowData;
  showStrategy: boolean;
  showFund: boolean;
  onRowClick?: React.MouseEventHandler;
};

export const PortfolioTableRow = ({ portfolio, showStrategy, showFund, onRowClick }: PortfolioTableRowProps) => {
  const theme = useTheme();
  const { formatDateTime } = useDateTime();

  return (
    <AlternatingTableRow hover={Boolean(onRowClick)} onClick={onRowClick}>
      <TableCell>
        {buildPortfolioTitle(portfolio.name)}&nbsp;
        {portfolio.deletedAt ? (
          <TooltipItem title={`Deleted on ${formatDateTime(portfolio.deletedAt)}`}>
            <DeletedIcon style={{ fontSize: theme.typography.body2.fontSize }} />
          </TooltipItem>
        ) : null}
      </TableCell>
      {showStrategy && <TableCell>{portfolio.strategyName}</TableCell>}
      {showFund && <TableCell>{portfolio.fundName}</TableCell>}
      <TableCell align="right">
        <UsdFormat amount={portfolio.managedCurrencyAmount} />
      </TableCell>
      <TableCell align="right">
        <PercentFormat amount={portfolio.stats.pnlCumulativeRoi} />
        <br />
        <UsdFormat amount={portfolio.stats.pnlCumulativeValue} />
      </TableCell>
      <TableCell align="right">
        <PercentFormat amount={portfolio.stats.realizedGainsCumulativeRoi} />
        <br />
        <UsdFormat amount={portfolio.stats.realizedGainsCumulativeValue} />
      </TableCell>
      <TableCell align="right">
        <PercentFormat amount={portfolio.stats.unrealizedGainsCumulativeRoi} />
        <br />
        <UsdFormat amount={portfolio.stats.unrealizedGainsCumulativeValue} />
      </TableCell>
      <TableCell align="right">
        <FloatFormat amount={portfolio.sharpeRatio} decimalScale={2} />
      </TableCell>
      <TableCell align="right">
        <FloatFormat amount={portfolio.beta} decimalScale={2} />
      </TableCell>
      <TableCell align="right">
        <PercentFormat amount={portfolio.maxDrawdown} />
      </TableCell>
    </AlternatingTableRow>
  );
};

export const portfoliosTableRowFragment = gql`
  fragment PortfoliosTableRow_Portfolio on Portfolio {
    id
    name
    managedCurrencyAmount
    fund {
      id
      name
    }
    strategy {
      id
      name
    }
    stats {
      pnlCumulativeRoi
      pnlCumulativeValue
      realizedGainsCumulativeRoi
      realizedGainsCumulativeValue
      unrealizedGainsCumulativeRoi
      unrealizedGainsCumulativeValue
      beta {
        allTime
      }
      maxDrawdown {
        allTime
      }
      sharpeRatio {
        allTime
      }
    }
    createdAt
    deletedAt
  }
`;
