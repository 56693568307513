import { DshForceAggressiveMode, DshStartMode } from '../../generated/graphql';
import { snakeOrKebabToDisplay } from '../../utils/strings';

export const buildDshStartModeDisplay = (startMode: DshStartMode): string => snakeOrKebabToDisplay(startMode);

export const buildDshForceAggressiveModeDisplay = (forceAggressiveMode: DshForceAggressiveMode): string => {
  switch (forceAggressiveMode) {
    case DshForceAggressiveMode.Override:
      return 'Yes';

    case DshForceAggressiveMode.None:
      return 'No';

    case DshForceAggressiveMode.Aggressive:
      return 'Aggressive (deprecated)';

    case DshForceAggressiveMode.AutoEscalation:
      return 'Auto-escalate (deprecated)';

    case DshForceAggressiveMode.Freq:
      return 'Frequent (deprecated)';

    default:
      console.warn(`Unknown DshForceAggressiveMode: ${forceAggressiveMode}`);
      return snakeOrKebabToDisplay(forceAggressiveMode);
  }
};

export const dshForceAggressiveMaxSoldMaxValue = 8;
