import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import * as React from 'react';
import AppPage from '../../components/common/page/AppPage';
import WidePageContainer from '../../components/common/page/WidePageContainer';
import { DshStrategyAssetPageParams } from '../../components/dsh/dshPaths';
import DshStrategyAssetConfigIntegration from '../../components/dsh/config/DshStrategyAssetConfigIntegration';
import DshStrategyAssetLogsIntegration from '../../components/dsh/DshStrategyAssetLogsIntegration';
import DshStrategyAssetReports from '../../components/dsh/DshStrategyAssetReports';
import DshStrategyVersionTitle, { useDshStrategyVersionPageTitle } from '../../components/dsh/DshStrategyVersionTitle';
import { usePageParams } from '../../hooks/page';

const DshStrategyAssetPage = () => {
  const { version, asset } = usePageParams<keyof DshStrategyAssetPageParams>();

  useDshStrategyVersionPageTitle(version, undefined, asset);

  return (
    <AppPage>
      <WidePageContainer>
        <SectionTitle>
          <DshStrategyVersionTitle version={version} asset={asset} />
        </SectionTitle>
        <DshStrategyAssetConfigIntegration version={version} asset={asset} />
        <DshStrategyAssetReports version={version} asset={asset} />
        <DshStrategyAssetLogsIntegration version={version} asset={asset} />
      </WidePageContainer>
    </AppPage>
  );
};
export default DshStrategyAssetPage;
