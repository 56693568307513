import BigNumberFormat, {
  BigNumberFormatProps,
} from '@paypr/mui5-common-components/dist/components/typography/BigNumberFormat';
import React from 'react';

export interface UsdFormatProps extends Omit<BigNumberFormatProps, 'prefix' | 'suffix' | ' fixedDecimalScale'> {}

const UsdFormat = ({ decimalScale, ...props }: UsdFormatProps) => (
  <BigNumberFormat
    {...props}
    prefix="$"
    decimalScale={decimalScale !== undefined ? decimalScale : 2}
    fixedDecimalScale
  />
);
export default UsdFormat;

export const formatUsd = (amount: number) => `\$${amount.toFixed(2)}`;
