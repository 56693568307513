// dsh

export const dshStrategyPath = '/dsh';
export const buildDshStrategyAssetPath = (version: string, asset: string) =>
  `${dshStrategyPath}/asset/${version}/${asset}`;

export const parameterizedDshStrategyAssetPath = buildDshStrategyAssetPath(':version', ':asset');

export interface DshStrategyAssetPageParams {
  version: string;
  asset: string;
}

export const buildDshStrategyAllocationsPath = (version: string) => `${dshStrategyPath}/allocations/${version}`;

export const parameterizedDshStrategyAllocationsPath = buildDshStrategyAllocationsPath(':version');
export interface DshStrategyAllocationsPageParams {
  version: string;
}

export const buildDshStrategyReportsPath = (version: string) => `${dshStrategyPath}/reports/${version}`;

export const parameterizedDshStrategyReportsPath = buildDshStrategyReportsPath(':version');
export interface DshStrategyReportsPageParams {
  version: string;
}
