import { gql } from '@apollo/client';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import * as React from 'react';
import { SubsystemSection_Subsystem } from '../../generated/graphql';
import { AuditActionClickEventHandler } from '../auditActions/AuditActionsTable';
import SubsystemDetail, { buildSubsystemTitle, subsystemDetailFragment } from './SubsystemDetail';
import SubsystemIcon from './SubsystemIcon';

interface SubsystemSectionProps extends RefreshingComponentProps {
  subsystem?: SubsystemSection_Subsystem;
  onClickAuditAction?: AuditActionClickEventHandler;
}

const SubsystemSection = ({
  subsystem,
  onClickAuditAction,
  loading,
  refreshing,
  onRefresh,
  autoRefresh,
}: SubsystemSectionProps) => (
  <Paper>
    <Toolbar>
      <SubsystemIcon />
      &nbsp;
      <SectionTitle>{buildSubsystemTitle(subsystem?.label)}</SectionTitle>
      <HorizontalAutoSpacer />
      <RefreshIconButton
        refreshing={refreshing}
        loading={loading}
        onRefresh={onRefresh}
        autoRefresh={autoRefresh}
        includeRefreshText
      />
    </Toolbar>

    {subsystem ? (
      <SubsystemDetail subsystem={subsystem} onClickAuditAction={onClickAuditAction} autoRefresh={autoRefresh} />
    ) : null}
  </Paper>
);
export default SubsystemSection;

export const subsystemSectionFragment = gql`
  fragment SubsystemSection_Subsystem on Subsystem {
    id
    label
    ...SubsystemDetail_Subsystem
  }
  ${subsystemDetailFragment}
`;
