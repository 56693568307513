import { gql } from '@apollo/client';
import NavItem, { NavItemProps } from '@paypr/mui5-common-components/dist/components/page/nav/NavItem';
import * as React from 'react';
import { useMatch } from 'react-router-dom';
import { useStrategyNavItemQuery } from '../../../generated/graphql';
import { buildStrategyTitle } from '../StrategyDetail';
import { StrategyPageParams } from '../strategyPaths';

type StrategyNavItemProps = Omit<NavItemProps, 'title'>;

const AddStrategyLedgerEventNavItem = ({ path, ...rest }: StrategyNavItemProps) => {
  const match = useMatch<keyof StrategyPageParams, typeof path>(path);

  const extractStrategyId = (): string | null => {
    return match?.params?.strategyId || null;
  };

  const strategyId = extractStrategyId();

  const { data } = useStrategyNavItemQuery({
    variables: strategyId ? { strategyId } : undefined,
    skip: !strategyId || !match,
  });

  const title = buildStrategyTitle(data?.strategy?.name);

  return <NavItem {...rest} title={`Add ${title} Event`} path={path} />;
};
export default AddStrategyLedgerEventNavItem;

gql`
  query StrategyNavItemQuery($strategyId: ID!) {
    strategy(id: $strategyId) {
      id
      name
    }
  }
`;
