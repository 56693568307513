import { gql } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import AlternatingTableRow from '@paypr/mui5-common-components/dist/components/tables/AlternatingTableRow';
import EmptyTableRow from '@paypr/mui5-common-components/dist/components/tables/EmptyTableRow';
import HeightLimitedTableContainer from '@paypr/mui5-common-components/dist/components/tables/HeightLimitedTableContainer';
import {
  SimpleSortingTableHeadCell,
  SimpleSortingTableHeadProps,
} from '@paypr/mui5-common-components/dist/components/tables/SortingTableHead';
import { getComparator, stableSort, useSorting } from '@paypr/mui5-common-components/dist/components/tables/sortUtils';
import TooltipItem from '@paypr/mui5-common-components/dist/components/typography/TooltipItem';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { StrategiesTableRow_Strategy } from '../../generated/graphql';
import DeletedIcon from '../common/icons/DeletedIcon';
import SimpleSortingTableHead from '../common/tables/SortingTableHead';
import FloatFormat from '../common/typography/FloatFormat';
import PercentFormat from '../common/typography/PercentFormat';
import UsdFormat from '../common/typography/UsdFormat';
import { buildStrategyTitle } from './StrategyDetail';

interface StrategiesTableProps {
  strategies: readonly StrategiesTableRow_Strategy[];
  onClickStrategyRow?: StrategyClickEventHandler;
}

export type StrategyClickEventHandler = (strategyId: string, newTab: boolean) => void;

interface StrategyTableRowData
  extends StrategiesTableRow_Strategy,
    Pick<
      StrategiesTableRow_Strategy['stats'],
      | 'managedCurrency'
      | 'pnlCumulativeRoi'
      | 'pnlCumulativeValue'
      | 'realizedGainsCumulativeRoi'
      | 'realizedGainsCumulativeValue'
      | 'unrealizedGainsCumulativeRoi'
      | 'unrealizedGainsCumulativeValue'
    > {
  beta: number | null;
  maxDrawdown: number | null;
  sharpeRatio: number | null;
}

const StrategiesTable = ({ strategies, onClickStrategyRow }: StrategiesTableProps) => {
  const { order, orderBy, handleRequestSort } = useSorting<StrategyTableRowData>('name', 'asc');

  const createClickHandler = (strategyId: string) => {
    if (!onClickStrategyRow) {
      return undefined;
    }
    return (event) => onClickStrategyRow(strategyId, event.ctrlKey || event.metaKey);
  };

  const strategiesData = strategies.map((strategy) => {
    const { __typename, beta, maxDrawdown, sharpeRatio, ...stats } = strategy.stats;
    return {
      ...strategy,
      ...stats,
      beta: beta.allTime,
      maxDrawdown: maxDrawdown.allTime,
      sharpeRatio: sharpeRatio.allTime,
    };
  });

  return (
    <HeightLimitedTableContainer>
      <Table stickyHeader>
        <StrategiesTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
        <TableBody>
          {strategiesData.length > 0 ? (
            stableSort(strategiesData, getComparator(order, orderBy)).map((strategy) => (
              <StrategyTableRow key={strategy.id} strategy={strategy} onRowClick={createClickHandler(strategy.id)} />
            ))
          ) : (
            <EmptyTableRow columnCount={columnHeadings.length}>No strategies were found.</EmptyTableRow>
          )}
        </TableBody>
      </Table>
    </HeightLimitedTableContainer>
  );
};
export default StrategiesTable;

type StrategiesTableHeadProps = Omit<SimpleSortingTableHeadProps<StrategyTableRowData>, 'headings'>;

const columnHeadings: SimpleSortingTableHeadCell<StrategyTableRowData>[] = [
  { key: 'name', label: 'Name' },
  { key: 'managedCurrency', label: 'Allocated Funds', align: 'right' },
  { key: 'pnlCumulativeRoi', label: 'All Time PnL', align: 'right' },
  { key: 'realizedGainsCumulativeRoi', label: 'Realized Gains/Losses', align: 'right' },
  { key: 'unrealizedGainsCumulativeRoi', label: 'Unrealized Gains/Losses', align: 'right' },
  { key: 'sharpeRatio', label: 'Sharpe Ratio', align: 'right' },
  { key: 'beta', label: 'Beta', align: 'right' },
  { key: 'maxDrawdown', label: 'Max Drawdown', align: 'right' },
];

const StrategiesTableHead = (props: StrategiesTableHeadProps) => (
  <SimpleSortingTableHead {...props} headings={columnHeadings} />
);

type StrategyTableRowProps = {
  strategy: StrategyTableRowData;
  onRowClick?: React.MouseEventHandler;
};

export const StrategyTableRow = ({ strategy, onRowClick }: StrategyTableRowProps) => {
  const theme = useTheme();
  const { formatDateTime } = useDateTime();

  return (
    <AlternatingTableRow hover={Boolean(onRowClick)} onClick={onRowClick}>
      <TableCell>
        {buildStrategyTitle(strategy.name)}&nbsp;
        {strategy.deletedAt ? (
          <TooltipItem title={`Deleted on ${formatDateTime(strategy.deletedAt)}`}>
            <DeletedIcon style={{ fontSize: theme.typography.body2.fontSize }} />
          </TooltipItem>
        ) : null}
      </TableCell>
      <TableCell align="right">
        <UsdFormat amount={strategy.stats.managedCurrency} />
      </TableCell>
      <TableCell align="right">
        <PercentFormat amount={strategy.stats.pnlCumulativeRoi} />
        <br />
        <UsdFormat amount={strategy.stats.pnlCumulativeValue} />
      </TableCell>
      <TableCell align="right">
        <PercentFormat amount={strategy.stats.realizedGainsCumulativeRoi} />
        <br />
        <UsdFormat amount={strategy.stats.realizedGainsCumulativeValue} />
      </TableCell>
      <TableCell align="right">
        <PercentFormat amount={strategy.stats.unrealizedGainsCumulativeRoi} />
        <br />
        <UsdFormat amount={strategy.stats.unrealizedGainsCumulativeValue} />
      </TableCell>
      <TableCell align="right">
        <FloatFormat amount={strategy.sharpeRatio} decimalScale={2} />
      </TableCell>
      <TableCell align="right">
        <FloatFormat amount={strategy.beta} decimalScale={2} />
      </TableCell>
      <TableCell align="right">
        <PercentFormat amount={strategy.maxDrawdown} />
      </TableCell>
    </AlternatingTableRow>
  );
};

export const strategiesTableRowFragment = gql`
  fragment StrategiesTableRow_Strategy on Strategy {
    id
    name
    stats {
      managedCurrency
      pnlCumulativeRoi
      pnlCumulativeValue
      realizedGainsCumulativeRoi
      realizedGainsCumulativeValue
      unrealizedGainsCumulativeRoi
      unrealizedGainsCumulativeValue
      beta {
        allTime
      }
      maxDrawdown {
        allTime
      }
      sharpeRatio {
        allTime
      }
    }
    createdAt
    deletedAt
  }
`;
