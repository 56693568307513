import { buildLpPath } from '../clients/clientPaths';

export const buildLpFundPath = (clientId: string, fundId: string) => `${buildLpPath(clientId)}/funds/${fundId}`;

export const buildAddLpFundPath = (clientId) => buildLpFundPath(clientId, 'add');
export const parameterizedAddLpFundPath = buildAddLpFundPath(':fundId');

export const parameterizedLpFundPath = buildLpFundPath(':clientId', ':fundId');

export interface LpFundPageParams {
  clientId: string;
  fundId: string;
}
