import { NetworkStatus } from '@apollo/client';
import React from 'react';
import { buildApprovalPath } from '../../components/approvals/approvalPaths';
import ApprovalsSection from '../../components/approvals/ApprovalsSection';
import AppPage from '../../components/common/page/AppPage';
import AppPageError from '../../components/common/page/AppPageError';
import WidePageContainer from '../../components/common/page/WidePageContainer';
import { useApprovalsPageQuery } from '../../generated/graphql';
import { usePageTitle } from '../../hooks/page';
import { usePaths } from '../../hooks/paths';

const ApprovalsInProgressPage = () => {
  usePageTitle('Approvals');
  const { navigateTo } = usePaths();

  const { data, error, refetch, networkStatus, loading } = useApprovalsPageQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      inProgress: true,
      status: null,
    },
  });

  if (error) {
    return <AppPageError error={error} />;
  }

  const handleApprovalClicked = (approvalId: string, newTab: boolean) => {
    navigateTo(buildApprovalPath(approvalId), newTab);
  };

  return (
    <AppPage>
      <WidePageContainer>
        <ApprovalsSection
          approvals={data?.approvals}
          loading={loading}
          refreshing={networkStatus === NetworkStatus.refetch}
          onRefresh={() => refetch()}
          autoRefresh
          onClickApproval={handleApprovalClicked}
        />
      </WidePageContainer>
    </AppPage>
  );
};
export default ApprovalsInProgressPage;
