import Toolbar from '@mui/material/Toolbar';
import TooltipIconButton from '@paypr/mui5-common-components/dist/components/actions/TooltipIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import React, { useState } from 'react';
import EditIcon from '../../components/common/icons/EditIcon';
import RemoveIcon from '../../components/common/icons/RemoveIcon';
import AppPage from '../../components/common/page/AppPage';
import AppPageError from '../../components/common/page/AppPageError';
import WiderPageContainer from '../../components/common/page/WiderPageContainer';
import { favoriteLedgerReportToSettings, useFavoriteReports } from '../../components/reports/favorites';
import LedgerReportIntegration from '../../components/reports/LedgerReportIntegration';
import { buildReportUrlWithDefinition } from '../../components/reports/ledgerReportPaths';
import RemoveFavoriteReportDialog from '../../components/reports/RemoveFavoriteReportDialog';
import { FavoriteReportPageParams, reportsPath } from '../../components/reports/reportPaths';
import { usePageParams, usePageTitle } from '../../hooks/page';
import { usePaths } from '../../hooks/paths';

const FavoriteReportsPage = () => {
  const { reportId } = usePageParams<keyof FavoriteReportPageParams>();

  const { getFavoriteReport, removeFavoriteReport } = useFavoriteReports();
  const favoriteReport = getFavoriteReport(reportId);
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmRemoveDialogOpen, setConfirmRemoveDialogOpen] = useState<boolean>(false);
  const { navigateTo } = usePaths();

  usePageTitle(`${favoriteReport?.name || 'Unknown'} Report`);

  if (!favoriteReport) {
    return <AppPageError error={new Error(`Report not found: ${reportId}`)} />;
  }

  const reportSettings = favoriteLedgerReportToSettings(favoriteReport);

  const handleEditFavoriteReport = () => {
    const path = buildReportUrlWithDefinition(
      favoriteLedgerReportToSettings(favoriteReport),
      true,
      favoriteReport.name,
    );
    console.log('path', path);
    navigateTo(path);
  };

  const openConfirmRemoveDialog = () => {
    setConfirmRemoveDialogOpen(true);
  };

  const handleRemoveFavoriteReport = () => {
    removeFavoriteReport(reportId);

    closeConfirmRemoveDialog();

    navigateTo(reportsPath);
  };

  const closeConfirmRemoveDialog = () => {
    setConfirmRemoveDialogOpen(false);
  };

  return (
    <AppPage>
      <WiderPageContainer>
        <Toolbar>
          <SectionTitle>{favoriteReport.name} Report</SectionTitle>
          <HorizontalAutoSpacer />
          {loading ? 'Loading...' : null}
          <TooltipIconButton title="Edit Favorite Report" onClick={handleEditFavoriteReport} disabled={loading}>
            <EditIcon />
          </TooltipIconButton>
          <TooltipIconButton title="Remove Favorite Report" onClick={openConfirmRemoveDialog} disabled={loading}>
            <RemoveIcon />
          </TooltipIconButton>
        </Toolbar>
        <LedgerReportIntegration reportSettings={reportSettings} onLoading={setLoading} defaultAutoRefresh />

        <RemoveFavoriteReportDialog
          favoriteReport={favoriteReport}
          open={confirmRemoveDialogOpen}
          onRemoveFavoriteReport={handleRemoveFavoriteReport}
          onClose={closeConfirmRemoveDialog}
        />
      </WiderPageContainer>
    </AppPage>
  );
};
export default FavoriteReportsPage;
