import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import NumberField from '@paypr/mui5-common-components/dist/components/forms/NumberField';
import SecondaryButton from '@paypr/mui5-common-components/dist/components/forms/SecondaryButton';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailError from '@paypr/mui5-common-components/dist/components/typography/DetailError';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailInlineTitle from '@paypr/mui5-common-components/dist/components/typography/DetailInlineTitle';
import DetailInlineValue from '@paypr/mui5-common-components/dist/components/typography/DetailInlineValue';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import React from 'react';
import { FormFieldChangeEventHandler, FormFieldValueSetter } from '../../../hooks/forms';
import { isTextPositiveFloatOrZero } from '../../../utils/strings';
import DetailGridItem from '../../common/containers/DetailGridItem';
import PercentFormat from '../../common/typography/PercentFormat';

export interface UpdateClientFundEditorProps {
  values: UpdateClientFundDetails;
  fundManagementFeePercent: number;
  fundPerformanceFeePercent: number;

  fields: UpdateClientFundFields;
  handleFieldChange: FormFieldChangeEventHandler;
  setFieldValue: FormFieldValueSetter<UpdateClientFundFields>;
}

export interface UpdateClientFundDetails {
  managementFeePercent: number;
  performanceFeePercent: number;
  navId: string | null;
}

export interface UpdateClientFundFields {
  managementFeePercent: string;
  performanceFeePercent: string;
  navId: string;
}

const UpdateClientFundEditor = ({
  values,
  fundManagementFeePercent,
  fundPerformanceFeePercent,
  fields,
  handleFieldChange,
  setFieldValue,
}: UpdateClientFundEditorProps) => {
  const validManagementFeePercent =
    isTextPositiveFloatOrZero(fields.managementFeePercent) && parseFloat(fields.managementFeePercent) <= 100;

  const newManagementFeePercent = validManagementFeePercent
    ? parseFloat(fields.managementFeePercent) / 100
    : values.managementFeePercent;

  const validPerformanceFeePercent =
    isTextPositiveFloatOrZero(fields.performanceFeePercent) && parseFloat(fields.performanceFeePercent) <= 100;

  const newPerformanceFeePercent = validPerformanceFeePercent
    ? parseFloat(fields.performanceFeePercent) / 100
    : values.performanceFeePercent;

  return (
    <DetailContainer>
      <Grid container justifyContent="left" spacing={2}>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Management Fee %</DetailTitle>
            <Box>
              <DetailInlineTitle>Current</DetailInlineTitle>
              <DetailInlineValue>
                <PercentFormat amount={values.managementFeePercent} />
              </DetailInlineValue>
            </Box>
            <Box>
              <DetailInlineTitle>Fund</DetailInlineTitle>
              <DetailInlineValue>
                <PercentFormat amount={fundManagementFeePercent} />
              </DetailInlineValue>
            </Box>
            {values.managementFeePercent !== fundManagementFeePercent ? (
              <SecondaryButton
                variant="contained"
                size="small"
                onClick={() => setFieldValue('managementFeePercent', (fundManagementFeePercent * 100).toString())}
                disabled={newManagementFeePercent === fundManagementFeePercent}
              >
                Reset to Fund
              </SecondaryButton>
            ) : null}
            <NumberField
              name="managementFeePercent"
              label="Management Fee %"
              autoComplete="off"
              value={fields.managementFeePercent}
              min={0}
              max={100}
              required
              onChange={handleFieldChange}
            />
            {!validManagementFeePercent ? (
              <DetailError>Management Fee % must be a positive number between 0 and 100.</DetailError>
            ) : null}
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Performance Fee %</DetailTitle>
            <Box>
              <DetailInlineTitle>Current</DetailInlineTitle>
              <DetailInlineValue>
                <PercentFormat amount={values.performanceFeePercent} />
              </DetailInlineValue>
            </Box>
            <Box>
              <DetailInlineTitle>Fund</DetailInlineTitle>
              <DetailInlineValue>
                <PercentFormat amount={fundPerformanceFeePercent} />
              </DetailInlineValue>
            </Box>
            {values.performanceFeePercent !== fundPerformanceFeePercent ? (
              <SecondaryButton
                variant="contained"
                size="small"
                onClick={() => setFieldValue('performanceFeePercent', (fundPerformanceFeePercent * 100).toString())}
                disabled={newPerformanceFeePercent === fundPerformanceFeePercent}
              >
                Reset to Fund
              </SecondaryButton>
            ) : null}
            <NumberField
              name="performanceFeePercent"
              label="Performance Fee %"
              autoComplete="off"
              value={fields.performanceFeePercent}
              min={0}
              max={100}
              required
              onChange={handleFieldChange}
            />
            {!validPerformanceFeePercent ? (
              <DetailError>Performance Fee % must be a positive number between 0 and 100.</DetailError>
            ) : null}
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>NAV ID</DetailTitle>
            <TextField
              name="navId"
              label="NAV ID"
              autoComplete="off"
              value={fields.navId}
              required
              onChange={handleFieldChange}
            />
          </DetailField>
        </DetailGridItem>
      </Grid>
    </DetailContainer>
  );
};
export default UpdateClientFundEditor;
