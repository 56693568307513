import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import React from 'react';
import { TransferNoticesTableRow_TransferNotice } from '../../../generated/graphql';
import TransferNoticesTable, { TransferNoticeClickEventHandler } from './TransferNoticesTable';

export interface TransferNoticesSectionProps extends RefreshingComponentProps {
  transferNotices?: readonly TransferNoticesTableRow_TransferNotice[];
  title?: string;
  hideClient?: boolean;
  hideFund?: boolean;
  onClickTransferNotice?: TransferNoticeClickEventHandler;
}

const TransferNoticesSection = ({
  transferNotices,
  title,
  hideClient,
  hideFund,
  loading,
  refreshing,
  onRefresh,
  autoRefresh,
  onClickTransferNotice,
}: TransferNoticesSectionProps) => (
  <Paper>
    <Toolbar>
      <SectionTitle>{title || 'Transfer Notices'}</SectionTitle>
      <HorizontalAutoSpacer />
      <RefreshIconButton
        refreshing={refreshing}
        loading={loading}
        onRefresh={onRefresh}
        autoRefresh={autoRefresh}
        includeRefreshText
      />
    </Toolbar>

    <TransferNoticesTable
      transferNotices={transferNotices}
      hideClient={hideClient}
      hideFund={hideFund}
      onClickTransferNoticeRow={onClickTransferNotice}
    />
  </Paper>
);
export default TransferNoticesSection;
