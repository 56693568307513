import { useTheme } from '@mui/material/styles';
import TableContainer, { TableContainerProps } from '@mui/material/TableContainer';
import * as React from 'react';

interface HeightLimitedTableContainerProps extends TableContainerProps {
  heightUnits?: number;
  fullHeight?: boolean;
}

const HeightLimitedTableContainer = ({ heightUnits, style, fullHeight, ...rest }: HeightLimitedTableContainerProps) => {
  const theme = useTheme();
  const maxHeightStyle = !fullHeight ? { maxHeight: theme.spacing(heightUnits || 75) } : {};
  const updatedStyle = { ...style, ...maxHeightStyle };

  return <TableContainer {...rest} style={updatedStyle} />;
};
export default HeightLimitedTableContainer;
