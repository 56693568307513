import React from 'react';
import { LedgerReportDetailsDetails } from '../../generated/graphql';
import LedgerReportHorizontalDataTable from './LedgerReportHorizontalDataTable';
import { ReportTableOrientation, ReportTableSettings } from './ledgerReportModel';
import LedgerReportVerticalDataTable from './LedgerReportVerticalDataTable';

export interface LedgerReportDataTableProps {
  tableSettings: ReportTableSettings;
  report: LedgerReportDetailsDetails;
}

const LedgerReportDataTable = ({ tableSettings, report }: LedgerReportDataTableProps) =>
  tableSettings.orientation === ReportTableOrientation.Vertical ? (
    <LedgerReportVerticalDataTable report={report} />
  ) : (
    <LedgerReportHorizontalDataTable report={report} />
  );
export default LedgerReportDataTable;
