import { css } from '@emotion/react';
import { useTheme } from '@mui/material/styles';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import React from 'react';

export interface HeaderColumnTableCellProps extends TableCellProps {
  front?: boolean | number;
}

const HeaderColumnTableCell = ({ front, variant, ...props }: HeaderColumnTableCellProps) => {
  const theme = useTheme();
  const frontZIndex = 5;

  return (
    <TableCell
      css={css`
        position: sticky;
        left: 0;
        background-color: ${theme.palette.background.paper};
        white-space: nowrap;
        ${front ? `z-index: ${front === true ? frontZIndex : front > 0 ? front : frontZIndex + front};` : ''}
      `}
      {...props}
      variant={variant || 'head'}
    />
  );
};
export default HeaderColumnTableCell;
