export const strategiesPath = '/strategies';

export interface StrategiesPageQueryParams {
  includeDeleted: string;
}

export const buildStrategyPath = (strategyId: string) => `${strategiesPath}/${strategyId}`;

export const parameterizedStrategyPath = buildStrategyPath(':strategyId');
export interface StrategyPageParams {
  strategyId: string;
}
export interface StrategyPageQueryParams {}

export const buildStrategyLedgerEventPath = (strategyId: string, ledgerEventId) =>
  `${buildStrategyPath(strategyId)}/events/${ledgerEventId}`;

export const buildAddStrategyLedgerEventPath = (strategyId: string) => buildStrategyLedgerEventPath(strategyId, 'add');

export const parameterizedAddStrategyLedgerEventPath = buildAddStrategyLedgerEventPath(':strategyId');

export const parameterizedStrategyLedgerEventPath = buildStrategyLedgerEventPath(':strategyId', ':ledgerEventId');

export interface StrategyLedgerEventPageParams {
  strategyId: string;
  ledgerEventId: string;
}
