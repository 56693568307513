// funds
export const fundsPath = '/funds';

export interface FundsPageQueryParams {
  includeDeleted: string;
}

export const buildFundPath = (fundId: string) => `${fundsPath}/${fundId}`;

export const parameterizedFundPath = buildFundPath(':fundId');
export interface FundPageParams {
  fundId: string;
}
