import { gql } from '@apollo/client';
import Grid from '@mui/material/Grid';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import DetailValue from '@paypr/mui5-common-components/dist/components/typography/DetailValue';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { InternalUserDetail_InternalUser } from '../../generated/graphql';
import { AuditActionClickEventHandler } from '../auditActions/AuditActionsTable';
import DetailGridItem from '../common/containers/DetailGridItem';
import ExplodingContainer from '../common/containers/ExplodingContainer';
import InternalUserAuditActionsSectionIntegration from './InternalUserAuditActionsSectionIntegration';
import InternalUserDeletedNote, { internalUserDeletedNoteFragment } from './InternalUserDeletedNote';
import InternalUserDisabledNote, { internalUserDisabledNoteFragment } from './InternalUserDisabledNote';

export type InternalUserDetailData = InternalUserDetail_InternalUser;

interface InternalUserDetailProps {
  internalUser: InternalUserDetailData;
  onClickAuditAction?: AuditActionClickEventHandler;
  autoRefresh?: boolean | number;
}

const InternalUserDetail = ({ internalUser, onClickAuditAction, autoRefresh }: InternalUserDetailProps) => {
  const { formatDateTime } = useDateTime();

  return (
    <>
      <DetailContainer>
        <InternalUserDisabledNote internalUser={internalUser} />
        <InternalUserDeletedNote internalUser={internalUser} />

        <Grid container justifyContent="left" spacing={2}>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Name</DetailTitle>
              <DetailValue>{internalUser.fullName}</DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Created</DetailTitle>
              <DetailValue>{formatDateTime(internalUser.createdAt)}</DetailValue>
            </DetailField>
          </DetailGridItem>
        </Grid>
      </DetailContainer>
      <ExplodingContainer
        renderComponent={(exploded, onExplodeChanged) => (
          <InternalUserAuditActionsSectionIntegration
            internalUserId={internalUser.id}
            onClickAuditAction={onClickAuditAction}
            exploded={exploded}
            onExplodeChanged={onExplodeChanged}
            autoRefresh={autoRefresh}
          />
        )}
      />
    </>
  );
};
export default InternalUserDetail;

export const buildInternalUserTitle = (fullName?: string) => fullName || 'Admin User';

export const internalUserDetailFragment = gql`
  fragment InternalUserDetail_InternalUser on InternalUser {
    id
    fullName
    createdAt

    ...InternalUserDisabledNote_InternalUser
    ...InternalUserDeletedNote_InternalUser
  }

  ${internalUserDisabledNoteFragment}
  ${internalUserDeletedNoteFragment}
`;
