import { gql, NetworkStatus } from '@apollo/client';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import ApprovalDecisionSection, {
  approvalDecisionSectionFragment,
} from '../../components/approvals/ApprovalDecisionSection';
import { ApprovalDecisionPageParams, ApprovalDecisionPageQueryParams } from '../../components/approvals/approvalPaths';
import { buildApprovalTitle } from '../../components/approvals/approvalUtils';
import AppPage from '../../components/common/page/AppPage';
import AppPageError from '../../components/common/page/AppPageError';
import AppPageMessage from '../../components/common/page/AppPageMessage';
import WiderPageContainer from '../../components/common/page/WiderPageContainer';
import { useQuery } from '../../components/common/routes/paths';
import { workflowReferenceFragment } from '../../components/workflows/workflowUtils';
import {
  ApprovalStatus,
  useApprovalDecisionPageApproveMutation,
  useApprovalDecisionPageQuery,
} from '../../generated/graphql';
import { useErrors } from '../../hooks/errors';
import { usePageParams, usePageTitle } from '../../hooks/page';

const autoRefreshAfterSubmit = 1000; // 1 second

const ApprovalDecisionPage = () => {
  const { approvalId } = usePageParams<keyof ApprovalDecisionPageParams>();
  const { enqueueSnackbar } = useSnackbar();
  const { showError, clearError } = useErrors();

  const { params, updateParam } = useQuery<ApprovalDecisionPageQueryParams>();

  const { data, error, refetch, networkStatus, loading } = useApprovalDecisionPageQuery({
    fetchPolicy: 'cache-and-network',
    variables: { approvalId },
  });

  const [submitApproval, { loading: submittingApproval }] = useApprovalDecisionPageApproveMutation();

  const [submittedApproval, setSubmittedApproval] = useState(false);

  usePageTitle(`${buildApprovalTitle(approvalId, data?.approval?.workflow)}`);

  if (error) {
    return <AppPageError error={error} />;
  }

  const approval = data?.approval;

  if (approval === null) {
    return <AppPageMessage text={`Approval ${approvalId} does not exist`} />;
  }

  const handleSubmitApproval = async (processApprovalId: string, approved: boolean) => {
    clearError();

    if (!confirm(`Are you sure you want to ${approved ? 'Approve' : 'Reject'}?`)) {
      return;
    }

    try {
      await submitApproval({ variables: { approvalId: processApprovalId, approved } });
      setSubmittedApproval(true);

      enqueueSnackbar('Submitted decision.', { variant: 'success' });
    } catch (e) {
      showError(e);
    }
  };

  const submittedAndPending = submittedApproval && approval?.status == ApprovalStatus.Pending;

  return (
    <AppPage>
      <WiderPageContainer>
        <ApprovalDecisionSection
          approval={approval}
          onApproval={handleSubmitApproval}
          approving={submittingApproval}
          submittedApproval={submittedApproval}
          loading={loading}
          refreshing={networkStatus === NetworkStatus.refetch}
          onRefresh={() => refetch()}
          autoRefresh={submittedAndPending ? autoRefreshAfterSubmit : true}
        />
      </WiderPageContainer>
    </AppPage>
  );
};
export default ApprovalDecisionPage;

gql`
  query ApprovalDecisionPageQuery($approvalId: ID!) {
    approval(id: $approvalId) {
      id
      workflow {
        id
        ...WorkflowReference_Workflow
      }

      status

      ...ApprovalDecisionSection_Approval
    }
  }

  mutation ApprovalDecisionPageApproveMutation($approvalId: ID!, $approved: Boolean!) {
    completeApproval(approvalId: $approvalId, approved: $approved)
  }

  ${approvalDecisionSectionFragment}
  ${workflowReferenceFragment}
`;
