import Grid from '@mui/material/Grid';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import DetailValue from '@paypr/mui5-common-components/dist/components/typography/DetailValue';
import React from 'react';
import { useThemeHelpers } from '../../../style/theme';
import DetailGridItem from '../../common/containers/DetailGridItem';
import UsdFormat from '../../common/typography/UsdFormat';

export interface UpdatePortfolioManagedCurrencyChangesProps {
  oldManagedCurrencyAmount: number;
  newManagedCurrencyAmount: number;
}

const UpdatePortfolioManagedCurrencyChanges = ({
  oldManagedCurrencyAmount,
  newManagedCurrencyAmount,
}: UpdatePortfolioManagedCurrencyChangesProps) => {
  const { buildChangeCss } = useThemeHelpers();

  return (
    <DetailContainer>
      <Grid container justifyContent="left" spacing={2}>
        <DetailGridItem size="half">
          <DetailField>
            <DetailTitle>Old Amount</DetailTitle>
            <DetailValue>
              <UsdFormat amount={oldManagedCurrencyAmount} />
            </DetailValue>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem size="half">
          <DetailField>
            <DetailTitle>New Amount</DetailTitle>
            <DetailValue css={buildChangeCss(oldManagedCurrencyAmount, newManagedCurrencyAmount)}>
              <UsdFormat amount={newManagedCurrencyAmount} />
            </DetailValue>
          </DetailField>
        </DetailGridItem>
      </Grid>
    </DetailContainer>
  );
};
export default UpdatePortfolioManagedCurrencyChanges;
