import { gql } from '@apollo/client';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import * as React from 'react';
import { ApprovalSection_Approval } from '../../generated/graphql';
import { workflowReferenceFragment } from '../workflows/workflowUtils';
import ApprovalDetail, { approvalDetailFragment } from './ApprovalDetail';
import ApprovalIcon from './ApprovalIcon';
import { buildApprovalTitle } from './approvalUtils';

interface ApprovalSectionProps extends RefreshingComponentProps {
  approval?: ApprovalSection_Approval;
}

const ApprovalSection = ({ approval, loading, refreshing, onRefresh, autoRefresh }: ApprovalSectionProps) => (
  <Paper>
    <Toolbar>
      <ApprovalIcon />
      &nbsp;
      <SectionTitle>{buildApprovalTitle(approval?.id, approval?.workflow)}</SectionTitle>
      <HorizontalAutoSpacer />
      <RefreshIconButton
        refreshing={refreshing}
        loading={loading}
        onRefresh={onRefresh}
        autoRefresh={autoRefresh}
        includeRefreshText
      />
    </Toolbar>

    {approval ? <ApprovalDetail approval={approval} disabled={loading} /> : null}
  </Paper>
);
export default ApprovalSection;

export const approvalSectionFragment = gql`
  fragment ApprovalSection_Approval on Approval {
    id
    workflow {
      id
      ...WorkflowReference_Workflow
    }
    ...ApprovalDetail_Approval
  }
  ${approvalDetailFragment}
  ${workflowReferenceFragment}
`;
