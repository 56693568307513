import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import TooltipIconButton from '@paypr/mui5-common-components/dist/components/actions/TooltipIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import React from 'react';
import { ClientFilterChoices_Fund, ClientsTableRow_Client } from '../../generated/graphql';
import AddIcon from '../common/icons/AddIcon';
import NoDataError from '../common/page/NoDataError';
import ClientFilterChoices from './ClientFilterChoices';
import { ClientFilters } from './clientPaths';
import ClientsTable, { ClientClickEventHandler } from './ClientsTable';

export interface ClientsSectionProps extends RefreshingComponentProps {
  clients?: readonly ClientsTableRow_Client[];
  onClickClient?: ClientClickEventHandler;
  onClickAddClient?: (newTab: boolean) => void;
  defaultOptionsExpanded?: boolean;
  onOptionsExpandedChanged?: (expanded: boolean) => void;
  defaultFilters?: ClientFilters;
  funds?: readonly ClientFilterChoices_Fund[];
  onChangeFilters?: (filters: ClientFilters) => void;
  showDeletedOption?: boolean;
}

const ClientsSection = ({
  clients,
  loading,
  refreshing,
  onRefresh,
  autoRefresh,
  onClickClient,
  onClickAddClient,
  defaultOptionsExpanded,
  onOptionsExpandedChanged,
  defaultFilters,
  funds,
  onChangeFilters,
  showDeletedOption,
}: ClientsSectionProps) => {
  const handleAddClient = (event) => {
    if (!onClickAddClient) {
      return;
    }

    event.stopPropagation();
    onClickAddClient(event.ctrlKey || event.metaKey);
  };

  return (
    <Paper>
      <Toolbar>
        <SectionTitle>LPs</SectionTitle>
        <HorizontalAutoSpacer />
        {onClickAddClient && (
          <TooltipIconButton title="Add LP" onClick={handleAddClient} disabled={refreshing || loading}>
            <AddIcon />
          </TooltipIconButton>
        )}
        <RefreshIconButton
          refreshing={refreshing}
          loading={loading}
          onRefresh={onRefresh}
          autoRefresh={autoRefresh}
          includeRefreshText
        />
      </Toolbar>
      {onChangeFilters ? (
        <ClientFilterChoices
          defaultExpanded={defaultOptionsExpanded}
          onOptionsExpandedChanged={onOptionsExpandedChanged}
          defaultFilters={defaultFilters || { fundIds: null, includeDeleted: false }}
          funds={funds}
          onChangeFilters={onChangeFilters}
          showDeletedOption={showDeletedOption}
        />
      ) : null}

      {clients ? (
        <ClientsTable clients={clients} onClickClientRow={onClickClient} />
      ) : !(loading || refreshing) ? (
        <NoDataError />
      ) : null}
    </Paper>
  );
};
export default ClientsSection;
