import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useUiConfig } from './uiConfig';

export const basePageTitle = 'Admin Tools';

export const usePageTitle = (title?: string, replaceAll: boolean = false) => {
  const { isProd, stage } = useUiConfig();

  useEffect(() => {
    updateTitle();
  }, [title, replaceAll, isProd, stage]);

  const buildTitle = () => {
    if (!title) {
      return basePageTitle;
    }

    if (replaceAll) {
      return title;
    }

    return `${title} - ${basePageTitle}`;
  };

  const updateTitle = () => {
    const title = buildTitle();
    document.title = isProd ? title : `${stage}: ${title}`;
  };
};

export type PageParams<Key extends string> = {
  readonly [key in Key]: string;
};

export const usePageParams = <Key extends string>() => {
  const params = useParams<Key>();
  return params as PageParams<Key>;
};
