import { gql, NetworkStatus } from '@apollo/client';
import React from 'react';
import AppPage from '../../components/common/page/AppPage';
import AppPageError from '../../components/common/page/AppPageError';
import WiderPageContainer from '../../components/common/page/WiderPageContainer';
import { useQuery } from '../../components/common/routes/paths';
import {
  addInternalUserPath,
  buildInternalUserPath,
  InternalUsersPageQueryParams,
} from '../../components/internalUsers/internalUserPaths';
import InternalUsersSection from '../../components/internalUsers/InternalUsersSection';
import { internalUsersTableRowFragment } from '../../components/internalUsers/InternalUsersTable';
import { useInternalUsersPageQuery } from '../../generated/graphql';
import { usePageTitle } from '../../hooks/page';
import { usePaths } from '../../hooks/paths';

const InternalUserPage = () => {
  usePageTitle('Admin Users');
  const { navigateTo } = usePaths();

  const { params, updateParam } = useQuery<InternalUsersPageQueryParams>();

  const includeDeletedFlag = params.get('includeDeleted');
  const includeDeleted = includeDeletedFlag === 'true';

  const { data, error, refetch, networkStatus, loading } = useInternalUsersPageQuery({
    fetchPolicy: 'cache-and-network',
    variables: { includeDeleted: includeDeleted || false },
  });

  if (error) {
    return <AppPageError error={error} />;
  }

  const handleInternalUserClicked = (internalUserId: string, newTab: boolean) => {
    navigateTo(buildInternalUserPath(internalUserId), newTab);
  };

  const handleIncludeDeletedInternalUsersChanged = (includeDeletedInternalUsers: boolean) => {
    updateParam('includeDeleted', includeDeletedInternalUsers ? 'true' : 'false');
  };

  const handleClickAddInternalUser = (newTab: boolean) => {
    navigateTo(addInternalUserPath, newTab);
  };

  return (
    <AppPage>
      <WiderPageContainer>
        <InternalUsersSection
          internalUsers={data?.internalUsers}
          loading={loading}
          refreshing={networkStatus === NetworkStatus.refetch}
          onRefresh={() => refetch()}
          autoRefresh
          onClickInternalUser={handleInternalUserClicked}
          onClickAddInternalUser={handleClickAddInternalUser}
          includeDeletedInternalUsers={includeDeleted}
          onUpdateIncludeDeletedInternalUsers={
            includeDeletedFlag !== null ? handleIncludeDeletedInternalUsersChanged : undefined
          }
        />
      </WiderPageContainer>
    </AppPage>
  );
};
export default InternalUserPage;

gql`
  query InternalUsersPageQuery($includeDeleted: Boolean!) {
    internalUsers(includeDeleted: $includeDeleted) {
      id
      ...InternalUsersTableRow_InternalUser
    }
  }

  ${internalUsersTableRowFragment}
`;
