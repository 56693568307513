export const transactionsPath = '/transactions';

export interface TransactionsPageQueryParams {
  inProgress: string;
  status: string;
}

export const buildTransactionPath = (transactionId: string) => `${transactionsPath}/${transactionId}`;

export const parameterizedTransactionPath = buildTransactionPath(':transactionId');
export interface TransactionPageParams {
  exchange: string;
  transactionId: string;
}
export interface TransactionPageQueryParams {}
