import { gql } from '@apollo/client';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import * as React from 'react';
import { TradeSection_Trade } from '../../generated/graphql';
import TradeDetail, { buildTradeTitle, tradeDetailFragment } from './TradeDetail';
import TradeIcon from './TradeIcon';

interface TradeSectionProps extends RefreshingComponentProps {
  trade?: TradeSection_Trade;
}

const TradeSection = ({ trade, loading, refreshing, onRefresh, autoRefresh }: TradeSectionProps) => (
  <Paper>
    <Toolbar>
      <TradeIcon />
      &nbsp;
      <SectionTitle>{buildTradeTitle(trade?.id, trade?.order.portfolio.name)}</SectionTitle>
      <HorizontalAutoSpacer />
      <RefreshIconButton
        refreshing={refreshing}
        loading={loading}
        onRefresh={onRefresh}
        autoRefresh={autoRefresh}
        includeRefreshText
      />
    </Toolbar>

    {trade ? <TradeDetail trade={trade} /> : null}
  </Paper>
);
export default TradeSection;

export const tradeSectionFragment = gql`
  fragment TradeSection_Trade on Trade {
    id
    order {
      id
      portfolio {
        id
        name
      }
    }
    ...TradeDetail_Trade
  }
  ${tradeDetailFragment}
`;
