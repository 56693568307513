import React from 'react';
import BigNumberFormat, {
  BigNumberFormatProps,
} from '@paypr/mui5-common-components/dist/components/typography/BigNumberFormat';

export interface PercentFormatProps extends Omit<BigNumberFormatProps, 'prefix' | 'suffix' | ' fixedDecimalScale'> {
  amount?: number | null;
}

const PercentFormat = ({ decimalScale, amount, ...props }: PercentFormatProps) =>
  amount !== null && amount !== undefined ? (
    <BigNumberFormat
      {...props}
      amount={100 * amount}
      suffix="%"
      decimalScale={decimalScale !== undefined ? decimalScale : 2}
      fixedDecimalScale
    />
  ) : (
    <>&nbsp;</>
  );
export default PercentFormat;

export const formatPercent = (amount: number) => `${(100 * amount).toFixed(2)}%`;
