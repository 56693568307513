import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import React from 'react';
import { CreateSubsystemInput } from '../../generated/graphql';
import CreateSubsystemForm from './CreateSubsystemForm';
import SubsystemIcon from './SubsystemIcon';

export interface CreateSubsystemSectionProps {
  onCreateSubsystem: (createSubsystemInput: CreateSubsystemInput) => void;
  onCancel?: () => void;
  creating?: boolean;
}

const CreateSubsystemSection = ({ onCreateSubsystem, onCancel, creating }: CreateSubsystemSectionProps) => (
  <Paper>
    <Toolbar>
      <SubsystemIcon />
      &nbsp;
      <SectionTitle>Add Subsystem</SectionTitle>
    </Toolbar>

    <CreateSubsystemForm onCreateSubsystem={onCreateSubsystem} onCancel={onCancel} creating={creating} />
  </Paper>
);
export default CreateSubsystemSection;
