import { gql } from '@apollo/client';
import Stack from '@mui/material/Stack';
import PageContainer from '@paypr/mui5-common-components/dist/components/page/PageContainer';
import RouteButton from '@paypr/mui5-common-components/dist/components/routes/RouteButton';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import SubSectionTitle from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import * as React from 'react';
import AppPage from '../../components/common/page/AppPage';
import {
  buildDshStrategyAllocationsPath,
  buildDshStrategyAssetPath,
  buildDshStrategyReportsPath,
} from '../../components/dsh/dshPaths';
import { buildPortfolioPath } from '../../components/portfolios/portfolioPaths';
import { DshStrategyPageVersionDetails, useDshStrategyPageQuery } from '../../generated/graphql';
import { usePageTitle } from '../../hooks/page';

const DshStrategyPage = () => {
  usePageTitle('DSH Strategy');

  const { data, loading } = useDshStrategyPageQuery();

  const dshVersions = data?.dshVersions ?? [];

  return (
    <AppPage>
      <PageContainer>
        <SectionTitle>DSH Strategy</SectionTitle>
        {loading ? 'Loading...' : null}
        {dshVersions.map((version) => (
          <DshStrategyVersion key={version.id} version={version} />
        ))}
      </PageContainer>
    </AppPage>
  );
};
export default DshStrategyPage;

interface DshStrategyVersionProps {
  version: DshStrategyPageVersionDetails;
}

const DshStrategyVersion = ({ version }: DshStrategyVersionProps) => {
  return (
    <>
      <SubSectionTitle>
        {version.name} {!version.active ? '(inactive)' : ''}
      </SubSectionTitle>
      <DetailField>
        <Stack direction="row" spacing={2}>
          <RouteButton variant="contained" color="inherit" path={buildDshStrategyReportsPath(version.id)}>
            Reports
          </RouteButton>
          <RouteButton variant="contained" color="inherit" path={buildPortfolioPath(version.portfolio.id)}>
            Portfolio
          </RouteButton>
          {version.active && (
            <RouteButton variant="contained" color="inherit" path={buildDshStrategyAllocationsPath(version.id)}>
              Allocations
            </RouteButton>
          )}
        </Stack>
        <DetailField>
          <DetailTitle>Asset Detail</DetailTitle>
          <Stack direction="row" spacing={2}>
            {version.assets.map((asset) => (
              <AssetButton key={asset.id} version={version.id} asset={asset.asset} />
            ))}
          </Stack>
        </DetailField>
      </DetailField>
    </>
  );
};

interface AssetButtonProps {
  version: string;
  asset: string;
}

const AssetButton = ({ version, asset }: AssetButtonProps) => (
  <RouteButton variant="contained" color="inherit" path={buildDshStrategyAssetPath(version, asset)}>
    {asset}
  </RouteButton>
);

gql`
  query DshStrategyPageQuery {
    dshVersions {
      id
      ...DshStrategyPageVersionDetails
    }
  }

  fragment DshStrategyPageVersionDetails on DshVersion {
    id
    name
    portfolio {
      id
    }
    assets {
      id
      asset
    }
    active
  }
`;
