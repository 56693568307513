import { gql } from '@apollo/client';
import PageContainer from '@paypr/mui5-common-components/dist/components/page/PageContainer';
import { useSnackbar } from 'notistack';
import React from 'react';
import AppPage from '../../components/common/page/AppPage';
import AddInternalUserForm from '../../components/internalUsers/AddInternalUserForm';
import { buildInternalUserPath } from '../../components/internalUsers/internalUserPaths';
import { CreateInternalUserInput, useAddInternalUserPageCreateInternalUserMutation } from '../../generated/graphql';
import { useErrors } from '../../hooks/errors';
import { usePageTitle } from '../../hooks/page';
import { usePaths } from '../../hooks/paths';

const InternalUserPage = () => {
  const { navigateTo } = usePaths();
  const { enqueueSnackbar } = useSnackbar();
  const { showError } = useErrors();

  usePageTitle('Add New Admin User');

  const [addInternalUser, { loading: updating }] = useAddInternalUserPageCreateInternalUserMutation();

  const handleAddInternalUser = async (newInternalUser: CreateInternalUserInput) => {
    try {
      const { data } = await addInternalUser({
        variables: {
          input: newInternalUser,
        },
      });

      if (!data) {
        showError(new Error('No data returned'));
        return;
      }

      enqueueSnackbar('Admin User added successfully.', { variant: 'success' });

      navigateTo(buildInternalUserPath(data.createInternalUser.id));
    } catch (e) {
      showError(e);
    }
  };

  return (
    <AppPage>
      <PageContainer>
        <AddInternalUserForm onAddInternalUser={handleAddInternalUser} updating={updating} />
      </PageContainer>
    </AppPage>
  );
};
export default InternalUserPage;

gql`
  mutation AddInternalUserPageCreateInternalUserMutation($input: CreateInternalUserInput!) {
    createInternalUser(input: $input) {
      id
    }
  }
`;
