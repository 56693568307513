import { gql } from '@apollo/client';
import NavItem, { NavItemProps } from '@paypr/mui5-common-components/dist/components/page/nav/NavItem';
import * as React from 'react';
import { useMatch } from 'react-router-dom';
import { useSubsystemNavItemQuery } from '../../generated/graphql';
import { buildSubsystemTitle } from './SubsystemDetail';
import { SubsystemPageParams } from './subsystemPaths';

type SubsystemNavItemProps = Omit<NavItemProps, 'title'>;

const SubsystemNavItem = ({ path, ...rest }: SubsystemNavItemProps) => {
  const match = useMatch<keyof SubsystemPageParams, typeof path>(path);

  const extractSubsystemId = (): string | null => {
    return match?.params?.subsystemId || null;
  };

  const subsystemId = extractSubsystemId();

  const { data } = useSubsystemNavItemQuery({
    variables: subsystemId ? { subsystemId } : undefined,
    skip: subsystemId === 'add' || !subsystemId || !match,
  });

  const title = buildSubsystemTitle(data?.subsystem?.label);

  return <NavItem {...rest} title={title} path={path} />;
};
export default SubsystemNavItem;

gql`
  query SubsystemNavItemQuery($subsystemId: ID!) {
    subsystem(id: $subsystemId) {
      id
      label
    }
  }
`;
