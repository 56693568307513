export const portfoliosPath = '/portfolios';

export interface PortfoliosPageQueryParams {
  includeDeleted: string;
}

export const buildPortfolioPath = (portfolioId: string) => `${portfoliosPath}/${portfolioId}`;

export const parameterizedPortfolioPath = buildPortfolioPath(':portfolioId');
export interface PortfolioPageParams {
  portfolioId: string;
}
export interface PortfolioPageQueryParams {
  includeDeletedAssets: string;
}

export const buildPortfolioLedgerEventPath = (portfolioId: string, ledgerEventId) =>
  `${buildPortfolioPath(portfolioId)}/events/${ledgerEventId}`;

export const buildAddPortfolioLedgerEventPath = (portfolioId: string) =>
  buildPortfolioLedgerEventPath(portfolioId, 'add');

export const parameterizedAddPortfolioLedgerEventPath = buildAddPortfolioLedgerEventPath(':portfolioId');

export const parameterizedPortfolioLedgerEventPath = buildPortfolioLedgerEventPath(':portfolioId', ':ledgerEventId');

export interface PortfolioLedgerEventPageParams {
  portfolioId: string;
  ledgerEventId: string;
}
