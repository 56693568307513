import { gql } from '@apollo/client';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import * as React from 'react';
import { AuditActionSection_AuditAction } from '../../generated/graphql';
import AuditActionDetail, { auditActionDetailFragment, buildAuditActionTitle } from './AuditActionDetail';
import AuditActionIcon from './AuditActionIcon';

interface AuditActionSectionProps extends RefreshingComponentProps {
  auditAction?: AuditActionSection_AuditAction;
}

const AuditActionSection = ({ auditAction, loading, refreshing, onRefresh, autoRefresh }: AuditActionSectionProps) => (
  <Paper>
    <Toolbar>
      <AuditActionIcon />
      &nbsp;
      <SectionTitle>{buildAuditActionTitle(auditAction?.subsystem?.label, auditAction?.type)}</SectionTitle>
      <HorizontalAutoSpacer />
      <RefreshIconButton
        refreshing={refreshing}
        loading={loading}
        onRefresh={onRefresh}
        autoRefresh={autoRefresh}
        includeRefreshText
      />
    </Toolbar>

    {auditAction ? <AuditActionDetail auditAction={auditAction} /> : null}
  </Paper>
);
export default AuditActionSection;

export const auditActionSectionFragment = gql`
  fragment AuditActionSection_AuditAction on AuditAction {
    id
    subsystem {
      id
      label
    }
    type
    ...AuditActionWorkflowDetail_AuditAction
  }
  ${auditActionDetailFragment}
`;
