import { css } from '@emotion/react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SubSectionTitle from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import React, { useEffect, useState } from 'react';
import { OrdersTableData_OrderConnection } from '../../generated/graphql';
import ExpandIcon from '../common/icons/ExpandIcon';
import ExplodeIconButton, { ExplodingComponentProps } from '../common/icons/ExplodeIconButton';
import NoDataError from '../common/page/NoDataError';
import OrdersTable, { OrderClickEventHandler } from './OrdersTable';

export interface OrdersAccordionProps extends ExplodingComponentProps, RefreshingComponentProps {
  orders?: OrdersTableData_OrderConnection;
  onClickOrder?: OrderClickEventHandler;
  defaultExpanded?: boolean;
}

const OrdersAccordion = ({
  orders,
  onClickOrder,
  defaultExpanded,
  exploded,
  onExplodeChanged,
  loading,
  refreshing,
  onRefresh,
  autoRefresh,
}: OrdersAccordionProps) => {
  const [expanded, setExpanded] = useState(exploded ? true : defaultExpanded);

  useEffect(() => {
    if (!expanded) {
      return;
    }

    onRefresh?.();
  }, [expanded]);

  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      expanded={expanded}
      onChange={(event, expanded) => setExpanded(expanded)}
    >
      <AccordionSummary expandIcon={!exploded ? <ExpandIcon /> : undefined}>
        <Toolbar
          css={css`
            display: flex;
            flex-grow: 1;
          `}
        >
          <SubSectionTitle>Orders</SubSectionTitle>
          <HorizontalAutoSpacer />
          <RefreshIconButton
            refreshing={refreshing}
            loading={loading}
            onRefresh={expanded ? onRefresh : undefined}
            includeRefreshText
            autoRefresh={autoRefresh}
          />
          <ExplodeIconButton exploded={exploded} onExplodeChanged={onExplodeChanged} />
        </Toolbar>
      </AccordionSummary>
      <AccordionDetails>
        {orders ? (
          <OrdersTable orderConnection={orders} onClickOrderRow={onClickOrder} fullHeight={exploded} />
        ) : !(loading || refreshing) ? (
          <NoDataError />
        ) : null}
      </AccordionDetails>
    </Accordion>
  );
};
export default OrdersAccordion;
