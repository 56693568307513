import { css } from '@emotion/react';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import HeightLimitedTableContainer from '@paypr/mui5-common-components/dist/components/tables/HeightLimitedTableContainer';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import SubSectionTitle from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import TooltipItem from '@paypr/mui5-common-components/dist/components/typography/TooltipItem';
import { DateTime } from 'luxon';
import React from 'react';
import { useDateTime } from '../../data/dates';
import {
  LedgerReportDetailsDetails,
  LedgerReportResultDetails,
  LedgerReportValueDetails,
  LedgerReportValueField,
} from '../../generated/graphql';
import NorrioIcon from '../common/icons/NorrioIcon';
import HeaderColumnTableCell from '../common/tables/HeaderColumnTableCell';
import {
  extractAllGroupValueCombos,
  extractAllResultsByPeriodStartAt,
  resultGroupsSameAsCombo,
} from './ledgerDataTableHelpers';
import {
  determineFieldDescription,
  determineFieldName,
  findLedgerReportFieldValueDetails,
  formatDateTime,
  sortReportValueFields,
} from './ledgerReportHelpers';
import LedgerReportTableFieldRow from './LedgerReportTableFieldRow';
import ReportTableGroupedHeaderRows from './ReportTableGroupedHeaderRows';

export interface LedgerReportVerticalDataTableProps {
  report: LedgerReportDetailsDetails;
}

const LedgerReportVerticalDataTable = ({ report }: LedgerReportVerticalDataTableProps) => {
  const theme = useTheme();
  const { toDateTime } = useDateTime();

  const allGroupValuesCombos = extractAllGroupValueCombos(report);
  const allResultsByPeriodStartAt: Record<DateTime, LedgerReportResultDetails[]> = extractAllResultsByPeriodStartAt(
    report,
    { toDateTime },
  );
  const allPeriodStartAts: DateTime[] = Object.keys(allResultsByPeriodStartAt)
    .map((periodStartAtStr) => toDateTime(periodStartAtStr))
    .sort()
    .slice(1);

  const valueFields = sortReportValueFields(
    report.values.filter(
      (field) =>
        field !== LedgerReportValueField.PeriodStartAt &&
        field != LedgerReportValueField.PeriodEndAt &&
        field != LedgerReportValueField.PeriodNumber,
    ),
  );

  const getValuesAtPeriodStartAt = (
    periodStartAt: DateTime,
    valueField: LedgerReportValueField,
  ): (LedgerReportValueDetails | null)[] => {
    const results = allResultsByPeriodStartAt[periodStartAt];
    return allGroupValuesCombos.map((combo) => {
      const foundResult = results.find((result) => resultGroupsSameAsCombo(result, combo));

      // const groups = combo.groupValues.map((groupValue) => `${groupValue.group}=${groupValue.value}`);

      if (!foundResult) {
        // console.debug('No result found for combo:', groups, 'at', periodStartAt.toISO());
        return null;
      }

      const value = findLedgerReportFieldValueDetails(foundResult.values, valueField);
      if (value === undefined) {
        // console.debug('No value found for field:', valueField, 'for combo', groups, 'at', periodStartAt.toISO());
        return null;
      }

      return value;
    });
  };

  return (
    <>
      {valueFields.length === 1 && report.groupBy.length > 0 ? (
        <DetailContainer>
          <SubSectionTitle>
            <TooltipItem title={determineFieldDescription(valueFields[0])}>
              <>{determineFieldName(valueFields[0])}</>
            </TooltipItem>
          </SubSectionTitle>
        </DetailContainer>
      ) : null}
      <HeightLimitedTableContainer>
        <Table stickyHeader>
          <TableHead>
            {report.groupBy.length > 0 ? (
              <ReportTableGroupedHeaderRows groups={report.groupBy} combos={allGroupValuesCombos} />
            ) : (
              <TableRow>
                <HeaderColumnTableCell front>
                  <NorrioIcon height={theme.spacing(4)} />
                </HeaderColumnTableCell>
                {valueFields.length === 1 ? (
                  <TableCell align="right">
                    <TooltipItem title={determineFieldDescription(valueFields[0])}>
                      <>{determineFieldName(valueFields[0])}</>
                    </TooltipItem>
                  </TableCell>
                ) : null}
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {allPeriodStartAts.map((periodStartAt) => (
              <React.Fragment key={periodStartAt}>
                {valueFields.length > 1 ? (
                  <TableRow>
                    <HeaderColumnTableCell
                      css={css`
                        color: ${theme.palette.primary.contrastText};
                        background-color: ${theme.palette.primary.main};
                      `}
                    >
                      {formatDateTime(periodStartAt, report.timeResolution)}
                    </HeaderColumnTableCell>
                    <TableCell
                      css={css`
                        color: ${theme.palette.primary.contrastText};
                        background-color: ${theme.palette.primary.main};
                      `}
                      colSpan={allGroupValuesCombos.length}
                    >
                      &nbsp;
                    </TableCell>
                  </TableRow>
                ) : null}
                {valueFields.map((valueField) => (
                  <LedgerReportTableFieldRow
                    key={`${periodStartAt}-${valueField}`}
                    valueField={valueField}
                    fieldOverride={
                      valueFields.length === 1 ? formatDateTime(periodStartAt, report.timeResolution) : undefined
                    }
                    values={getValuesAtPeriodStartAt(periodStartAt, valueField)}
                  />
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </HeightLimitedTableContainer>
    </>
  );
};
export default LedgerReportVerticalDataTable;
