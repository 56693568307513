import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import React from 'react';
import { PortfoliosTableRow_Portfolio } from '../../generated/graphql';
import NoDataError from '../common/page/NoDataError';
import PortfoliosTable, { PortfolioClickEventHandler } from './PortfoliosTable';

export interface PortfoliosSectionProps extends RefreshingComponentProps {
  portfolios?: readonly PortfoliosTableRow_Portfolio[];
  hideStrategy?: boolean;
  hideFund?: boolean;
  onClickPortfolio?: PortfolioClickEventHandler;
  includeDeletedPortfolios?: boolean;
  onUpdateIncludeDeletedPortfolios?: (includeDeletedPortfolios: boolean) => void;
}

const PortfoliosSection = ({
  portfolios,
  loading,
  refreshing,
  onRefresh,
  autoRefresh,
  hideStrategy,
  hideFund,
  onClickPortfolio,
  includeDeletedPortfolios,
  onUpdateIncludeDeletedPortfolios,
}: PortfoliosSectionProps) => (
  <Paper>
    <Toolbar>
      <SectionTitle>Portfolios</SectionTitle>
      <HorizontalAutoSpacer />
      <RefreshIconButton
        refreshing={refreshing}
        loading={loading}
        onRefresh={onRefresh}
        autoRefresh={autoRefresh}
        includeRefreshText
      />
    </Toolbar>
    {onUpdateIncludeDeletedPortfolios ? (
      <DetailContainer>
        <FormControlLabel
          control={
            <Switch
              defaultChecked={includeDeletedPortfolios}
              onChange={(_, value) => onUpdateIncludeDeletedPortfolios(value)}
            />
          }
          label="Include Deleted"
        />
      </DetailContainer>
    ) : null}

    {portfolios ? (
      <PortfoliosTable
        portfolios={portfolios}
        hideStrategy={hideStrategy}
        hideFund={hideFund}
        onClickPortfolioRow={onClickPortfolio}
      />
    ) : !(loading || refreshing) ? (
      <NoDataError />
    ) : null}
  </Paper>
);
export default PortfoliosSection;
