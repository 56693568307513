import { gql, NetworkStatus } from '@apollo/client';
import { useSnackbar } from 'notistack';
import React from 'react';
import { buildInternalUserTitle } from '../../components/internalUsers/InternalUserDetail';
import { InternalUserPageParams } from '../../components/internalUsers/internalUserPaths';
import InternalUserSection, { internalUserSectionFragment } from '../../components/internalUsers/InternalUserSection';
import AppPage from '../../components/common/page/AppPage';
import AppPageError from '../../components/common/page/AppPageError';
import AppPageMessage from '../../components/common/page/AppPageMessage';
import WiderPageContainer from '../../components/common/page/WiderPageContainer';
import { buildAuditActionPath } from '../../components/auditActions/auditActionPaths';
import {
  useInternalUserPageDisableInternalUserMutation,
  useInternalUserPageEnableInternalUserMutation,
  useInternalUserPageQuery,
} from '../../generated/graphql';
import { useErrors } from '../../hooks/errors';
import { usePageParams, usePageTitle } from '../../hooks/page';
import { usePaths } from '../../hooks/paths';

const InternalUserPage = () => {
  const { internalUserId } = usePageParams<keyof InternalUserPageParams>();
  const { navigateTo } = usePaths();
  const { enqueueSnackbar } = useSnackbar();
  const { showError } = useErrors();

  const { data, error, refetch, networkStatus, loading } = useInternalUserPageQuery({
    fetchPolicy: 'cache-and-network',
    variables: { internalUserId },
  });

  usePageTitle(`Admin User - ${buildInternalUserTitle(data?.internalUser?.fullName)}`);

  const [enableInternalUser, { loading: enabling }] = useInternalUserPageEnableInternalUserMutation({
    variables: {
      internalUserId,
    },
    onCompleted: async () => {
      await refetch();
    },
  });

  const [disableInternalUser, { loading: disabling }] = useInternalUserPageDisableInternalUserMutation({
    variables: {
      internalUserId,
    },
    onCompleted: async () => {
      await refetch();
    },
  });

  if (error) {
    return <AppPageError error={error} />;
  }

  const internalUser = data?.internalUser;

  if (internalUser === null) {
    return <AppPageMessage text={`Admin User ${internalUserId} does not exist`} />;
  }

  const handleEnableInternalUser = async () => {
    if (
      !confirm(
        `Are you sure you want to enable the Admin Tools Login ${buildInternalUserTitle(internalUser?.fullName)}?`,
      )
    ) {
      return;
    }

    try {
      await enableInternalUser();

      enqueueSnackbar('Admin Tools Login enabled successfully.', { variant: 'success' });
    } catch (e) {
      showError(e);
    }
  };

  const handleDisableInternalUser = async () => {
    if (
      !confirm(
        `Are you sure you want to disable the Admin Tools Login for ${buildInternalUserTitle(internalUser?.fullName)}?`,
      )
    ) {
      return;
    }

    try {
      await disableInternalUser();

      enqueueSnackbar('Admin Tools Login disabled successfully.', { variant: 'success' });
    } catch (e) {
      showError(e);
    }
  };

  const handleAuditActionClicked = (auditActionId: string, newTab: boolean) => {
    navigateTo(buildAuditActionPath(auditActionId), newTab);
  };

  return (
    <AppPage>
      <WiderPageContainer>
        <InternalUserSection
          internalUser={internalUser}
          onEnableInternalUser={handleEnableInternalUser}
          onDisableInternalUser={handleDisableInternalUser}
          onClickAuditAction={handleAuditActionClicked}
          loading={loading || enabling || disabling}
          refreshing={networkStatus === NetworkStatus.refetch}
          onRefresh={() => refetch()}
          autoRefresh
        />
      </WiderPageContainer>
    </AppPage>
  );
};
export default InternalUserPage;

gql`
  query InternalUserPageQuery($internalUserId: ID!) {
    internalUser(id: $internalUserId) {
      id
      firstName
      lastName
      ...InternalUserSection_InternalUser
    }
  }

  mutation InternalUserPageEnableInternalUserMutation($internalUserId: ID!) {
    enableInternalUser(userId: $internalUserId)
  }

  mutation InternalUserPageDisableInternalUserMutation($internalUserId: ID!) {
    disableInternalUser(userId: $internalUserId)
  }

  ${internalUserSectionFragment}
`;
