import { gql } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import AlternatingTableRow from '@paypr/mui5-common-components/dist/components/tables/AlternatingTableRow';
import EmptyTableRow from '@paypr/mui5-common-components/dist/components/tables/EmptyTableRow';
import {
  SimpleSortingTableHeadCell,
  SimpleSortingTableHeadProps,
} from '@paypr/mui5-common-components/dist/components/tables/SortingTableHead';
import { getComparator, stableSort, useSorting } from '@paypr/mui5-common-components/dist/components/tables/sortUtils';
import TooltipItem from '@paypr/mui5-common-components/dist/components/typography/TooltipItem';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { TradesTableData_TradeConnection, TradesTableRow_Trade, TransactionSide } from '../../generated/graphql';
import ErrorIcon from '../common/icons/ErrorIcon';
import { buildExchangeDisplay } from '../common/model/exchange';
import { buildTimeframeDisplay } from '../common/model/timeframe';
import HeightLimitedTableContainer from '../common/tables/HeightLimitedTableContainer';
import SimpleSortingTableHead from '../common/tables/SortingTableHead';
import FloatFormat from '../common/typography/FloatFormat';
import UsdFormat from '../common/typography/UsdFormat';
import { buildTransactionSideDisplay, buildTransactionTypeDisplay } from '../transactions/TransactionDetail';
import { buildTradeStatusDisplay } from './TradeDetail';

interface TradesTableProps {
  tradeConnection?: TradesTableData_TradeConnection;
  trades?: readonly TradesTableRow_Trade[];
  onClickTradeRow?: TradeClickEventHandler;
  fullHeight?: boolean;
}

export type TradeClickEventHandler = (tradeId: string, newTab: boolean) => void;

interface TradesTableRowData extends TradesTableRow_Trade {
  portfolioName: string;
  transactionType: string;
  transactionSide: string;
}

const TradesTable = ({ trades, tradeConnection, onClickTradeRow, fullHeight }: TradesTableProps) => {
  const { order, orderBy, handleRequestSort } = useSorting<TradesTableRowData>('createdAt', 'desc');

  const createClickHandler = (tradeId: string) => {
    if (!onClickTradeRow) {
      return undefined;
    }
    return (event) => onClickTradeRow(tradeId, event.ctrlKey || event.metaKey);
  };

  const tradeList: TradesTableRowData[] = buildTrades({ tradeConnection, trades });

  return (
    <HeightLimitedTableContainer fullHeight={fullHeight}>
      <Table stickyHeader>
        <TradesTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
        <TableBody>
          {tradeList.length > 0 ? (
            stableSort(tradeList, getComparator(order, orderBy)).map((trade) => (
              <TradeTableRow key={trade.id} trade={trade} onRowClick={createClickHandler(trade.id)} />
            ))
          ) : (
            <EmptyTableRow columnCount={columnHeadings.length}>No trades were found.</EmptyTableRow>
          )}
        </TableBody>
      </Table>
    </HeightLimitedTableContainer>
  );
};
export default TradesTable;

export interface BuildTradesOptions {
  tradeConnection?: TradesTableData_TradeConnection;
  trades?: readonly TradesTableRow_Trade[];
}

export const buildTrades = ({ tradeConnection, trades }: BuildTradesOptions): TradesTableRowData[] =>
  tradeConnection
    ? tradeConnection.edges.map(({ node }) => node).map(buildTradeRowData)
    : trades
      ? trades.map(buildTradeRowData)
      : [];

const buildTradeRowData = (trade: TradesTableRow_Trade): TradesTableRowData => ({
  ...trade,
  portfolioName: trade.order.portfolio.name,
  transactionType: trade.transaction ? buildTransactionTypeDisplay(trade.transaction.type) : 'None',
  transactionSide: buildTransactionSideDisplay(determineTradeSide(trade)),
});

const determineTradeSide = (trade: TradesTableRow_Trade): TransactionSide => {
  if (trade.transaction) {
    return trade.transaction.side;
  }

  if (trade.desiredCurrencyAmount) {
    return trade.desiredCurrencyAmount > 0 ? TransactionSide.Buy : TransactionSide.Sell;
  }

  if (trade.desiredAssetAmount) {
    return trade.desiredAssetAmount > 0 ? TransactionSide.Buy : TransactionSide.Sell;
  }

  console.warn('No desired currency or amount in trade:', trade);
  return TransactionSide.Buy;
};

type TradesTableHeadProps = Omit<SimpleSortingTableHeadProps<TradesTableRowData>, 'headings'>;

const columnHeadings: SimpleSortingTableHeadCell<TradesTableRowData>[] = [
  { key: 'portfolioName', label: 'Portfolio' },
  { key: 'status', label: 'Status' },
  { key: 'asset', label: 'Asset' },
  { key: 'exchange', label: 'Exchange' },
  { key: 'transactionType', label: 'Type' },
  { key: 'transactionSide', label: 'Side' },
  { key: 'timeframe', label: 'Timeframe' },
  { key: 'desiredCurrencyAmount', label: 'Desired', align: 'right' },
  { key: 'filledCurrencyAmount', label: 'Filled', align: 'right' },
  { key: 'unfilledCurrencyAmount', label: 'Unfilled', align: 'right' },
  { key: 'averagePrice', label: 'Price', align: 'right' },
  { key: 'feesPaid', label: 'Fees', align: 'right' },
  { key: 'dueAt', label: 'Due', align: 'right' },
  { key: 'updatedAt', label: 'Last Update', align: 'right' },
  { key: 'createdAt', label: 'Created', align: 'right' },
];

const TradesTableHead = (props: TradesTableHeadProps) => (
  <SimpleSortingTableHead {...props} headings={columnHeadings} />
);

type TradeTableRowProps = {
  trade: TradesTableRowData;
  onRowClick?: React.MouseEventHandler;
};

export const TradeTableRow = ({ trade, onRowClick }: TradeTableRowProps) => {
  const theme = useTheme();
  const { formatShortDateTime } = useDateTime();

  return (
    <AlternatingTableRow hover={Boolean(onRowClick)} onClick={onRowClick}>
      <TableCell>{trade.portfolioName}</TableCell>
      <TableCell>
        {buildTradeStatusDisplay(trade.status)}{' '}
        {trade.error ? (
          <TooltipItem title={trade.error}>
            <ErrorIcon style={{ fontSize: theme.typography.body2.fontSize }} color="error" />
          </TooltipItem>
        ) : null}
      </TableCell>
      <TableCell>{trade.asset}</TableCell>
      <TableCell>{buildExchangeDisplay(trade.exchange)}</TableCell>
      <TableCell>{trade.transactionType}</TableCell>
      <TableCell>{trade.transactionSide}</TableCell>
      <TableCell>{buildTimeframeDisplay(trade.timeframe)}</TableCell>
      <TableCell align="right">
        <UsdFormat amount={trade.desiredCurrencyAmount} />
        <br />
        <FloatFormat amount={trade.desiredAssetAmount} asset={trade.asset} />
      </TableCell>
      <TableCell align="right">
        <UsdFormat amount={trade.filledCurrencyAmount} />
        <br />
        <FloatFormat amount={trade.filledAssetAmount} asset={trade.asset} />
      </TableCell>
      <TableCell align="right">
        <UsdFormat amount={trade.unfilledCurrencyAmount} />
        <br />
        <FloatFormat amount={trade.unfilledAssetAmount} asset={trade.asset} />
      </TableCell>
      <TableCell align="right">
        <UsdFormat amount={trade.averagePrice} />
      </TableCell>
      <TableCell align="right">
        <UsdFormat amount={trade.feesPaid} />
      </TableCell>
      <TableCell align="right">{formatShortDateTime(trade.dueAt)}</TableCell>
      <TableCell align="right">{formatShortDateTime(trade.updatedAt)}</TableCell>
      <TableCell align="right">{formatShortDateTime(trade.createdAt)}</TableCell>
    </AlternatingTableRow>
  );
};

export const buildTradeCsvData = (
  trades: TradesTableRowData[],
  formatShortDateTime: (date: DateTime | string) => string,
): (string | number | null)[][] => {
  const headerRow = [
    'Portfolio',
    'Status',
    'Asset',
    'Exchange',
    'Type',
    'Side',
    'Timeframe',
    'Desired Currency',
    'Desired Asset',
    'Filled Currency',
    'Filled Asset',
    'Unfilled Currency',
    'Unfilled Asset',
    'Average Price',
    'Fees Paid',
    'Due At',
    'Last Update',
    'Created',
  ];

  const valueRows = trades.map((trade) => [
    trade.portfolioName,
    buildTradeStatusDisplay(trade.status),
    trade.asset,
    buildExchangeDisplay(trade.exchange),
    trade.transactionType,
    trade.transactionSide,
    buildTimeframeDisplay(trade.timeframe),
    trade.desiredCurrencyAmount,
    trade.desiredAssetAmount,
    trade.filledCurrencyAmount,
    trade.filledAssetAmount,
    trade.unfilledCurrencyAmount,
    trade.unfilledAssetAmount,
    trade.averagePrice,
    trade.feesPaid,
    formatShortDateTime(trade.dueAt),
    formatShortDateTime(trade.updatedAt),
    formatShortDateTime(trade.createdAt),
  ]);

  return [headerRow, ...valueRows];
};

export const tradesTableRowFragment = gql`
  fragment TradesTableData_TradeConnection on TradeConnection {
    edges {
      node {
        id
        ...TradesTableRow_Trade
      }
    }
  }

  fragment TradesTableRow_Trade on Trade {
    id
    order {
      id
      portfolio {
        id
        name
      }
    }
    status
    asset
    exchange
    transaction {
      id
      type
      side
    }
    desiredCurrencyAmount
    desiredAssetAmount
    filledCurrencyAmount
    filledAssetAmount
    unfilledCurrencyAmount
    unfilledAssetAmount
    averagePrice
    feesPaid
    timeframe
    error
    dueAt
    createdAt
    updatedAt
  }
`;
