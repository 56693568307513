import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { DatePicker } from '@mui/x-date-pickers';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useDateTime, useTimeZone } from '../../../data/dates';
import { getDshRealizedGainsCustomReportState } from '../../../data/reports';
import { useFormFields } from '../../../hooks/forms';
import { fetchDshCustomReport, ReportType } from '../../../services/dshReports';
import DshGainsReport from './DshGainsReport';

export interface DshRealizedGainsReportIntegrationProps {
  version: string;
}

interface DshRealizedGainsReportIntegrationFields {
  reportType: ReportType;
  reportDate: DateTime;
}

const DshRealizedGainsReportIntegration = ({ version }: DshRealizedGainsReportIntegrationProps) => {
  const { now } = useDateTime();
  const { fields, handleFieldChange, setFieldValue } = useFormFields<DshRealizedGainsReportIntegrationFields>({
    reportType: ReportType.Daily,
    reportDate: now(),
  });
  const [{ report }, setDshRealizedGainsReportState] = useRecoilState(
    getDshRealizedGainsCustomReportState(version, fields.reportType, fields.reportDate),
  );
  const [loading, setLoading] = useState<boolean>(false);
  const timeZone = useTimeZone();

  useEffect(() => {
    if (report) {
      return;
    }

    // noinspection JSIgnoredPromiseFromCall
    loadReportText();
  }, [report]);

  const loadReportText = async () => {
    try {
      setLoading(true);
      const report = await fetchDshCustomReport(version, fields.reportType, fields.reportDate, timeZone);
      setDshRealizedGainsReportState((currentState) => ({ ...currentState, report }));
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (newDate) => {
    if (newDate > now()) {
      setFieldValue('reportDate', now());
      return;
    }
    setFieldValue('reportDate', newDate);
  };

  return (
    <>
      <DetailField>
        <DetailTitle>Report Type</DetailTitle>
        <RadioGroup name="reportType" row onChange={handleFieldChange} value={fields.reportType}>
          <FormControlLabel radioGroup="reportType" value={ReportType.Daily} control={<Radio />} label="Daily" />
          <FormControlLabel radioGroup="reportType" value={ReportType.Monthly} control={<Radio />} label="Monthly" />
        </RadioGroup>
      </DetailField>
      <DetailField>
        <DetailTitle>Report Date</DetailTitle>
        <DatePicker label="Report Date" value={fields.reportDate} onChange={handleDateChange} />
      </DetailField>
      <DshGainsReport
        report={report}
        onRefresh={loadReportText}
        loading={loading}
        refreshing={Boolean(loading && report)}
        autoRefresh
      />
    </>
  );
};
export default DshRealizedGainsReportIntegration;
