import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TooltipIconButton from '@paypr/mui5-common-components/dist/components/actions/TooltipIconButton';
import DefaultTextField from '@paypr/mui5-common-components/dist/components/forms/DefaultTextField';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailInlineValue from '@paypr/mui5-common-components/dist/components/typography/DetailInlineValue';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import SubSectionTitle from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import React, { useEffect } from 'react';
import { TradeStatus } from '../../generated/graphql';
import { useFormFields } from '../../hooks/forms';
import ExpandIcon from '../common/icons/ExpandIcon';
import RemoveIcon from '../common/icons/RemoveIcon';
import { TradeFilters } from './tradePaths';

export interface TradeFilterChoicesProps {
  defaultFilters: TradeFilters;
  defaultExpanded?: boolean;
  onOptionsExpandedChanged?: (expanded: boolean) => void;
  assets?: string[];
  loading?: boolean;
  onChangeFilters: (filters: TradeFilters) => void;
}

interface TradeFilterFields {
  addAssetText: string;
  statusType: string;
  status: string;
}

const TradeFilterChoices = ({
  defaultFilters,
  defaultExpanded,
  onOptionsExpandedChanged,
  assets,
  loading,
  onChangeFilters,
}: TradeFilterChoicesProps) => {
  const [selectedAssets, setSelectedAssets] = React.useState<string[] | null>(defaultFilters.assets);

  const { fields, handleFieldChange, setFieldValue } = useFormFields<TradeFilterFields>({
    addAssetText: '',
    statusType:
      defaultFilters.inProgress !== null ? defaultFilters.inProgress.toString() : defaultFilters.status ? 'status' : '',
    status: defaultFilters.status || '',
  });

  useEffect(() => {
    onChangeFilters({
      assets: selectedAssets,
      inProgress: fields.statusType === 'true' ? true : fields.statusType === 'false' ? false : null,
      status: fields.statusType === 'status' && fields.status ? (fields.status as TradeStatus) : null,
    });
  }, [selectedAssets, fields]);

  // assets
  const addAssetOptions: string[] = (assets || [])
    .filter((asset) => !selectedAssets?.includes(asset))
    .sort((a, b) => a.localeCompare(b));

  const handleAddAsset = (event, newAsset: string) => {
    event.preventDefault();
    if (!newAsset) {
      return;
    }
    setSelectedAssets((currentAssets) => [...(currentAssets || []), newAsset]);
    setFieldValue('addAssetText', '');
  };

  const handleRemoveAssetFilter = (removeAssetFilter: string) =>
    setSelectedAssets((currentAssets) => {
      const newAssets = [...(currentAssets || []).filter((asset) => asset !== removeAssetFilter)];
      if (newAssets.length === 0) {
        return null;
      }
      return newAssets;
    });

  const handleOptionsExpandedChanged = (_event, expanded) => {
    onOptionsExpandedChanged?.(expanded);
  };

  return (
    <Accordion defaultExpanded={defaultExpanded} onChange={handleOptionsExpandedChanged}>
      <AccordionSummary expandIcon={<ExpandIcon />}>
        <SubSectionTitle>Options</SubSectionTitle>
      </AccordionSummary>
      <AccordionDetails>
        <DetailContainer>
          {((assets && assets.length > 0) || (selectedAssets && selectedAssets.length > 0)) && (
            <DetailField>
              <DetailTitle>Asset Filter</DetailTitle>
              <FormGroup>
                <Autocomplete
                  id="addAssetText"
                  value=""
                  inputValue={fields.addAssetText}
                  onInputChange={(event, newInputValue) => setFieldValue('addAssetText', newInputValue)}
                  options={addAssetOptions}
                  onChange={handleAddAsset}
                  disabled={loading || addAssetOptions.length === 0}
                  renderInput={(params) => (
                    <DefaultTextField {...params} label="Add Asset" placeholder="Pick a asset" />
                  )}
                />
                {selectedAssets?.map((asset) => (
                  <div key={asset}>
                    <TooltipIconButton
                      title={`Remove ${asset}`}
                      onClick={() => handleRemoveAssetFilter(asset)}
                      disabled={loading}
                    >
                      <RemoveIcon fontSize="small" />
                    </TooltipIconButton>
                    <DetailInlineValue>{asset}</DetailInlineValue>
                  </div>
                ))}
              </FormGroup>
            </DetailField>
          )}
          <DetailField>
            <DetailTitle>Status</DetailTitle>
            <RadioGroup name="statusType" row onChange={handleFieldChange} value={fields.statusType}>
              <FormControlLabel radioGroup="statusType" value="" control={<Radio />} label="All" disabled={loading} />
              <FormControlLabel
                radioGroup="statusType"
                value="true"
                control={<Radio />}
                label="In Progress"
                disabled={loading}
              />
              <FormControlLabel
                radioGroup="statusType"
                value="false"
                control={<Radio />}
                label="Not In Progress"
                disabled={loading}
              />
              <FormControlLabel
                radioGroup="statusType"
                value="status"
                control={<Radio />}
                label="Choose Status"
                disabled={loading}
              />
            </RadioGroup>
          </DetailField>
          {fields.statusType === 'status' && (
            <DetailField>
              <DefaultTextField
                id="status"
                value={fields.status}
                label="Status"
                select
                onChange={(event) => setFieldValue('status', event.target.value)}
              >
                <MenuItem value={TradeStatus.Created}>Created</MenuItem>
                <MenuItem value={TradeStatus.Initiating}>Initiating</MenuItem>
                <MenuItem value={TradeStatus.Initiated}>Initiated</MenuItem>
                <MenuItem value={TradeStatus.Canceling}>Canceling</MenuItem>
                <MenuItem value={TradeStatus.Canceled}>Canceled</MenuItem>
                <MenuItem value={TradeStatus.Done}>Done</MenuItem>
                <MenuItem value={TradeStatus.Error}>Error</MenuItem>
              </DefaultTextField>
            </DetailField>
          )}
        </DetailContainer>
      </AccordionDetails>
    </Accordion>
  );
};
export default TradeFilterChoices;
