import { atomFamily } from 'recoil';
import { DshReportData } from '../services/dshReports';

// dsh reports
export interface DshRealizedGainsReportState {
  report: DshReportData | null;
}

export const getDshRealizedGainsReportState = (version: string, reportType: string) =>
  getDshReportState(`${version}-${reportType}`);

export const getDshRealizedGainsCustomReportState = (version: string, reportType: string, reportDate: string) =>
  getDshReportState(`${version}-custom-${reportType}-${reportDate}`);

export const getDshAssetReportState = (version: string, reportName: string, asset: string) =>
  getDshReportState(`${version}-${reportName}-${asset}`);

const getDshReportState = atomFamily<DshRealizedGainsReportState, string>({
  key: 'dshReport',
  default: { report: null },
});
