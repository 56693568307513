import { gql } from '@apollo/client';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import TooltipIconButton from '@paypr/mui5-common-components/dist/components/actions/TooltipIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import * as React from 'react';
import { InternalUserSection_InternalUser } from '../../generated/graphql';
import { AuditActionClickEventHandler } from '../auditActions/AuditActionsTable';
import { withStopPropagation } from '../common/events/eventUtils';
import EnabledUserIcon from '../common/icons/EnabledUserIcon';
import InternalUserDetail, { buildInternalUserTitle, internalUserDetailFragment } from './InternalUserDetail';
import InternalUserIcon from './InternalUserIcon';

interface InternalUserSectionProps extends RefreshingComponentProps {
  internalUser?: InternalUserSection_InternalUser;
  onEnableInternalUser?: () => Promise<void>;
  onDisableInternalUser?: () => Promise<void>;
  onClickAuditAction?: AuditActionClickEventHandler;
}

const InternalUserSection = ({
  internalUser,
  onEnableInternalUser,
  onDisableInternalUser,
  onClickAuditAction,
  loading,
  refreshing,
  onRefresh,
  autoRefresh,
}: InternalUserSectionProps) => (
  <Paper>
    <Toolbar>
      <InternalUserIcon />
      &nbsp;
      <SectionTitle>{buildInternalUserTitle(internalUser?.fullName)}</SectionTitle>
      <HorizontalAutoSpacer />
      {internalUser?.deleted ? (
        <TooltipIconButton title="Admin User is deleted" disabled>
          <EnabledUserIcon color={refreshing || loading ? 'inherit' : 'error'} />
        </TooltipIconButton>
      ) : onEnableInternalUser && internalUser?.disabled ? (
        <TooltipIconButton
          title="Admin Tools Login is Disabled. Click to Enable"
          onClick={withStopPropagation(onEnableInternalUser)}
          disabled={refreshing || loading}
        >
          <EnabledUserIcon color={refreshing || loading ? 'inherit' : 'warning'} />
        </TooltipIconButton>
      ) : onDisableInternalUser && internalUser && !internalUser.disabled ? (
        <TooltipIconButton
          title="Admin Tools Login is Enabled. Click to Disable"
          onClick={withStopPropagation(onDisableInternalUser)}
          disabled={refreshing || loading}
        >
          <EnabledUserIcon color={refreshing || loading ? 'inherit' : 'success'} />
        </TooltipIconButton>
      ) : null}
      <RefreshIconButton
        refreshing={refreshing}
        loading={loading}
        onRefresh={onRefresh}
        autoRefresh={autoRefresh}
        includeRefreshText
      />
    </Toolbar>

    {internalUser ? (
      <InternalUserDetail
        internalUser={internalUser}
        onClickAuditAction={onClickAuditAction}
        autoRefresh={autoRefresh}
      />
    ) : null}
  </Paper>
);
export default InternalUserSection;

export const internalUserSectionFragment = gql`
  fragment InternalUserSection_InternalUser on InternalUser {
    id
    firstName
    lastName
    fullName
    disabled
    deleted
    ...InternalUserDetail_InternalUser
  }
  ${internalUserDetailFragment}
`;
