import { css } from '@emotion/react';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AlternatingTableRow from '@paypr/mui5-common-components/dist/components/tables/AlternatingTableRow';
import HeightLimitedTableContainer from '@paypr/mui5-common-components/dist/components/tables/HeightLimitedTableContainer';
import TooltipItem from '@paypr/mui5-common-components/dist/components/typography/TooltipItem';
import { DateTime } from 'luxon';
import React from 'react';
import { useDateTime } from '../../data/dates';
import {
  LedgerReportDetailsDetails,
  LedgerReportResultDetails,
  LedgerReportValueDetails,
  LedgerReportValueField,
} from '../../generated/graphql';
import NorrioIcon from '../common/icons/NorrioIcon';
import HeaderColumnTableCell from '../common/tables/HeaderColumnTableCell';
import {
  extractAllGroupValueCombos,
  extractAllResultsByPeriodStartAt,
  ReportGroupValueCombo,
  resultGroupsSameAsCombo,
} from './ledgerDataTableHelpers';
import {
  buildGroupValueDisplay,
  determineFieldDescription,
  determineFieldName,
  determineFieldType,
  findLedgerReportFieldValue,
  findLedgerReportFieldValueDetails,
  formatDateTime,
  sortReportValueFields,
} from './ledgerReportHelpers';
import LedgerReportTableFieldRow from './LedgerReportTableFieldRow';
import ReportTableCell from './ReportTableCell';

export interface LedgerReportHorizontalDataTableProps {
  report: LedgerReportDetailsDetails;
}

const LedgerReportHorizontalDataTable = ({ report }: LedgerReportHorizontalDataTableProps) => {
  const theme = useTheme();
  const { toDateTime } = useDateTime();

  const allGroupValuesCombos = extractAllGroupValueCombos(report);
  const allResultsByPeriodStartAt: Record<DateTime, LedgerReportResultDetails[]> = extractAllResultsByPeriodStartAt(
    report,
    { toDateTime },
  );
  const allPeriodStartAts: DateTime[] = Object.keys(allResultsByPeriodStartAt)
    .map((periodStartAtStr) => toDateTime(periodStartAtStr))
    .sort()
    .slice(1);

  const valueFields = sortReportValueFields(
    report.values.filter(
      (field) =>
        field !== LedgerReportValueField.PeriodStartAt &&
        field != LedgerReportValueField.PeriodEndAt &&
        field != LedgerReportValueField.PeriodNumber,
    ),
  );

  const getValuesAtPeriodStartAt = (
    periodStartAt: DateTime,
    valueField: LedgerReportValueField,
  ): (LedgerReportValueDetails | null)[] => {
    const results = allResultsByPeriodStartAt[periodStartAt];
    return allGroupValuesCombos.map((combo) => {
      const foundResult = results.find((result) => resultGroupsSameAsCombo(result, combo));

      // const groups = combo.groupValues.map((groupValue) => `${groupValue.group}=${groupValue.value}`);

      if (!foundResult) {
        // console.debug('No result found for combo:', groups, 'at', periodStartAt.toISO());
        return null;
      }

      const value = findLedgerReportFieldValueDetails(foundResult.values, valueField);
      if (value === undefined) {
        // console.debug('No value found for field:', valueField, 'for combo', groups, 'at', periodStartAt.toISO());
        return null;
      }

      return value;
    });
  };

  const getValueAtPeriod = (periodStartAt, combo: ReportGroupValueCombo, valueField: LedgerReportValueField) => {
    const value = findLedgerReportFieldValue(
      allResultsByPeriodStartAt[periodStartAt].find((result) => resultGroupsSameAsCombo(result, combo))?.values ?? [],
      valueField,
    );
    if (value === null || value === undefined) {
      return null;
    }
    return value;
  };

  return (
    <HeightLimitedTableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <HeaderColumnTableCell front>
              <NorrioIcon height={theme.spacing(4)} />
            </HeaderColumnTableCell>
            {new Array(report.groupBy.length).fill(null).map((_, index) => (
              <HeaderColumnTableCell key={`blank-${index}`} front={-1}>
                &nbsp;
              </HeaderColumnTableCell>
            ))}
            {allPeriodStartAts.map((periodStartAt) => (
              <HeaderColumnTableCell key={periodStartAt.toISO()}>
                {formatDateTime(periodStartAt, report.timeResolution)}
              </HeaderColumnTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {allGroupValuesCombos.map((combo, groupIndex) =>
            valueFields.map((valueField) => (
              <AlternatingTableRow key={`${groupIndex}-${valueField}`}>
                {combo.groupValues.map((groupValue, groupValueIndex) => (
                  <HeaderColumnTableCell key={groupValueIndex}>
                    {buildGroupValueDisplay(groupValue.value) || (groupValueIndex == 0 ? 'Total' : '')}
                  </HeaderColumnTableCell>
                ))}
                <HeaderColumnTableCell>
                  <TooltipItem title={determineFieldDescription(valueField)}>
                    <>{determineFieldName(valueField)}</>
                  </TooltipItem>
                </HeaderColumnTableCell>
                {allPeriodStartAts.map((periodStartAt) => (
                  <ReportTableCell
                    key={periodStartAt}
                    propertyType={determineFieldType(valueField)}
                    value={getValueAtPeriod(periodStartAt, combo, valueField)}
                  />
                ))}
              </AlternatingTableRow>
            )),
          )}

          {/* old vertical */}
          {[].map((periodStartAt) => (
            <React.Fragment key={periodStartAt}>
              {valueFields.length > 1 ? (
                <TableRow>
                  <TableCell
                    css={css`
                      color: ${theme.palette.primary.contrastText};
                      background-color: ${theme.palette.primary.main};
                    `}
                    colSpan={allGroupValuesCombos.length + 1}
                  >
                    <strong>{formatDateTime(periodStartAt, report.timeResolution)}</strong>
                  </TableCell>
                </TableRow>
              ) : null}
              {valueFields.map((valueField) => (
                <LedgerReportTableFieldRow
                  key={`${periodStartAt}-${valueField}`}
                  valueField={valueField}
                  fieldOverride={
                    valueFields.length === 1 ? formatDateTime(periodStartAt, report.timeResolution) : undefined
                  }
                  values={getValuesAtPeriodStartAt(periodStartAt, valueField)}
                />
              ))}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </HeightLimitedTableContainer>
  );
};
export default LedgerReportHorizontalDataTable;
