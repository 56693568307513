import { gql } from '@apollo/client';
import NoteBlock from '@paypr/mui5-common-components/dist/components/typography/NoteBlock';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { LedgerEventDeletedNote_LedgerAccountEvent } from '../../generated/graphql';

interface LedgerEventDeletedNoteProps {
  ledgerEvent: LedgerEventDeletedNote_LedgerAccountEvent;
}

const LedgerEventDeletedNote = ({ ledgerEvent: { deletedAt } }: LedgerEventDeletedNoteProps) => {
  const { formatDateTime } = useDateTime();

  if (!deletedAt) {
    return null;
  }

  return (
    <NoteBlock>
      <em>This ledgerEvent was deleted on {formatDateTime(deletedAt)}</em>
    </NoteBlock>
  );
};
export default LedgerEventDeletedNote;

export const ledgerEventDeletedNoteFragment = gql`
  fragment LedgerEventDeletedNote_LedgerAccountEvent on LedgerAccountEvent {
    deletedAt
  }
`;
