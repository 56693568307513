import React from 'react';

export type EventHandler<E extends React.SyntheticEvent<any>, Args extends any[] = []> = (
  event: E,
  ...args: Args
) => void;

export const withStopPropagation = <E extends React.SyntheticEvent<any>, Args extends any[]>(
  handler: EventHandler<E, Args>,
): EventHandler<E, Args> => {
  return (event: E, ...args: Args) => {
    event.stopPropagation();
    handler(event, ...args);
  };
};
