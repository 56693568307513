import { gql, NetworkStatus } from '@apollo/client';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { buildApprovalPath } from '../../components/approvals/approvalPaths';
import AppPage from '../../components/common/page/AppPage';
import AppPageError from '../../components/common/page/AppPageError';
import AppPageMessage from '../../components/common/page/AppPageMessage';
import WiderPageContainer from '../../components/common/page/WiderPageContainer';
import { useQuery } from '../../components/common/routes/paths';
import { WorkflowPageParams, WorkflowPageQueryParams } from '../../components/workflows/workflowPaths';
import WorkflowSection, { workflowSectionFragment } from '../../components/workflows/WorkflowSection';
import { buildWorkflowTitle } from '../../components/workflows/workflowUtils';
import { useWorkflowPageCancelWorkflowMutation, useWorkflowPageQuery, WorkflowStatus } from '../../generated/graphql';
import { useErrors } from '../../hooks/errors';
import { usePageParams, usePageTitle } from '../../hooks/page';
import { usePaths } from '../../hooks/paths';

const autoRefreshAfterSubmit = 1000; // 1 second

const WorkflowPage = () => {
  const { workflowId } = usePageParams<keyof WorkflowPageParams>();
  const { enqueueSnackbar } = useSnackbar();
  const { showError, clearError } = useErrors();
  const { navigateTo } = usePaths();

  const { params, updateParam } = useQuery<WorkflowPageQueryParams>();

  const { data, error, refetch, networkStatus, loading } = useWorkflowPageQuery({
    fetchPolicy: 'cache-and-network',
    variables: { workflowId },
  });

  const [submitCancelWorkflow, { loading: submittingCancelWorkflow }] = useWorkflowPageCancelWorkflowMutation();

  const [submittedCancelWorkflow, setSubmittedCancelWorkflow] = useState(false);

  const workflow = data?.workflow;
  usePageTitle(`${buildWorkflowTitle(workflowId, workflow?.title)}`);

  if (error) {
    return <AppPageError error={error} />;
  }

  if (workflow === null) {
    return <AppPageMessage text={`Workflow ${workflowId} does not exist`} />;
  }

  const handleApprovalClicked = (approvalId: string, newTab: boolean) => {
    navigateTo(buildApprovalPath(approvalId), newTab);
  };

  const handleSubmitCancelWorkflow = async (cancelWorkflowId: string) => {
    clearError();

    if (!confirm(`Are you sure you want to cancel the workflow?`)) {
      return;
    }

    try {
      await submitCancelWorkflow({ variables: { workflowId: cancelWorkflowId } });
      setSubmittedCancelWorkflow(true);

      enqueueSnackbar('Submitted cancellation.', { variant: 'success' });
    } catch (e) {
      showError(e);
    }
  };

  const submittedCancelAndNotCanceled = submittedCancelWorkflow && workflow?.status != WorkflowStatus.Canceled;

  return (
    <AppPage>
      <WiderPageContainer>
        <WorkflowSection
          workflow={workflow}
          onClickApproval={handleApprovalClicked}
          onCancelWorkflow={handleSubmitCancelWorkflow}
          cancelingWorkflow={submittingCancelWorkflow}
          submittedCancelWorkflow={submittedCancelWorkflow}
          loading={loading}
          refreshing={networkStatus === NetworkStatus.refetch}
          onRefresh={() => refetch()}
          autoRefresh={submittedCancelAndNotCanceled ? autoRefreshAfterSubmit : true}
        />
      </WiderPageContainer>
    </AppPage>
  );
};
export default WorkflowPage;

gql`
  query WorkflowPageQuery($workflowId: ID!) {
    workflow(id: $workflowId) {
      id
      title
      status
      ...WorkflowReference_Workflow
      ...WorkflowSection_Workflow
    }
  }

  mutation WorkflowPageCancelWorkflowMutation($workflowId: ID!) {
    cancelWorkflow(workflowId: $workflowId)
  }

  ${workflowSectionFragment}
`;
