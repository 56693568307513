import { css } from '@emotion/react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SubSectionTitle from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import React, { useEffect, useState } from 'react';
import { useDateTime } from '../../data/dates';
import { TradesTableData_TradeConnection, TradesTableRow_Trade } from '../../generated/graphql';
import ExpandIcon from '../common/icons/ExpandIcon';
import ExplodeIconButton, { ExplodingComponentProps } from '../common/icons/ExplodeIconButton';
import NoDataError from '../common/page/NoDataError';
import DownloadCsvButton from '../common/tables/DownloadCsvButton';
import TradeFilterChoices from './TradeFilterChoices';
import { TradeFilters } from './tradePaths';
import TradesTable, { buildTradeCsvData, buildTrades, TradeClickEventHandler } from './TradesTable';

export interface TradesAccordionProps extends ExplodingComponentProps, RefreshingComponentProps {
  tradeConnection?: TradesTableData_TradeConnection;
  trades?: readonly TradesTableRow_Trade[];
  onClickTrade?: TradeClickEventHandler;
  defaultExpanded?: boolean;
  defaultOptionsExpanded?: boolean;
  onOptionsExpandedChanged?: (expanded: boolean) => void;
  defaultFilters?: TradeFilters;
  assets?: string[];
  onChangeFilters?: (filters: TradeFilters) => void;
}

const TradesAccordion = ({
  trades,
  tradeConnection,
  onClickTrade,
  defaultExpanded,
  defaultOptionsExpanded,
  onOptionsExpandedChanged,
  defaultFilters,
  assets,
  onChangeFilters,
  exploded,
  onExplodeChanged,
  loading,
  refreshing,
  onRefresh,
  autoRefresh,
}: TradesAccordionProps) => {
  const [expanded, setExpanded] = useState(exploded ? true : defaultExpanded);
  const { formatShortDateTime } = useDateTime();

  useEffect(() => {
    if (!expanded) {
      return;
    }

    onRefresh?.();
  }, [expanded]);

  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      expanded={expanded}
      onChange={(event, expanded) => setExpanded(expanded)}
    >
      <AccordionSummary expandIcon={!exploded ? <ExpandIcon /> : undefined}>
        <Toolbar
          css={css`
            display: flex;
            flex-grow: 1;
          `}
        >
          <SubSectionTitle>Trades</SubSectionTitle>
          <HorizontalAutoSpacer />
          <RefreshIconButton
            refreshing={refreshing}
            loading={loading}
            onRefresh={expanded ? onRefresh : undefined}
            includeRefreshText
            autoRefresh={autoRefresh}
          />
          {expanded && (
            <DownloadCsvButton
              filenamePrefix="trades"
              dataProvider={
                trades || tradeConnection
                  ? () => buildTradeCsvData(buildTrades({ trades, tradeConnection }), formatShortDateTime)
                  : undefined
              }
            />
          )}
          <ExplodeIconButton exploded={exploded} onExplodeChanged={onExplodeChanged} />
        </Toolbar>
      </AccordionSummary>
      <AccordionDetails>
        {defaultFilters && onChangeFilters && (
          <TradeFilterChoices
            defaultExpanded={defaultOptionsExpanded}
            onOptionsExpandedChanged={onOptionsExpandedChanged}
            defaultFilters={defaultFilters}
            assets={assets}
            onChangeFilters={onChangeFilters}
            loading={loading}
          />
        )}
        {trades || tradeConnection ? (
          <TradesTable
            trades={trades}
            tradeConnection={tradeConnection}
            onClickTradeRow={onClickTrade}
            fullHeight={exploded}
          />
        ) : !(loading || refreshing) ? (
          <NoDataError />
        ) : null}
      </AccordionDetails>
    </Accordion>
  );
};
export default TradesAccordion;
