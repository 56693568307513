import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import * as React from 'react';
import AppPage from '../components/common/page/AppPage';
import WiderPageContainer from '../components/common/page/WiderPageContainer';
import { determineStartAtFromStandardTimeframe } from '../components/reports/ledgerReportHelpers';
import LedgerReportIntegration from '../components/reports/LedgerReportIntegration';
import {
  LedgerReportSettings,
  LedgerReportStandardTimeframe,
  ReportTableOrientation,
  ReportView,
} from '../components/reports/ledgerReportModel';
import { useDateTime } from '../data/dates';
import { LedgerReportTimeResolution, LedgerReportValueField } from '../generated/graphql';
import { usePageTitle } from '../hooks/page';

export const Main = () => {
  usePageTitle();
  const { now, toDateTime } = useDateTime();

  const reportSettings: LedgerReportSettings = {
    definition: {
      standardTimeframe: LedgerReportStandardTimeframe.AllTime,
      startAt: determineStartAtFromStandardTimeframe(LedgerReportStandardTimeframe.AllTime, { now, toDateTime }),
      timeResolution: LedgerReportTimeResolution.Day,
      groupBy: [],
      includeTotals: false,
      values: [
        LedgerReportValueField.PnlCumulativeInvestmentRoi,
        LedgerReportValueField.CryptoMarketPnlCumulativeRoi,
        LedgerReportValueField.EquityMarketPnlCumulativeRoi,
      ],
    },
    view: ReportView.Chart,
    chartSettings: {},
    tableSettings: { orientation: ReportTableOrientation.Horizontal },
  };

  return (
    <AppPage>
      <WiderPageContainer>
        <SectionTitle>Overall Performance</SectionTitle>
        <LedgerReportIntegration reportSettings={reportSettings} defaultAutoRefresh />
      </WiderPageContainer>
    </AppPage>
  );
};
