import { gql } from '@apollo/client';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import TooltipIconButton from '@paypr/mui5-common-components/dist/components/actions/TooltipIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import * as React from 'react';
import { ClientSection_Client } from '../../generated/graphql';
import { ClientFundClickEventHandler } from '../clientFunds/ClientFundsTable';
import { withStopPropagation } from '../common/events/eventUtils';
import EnabledUserIcon from '../common/icons/EnabledUserIcon';
import ClientDetail, { clientDetailFragment } from './ClientDetail';
import ClientIcon from './ClientIcon';
import { buildClientTitle } from './clientUtils';
import { ClientTransactionClickEventHandler } from './transactions/ClientTransactionsTable';
import { TransferNoticeClickEventHandler } from './transfers/TransferNoticesTable';

interface ClientSectionProps extends RefreshingComponentProps {
  client?: ClientSection_Client;
  onEnableClient?: () => Promise<void>;
  onDisableClient?: () => Promise<void>;
  onClickClientFund?: ClientFundClickEventHandler;
  onClickTransferNotice?: TransferNoticeClickEventHandler;
  onClickClientTransaction?: ClientTransactionClickEventHandler;
}

const ClientSection = ({
  client,
  onEnableClient,
  onDisableClient,
  onClickClientFund,
  onClickTransferNotice,
  onClickClientTransaction,
  loading,
  refreshing,
  onRefresh,
  autoRefresh,
}: ClientSectionProps) => (
  <Paper>
    <Toolbar>
      <ClientIcon />
      &nbsp;
      <SectionTitle>{buildClientTitle(client?.fullName)}</SectionTitle>
      <HorizontalAutoSpacer />
      {client?.deleted || !client?.user || client.user.deleted ? (
        <TooltipIconButton title="LP Portal Login is deleted" disabled>
          <EnabledUserIcon color={refreshing || loading ? 'inherit' : 'error'} />
        </TooltipIconButton>
      ) : onEnableClient && client?.user?.disabled ? (
        <TooltipIconButton
          title="LP Portal Login is Disabled. Click to Enable"
          onClick={withStopPropagation(onEnableClient)}
          disabled={refreshing || loading}
        >
          <EnabledUserIcon color={refreshing || loading ? 'inherit' : 'warning'} />
        </TooltipIconButton>
      ) : onDisableClient && client?.user && !client.user.disabled ? (
        <TooltipIconButton
          title="LP Portal Login is Enabled. Click to Disable"
          onClick={withStopPropagation(onDisableClient)}
          disabled={refreshing || loading}
        >
          <EnabledUserIcon color={refreshing || loading ? 'inherit' : 'success'} />
        </TooltipIconButton>
      ) : null}
      <RefreshIconButton
        refreshing={refreshing}
        loading={loading}
        onRefresh={onRefresh}
        autoRefresh={autoRefresh}
        includeRefreshText
      />
    </Toolbar>

    {client ? (
      <ClientDetail
        client={client}
        onClickClientFund={onClickClientFund}
        onClickTransferNotice={onClickTransferNotice}
        onClickClientTransaction={onClickClientTransaction}
      />
    ) : null}
  </Paper>
);
export default ClientSection;

export const clientSectionFragment = gql`
  fragment ClientSection_Client on Client {
    id
    fullName
    deleted

    ...ClientDetail_Client

    user {
      id
      disabled
      deleted
    }
  }
  ${clientDetailFragment}
`;
