import { gql } from '@apollo/client';
import NoteBlock from '@paypr/mui5-common-components/dist/components/typography/NoteBlock';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { ClientDeletedNote_Client } from '../../generated/graphql';

interface ClientDeletedNoteProps {
  client: ClientDeletedNote_Client;
}

const ClientDeletedNote = ({ client: { deletedAt } }: ClientDeletedNoteProps) => {
  const { formatDateTime } = useDateTime();

  if (!deletedAt) {
    return null;
  }

  return (
    <NoteBlock>
      <em>This LP was deleted on {formatDateTime(deletedAt)}</em>
    </NoteBlock>
  );
};
export default ClientDeletedNote;

export const clientDeletedNoteFragment = gql`
  fragment ClientDeletedNote_Client on Client {
    deletedAt
  }
`;
