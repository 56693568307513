import { gql, NetworkStatus } from '@apollo/client';
import { useSnackbar } from 'notistack';
import React from 'react';
import {
  CreateTransferNoticeInput,
  useClientTransferNoticesSectionIntegrationCreateTransferNoticeMutation,
  useClientTransferNoticesSectionIntegrationQueryLazyQuery,
} from '../../generated/graphql';
import { useErrors } from '../../hooks/errors';
import { ExplodingComponentProps } from '../common/icons/ExplodeIconButton';
import { addTransferNoticeDialogFragment } from './transfers/add/AddTransferNoticeDialog';
import TransferNoticesAccordion from './transfers/TransferNoticesAccordion';
import { TransferNoticeClickEventHandler, transferNoticesTableRowFragment } from './transfers/TransferNoticesTable';

export interface ClientTransferNoticesSectionIntegrationProps extends ExplodingComponentProps {
  clientId: string;
  onClickTransferNotice?: TransferNoticeClickEventHandler;
  defaultExpanded?: boolean;
  autoRefresh?: boolean | number;
}

const refreshAfterSubmitDelay = 15 * 1000; // 15 seconds

const ClientTransferNoticesSectionIntegration = ({
  clientId,
  onClickTransferNotice,
  defaultExpanded,
  autoRefresh,
  exploded,
  onExplodeChanged,
}: ClientTransferNoticesSectionIntegrationProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { showError, clearError } = useErrors();

  const [fetchTransferNotices, { data, error, refetch, networkStatus, loading }] =
    useClientTransferNoticesSectionIntegrationQueryLazyQuery({
      fetchPolicy: 'cache-and-network',
      variables: { clientId },
    });

  const [createTransferNotice, { loading: submittingCreateTransferNotice }] =
    useClientTransferNoticesSectionIntegrationCreateTransferNoticeMutation();

  const handleCreateTransferNotice = async (input: CreateTransferNoticeInput) => {
    clearError();

    try {
      await createTransferNotice({
        variables: {
          input,
        },
      });

      setTimeout(async () => {
        await refetch();
      }, refreshAfterSubmitDelay);

      enqueueSnackbar('Submitted Transfer Notice successfully.', { variant: 'success' });
    } catch (e) {
      showError(e);
    }
  };

  const client = data?.client;

  const handleRefresh = async () => {
    if (client) {
      await refetch();
    } else {
      await fetchTransferNotices();
    }
  };

  return (
    <TransferNoticesAccordion
      client={client || undefined}
      transferNotices={client?.transferNotices}
      hideClient
      onClickTransferNotice={onClickTransferNotice}
      onSubmitCreateTransferNotice={handleCreateTransferNotice}
      submittingCreateTransferNotice={submittingCreateTransferNotice}
      defaultExpanded={defaultExpanded}
      exploded={exploded}
      onExplodeChanged={onExplodeChanged}
      refreshing={networkStatus === NetworkStatus.refetch}
      loading={loading}
      onRefresh={handleRefresh}
      autoRefresh={autoRefresh}
      error={error}
    />
  );
};
export default ClientTransferNoticesSectionIntegration;

gql`
  query ClientTransferNoticesSectionIntegrationQuery($clientId: ID!) {
    client(id: $clientId) {
      id

      transferNotices {
        id
        ...TransferNoticesTableRow_TransferNotice
      }

      ...AddTransferNoticeDialog_Client
    }
  }

  mutation ClientTransferNoticesSectionIntegrationCreateTransferNoticeMutation($input: CreateTransferNoticeInput!) {
    createTransferNotice(input: $input)
  }

  ${transferNoticesTableRowFragment}
  ${addTransferNoticeDialogFragment}
`;
