import { useNavigate } from 'react-router-dom';

export interface PathsHook {
  navigateTo: (path: string, newTab?: boolean) => void;
}

export const usePaths = (): PathsHook => {
  const navigate = useNavigate();

  const navigateTo = (path: string, newTab: boolean = false) => {
    if (newTab) {
      window.open(path, '_blank');
      return;
    }

    navigate(path);
  };

  return {
    navigateTo,
  };
};
