export const ordersPath = '/orders';

export interface OrdersPageQueryParams {
  inProgress: string;
  status: string;
}

export const buildOrderPath = (orderId: string) => `${ordersPath}/${orderId}`;

export const parameterizedOrderPath = buildOrderPath(':orderId');
export interface OrderPageParams {
  orderId: string;
}
export interface OrderPageQueryParams {}
