import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import DefaultTextField from '@paypr/mui5-common-components/dist/components/forms/DefaultTextField';
import LoaderButton from '@paypr/mui5-common-components/dist/components/forms/LoaderButton';
import RouteButton from '@paypr/mui5-common-components/dist/components/routes/RouteButton';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import React from 'react';
import { CreateInternalUserInput } from '../../generated/graphql';
import { useFormFields } from '../../hooks/forms';
import { isNotBlank } from '../../utils/strings';
import InternalUserIcon from './InternalUserIcon';
import { internalUsersPath } from './internalUserPaths';

export interface AddInternalUserFormProps {
  onAddInternalUser: (newInternalUser: CreateInternalUserInput) => Promise<void>;
  updating?: boolean;
}

interface AddInternalUserFormFields {
  firstName: string;
  lastName: string;
  emailAddress: string;
}

const AddInternalUserForm = ({ onAddInternalUser, updating }: AddInternalUserFormProps) => {
  const { fields, handleFieldChange } = useFormFields<AddInternalUserFormFields>({
    firstName: '',
    lastName: '',
    emailAddress: '',
  });

  const formValid = isNotBlank(fields.firstName) && isNotBlank(fields.lastName) && isNotBlank(fields.emailAddress);

  const handleAddInternalUser = async () => {
    if (!formValid) {
      return;
    }

    const newInternalUser: CreateInternalUserInput = {
      firstName: fields.firstName,
      lastName: fields.lastName,
      emailAddress: fields.emailAddress,
    };

    await onAddInternalUser(newInternalUser);
  };

  return (
    <Paper>
      <Toolbar>
        <InternalUserIcon />
        &nbsp;
        <SectionTitle>Add Admin User</SectionTitle>
      </Toolbar>
      <DetailContainer>
        <DetailField>
          <DetailTitle>Name</DetailTitle>
          <DefaultTextField
            autoFocus
            name="firstName"
            label="First name"
            autoComplete="off"
            value={fields.firstName}
            required
            onChange={handleFieldChange}
          />
          <DefaultTextField
            name="lastName"
            label="Last name"
            autoComplete="off"
            value={fields.lastName}
            required
            onChange={handleFieldChange}
          />
        </DetailField>
        <DetailField>
          <DetailTitle>Email Address</DetailTitle>
          <DefaultTextField
            name="emailAddress"
            type="emailAddress"
            label="Email"
            autoComplete="off"
            value={fields.emailAddress}
            required
            onChange={handleFieldChange}
          />
        </DetailField>
        <DetailField textAlign="right">
          <Stack direction="row" spacing={2}>
            <RouteButton path={internalUsersPath} disabled={updating} color="inherit" variant="text">
              Back to Admin Users
            </RouteButton>
            <LoaderButton loading={updating} disabled={!formValid} onClick={handleAddInternalUser}>
              Add Admin User
            </LoaderButton>
          </Stack>
        </DetailField>
        <code hidden>{JSON.stringify({ fields })}</code>
      </DetailContainer>
    </Paper>
  );
};
export default AddInternalUserForm;
