import Grid from '@mui/material/Grid';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import DetailValue from '@paypr/mui5-common-components/dist/components/typography/DetailValue';
import React from 'react';
import { AddTransferNoticeDialog_Client, CreateTransferNoticeInput } from '../../../../generated/graphql';
import DetailGridItem from '../../../common/containers/DetailGridItem';
import BooleanFormat from '../../../common/typography/BooleanFormat';
import UsdFormat from '../../../common/typography/UsdFormat';
import { buildFundTitle } from '../../../funds/fundUtils';

export interface AddTransferNoticeCurrencyChangesProps {
  client: AddTransferNoticeDialog_Client;
  input: CreateTransferNoticeInput;
}

const AddTransferNoticeChanges = ({ client, input }: AddTransferNoticeCurrencyChangesProps) => {
  const fund = client.funds.find((fund) => fund.fund.id === input.fundId)?.fund;

  return (
    <DetailContainer>
      <Grid container justifyContent="left" spacing={2}>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Fund</DetailTitle>
            <DetailValue>{buildFundTitle(fund?.name)}</DetailValue>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Originating Account Number</DetailTitle>
            <DetailValue>{input.originatingAccountNumber}</DetailValue>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Amount</DetailTitle>
            <DetailValue>
              <UsdFormat amount={input.amount} />
            </DetailValue>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Mark Processed?</DetailTitle>
            <DetailValue>
              <BooleanFormat value={input.markProcessed} />
            </DetailValue>
          </DetailField>
        </DetailGridItem>
      </Grid>
    </DetailContainer>
  );
};
export default AddTransferNoticeChanges;
