import { css } from '@emotion/react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import TooltipIconButton from '@paypr/mui5-common-components/dist/components/actions/TooltipIconButton';
import ContentLoader from '@paypr/mui5-common-components/dist/components/content/ContentLoader';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import React, { useState } from 'react';
import { DshConfigUpdatesInput, DshStrategyAssetConfigDetails } from '../../../generated/graphql';
import EditIcon from '../../common/icons/EditIcon';
import ExpandIcon from '../../common/icons/ExpandIcon';
import SubSectionOneLineTitle from '../../common/typography/SubSectionOneLineTitle';
import DshStrategyAssetConfig from './DshStrategyAssetConfig';
import UpdateDshStrategyAssetConfigDialog, {
  SubmitDshConfigUpdateEventHandler,
} from './UpdateDshStrategyAssetConfigDialog';

export interface DshStrategyAssetConfigAccordionProps extends RefreshingComponentProps {
  version: string;
  asset: string;
  dshConfig?: DshStrategyAssetConfigDetails | null;
  active?: boolean;
  onSubmitDshConfigUpdate?: SubmitDshConfigUpdateEventHandler;
  submittingDshConfigUpdate?: boolean;
}

const DshStrategyAssetConfigAccordion = ({
  version,
  asset,
  dshConfig,
  active,
  onSubmitDshConfigUpdate,
  submittingDshConfigUpdate,
  loading,
  refreshing,
  onRefresh,
  autoRefresh,
}: DshStrategyAssetConfigAccordionProps) => {
  const startExpanded = false;
  const [expanded, setExpanded] = useState<boolean>(startExpanded);

  const [updateDshConfigDialogOpen, setUpdateDshConfigDialogOpen] = useState(false);

  const openUpdateDshConfigDialog = () => {
    setUpdateDshConfigDialogOpen(true);
  };

  const closeUpdateDshConfigDialog = () => {
    setUpdateDshConfigDialogOpen(false);
  };

  const handleEditDshConfig = async (event) => {
    event.stopPropagation();

    openUpdateDshConfigDialog();
  };

  const handleSubmitDshConfigUpdate = async (newDshConfig: DshConfigUpdatesInput) => {
    if (!onSubmitDshConfigUpdate) {
      return;
    }

    await onSubmitDshConfigUpdate(newDshConfig);
    closeUpdateDshConfigDialog();
  };

  if (!active) {
    return (
      <Accordion>
        <AccordionSummary disabled={active === false}>
          <Toolbar
            css={css`
              display: flex;
              flex-grow: 1;
            `}
          >
            <SubSectionOneLineTitle>
              {asset} Execution Config {active === false ? '(inactive)' : ''}
            </SubSectionOneLineTitle>
          </Toolbar>
        </AccordionSummary>
      </Accordion>
    );
  }

  return (
    <Accordion defaultExpanded={startExpanded} onChange={(event, expanded) => setExpanded(expanded)}>
      <AccordionSummary expandIcon={<ExpandIcon />}>
        <Toolbar
          css={css`
            display: flex;
            flex-grow: 1;
          `}
        >
          <SubSectionOneLineTitle>{asset} Execution Config</SubSectionOneLineTitle>
          <HorizontalAutoSpacer />
          {onSubmitDshConfigUpdate && expanded && (
            <TooltipIconButton
              title={`Edit DSH ${asset} Execution Config`}
              onClick={handleEditDshConfig}
              disabled={loading}
            >
              <EditIcon />
            </TooltipIconButton>
          )}
          <RefreshIconButton
            refreshing={refreshing}
            loading={loading}
            onRefresh={expanded ? onRefresh : undefined}
            includeRefreshText
            autoRefresh={autoRefresh}
          />
        </Toolbar>
      </AccordionSummary>
      <AccordionDetails>
        {dshConfig ? (
          <AccordionDetails>
            <DshStrategyAssetConfig dshConfig={dshConfig} />
            {onSubmitDshConfigUpdate && (
              <UpdateDshStrategyAssetConfigDialog
                open={submittingDshConfigUpdate || updateDshConfigDialogOpen}
                version={version}
                asset={asset}
                dshConfig={dshConfig}
                onSubmitDshConfigUpdate={handleSubmitDshConfigUpdate}
                submitting={submittingDshConfigUpdate}
                onClose={closeUpdateDshConfigDialog}
              />
            )}
          </AccordionDetails>
        ) : (
          <AccordionDetails>
            <ContentLoader />
          </AccordionDetails>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
export default DshStrategyAssetConfigAccordion;
