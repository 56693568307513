export const reportsPath = '/reports';

export const latestReportId = 'latest';

export const buildFavoriteReportPath = (reportId: string) => `${reportsPath}/${reportId}`;

export const parameterizedFavoriteReportPath = buildFavoriteReportPath(':reportId');

export interface FavoriteReportPageParams {
  reportId: string;
}
