import { gql, NetworkStatus } from '@apollo/client';
import React from 'react';
import { ApprovalPageParams, ApprovalPageQueryParams } from '../../components/approvals/approvalPaths';
import ApprovalSection, { approvalSectionFragment } from '../../components/approvals/ApprovalSection';
import { buildApprovalTitle } from '../../components/approvals/approvalUtils';
import AppPage from '../../components/common/page/AppPage';
import AppPageError from '../../components/common/page/AppPageError';
import AppPageMessage from '../../components/common/page/AppPageMessage';
import WiderPageContainer from '../../components/common/page/WiderPageContainer';
import { useQuery } from '../../components/common/routes/paths';
import { workflowReferenceFragment } from '../../components/workflows/workflowUtils';
import { useApprovalPageQuery } from '../../generated/graphql';
import { usePageParams, usePageTitle } from '../../hooks/page';

const ApprovalPage = () => {
  const { approvalId } = usePageParams<keyof ApprovalPageParams>();

  const { params, updateParam } = useQuery<ApprovalPageQueryParams>();

  const { data, error, refetch, networkStatus, loading } = useApprovalPageQuery({
    fetchPolicy: 'cache-and-network',
    variables: { approvalId },
  });

  usePageTitle(`${buildApprovalTitle(approvalId, data?.approval?.workflow)}`);

  if (error) {
    return <AppPageError error={error} />;
  }

  const approval = data?.approval;

  if (approval === null) {
    return <AppPageMessage text={`Approval ${approvalId} does not exist`} />;
  }

  return (
    <AppPage>
      <WiderPageContainer>
        <ApprovalSection
          approval={approval}
          loading={loading}
          refreshing={networkStatus === NetworkStatus.refetch}
          onRefresh={() => refetch()}
          autoRefresh
        />
      </WiderPageContainer>
    </AppPage>
  );
};
export default ApprovalPage;

gql`
  query ApprovalPageQuery($approvalId: ID!) {
    approval(id: $approvalId) {
      id
      workflow {
        id
        ...WorkflowReference_Workflow
      }
      ...ApprovalSection_Approval
    }
  }

  ${approvalSectionFragment}
  ${workflowReferenceFragment}
`;
