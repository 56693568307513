import { gql } from '@apollo/client';
import PageContainer from '@paypr/mui5-common-components/dist/components/page/PageContainer';
import { useSnackbar } from 'notistack';
import React from 'react';
import AppPage from '../../components/common/page/AppPage';
import CreateSubsystemSection from '../../components/subsystems/CreateSubsystemSection';
import { buildSubsystemTitle } from '../../components/subsystems/SubsystemDetail';
import { buildSubsystemPath, subsystemsPath } from '../../components/subsystems/subsystemPaths';
import { CreateSubsystemInput, useAddSubsystemPageCreateSubsystemMutation } from '../../generated/graphql';
import { useErrors } from '../../hooks/errors';
import { usePageTitle } from '../../hooks/page';
import { usePaths } from '../../hooks/paths';

const AddSubsystemPage = () => {
  const { navigateTo } = usePaths();

  usePageTitle('Add Subsystem');

  const [createSubsystem, { loading: creating }] = useAddSubsystemPageCreateSubsystemMutation({});
  const { enqueueSnackbar } = useSnackbar();

  const { showError, clearError } = useErrors();

  const handleCreateSubsystem = async (input: CreateSubsystemInput) => {
    clearError();
    try {
      if (!confirm(`Are you sure you want to create Subsystem '${input.label || input.name}'?`)) {
        return;
      }

      const { data } = await createSubsystem({ variables: { input } });

      if (!data) {
        return;
      }

      enqueueSnackbar(`Subsystem ${buildSubsystemTitle(data?.createSubsystem?.label)} created`, {
        variant: 'success',
      });
      navigateTo(buildSubsystemPath(data?.createSubsystem?.id));
    } catch (e) {
      showError(e);
    }
  };

  const handleCancel = () => {
    navigateTo(subsystemsPath);
  };

  return (
    <AppPage>
      <PageContainer>
        <CreateSubsystemSection onCreateSubsystem={handleCreateSubsystem} onCancel={handleCancel} creating={creating} />
      </PageContainer>
    </AppPage>
  );
};
export default AddSubsystemPage;

gql`
  mutation AddSubsystemPageCreateSubsystemMutation($input: CreateSubsystemInput!) {
    createSubsystem(input: $input) {
      id
      label
    }
  }
`;
