import { gql } from '@apollo/client';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import * as React from 'react';
import { FundSection_Fund } from '../../generated/graphql';
import { ClientFundClickEventHandler } from '../clientFunds/ClientFundsTable';
import { ClientTransactionClickEventHandler } from '../clients/transactions/ClientTransactionsTable';
import { TransferNoticeClickEventHandler } from '../clients/transfers/TransferNoticesTable';
import { PortfolioClickEventHandler } from '../portfolios/PortfoliosTable';
import FundDetail, { fundDetailFragment } from './FundDetail';
import FundIcon from './FundIcon';
import { buildFundTitle } from './fundUtils';

interface FundSectionProps extends RefreshingComponentProps {
  fund?: FundSection_Fund;
  onClickPortfolio?: PortfolioClickEventHandler;
  onClickClientFund?: ClientFundClickEventHandler;
  onClickClientTransaction?: ClientTransactionClickEventHandler;
  onClickTransferNotice?: TransferNoticeClickEventHandler;
}

const FundSection = ({
  fund,
  onClickPortfolio,
  onClickClientFund,
  onClickClientTransaction,
  onClickTransferNotice,
  loading,
  refreshing,
  onRefresh,
  autoRefresh,
}: FundSectionProps) => (
  <Paper>
    <Toolbar>
      <FundIcon />
      &nbsp;
      <SectionTitle>{buildFundTitle(fund?.name)}</SectionTitle>
      <HorizontalAutoSpacer />
      <RefreshIconButton
        refreshing={refreshing}
        loading={loading}
        onRefresh={onRefresh}
        autoRefresh={autoRefresh}
        includeRefreshText
      />
    </Toolbar>

    {fund ? (
      <FundDetail
        fund={fund}
        onClickPortfolio={onClickPortfolio}
        onClickClientFund={onClickClientFund}
        onClickClientTransaction={onClickClientTransaction}
        onClickTransferNotice={onClickTransferNotice}
      />
    ) : null}
  </Paper>
);
export default FundSection;

export const fundSectionFragment = gql`
  fragment FundSection_Fund on Fund {
    id
    name
    ...FundDetail_Fund
  }
  ${fundDetailFragment}
`;
