import { gql } from '@apollo/client';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import TooltipIconButton from '@paypr/mui5-common-components/dist/components/actions/TooltipIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import * as React from 'react';
import { useState } from 'react';
import { ClientFundSection_ClientFund, UpdateClientFundInput } from '../../generated/graphql';
import EditIcon from '../common/icons/EditIcon';
import FundIcon from '../funds/FundIcon';
import ClientFundDetail, { clientFundDetailFragment } from './ClientFundDetail';
import { buildClientFundTitle } from './clientFundUtils';
import UpdateClientFundDialog, {
  SubmitClientFundUpdateEventHandler,
  updateClientFundDialogFragment,
} from './update/UpdateClientFundDialog';

interface ClientFundSectionProps extends RefreshingComponentProps {
  clientFund?: ClientFundSection_ClientFund;
  onSubmitUpdateClientFund?: SubmitClientFundUpdateEventHandler;
  submittingClientFundUpdate?: boolean;
}

const ClientFundSection = ({
  clientFund,
  onSubmitUpdateClientFund,
  submittingClientFundUpdate,
  loading,
  refreshing,
  onRefresh,
  autoRefresh,
}: ClientFundSectionProps) => {
  const [updateClientFundDialogOpen, setUpdateClientFundDialogOpen] = useState(false);

  const openUpdateClientFundDialog = () => {
    setUpdateClientFundDialogOpen(true);
  };

  const closeUpdateClientFundDialog = () => {
    setUpdateClientFundDialogOpen(false);
  };

  const handleEditClientFund = async (event) => {
    event.stopPropagation();

    openUpdateClientFundDialog();
  };

  const handleSubmitClientFundUpdate = async (input: UpdateClientFundInput) => {
    if (!onSubmitUpdateClientFund) {
      return;
    }

    await onSubmitUpdateClientFund(input);
    closeUpdateClientFundDialog();
  };

  return (
    <>
      <Paper>
        <Toolbar>
          <FundIcon />
          &nbsp;
          <SectionTitle>{buildClientFundTitle(clientFund?.client.fullName, clientFund?.fund.name)}</SectionTitle>
          <HorizontalAutoSpacer />
          {onSubmitUpdateClientFund && (
            <TooltipIconButton title={`Edit LP Fund`} onClick={handleEditClientFund} disabled={loading}>
              <EditIcon />
            </TooltipIconButton>
          )}
          <RefreshIconButton
            refreshing={refreshing}
            loading={loading}
            onRefresh={onRefresh}
            autoRefresh={autoRefresh}
            includeRefreshText
          />
        </Toolbar>

        {clientFund ? <ClientFundDetail clientFund={clientFund} /> : null}
      </Paper>
      {onSubmitUpdateClientFund && clientFund && (
        <UpdateClientFundDialog
          open={updateClientFundDialogOpen}
          clientFund={clientFund}
          onSubmitClientFundUpdate={handleSubmitClientFundUpdate}
          submitting={submittingClientFundUpdate}
          onClose={closeUpdateClientFundDialog}
        />
      )}
    </>
  );
};
export default ClientFundSection;

export const clientFundSectionFragment = gql`
  fragment ClientFundSection_ClientFund on ClientFund {
    id
    client {
      id
      fullName
    }
    fund {
      id
      name
    }

    ...ClientFundDetail_ClientFund
    ...UpdateClientFundDialog_ClientFund
  }

  ${clientFundDetailFragment}
  ${updateClientFundDialogFragment}
`;
