import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailInlineTitle from '@paypr/mui5-common-components/dist/components/typography/DetailInlineTitle';
import DetailInlineValue from '@paypr/mui5-common-components/dist/components/typography/DetailInlineValue';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import SubSectionTitle from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import React from 'react';
import {
  UpdateDshStrategyAllocations_AssetWorkflowDetails,
  UpdateDshStrategyAllocations_WorkflowDetails,
} from '../../../generated/graphql';
import { useThemeHelpers } from '../../../style/theme';
import DetailGridItem from '../../common/containers/DetailGridItem';
import IntegerFormat from '../../common/typography/IntegerFormat';
import PercentFormat from '../../common/typography/PercentFormat';
import UsdFormat from '../../common/typography/UsdFormat';

export interface UpdateDshStrategyAllocationsProps {
  allocations: UpdateDshStrategyAllocations_WorkflowDetails;
}

const UpdateDshStrategyAllocationsChanges = ({ allocations }: UpdateDshStrategyAllocationsProps) => {
  const { buildChangeCss } = useThemeHelpers();

  return (
    <DetailContainer>
      <Grid container justifyContent="left" spacing={2}>
        <DetailGridItem size="full">
          <DetailField>
            <DetailTitle>Total Amount</DetailTitle>
            <Box>
              <DetailInlineTitle>Old</DetailInlineTitle>
              <DetailInlineValue>
                <UsdFormat amount={allocations.oldTotalAmount} />
              </DetailInlineValue>
            </Box>
            <Box>
              <DetailInlineTitle>New</DetailInlineTitle>
              <DetailInlineValue
                css={buildChangeCss(allocations.oldTotalAmount, allocations.estimatedTotalAmount, 0.01)}
              >
                <UsdFormat amount={allocations.estimatedTotalAmount} />
              </DetailInlineValue>
            </Box>
            <Box>
              <DetailInlineTitle>Desired</DetailInlineTitle>
              <DetailInlineValue css={buildChangeCss(allocations.oldTotalAmount, allocations.desiredTotalAmount, 0.01)}>
                <UsdFormat amount={allocations.desiredTotalAmount} />
              </DetailInlineValue>
            </Box>
            <Box>
              <DetailInlineTitle>Variance</DetailInlineTitle>
              <DetailInlineValue>
                <UsdFormat amount={allocations.varianceAmount} /> (
                <PercentFormat amount={allocations.variancePercent} />)
              </DetailInlineValue>
            </Box>
          </DetailField>
        </DetailGridItem>
        {allocations.assets.map((asset) => (
          <AssetAllocationChanges key={asset.asset} asset={asset} />
        ))}
      </Grid>
    </DetailContainer>
  );
};
export default UpdateDshStrategyAllocationsChanges;

interface AssetAllocationChangesProps {
  asset: UpdateDshStrategyAllocations_AssetWorkflowDetails;
}

const AssetAllocationChanges = ({ asset }: AssetAllocationChangesProps) => {
  const { buildChangeCss } = useThemeHelpers();

  return (
    <DetailGridItem>
      <SubSectionTitle>{asset.asset}</SubSectionTitle>
      <DetailGridItem>
        <DetailField>
          <DetailTitle>Asset Percent</DetailTitle>
          <Box>
            <DetailInlineTitle>Old</DetailInlineTitle>
            <DetailInlineValue>
              <PercentFormat amount={asset.oldPercent} />
            </DetailInlineValue>
          </Box>
          <Box>
            <DetailInlineTitle>New</DetailInlineTitle>
            <DetailInlineValue css={buildChangeCss(asset.oldPercent, asset.estimatedPercent, 0.01)}>
              <PercentFormat amount={asset.estimatedPercent} />
            </DetailInlineValue>
          </Box>
          <Box>
            <DetailInlineTitle>Desired</DetailInlineTitle>
            <DetailInlineValue css={buildChangeCss(asset.oldPercent, asset.desiredPercent, 0.01)}>
              <PercentFormat amount={asset.desiredPercent} />
            </DetailInlineValue>
          </Box>
        </DetailField>
        <DetailField>
          <DetailTitle>Max Slots</DetailTitle>
          <Box>
            <DetailInlineTitle>Old</DetailInlineTitle>
            <DetailInlineValue>
              <IntegerFormat amount={asset.oldMaxSlots} />
            </DetailInlineValue>
          </Box>
          <Box>
            <DetailInlineTitle>New</DetailInlineTitle>
            <DetailInlineValue css={buildChangeCss(asset.oldMaxSlots, asset.desiredMaxSlots, 0.01)}>
              <IntegerFormat amount={asset.desiredMaxSlots} />
            </DetailInlineValue>
          </Box>
        </DetailField>
        <DetailField>
          <DetailTitle>Slot Investment Size</DetailTitle>
          <Box>
            <DetailInlineTitle>Old</DetailInlineTitle>
            <DetailInlineValue>
              <UsdFormat amount={asset.oldSlotInvestmentSize} />
            </DetailInlineValue>
          </Box>
          <Box>
            <DetailInlineTitle>New</DetailInlineTitle>
            <DetailInlineValue css={buildChangeCss(asset.oldSlotInvestmentSize, asset.desiredSlotInvestmentSize, 0.01)}>
              <UsdFormat amount={asset.desiredSlotInvestmentSize} />
            </DetailInlineValue>
          </Box>
          <Box>
            <DetailInlineTitle>Ideal</DetailInlineTitle>
            <DetailInlineValue>
              <UsdFormat amount={asset.idealSlotInvestmentSize} />
            </DetailInlineValue>
          </Box>
        </DetailField>
        <DetailField>
          <DetailTitle>Allocation Amount</DetailTitle>
          <Box>
            <DetailInlineTitle>Old</DetailInlineTitle>
            <DetailInlineValue>
              <UsdFormat amount={asset.oldAmount} />
            </DetailInlineValue>
          </Box>
          <Box>
            <DetailInlineTitle>New</DetailInlineTitle>
            <DetailInlineValue css={buildChangeCss(asset.oldAmount, asset.estimatedAmount, 0.01)}>
              <UsdFormat amount={asset.estimatedAmount} />
            </DetailInlineValue>
          </Box>
          <Box>
            <DetailInlineTitle>Ideal</DetailInlineTitle>
            <DetailInlineValue>
              <UsdFormat amount={asset.idealAmount} />
            </DetailInlineValue>
          </Box>
          <Box>
            <DetailInlineTitle>Variance</DetailInlineTitle>
            <DetailInlineValue>
              <UsdFormat amount={asset.varianceAmount} /> (
              <PercentFormat amount={asset.variancePercent} />)
            </DetailInlineValue>
          </Box>
        </DetailField>
      </DetailGridItem>
    </DetailGridItem>
  );
};
