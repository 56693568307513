import { gql } from '@apollo/client';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import DefaultTextField from '@paypr/mui5-common-components/dist/components/forms/DefaultTextField';
import LoaderButton from '@paypr/mui5-common-components/dist/components/forms/LoaderButton';
import RouteButton from '@paypr/mui5-common-components/dist/components/routes/RouteButton';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailError from '@paypr/mui5-common-components/dist/components/typography/DetailError';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import React, { useEffect, useState } from 'react';
import { AddClientForm_FundDetails, ClientEntityType, CreateClientInput } from '../../generated/graphql';
import { useFormFields } from '../../hooks/forms';
import { isNotBlank, isTextPositiveFloatOrZero, isTextValidEmail, trimToNull } from '../../utils/strings';
import { buildFundTitle } from '../funds/fundUtils';
import ClientIcon from './ClientIcon';
import { lpsPath } from './clientPaths';

export interface AddClientFormProps {
  onAddClient: (clientInput: CreateClientInput) => Promise<void>;
  funds?: readonly AddClientForm_FundDetails[];

  loading?: boolean;
}

interface AddClientFormFields {
  entityType: ClientEntityType;
  entityName: string;
  firstName: string;
  lastName: string;
  managementFeePercent: string;
  useDefaultManagementFeePercent: string;
  performanceFeePercent: string;
  useDefaultPerformanceFeePercent: string;
  emailAddress: string;
  phoneNumber: string;
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}

const AddClientForm = ({ onAddClient, funds, loading }: AddClientFormProps) => {
  const { fields, handleFieldChange } = useFormFields<AddClientFormFields>({
    entityType: ClientEntityType.Individual,
    entityName: '',
    firstName: '',
    lastName: '',
    managementFeePercent: '2',
    useDefaultManagementFeePercent: 'true',
    performanceFeePercent: '20',
    useDefaultPerformanceFeePercent: 'true',
    emailAddress: '',
    phoneNumber: '',
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
  });

  const [fundIds, setFundIds] = useState<string[]>([]);

  useEffect(() => {
    if (!funds || funds.length > 1) {
      return;
    }

    if (funds.length === 0) {
      setFundIds([]);
      return;
    }

    setFundIds(funds.map((fund) => fund.id));
  }, [funds]);

  const formValid =
    (fields.entityType === ClientEntityType.Individual || isNotBlank(fields.entityName)) &&
    isNotBlank(fields.firstName) &&
    isNotBlank(fields.lastName) &&
    (!funds || funds.length === 0 || fundIds.length > 0) &&
    (fields.useDefaultManagementFeePercent === 'true' || isTextPositiveFloatOrZero(fields.managementFeePercent)) &&
    (fields.useDefaultPerformanceFeePercent === 'true' || isTextPositiveFloatOrZero(fields.performanceFeePercent)) &&
    isTextValidEmail(fields.emailAddress) &&
    isNotBlank(fields.phoneNumber) &&
    isNotBlank(fields.streetAddress1) &&
    isNotBlank(fields.city) &&
    isNotBlank(fields.postalCode) &&
    isNotBlank(fields.country);

  const handleUpdateFundIds = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const { name } = event.target;

    if (checked && !fundIds.includes(name)) {
      setFundIds([...fundIds, name]);
      return;
    }

    if (!checked && fundIds.includes(name)) {
      setFundIds(fundIds.filter((fundId) => fundId !== name));
    }
  };

  const handleAddClient = async () => {
    if (!formValid) {
      return;
    }

    const {
      entityType,
      entityName,
      firstName,
      lastName,
      managementFeePercent,
      useDefaultManagementFeePercent,
      performanceFeePercent,
      useDefaultPerformanceFeePercent,
      emailAddress,
      phoneNumber,
      streetAddress1,
      streetAddress2,
      city,
      state,
      postalCode,
      country,
    } = fields;

    await onAddClient({
      entityType,
      entityName: entityType === ClientEntityType.Individual ? null : entityName,
      fundIds,
      managementFeePercent: useDefaultManagementFeePercent === 'true' ? null : parseFloat(managementFeePercent) / 100,
      performanceFeePercent:
        useDefaultPerformanceFeePercent === 'true' ? null : parseFloat(performanceFeePercent) / 100,
      primaryContact: {
        firstName,
        lastName,
        emailAddress,
        phoneNumber,
        streetAddress1,
        streetAddress2: trimToNull(streetAddress2),
        city,
        state: trimToNull(state),
        postalCode,
        country,
      },
    });
  };

  return (
    <Paper>
      <Toolbar>
        <ClientIcon />
        &nbsp;
        <SectionTitle>Add LP</SectionTitle>
      </Toolbar>
      <DetailContainer>
        <DetailField>
          <DetailTitle>Entity Type</DetailTitle>
          <RadioGroup name="entityType" row onChange={handleFieldChange} value={fields.entityType}>
            <FormControlLabel
              radioGroup="entityType"
              value={ClientEntityType.Individual}
              control={<Radio />}
              label="Individual"
            />
            <FormControlLabel
              radioGroup="entityType"
              value={ClientEntityType.Company}
              control={<Radio />}
              label="LLC, LP, Trust, etc."
            />
          </RadioGroup>
        </DetailField>
        {fields.entityType === ClientEntityType.Company && (
          <DetailField>
            <DetailTitle>Entity Name</DetailTitle>
            <DefaultTextField
              name="entityName"
              label="Entity Name"
              autoComplete="off"
              value={fields.entityName}
              required
              onChange={handleFieldChange}
            />
          </DetailField>
        )}
        <DetailField>
          <DetailTitle>{fields.entityType === ClientEntityType.Company && 'Contact '}Name</DetailTitle>
          <DefaultTextField
            autoFocus
            name="firstName"
            label="First name"
            autoComplete="off"
            value={fields.firstName}
            required
            onChange={handleFieldChange}
          />
          <DefaultTextField
            name="lastName"
            label="Last name"
            autoComplete="off"
            value={fields.lastName}
            required
            onChange={handleFieldChange}
          />
        </DetailField>
        {funds && funds.length > 1 && (
          <DetailField>
            <DetailTitle>Funds</DetailTitle>
            <FormGroup>
              {funds.map((fund) => (
                <React.Fragment key={fund.id}>
                  <FormControlLabel
                    label={buildFundTitle(fund.name)}
                    control={
                      <Checkbox
                        name={fund.id}
                        checked={fundIds.includes(fund.id)}
                        onChange={handleUpdateFundIds}
                        disabled={loading}
                      />
                    }
                  />
                </React.Fragment>
              ))}
            </FormGroup>
          </DetailField>
        )}
        <DetailField>
          <DetailTitle>Fees</DetailTitle>
          <RadioGroup
            name="useDefaultManagementFeePercent"
            row
            onChange={handleFieldChange}
            value={fields.useDefaultManagementFeePercent}
          >
            <FormControlLabel
              radioGroup="useDefaultManagementFeePercent"
              value="true"
              control={<Radio />}
              label="Use Default Management Fees"
              disabled={loading}
            />
            <FormControlLabel
              radioGroup="useDefaultManagementFeePercent"
              value="false"
              control={<Radio />}
              label="Set Management Fee"
              disabled={loading}
            />
          </RadioGroup>
          {fields.useDefaultManagementFeePercent !== 'true' && (
            <DefaultTextField
              name="managementFeePercent"
              label="Management Fee %"
              type="number"
              autoComplete="off"
              value={fields.managementFeePercent}
              required
              onChange={handleFieldChange}
            />
          )}
          <RadioGroup
            name="useDefaultPerformanceFeePercent"
            row
            onChange={handleFieldChange}
            value={fields.useDefaultPerformanceFeePercent}
          >
            <FormControlLabel
              radioGroup="useDefaultPerformanceFeePercent"
              value="true"
              control={<Radio />}
              label="Use Default Performance Fees"
              disabled={loading}
            />
            <FormControlLabel
              radioGroup="useDefaultPerformanceFeePercent"
              value="false"
              control={<Radio />}
              label="Set Performance Fee"
              disabled={loading}
            />
          </RadioGroup>
          {fields.useDefaultPerformanceFeePercent !== 'true' && (
            <DefaultTextField
              name="performanceFeePercent"
              label="Performance Fee %"
              type="number"
              autoComplete="off"
              value={fields.performanceFeePercent}
              required
              onChange={handleFieldChange}
            />
          )}
        </DetailField>
        <DetailField>
          <DetailTitle>Email Address</DetailTitle>
          <DefaultTextField
            name="emailAddress"
            type="emailAddress"
            label="Email"
            autoComplete="off"
            value={fields.emailAddress}
            required
            onChange={handleFieldChange}
          />
          {isNotBlank(fields.emailAddress) && !isTextValidEmail(fields.emailAddress) && (
            <DetailError>Please enter a valid email address</DetailError>
          )}
        </DetailField>
        <DetailField>
          <DetailTitle>Phone Number</DetailTitle>
          <DefaultTextField
            name="phoneNumber"
            label="Phone Number"
            autoComplete="off"
            value={fields.phoneNumber}
            required
            onChange={handleFieldChange}
          />
        </DetailField>
        <DetailField>
          <DetailTitle>Address</DetailTitle>
          <DefaultTextField
            name="streetAddress1"
            label="Street Address 1"
            autoComplete="off"
            value={fields.streetAddress1}
            required
            onChange={handleFieldChange}
          />
          <DefaultTextField
            name="streetAddress2"
            label="Street Address 2"
            autoComplete="off"
            value={fields.streetAddress2}
            onChange={handleFieldChange}
          />
          <DefaultTextField
            name="city"
            label="City"
            autoComplete="off"
            value={fields.city}
            required
            onChange={handleFieldChange}
          />
          <DefaultTextField
            name="state"
            label="State / Province"
            autoComplete="off"
            value={fields.state}
            onChange={handleFieldChange}
          />
          <DefaultTextField
            name="postalCode"
            label="Postal Code"
            autoComplete="off"
            value={fields.postalCode}
            required
            onChange={handleFieldChange}
          />
          <DefaultTextField
            name="country"
            label="Country"
            autoComplete="off"
            value={fields.country}
            required
            onChange={handleFieldChange}
          />
        </DetailField>
        <DetailField textAlign="right">
          <Stack direction="row" spacing={2}>
            <RouteButton path={lpsPath} disabled={loading} color="inherit" variant="text">
              Back to LPs
            </RouteButton>
            <LoaderButton loading={loading} disabled={!formValid} onClick={handleAddClient}>
              Add LP
            </LoaderButton>
          </Stack>
        </DetailField>
        <code hidden>{JSON.stringify({ fields, fundIds })}</code>
      </DetailContainer>
    </Paper>
  );
};
export default AddClientForm;

export const addClientFormFundDetailsFragment = gql`
  fragment AddClientForm_FundDetails on Fund {
    id
    name
  }
`;
