import { gql } from '@apollo/client';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import * as React from 'react';
import { TransferNoticeSection_TransferNotice } from '../../../generated/graphql';
import TransferNoticeDetail, { buildTransferNoticeTitle, transferNoticeDetailFragment } from './TransferNoticeDetail';
import TransferNoticeIcon from './TransferNoticeIcon';

interface TransferNoticeSectionProps extends RefreshingComponentProps {
  transferNotice?: TransferNoticeSection_TransferNotice;
  onProcessTransferNotice?: (transferNoticeId: string) => void;
  processing?: boolean;
  submittedProcessing?: boolean;
}

const TransferNoticeSection = ({
  transferNotice,
  onProcessTransferNotice,
  processing,
  submittedProcessing,
  loading,
  refreshing,
  onRefresh,
  autoRefresh,
}: TransferNoticeSectionProps) => (
  <Paper>
    <Toolbar>
      <TransferNoticeIcon />
      &nbsp;
      <SectionTitle>{buildTransferNoticeTitle(transferNotice?.client?.fullName)}</SectionTitle>
      <HorizontalAutoSpacer />
      <RefreshIconButton
        refreshing={refreshing}
        loading={loading}
        onRefresh={onRefresh}
        autoRefresh={autoRefresh}
        includeRefreshText
      />
    </Toolbar>

    {transferNotice ? (
      <TransferNoticeDetail
        transferNotice={transferNotice}
        onProcessTransferNotice={onProcessTransferNotice}
        processing={processing}
        submittedProcessing={submittedProcessing}
      />
    ) : null}
  </Paper>
);
export default TransferNoticeSection;

export const transferNoticeSectionFragment = gql`
  fragment TransferNoticeSection_TransferNotice on TransferNotice {
    id
    client {
      id
      fullName
    }
    ...TransferNoticeDetail_TransferNotice
  }
  ${transferNoticeDetailFragment}
`;
