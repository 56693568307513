import { css } from '@emotion/react';
import SubSectionTitle, {
  SubSectionTitleProps,
} from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import React from 'react';

export interface SubSectionOneLineTitleProps extends SubSectionTitleProps {}

const SubSectionOneLineTitle = ({ ...props }: SubSectionOneLineTitleProps) => (
  <SubSectionTitle
    {...props}
    css={css`
      white-space: nowrap;
    `}
  />
);
export default SubSectionOneLineTitle;
