import { gql } from '@apollo/client';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import TooltipIconButton from '@paypr/mui5-common-components/dist/components/actions/TooltipIconButton';
import DefaultTextField from '@paypr/mui5-common-components/dist/components/forms/DefaultTextField';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailInlineValue from '@paypr/mui5-common-components/dist/components/typography/DetailInlineValue';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import SubSectionTitle from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import React, { useEffect } from 'react';
import { ClientFilterChoices_Fund } from '../../generated/graphql';
import { useFormFields } from '../../hooks/forms';
import ExpandIcon from '../common/icons/ExpandIcon';
import RemoveIcon from '../common/icons/RemoveIcon';
import { AutocompleteOption } from '../common/model/autocomplete';
import { ClientFilters } from './clientPaths';

export interface ClientFilterChoicesProps {
  defaultFilters: ClientFilters;
  defaultExpanded?: boolean;
  onOptionsExpandedChanged?: (expanded: boolean) => void;
  funds?: readonly ClientFilterChoices_Fund[];
  loading?: boolean;
  onChangeFilters: (filters: ClientFilters) => void;
  showDeletedOption?: boolean;
}

interface ClientFilterFields {
  addFundFilterText: string;
  includeDeleted: string;
}

const ClientFilterChoices = ({
  defaultFilters,
  defaultExpanded,
  onOptionsExpandedChanged,
  funds,
  loading,
  onChangeFilters,
  showDeletedOption,
}: ClientFilterChoicesProps) => {
  const [selectedFundIds, setSelectedFundIds] = React.useState<string[] | null>(defaultFilters.fundIds);

  const { fields, setFieldValue } = useFormFields<ClientFilterFields>({
    addFundFilterText: '',
    includeDeleted: defaultFilters.includeDeleted !== null ? defaultFilters.includeDeleted.toString() : '',
  });

  useEffect(() => {
    onChangeFilters({
      fundIds: selectedFundIds,
      includeDeleted: fields.includeDeleted ? fields.includeDeleted === 'true' : null,
    });
  }, [selectedFundIds, fields]);

  // funds
  const addFundFilterOptions: AutocompleteOption[] = (funds || [])
    .filter((fund) => !selectedFundIds?.includes(fund.id))
    .map((fund) => ({
      id: fund.id,
      label: fund.name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const handleAddFund = (event, newFund: AutocompleteOption) => {
    event.preventDefault();
    if (!newFund) {
      return;
    }
    setSelectedFundIds((currentFundIds) => [...(currentFundIds || []), newFund.id]);
    setFieldValue('addFundFilterText', '');
  };

  const handleRemoveFundFilter = (removeFundFilter: string) =>
    setSelectedFundIds((currentFunds) => {
      const newFunds = [...(currentFunds || []).filter((fund) => fund !== removeFundFilter)];
      if (newFunds.length === 0) {
        return null;
      }
      return newFunds;
    });

  const getFundName = (fundId: string) =>
    funds?.find((option) => option.id === fundId)?.name || `Unknown Fund ${fundId}`;

  const handleOptionsExpandedChanged = (_event: React.SyntheticEvent, expanded: boolean) => {
    onOptionsExpandedChanged?.(expanded);
  };

  const handleIncludeDeletedChanged = (_event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
    setFieldValue('includeDeleted', value ? 'true' : 'false');
  };

  return (
    <Accordion defaultExpanded={defaultExpanded} onChange={handleOptionsExpandedChanged}>
      <AccordionSummary expandIcon={<ExpandIcon />}>
        <SubSectionTitle>Options</SubSectionTitle>
      </AccordionSummary>
      <AccordionDetails>
        <DetailContainer>
          {((funds && funds.length > 0) || (selectedFundIds && selectedFundIds.length > 0)) && (
            <DetailField>
              <DetailTitle>Fund Filter</DetailTitle>
              <FormGroup>
                <Autocomplete
                  id="addFundFilterText"
                  inputValue={fields.addFundFilterText}
                  onInputChange={(event, newInputValue) => setFieldValue('addFundFilterText', newInputValue)}
                  options={addFundFilterOptions}
                  onChange={handleAddFund}
                  disabled={loading || addFundFilterOptions.length === 0}
                  renderInput={(params) => <DefaultTextField {...params} label="Add Fund" placeholder="Pick a fund" />}
                />
                {selectedFundIds?.map((fundId) => (
                  <div key={fundId}>
                    <TooltipIconButton
                      title={`Remove ${getFundName(fundId)}`}
                      onClick={() => handleRemoveFundFilter(fundId)}
                      disabled={loading}
                    >
                      <RemoveIcon fontSize="small" />
                    </TooltipIconButton>
                    <DetailInlineValue>{getFundName(fundId)}</DetailInlineValue>
                  </div>
                ))}
              </FormGroup>
            </DetailField>
          )}
          {showDeletedOption && (
            <DetailField>
              <FormControlLabel
                control={
                  <Switch defaultChecked={fields.includeDeleted === 'true'} onChange={handleIncludeDeletedChanged} />
                }
                label="Include Deleted"
              />
            </DetailField>
          )}
        </DetailContainer>
      </AccordionDetails>
    </Accordion>
  );
};
export default ClientFilterChoices;

export const clientFilterChoicesFundFragment = gql`
  fragment ClientFilterChoices_Fund on Fund {
    id
    name
  }
`;
