import Grid, { GridProps } from '@mui/material/Grid';
import React from 'react';

export interface DetailGridItemProps extends Omit<GridProps, 'item' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'> {
  size?: DetailGridItemSize;
}

export const DetailGridItemSize = {
  single: 'single',
  double: 'double',
  half: 'half',
  full: 'full',
} as const;
export type DetailGridItemSize = (typeof DetailGridItemSize)[keyof typeof DetailGridItemSize];

const defaultSize = DetailGridItemSize.single;

const DetailGridItem = ({ size, ...rest }: DetailGridItemProps) => {
  const usableSize = (size as DetailGridItemSize) || defaultSize;
  return (
    <Grid
      {...rest}
      item
      xs={12}
      sm={determineMediumSize(usableSize)}
      md={determineMediumSize(usableSize)}
      lg={determineLargeSize(usableSize)}
      xl={determineLargeSize(usableSize)}
    />
  );
};
export default DetailGridItem;

const determineMediumSize = (size: DetailGridItemSize) => {
  switch (size) {
    case DetailGridItemSize.single:
      return 6;
    case DetailGridItemSize.half:
      return 6;
    case DetailGridItemSize.double:
      return 12;
    case DetailGridItemSize.full:
      return 12;
  }
};

const determineLargeSize = (size: DetailGridItemSize) => {
  switch (size) {
    case DetailGridItemSize.single:
      return 4;
    case DetailGridItemSize.half:
      return 6;
    case DetailGridItemSize.double:
      return 8;
    case DetailGridItemSize.full:
      return 12;
  }
};
