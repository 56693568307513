import { css } from '@emotion/react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import TooltipIconButton from '@paypr/mui5-common-components/dist/components/actions/TooltipIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SubSectionTitle from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import React, { useEffect, useState } from 'react';
import { LedgerEventsTableRow_LedgerAccountEvent } from '../../generated/graphql';
import AddIcon from '../common/icons/AddIcon';
import ExpandIcon from '../common/icons/ExpandIcon';
import ExplodeIconButton, { ExplodingComponentProps } from '../common/icons/ExplodeIconButton';
import NoDataError from '../common/page/NoDataError';
import LedgerEventsTable, { LedgerEventClickEventHandler } from './LedgerEventsTable';

export interface LedgerEventsAccordionProps extends ExplodingComponentProps, RefreshingComponentProps {
  ledgerEvents?: readonly LedgerEventsTableRow_LedgerAccountEvent[];
  onClickLedgerEvent?: LedgerEventClickEventHandler;
  onClickAddLedgerEvent?: AddLedgerEventClickEventHandler;
  defaultExpanded?: boolean;
}

const LedgerEventsAccordion = ({
  ledgerEvents,
  onClickLedgerEvent,
  onClickAddLedgerEvent,
  defaultExpanded,
  exploded,
  onExplodeChanged,
  loading,
  refreshing,
  onRefresh,
  autoRefresh,
}: LedgerEventsAccordionProps) => {
  const [expanded, setExpanded] = useState(exploded ? true : defaultExpanded);

  useEffect(() => {
    if (!expanded) {
      return;
    }

    onRefresh?.();
  }, [expanded]);

  const handleAddPortfolioLedgerEvent = (event) => {
    if (!onClickAddLedgerEvent) {
      return;
    }

    event.stopPropagation();
    onClickAddLedgerEvent(event.ctrlKey || event.metaKey);
  };

  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      expanded={expanded}
      onChange={(event, expanded) => setExpanded(expanded)}
    >
      <AccordionSummary expandIcon={!exploded ? <ExpandIcon /> : undefined}>
        <Toolbar
          css={css`
            display: flex;
            flex-grow: 1;
          `}
        >
          <SubSectionTitle>Events</SubSectionTitle>
          <HorizontalAutoSpacer />
          {onClickAddLedgerEvent ? (
            <TooltipIconButton
              title="Add new event"
              onClick={handleAddPortfolioLedgerEvent}
              disabled={refreshing || loading}
            >
              <AddIcon />
            </TooltipIconButton>
          ) : null}
          <RefreshIconButton
            refreshing={refreshing}
            loading={loading}
            onRefresh={expanded ? onRefresh : undefined}
            includeRefreshText
            autoRefresh={autoRefresh}
          />
          <ExplodeIconButton exploded={exploded} onExplodeChanged={onExplodeChanged} />
        </Toolbar>
      </AccordionSummary>
      <AccordionDetails>
        {ledgerEvents ? (
          <LedgerEventsTable
            ledgerEvents={ledgerEvents}
            onClickLedgerEventRow={onClickLedgerEvent}
            fullHeight={exploded}
          />
        ) : !(loading || refreshing) ? (
          <NoDataError />
        ) : null}
      </AccordionDetails>
    </Accordion>
  );
};
export default LedgerEventsAccordion;

export type AddLedgerEventClickEventHandler = (newTab: boolean) => void;
