export const transferNoticesPath = '/transfer-notices';

export interface TransferNoticesPageQueryParams {}

export const buildTransferNoticePath = (transferNoticeId: string) => `${transferNoticesPath}/${transferNoticeId}`;

export const parameterizedTransferNoticePath = buildTransferNoticePath(':transferNoticeId');
export interface TransferNoticePageParams {
  transferNoticeId: string;
}
