import { gql, NetworkStatus } from '@apollo/client';
import React from 'react';
import AuditActionsSection from '../../components/auditActions/AuditActionsSection';
import { auditActionsTableRowFragment } from '../../components/auditActions/AuditActionsTable';
import AppPage from '../../components/common/page/AppPage';
import AppPageError from '../../components/common/page/AppPageError';
import WiderPageContainer from '../../components/common/page/WiderPageContainer';
import { AuditActionsPageQueryParams, buildAuditActionPath } from '../../components/auditActions/auditActionPaths';
import { useQuery } from '../../components/common/routes/paths';
import { useAuditActionsPageQuery } from '../../generated/graphql';
import { usePageTitle } from '../../hooks/page';
import { usePaths } from '../../hooks/paths';

const AuditActionPage = () => {
  usePageTitle('Activity');
  const { navigateTo } = usePaths();

  const { params, updateParam } = useQuery<AuditActionsPageQueryParams>();

  const includeDeletedFlag = params.get('includeDeleted');
  const includeDeleted = includeDeletedFlag === 'true';

  const { data, error, refetch, networkStatus, loading } = useAuditActionsPageQuery({
    fetchPolicy: 'cache-and-network',
  });

  if (error) {
    return <AppPageError error={error} />;
  }

  const handleAuditActionClicked = (auditActionId: string, newTab: boolean) => {
    navigateTo(buildAuditActionPath(auditActionId), newTab);
  };

  const handleIncludeDeletedAuditActionsChanged = (includeDeletedAuditActions: boolean) => {
    updateParam('includeDeleted', includeDeletedAuditActions ? 'true' : 'false');
  };

  return (
    <AppPage>
      <WiderPageContainer>
        <AuditActionsSection
          auditActions={data?.auditActions}
          loading={loading}
          refreshing={networkStatus === NetworkStatus.refetch}
          onRefresh={() => refetch()}
          autoRefresh
          onClickAuditAction={handleAuditActionClicked}
          includeDeletedAuditActions={includeDeleted}
          onUpdateIncludeDeletedAuditActions={
            includeDeletedFlag !== null ? handleIncludeDeletedAuditActionsChanged : undefined
          }
        />
      </WiderPageContainer>
    </AppPage>
  );
};
export default AuditActionPage;

gql`
  query AuditActionsPageQuery {
    auditActions {
      edges {
        node {
          id
          ...AuditActionsTableRow_AuditAction
        }
      }
    }
  }

  ${auditActionsTableRowFragment}
`;
