import { gql, NetworkStatus } from '@apollo/client';
import React from 'react';
import AppPage from '../../components/common/page/AppPage';
import AppPageError from '../../components/common/page/AppPageError';
import WidePageContainer from '../../components/common/page/WidePageContainer';
import { useQuery } from '../../components/common/routes/paths';
import { buildPortfolioPath, PortfoliosPageQueryParams } from '../../components/portfolios/portfolioPaths';
import PortfoliosSection from '../../components/portfolios/PortfoliosSection';
import { portfoliosTableRowFragment } from '../../components/portfolios/PortfoliosTable';
import { usePortfoliosPageQuery } from '../../generated/graphql';
import { usePageTitle } from '../../hooks/page';
import { usePaths } from '../../hooks/paths';

const PortfoliosPage = () => {
  usePageTitle('Portfolios');
  const { navigateTo } = usePaths();

  const { params, updateParam } = useQuery<PortfoliosPageQueryParams>();

  const includeDeletedPortfoliosFlag = params.get('includeDeleted');
  const includeDeletedPortfolios = includeDeletedPortfoliosFlag === 'true';

  const { data, error, refetch, networkStatus, loading } = usePortfoliosPageQuery({
    fetchPolicy: 'cache-and-network',
    variables: { includeDeletedPortfolios: includeDeletedPortfolios || false },
  });

  if (error) {
    return <AppPageError error={error} />;
  }

  const handlePortfolioClicked = (portfolioId: string, newTab: boolean) => {
    navigateTo(buildPortfolioPath(portfolioId), newTab);
  };

  const handleIncludeDeletedPortfoliosChanged = (includeDeletedPortfolios: boolean) => {
    updateParam('includeDeleted', includeDeletedPortfolios ? 'true' : 'false');
  };

  return (
    <AppPage>
      <WidePageContainer>
        <PortfoliosSection
          portfolios={data?.portfolios}
          loading={loading}
          refreshing={networkStatus === NetworkStatus.refetch}
          onRefresh={() => refetch()}
          autoRefresh
          onClickPortfolio={handlePortfolioClicked}
          includeDeletedPortfolios={includeDeletedPortfolios}
          onUpdateIncludeDeletedPortfolios={
            includeDeletedPortfoliosFlag !== null ? handleIncludeDeletedPortfoliosChanged : undefined
          }
        />
      </WidePageContainer>
    </AppPage>
  );
};
export default PortfoliosPage;

gql`
  query PortfoliosPageQuery($includeDeletedPortfolios: Boolean!) {
    portfolios(includeDeletedPortfolios: $includeDeletedPortfolios) {
      id
      ...PortfoliosTableRow_Portfolio
    }
  }

  ${portfoliosTableRowFragment}
`;
