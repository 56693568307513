import { gql } from '@apollo/client';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import * as React from 'react';
import { WorkflowSection_Workflow } from '../../generated/graphql';
import { ApprovalClickEventHandler } from '../approvals/ApprovalsTable';
import WorkflowDetail, { workflowDetailFragment } from './WorkflowDetail';
import WorkflowIcon from './WorkflowIcon';
import { buildWorkflowTitle } from './workflowUtils';

interface WorkflowSectionProps extends RefreshingComponentProps {
  workflow?: WorkflowSection_Workflow;
  onClickApproval?: ApprovalClickEventHandler;
  onCancelWorkflow?: (workflowId: string) => void;
  cancelingWorkflow?: boolean;
  submittedCancelWorkflow?: boolean;
}

const WorkflowSection = ({
  workflow,
  onClickApproval,
  onCancelWorkflow,
  cancelingWorkflow,
  submittedCancelWorkflow,
  loading,
  refreshing,
  onRefresh,
  autoRefresh,
}: WorkflowSectionProps) => (
  <Paper>
    <Toolbar>
      <WorkflowIcon />
      &nbsp;
      <SectionTitle>{buildWorkflowTitle(workflow?.id, workflow?.title)}</SectionTitle>
      <HorizontalAutoSpacer />
      <RefreshIconButton
        refreshing={refreshing}
        loading={loading}
        onRefresh={onRefresh}
        autoRefresh={autoRefresh}
        includeRefreshText
      />
    </Toolbar>

    {workflow ? (
      <WorkflowDetail
        workflow={workflow}
        onClickApproval={onClickApproval}
        onCancelWorkflow={onCancelWorkflow}
        cancelingWorkflow={cancelingWorkflow}
        submittedCancelWorkflow={submittedCancelWorkflow}
        disabled={loading}
      />
    ) : null}
  </Paper>
);
export default WorkflowSection;

export const workflowSectionFragment = gql`
  fragment WorkflowSection_Workflow on Workflow {
    id
    title
    ...WorkflowDetail_Workflow
  }
  ${workflowDetailFragment}
`;
