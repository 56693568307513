import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailValue from '@paypr/mui5-common-components/dist/components/typography/DetailValue';
import React from 'react';
import { ErrorLike, extractErrorMessage } from '../../../hooks/errors';

export interface ContentErrorProps {
  message: string;
  error?: ErrorLike;
}

const ContentError = ({ message, error }: ContentErrorProps) => (
  <DetailContainer>
    <DetailField>
      <DetailValue>{message}. Please retry.</DetailValue>
    </DetailField>
    {error ? (
      <DetailField>
        <DetailValue color="error">{extractErrorMessage(error)}</DetailValue>
      </DetailField>
    ) : null}
  </DetailContainer>
);
export default ContentError;
