import { gql, NetworkStatus } from '@apollo/client';
import React from 'react';
import { usePortfolioOrdersSectionQueryLazyQuery } from '../../generated/graphql';
import ContentError from '../common/content/ContentError';
import { ExplodingComponentProps } from '../common/icons/ExplodeIconButton';
import OrdersAccordion from '../orders/OrdersAccordion';
import { OrderClickEventHandler, ordersTableRowFragment } from '../orders/OrdersTable';

export interface PortfolioOrdersSectionIntegrationProps extends ExplodingComponentProps {
  portfolioId: string;
  onClickOrder?: OrderClickEventHandler;
  defaultExpanded?: boolean;
  autoRefresh?: boolean | number;
}

const PortfolioOrdersSectionIntegration = ({
  portfolioId,
  onClickOrder,
  defaultExpanded,
  autoRefresh,
  exploded,
  onExplodeChanged,
}: PortfolioOrdersSectionIntegrationProps) => {
  const [fetchPortfolioOrders, { data, error, refetch, networkStatus, loading }] =
    usePortfolioOrdersSectionQueryLazyQuery({
      fetchPolicy: 'cache-and-network',
      variables: { portfolioId },
    });

  if (error) {
    return <ContentError message="Error loading portfolio orders" error={error} />;
  }

  const portfolio = data?.portfolio;

  const handleRefresh = async () => {
    if (portfolio) {
      await refetch();
    } else {
      await fetchPortfolioOrders();
    }
  };

  return (
    <OrdersAccordion
      orders={portfolio?.orders}
      onClickOrder={onClickOrder}
      defaultExpanded={defaultExpanded}
      exploded={exploded}
      onExplodeChanged={onExplodeChanged}
      refreshing={networkStatus === NetworkStatus.refetch}
      loading={loading}
      onRefresh={handleRefresh}
      autoRefresh={autoRefresh}
    />
  );
};
export default PortfolioOrdersSectionIntegration;

gql`
  query PortfolioOrdersSectionQuery($portfolioId: ID!) {
    portfolio(id: $portfolioId) {
      id

      orders {
        ...OrdersTableData_OrderConnection
      }
    }
  }

  ${ordersTableRowFragment}
`;
