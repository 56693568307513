import { gql, NetworkStatus } from '@apollo/client';
import { useSnackbar } from 'notistack';
import React from 'react';
import AppPage from '../../components/common/page/AppPage';
import AppPageError from '../../components/common/page/AppPageError';
import AppPageMessage from '../../components/common/page/AppPageMessage';
import WidePageContainer from '../../components/common/page/WidePageContainer';
import { useQuery } from '../../components/common/routes/paths';
import { buildOrderPath } from '../../components/orders/orderPaths';
import { buildPortfolioTitle } from '../../components/portfolios/PortfolioDetail';
import {
  buildAddPortfolioLedgerEventPath,
  PortfolioPageParams,
  PortfolioPageQueryParams,
} from '../../components/portfolios/portfolioPaths';
import PortfolioSection, { portfolioSectionFragment } from '../../components/portfolios/PortfolioSection';
import { buildTradePath } from '../../components/trades/tradePaths';
import { usePortfolioPageQuery, usePortfolioPageUpdatePortfolioManagedCurrencyMutation } from '../../generated/graphql';
import { useErrors } from '../../hooks/errors';
import { usePageParams, usePageTitle } from '../../hooks/page';
import { usePaths } from '../../hooks/paths';

const PortfolioPage = () => {
  const { portfolioId } = usePageParams<keyof PortfolioPageParams>();
  const { navigateTo } = usePaths();

  const { params, updateParam } = useQuery<PortfolioPageQueryParams>();

  const includeDeletedAssetsFlag = params.get('includeDeletedAssets');
  const includeDeletedAssets = includeDeletedAssetsFlag === 'true';

  const { data, error, refetch, networkStatus, loading } = usePortfolioPageQuery({
    fetchPolicy: 'cache-and-network',
    variables: { portfolioId },
  });

  const [updatePortfolioManagedCurrency, { loading: updatingPortfolioManagedCurrency }] =
    usePortfolioPageUpdatePortfolioManagedCurrencyMutation();

  usePageTitle(`Portfolio - ${buildPortfolioTitle(data?.portfolio?.name)}`);
  const { showError, clearError } = useErrors();
  const { enqueueSnackbar } = useSnackbar();

  if (error) {
    return <AppPageError error={error} />;
  }

  const handleIncludeDeletedChanged = (includeDeletedAssets: boolean) => {
    updateParam('includeDeletedAssets', includeDeletedAssets ? 'true' : 'false');
  };

  const handleClickOrder = (orderId: string, newTab: boolean) => {
    navigateTo(buildOrderPath(orderId), newTab);
  };

  const handleClickTrade = (orderId: string, newTab: boolean) => {
    navigateTo(buildTradePath(orderId), newTab);
  };

  const handleClickAddLedgerEvent = (newTab: boolean) => {
    navigateTo(buildAddPortfolioLedgerEventPath(portfolioId), newTab);
  };

  const portfolio = data?.portfolio;

  if (portfolio === null) {
    return <AppPageMessage text={`Portfolio ${portfolioId} does not exist`} />;
  }

  const handleUpdatePortfolioManagedCurrency = async (portfolioId: string, managedCurrencyAmount: number) => {
    clearError();
    try {
      await updatePortfolioManagedCurrency({
        variables: {
          input: {
            portfolioId,
            managedCurrencyAmount,
          },
        },
      });

      enqueueSnackbar('Submitted Portfolio Funds Allocation update successfully', { variant: 'success' });
    } catch (e) {
      showError(e);
    }
  };

  return (
    <AppPage>
      <WidePageContainer>
        <PortfolioSection
          portfolio={portfolio}
          loading={loading}
          refreshing={networkStatus === NetworkStatus.refetch}
          onRefresh={() => refetch()}
          autoRefresh
          onClickOrder={handleClickOrder}
          onClickTrade={handleClickTrade}
          onClickAddLedgerEvent={handleClickAddLedgerEvent}
          onUpdatePortfolioManagedCurrency={handleUpdatePortfolioManagedCurrency}
          updatingPortfolioManagedCurrency={updatingPortfolioManagedCurrency}
          includeDeletedAssets={includeDeletedAssets}
          onUpdateIncludeDeletedAssets={includeDeletedAssetsFlag !== null ? handleIncludeDeletedChanged : undefined}
        />
      </WidePageContainer>
    </AppPage>
  );
};
export default PortfolioPage;

gql`
  query PortfolioPageQuery($portfolioId: ID!) {
    portfolio(id: $portfolioId) {
      id
      name
      ...PortfolioSection_Portfolio
    }
  }

  mutation PortfolioPageUpdatePortfolioManagedCurrencyMutation($input: UpdatePortfolioManagedCurrencyInput!) {
    updatePortfolioManagedCurrency(input: $input)
  }

  ${portfolioSectionFragment}
`;
