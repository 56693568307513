import { gql } from '@apollo/client';
import { css } from '@emotion/react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import TooltipIconButton from '@paypr/mui5-common-components/dist/components/actions/TooltipIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import ExternalLink from '@paypr/mui5-common-components/dist/components/typography/ExternalLink';
import React, { useEffect, useRef, useState } from 'react';
import { useDateTime } from '../../data/dates';
import { DshStrategyAssetLogEventDetail } from '../../generated/graphql';
import AutoScrollIcon from '../common/icons/AutoScrollIcon';
import ExpandIcon from '../common/icons/ExpandIcon';
import ScrollDownIcon from '../common/icons/ScrollDownIcon';
import SubSectionOneLineTitle from '../common/typography/SubSectionOneLineTitle';

export interface DshStrategyAssetLogsProps extends RefreshingComponentProps {
  asset: string;
  logs?: readonly DshStrategyAssetLogEventDetail[];
  logGroupUrl?: string;
  logStreamUrl?: string;
  active?: boolean;
}

const DshStrategyAssetLogs = ({
  asset,
  logs,
  logGroupUrl,
  logStreamUrl,
  active,
  loading,
  refreshing,
  onRefresh,
  autoRefresh,
}: DshStrategyAssetLogsProps) => {
  const bottomRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState<boolean>(false);
  const { toDateTime } = useDateTime();
  const [autoScroll, setAutoScroll] = useState(true);

  useEffect(() => {
    if (!autoScroll) {
      return;
    }

    scrollToBottom();
  }, [logs]);

  const handleExpandChanged = (_event, expanded) => {
    setExpanded(expanded);
    if (expanded && onRefresh) {
      onRefresh();
    }
  };

  const handleScrollToBottom = (event) => {
    event.stopPropagation();

    scrollToBottom();
  };

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView();
  };

  const handleStartAutoScroll = (event) => {
    event.stopPropagation();
    setAutoScroll(true);
  };

  const handleStopAutoScroll = (event) => {
    event.stopPropagation();
    setAutoScroll(false);
  };

  if (!active) {
    return (
      <Accordion disabled={active === false}>
        <AccordionSummary>
          <Toolbar
            css={css`
              display: flex;
              flex-grow: 1;
            `}
          >
            <SubSectionOneLineTitle>
              {asset} Logs {active === false ? '(inactive)' : ''}
            </SubSectionOneLineTitle>
          </Toolbar>
        </AccordionSummary>
      </Accordion>
    );
  }

  return (
    <Accordion expanded={expanded} onChange={handleExpandChanged}>
      <AccordionSummary expandIcon={<ExpandIcon />}>
        <Toolbar
          css={css`
            display: flex;
            flex-grow: 1;
          `}
        >
          <SubSectionOneLineTitle>{asset} Logs</SubSectionOneLineTitle>
          <HorizontalAutoSpacer />
          {expanded ? (
            <>
              <RefreshIconButton
                refreshing={refreshing}
                loading={loading}
                onRefresh={onRefresh}
                includeRefreshText
                autoRefresh={autoRefresh}
              />
              {autoScroll ? (
                <TooltipIconButton title="Stop autoscrolling" onClick={handleStopAutoScroll}>
                  <AutoScrollIcon />
                </TooltipIconButton>
              ) : (
                <TooltipIconButton title="Start autoscrolling" onClick={handleStartAutoScroll}>
                  <AutoScrollIcon color="primary" />
                </TooltipIconButton>
              )}
              <TooltipIconButton title="Scroll to bottom" onClick={handleScrollToBottom}>
                <ScrollDownIcon />
              </TooltipIconButton>
            </>
          ) : null}
        </Toolbar>
      </AccordionSummary>
      <AccordionDetails>
        {logGroupUrl ? (
          <DetailContainer>
            <DetailField>
              <ExternalLink href={logGroupUrl} suppressExternalIndicator>
                Log Group
              </ExternalLink>
              {logStreamUrl ? (
                <>
                  &nbsp;&mdash;&nbsp;
                  <ExternalLink href={logStreamUrl} suppressExternalIndicator>
                    Log Stream
                  </ExternalLink>
                </>
              ) : null}
            </DetailField>
          </DetailContainer>
        ) : null}
        <Box
          css={css`
            max-height: 600px;
            overflow: scroll;
            font-family: monospace;
            white-space: pre;
          `}
        >
          {logs?.map(({ id, timestamp, message }) => (
            <div key={id}>
              {toDateTime(timestamp).toISO()}: {message}
            </div>
          ))}
          <div ref={bottomRef}>
            {logs && logs.length > 0
              ? loading || refreshing
                ? 'Loading more log messages...'
                : 'No more log messages.'
              : 'Log messages not loaded yet...'}{' '}
          </div>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
export default DshStrategyAssetLogs;

export const dshStrategyAssetLogEventDetailFragment = gql`
  fragment DshStrategyAssetLogEventDetail on LogEvent {
    id
    timestamp
    message
  }
`;
