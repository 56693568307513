import { gql, NetworkStatus } from '@apollo/client';
import React, { useState } from 'react';
import { buildTransferNoticeTitle } from '../../components/clients/transfers/TransferNoticeDetail';
import { TransferNoticePageParams } from '../../components/clients/transfers/transferNoticePaths';
import TransferNoticeSection, {
  transferNoticeSectionFragment,
} from '../../components/clients/transfers/TransferNoticeSection';
import AppPage from '../../components/common/page/AppPage';
import AppPageError from '../../components/common/page/AppPageError';
import AppPageMessage from '../../components/common/page/AppPageMessage';
import WiderPageContainer from '../../components/common/page/WiderPageContainer';
import {
  useTransferNoticePageProcessTransferNoticeMutation,
  useTransferNoticePageQuery,
} from '../../generated/graphql';
import { useErrors } from '../../hooks/errors';
import { usePageParams, usePageTitle } from '../../hooks/page';

const refreshAfterSubmitDelay = 15 * 1000; // 15 seconds

const TransferNoticePage = () => {
  const { transferNoticeId } = usePageParams<keyof TransferNoticePageParams>();
  const { showError, clearError } = useErrors();

  const { data, error, refetch, networkStatus, loading } = useTransferNoticePageQuery({
    fetchPolicy: 'cache-and-network',
    variables: { transferNoticeId },
  });

  const [submitProcessing, { loading: processing }] = useTransferNoticePageProcessTransferNoticeMutation();

  const [submittedProcessing, setSubmittedProcessing] = useState(false);

  usePageTitle(`Transfer Notice - ${buildTransferNoticeTitle(data?.transferNotice?.client?.fullName)}`);

  if (error) {
    return <AppPageError error={error} />;
  }

  const transferNotice = data?.transferNotice;

  if (transferNotice === null) {
    return <AppPageMessage text={`Transfer notice ${transferNoticeId} does not exist`} />;
  }

  const handleProcessTransferNotice = async (processTransferNoticeId: string) => {
    clearError();

    if (!confirm(`Are you sure you want to mark the transfer notice as Processed?`)) {
      return;
    }

    try {
      await submitProcessing({ variables: { transferNoticeId: processTransferNoticeId } });
      setSubmittedProcessing(true);

      setTimeout(async () => {
        await refetch();
      }, refreshAfterSubmitDelay);
    } catch (e) {
      showError(e);
    }
  };

  return (
    <AppPage>
      <WiderPageContainer>
        <TransferNoticeSection
          transferNotice={transferNotice}
          onProcessTransferNotice={handleProcessTransferNotice}
          processing={processing}
          submittedProcessing={submittedProcessing}
          loading={loading}
          refreshing={networkStatus === NetworkStatus.refetch}
          onRefresh={() => refetch()}
        />
      </WiderPageContainer>
    </AppPage>
  );
};
export default TransferNoticePage;

gql`
  query TransferNoticePageQuery($transferNoticeId: ID!) {
    transferNotice(id: $transferNoticeId) {
      id
      client {
        id
        fullName
      }
      ...TransferNoticeSection_TransferNotice
    }
  }

  mutation TransferNoticePageProcessTransferNoticeMutation($transferNoticeId: ID!) {
    processTransferNotice(transferNoticeId: $transferNoticeId)
  }

  ${transferNoticeSectionFragment}
`;
