import { DateTimeHook } from '../../data/dates';
import { LedgerReportGroupField, LedgerReportTimeResolution, LedgerReportValueField } from '../../generated/graphql';
import { QueryParams } from '../common/routes/paths';
import {
  LedgerReportAugmentedDefinition,
  LedgerReportSettings,
  LedgerReportStandardTimeframe,
  ReportTableOrientation,
  ReportTableSettings,
  ReportView,
} from './ledgerReportModel';
import { reportsPath } from './reportPaths';

export interface ReportPageQueryParams {
  standardTimeframe: LedgerReportStandardTimeframe;
  startAt: string;
  endAt: string;
  timeResolution: LedgerReportTimeResolution;
  groupBy: string;
  groupFilters: string;
  includeTotals: string;
  values: string;
  includeAccountEvents: string;
  marketAssets: string;
  reportView: ReportView;
  tableOrientation: string;
  optionsExpanded: string;
  editFavorite: string;
}

export const extractReportSettingsFromUrl = (
  params: QueryParams<ReportPageQueryParams>,
  defaults: LedgerReportSettings,
  { toDateTime }: Pick<DateTimeHook, 'toDateTime'>,
): LedgerReportSettings => {
  const reportDefinition = extractReportDefinitionFromUrl(params, defaults.definition, { toDateTime });
  const reportViewText = params.get('reportView');
  const reportView = reportViewText ? (reportViewText as ReportView) : defaults.view;
  const tableSettings = extractReportTableSettingsFromUrl(params, defaults.tableSettings);
  return {
    definition: reportDefinition,
    view: reportView,
    chartSettings: defaults.chartSettings,
    tableSettings,
  };
};

const extractReportDefinitionFromUrl = (
  params: QueryParams<ReportPageQueryParams>,
  defaults: LedgerReportAugmentedDefinition,
  { toDateTime }: Pick<DateTimeHook, 'toDateTime'>,
): LedgerReportAugmentedDefinition => {
  const standardTimeframeText = params.get('standardTimeframe');
  const standardTimeframe = standardTimeframeText
    ? (standardTimeframeText as LedgerReportStandardTimeframe)
    : standardTimeframeText === ''
    ? undefined
    : defaults.standardTimeframe;

  const startAtText = params.get('startAt');
  const startAt = startAtText ? toDateTime(startAtText) : defaults.startAt;

  const endAtText = params.get('endAt');
  const endAt = endAtText ? toDateTime(endAtText) : endAtText === '' ? undefined : defaults.endAt;

  const timeResolutionText = params.get('timeResolution');
  const timeResolution = timeResolutionText
    ? (timeResolutionText as LedgerReportTimeResolution)
    : defaults.timeResolution;

  const groupByText = params.get('groupBy');
  const groupBy = groupByText
    ? (groupByText.split(',') as LedgerReportGroupField[])
    : groupByText === ''
    ? []
    : defaults.groupBy;

  const groupFiltersText = params.get('groupFilters');
  const groupFilters = groupFiltersText
    ? extractGroupFiltersParamValue(groupFiltersText)
    : groupFiltersText === ''
    ? {}
    : defaults.groupFilters;

  const includeTotalsText = params.get('includeTotals');
  const includeTotals =
    includeTotalsText !== undefined && includeTotalsText !== null && includeTotalsText !== ''
      ? includeTotalsText == 'true'
      : defaults.includeTotals;

  const valuesText = params.get('values');
  const values = valuesText ? (valuesText.split(',') as LedgerReportValueField[]) : defaults.values;

  const includeAccountEventsText = params.get('includeAccountEvents');
  const includeAccountEvents =
    includeAccountEventsText !== undefined && includeAccountEventsText !== null && includeAccountEventsText !== ''
      ? includeAccountEventsText == 'true'
      : includeAccountEventsText === ''
      ? false
      : defaults.includeAccountEvents;

  const marketAssetsText = params.get('marketAssets');
  const marketAssets = marketAssetsText ? marketAssetsText.split(',') : defaults.marketAssets;

  return {
    standardTimeframe,
    startAt,
    endAt,
    timeResolution,
    groupBy,
    groupFilters,
    includeTotals,
    values,
    includeAccountEvents,
    marketAssets,
  };
};

const extractGroupFiltersParamValue = (groupFiltersText: string): Partial<Record<LedgerReportGroupField, string[]>> =>
  groupFiltersText.split('|').reduce((results, currentValue) => {
    if (!currentValue) {
      return results;
    }

    const [key, values] = currentValue.split(':');
    if (!values) {
      return results;
    }
    results[key] = values.split(',');
    return results;
  }, {});

const extractReportTableSettingsFromUrl = (
  params: QueryParams<ReportPageQueryParams>,
  defaults: ReportTableSettings,
): ReportTableSettings => {
  const tableOrientationText = params.get('tableOrientation');
  const tableOrientation = tableOrientationText
    ? (tableOrientationText as ReportTableOrientation)
    : defaults.orientation;

  return { orientation: tableOrientation };
};

export const buildReportUrlWithDefinition = (
  settings: LedgerReportSettings,
  optionsExpanded: boolean,
  editFavorite?: string,
): string => {
  const reportDefinition = settings.definition;

  const groupBy = reportDefinition.groupBy.join(',');
  const values = reportDefinition.values.join(',');

  const params = {
    standardTimeframe: reportDefinition.standardTimeframe || '',
    startAt: reportDefinition.startAt.toISO(),
    endAt: reportDefinition.endAt?.toISO() || '',
    timeResolution: reportDefinition.timeResolution,
    groupBy,
    groupFilters: Object.entries(reportDefinition.groupFilters || {})
      .map(([key, values]) => `${key}:${values.join(',')}`)
      .join('|'),
    includeTotals: reportDefinition.includeTotals === true ? 'true' : 'false',
    values,
    includeAccountEvents: reportDefinition.includeAccountEvents === true ? 'true' : 'false',
    marketAssets: reportDefinition.marketAssets?.join(',') || '',
    reportView: settings.view,
    tableOrientation: settings.tableSettings.orientation,
    optionsExpanded: optionsExpanded ? 'true' : 'false',
    editFavorite: editFavorite ? encodeURIComponent(editFavorite) : '',
  };
  return `${reportsPath}?${Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')}`;
};
