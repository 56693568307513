import { gql } from '@apollo/client';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import AlternatingTableRow from '@paypr/mui5-common-components/dist/components/tables/AlternatingTableRow';
import EmptyTableRow from '@paypr/mui5-common-components/dist/components/tables/EmptyTableRow';
import HeightLimitedTableContainer from '@paypr/mui5-common-components/dist/components/tables/HeightLimitedTableContainer';
import {
  SimpleSortingTableHeadCell,
  SimpleSortingTableHeadProps,
} from '@paypr/mui5-common-components/dist/components/tables/SortingTableHead';
import { getComparator, stableSort, useSorting } from '@paypr/mui5-common-components/dist/components/tables/sortUtils';
import { DateTime } from 'luxon';
import * as React from 'react';
import { ClientDocumentsTableRow_ClientDocument, ClientDocumentType } from '../../../generated/graphql';
import { ErrorLike } from '../../../hooks/errors';
import { snakeOrKebabToDisplay } from '../../../utils/strings';
import ContentError from '../../common/content/ContentError';
import SimpleSortingTableHead from '../../common/tables/SortingTableHead';
import { buildFundTitle } from '../../funds/fundUtils';
import { buildClientTitle } from '../clientUtils';

interface ClientDocumentsTableProps {
  clientDocuments?: readonly ClientDocumentsTableRow_ClientDocument[];
  hideClient?: boolean;
  hideFund?: boolean;
  onClickClientDocumentRow?: ClientDocumentClickEventHandler;
  error?: ErrorLike;
}

export type ClientDocumentClickEventHandler = (clientDocumentId: string) => void;

interface ClientDocumentsTableRowData extends ClientDocumentsTableRow_ClientDocument {
  clientName: string;
  fundName: string;
  periodSortKey: string;
}

const ClientDocumentsTable = ({
  hideClient,
  hideFund,
  clientDocuments,
  onClickClientDocumentRow,
  error,
}: ClientDocumentsTableProps) => {
  const { order, orderBy, handleRequestSort } = useSorting<ClientDocumentsTableRowData>('periodSortKey', 'desc');

  const showClient = !hideClient;
  const showFund = !hideFund;

  const createClickHandler = (clientDocumentId: string) => {
    if (!onClickClientDocumentRow) {
      return undefined;
    }
    return () => onClickClientDocumentRow(clientDocumentId);
  };

  const clientDocumentData = clientDocuments?.map((clientDocument) => ({
    ...clientDocument,
    clientName: buildClientTitle(clientDocument.client.fullName),
    fundName: buildFundTitle(clientDocument.fund.name),
    periodSortKey: buildClientDocumentPeriodSortKey(clientDocument.type, clientDocument.periodStartOn),
  }));

  return (
    <HeightLimitedTableContainer>
      <Table stickyHeader>
        <ClientDocumentsTableHead
          showClient={showClient}
          showFund={showFund}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {clientDocumentData && clientDocumentData.length > 0 ? (
            stableSort(clientDocumentData, getComparator(order, orderBy)).map((clientDocument) => (
              <ClientDocumentTableRow
                key={clientDocument.id}
                clientDocument={clientDocument}
                showClient={showClient}
                showFund={showFund}
                onRowClick={createClickHandler(clientDocument.id)}
              />
            ))
          ) : (
            <EmptyTableRow columnCount={columnHeadings.length}>
              {error ? (
                <ContentError message="Error loading LP documents" error={error} />
              ) : clientDocuments ? (
                'No LP documents were found.'
              ) : (
                'Loading LP documents...'
              )}
            </EmptyTableRow>
          )}
        </TableBody>
      </Table>
    </HeightLimitedTableContainer>
  );
};
export default ClientDocumentsTable;

interface ClientDocumentsTableHeadProps
  extends Omit<SimpleSortingTableHeadProps<ClientDocumentsTableRowData>, 'headings'> {
  showClient: boolean;
  showFund: boolean;
}

const columnHeadings: SimpleSortingTableHeadCell<ClientDocumentsTableRowData>[] = [
  { key: 'clientName', label: 'LP', align: 'left' },
  { key: 'fundName', label: 'Fund', align: 'left' },
  { key: 'type', label: 'Type', align: 'left' },
  { key: 'periodSortKey', label: 'Period', align: 'right' },
];

const columnHeadingsWithoutFund = columnHeadings.filter(({ key }) => key !== 'fundName');
const columnHeadingsWithoutClient = columnHeadings.filter(({ key }) => key !== 'clientName');
const columnHeadingsWithoutFundOrClient = columnHeadings.filter(
  ({ key }) => key !== 'fundName' && key !== 'clientName',
);

const ClientDocumentsTableHead = ({ showClient, showFund, ...props }: ClientDocumentsTableHeadProps) => {
  const buildColumnHeadings = () => {
    if (showClient && showFund) {
      return columnHeadings;
    }
    if (showClient) {
      return columnHeadingsWithoutFund;
    }
    if (showFund) {
      return columnHeadingsWithoutClient;
    }
    return columnHeadingsWithoutFundOrClient;
  };

  return <SimpleSortingTableHead {...props} headings={buildColumnHeadings()} />;
};

type ClientDocumentTableRowProps = {
  clientDocument: ClientDocumentsTableRowData;
  showClient: boolean;
  showFund: boolean;
  onRowClick?: React.MouseEventHandler;
};

export const ClientDocumentTableRow = ({
  clientDocument,
  showClient,
  showFund,
  onRowClick,
}: ClientDocumentTableRowProps) => (
  <AlternatingTableRow hover={Boolean(onRowClick)} onClick={onRowClick}>
    {showClient && <TableCell>{clientDocument.clientName}</TableCell>}
    {showFund && <TableCell>{clientDocument.fundName}</TableCell>}
    <TableCell>{buildClientDocumentTypeDisplay(clientDocument.type)}</TableCell>
    <TableCell align="right">
      {buildClientDocumentPeriodDisplay(clientDocument.type, clientDocument.periodStartOn)}
    </TableCell>
  </AlternatingTableRow>
);

const buildClientDocumentPeriodSortKey = (type: ClientDocumentType, periodStartOn: string) => {
  switch (type) {
    case ClientDocumentType.K1:
      return DateTime.fromISO(periodStartOn).toFormat("yyyy-'99'");

    case ClientDocumentType.Statement:
      return DateTime.fromISO(periodStartOn).toFormat('yyyy-MM');

    default:
      console.warn(`Unknown client document type: ${type}`);
      return periodStartOn;
  }
};

export const buildClientDocumentPeriodDisplay = (type: ClientDocumentType, periodStartOn: string) => {
  switch (type) {
    case ClientDocumentType.K1:
      return DateTime.fromISO(periodStartOn).toFormat('yyyy');

    case ClientDocumentType.Statement:
      return DateTime.fromISO(periodStartOn).toFormat('MMM yyyy');

    default:
      console.warn(`Unknown client document type: ${type}`);
      return periodStartOn;
  }
};

export const buildClientDocumentTypeDisplay = (type: ClientDocumentType) => {
  return snakeOrKebabToDisplay(type);
};

export const clientDocumentsTableRowFragment = gql`
  fragment ClientDocumentsTableRow_ClientDocument on ClientDocument {
    id
    type
    client {
      id
      fullName
    }
    fund {
      id
      name
    }
    periodStartOn
    deletedAt
  }
`;
