import { gql } from '@apollo/client';
import RouteLink from '@paypr/mui5-common-components/dist/components/routes/RouteLink';
import SubSectionTitle from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import React from 'react';
import { WorkflowAuditActionDetail_Action } from '../../generated/graphql';
import AuditActionDetail, { auditActionDetailFragment, buildAuditActionTitle } from '../auditActions/AuditActionDetail';
import { buildAuditActionPath } from '../auditActions/auditActionPaths';

export interface WorkflowAuditActionDetailProps {
  action: WorkflowAuditActionDetail_Action;
}

const WorkflowAuditActionDetail = ({ action }: WorkflowAuditActionDetailProps) => (
  <>
    <SubSectionTitle>
      <RouteLink path={buildAuditActionPath(action.id)}>
        {buildAuditActionTitle(action.subsystem.label, action.type)}
      </RouteLink>
    </SubSectionTitle>
    <AuditActionDetail auditAction={action} />
  </>
);
export default WorkflowAuditActionDetail;

export const workflowAuditActionDetailFragment = gql`
  fragment WorkflowAuditActionDetail_Action on AuditAction {
    id
    type

    subsystem {
      id
      label
    }

    ...AuditActionDetail_AuditAction
  }

  ${auditActionDetailFragment}
`;
