import { gql, NetworkStatus } from '@apollo/client';
import React from 'react';
import { useFundClientTransactionsSectionIntegrationQueryLazyQuery } from '../../generated/graphql';
import ClientTransactionsAccordion from '../clients/transactions/ClientTransactionsAccordion';
import {
  ClientTransactionClickEventHandler,
  clientTransactionsTableRowFragment,
} from '../clients/transactions/ClientTransactionsTable';
import { ExplodingComponentProps } from '../common/icons/ExplodeIconButton';

export interface FundClientTransactionsSectionIntegrationProps extends ExplodingComponentProps {
  fundId: string;
  onClickClientTransaction?: ClientTransactionClickEventHandler;
  defaultExpanded?: boolean;
  autoRefresh?: boolean | number;
}

const FundClientTransactionsSectionIntegration = ({
  fundId,
  onClickClientTransaction,
  defaultExpanded,
  autoRefresh,
  exploded,
  onExplodeChanged,
}: FundClientTransactionsSectionIntegrationProps) => {
  const [fetchTransactions, { data, error, refetch, networkStatus, loading }] =
    useFundClientTransactionsSectionIntegrationQueryLazyQuery({
      fetchPolicy: 'cache-and-network',
      variables: { fundId: fundId },
    });

  const fund = data?.fund;

  const handleRefresh = async () => {
    if (fund) {
      await refetch();
    } else {
      await fetchTransactions();
    }
  };

  return (
    <ClientTransactionsAccordion
      clientTransactions={fund?.clientTransactions}
      hideFund
      onClickClientTransaction={onClickClientTransaction}
      defaultExpanded={defaultExpanded}
      exploded={exploded}
      onExplodeChanged={onExplodeChanged}
      refreshing={networkStatus === NetworkStatus.refetch}
      loading={loading}
      onRefresh={handleRefresh}
      autoRefresh={autoRefresh}
      error={error}
    />
  );
};
export default FundClientTransactionsSectionIntegration;

gql`
  query FundClientTransactionsSectionIntegrationQuery($fundId: ID!) {
    fund(id: $fundId) {
      id

      clientTransactions {
        id
        ...ClientTransactionsTableRow_ClientTransaction
      }
    }
  }

  ${clientTransactionsTableRowFragment}
`;
