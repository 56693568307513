import { css } from '@emotion/react';
import { useTheme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { LedgerReportGroupField } from '../../generated/graphql';
import NorrioIcon from '../common/icons/NorrioIcon';
import HeaderColumnTableCell from '../common/tables/HeaderColumnTableCell';
import { ReportGroupValueCombo } from './ledgerDataTableHelpers';
import { buildGroupValueDisplay } from './ledgerReportHelpers';

export interface ReportTableGroupedHeaderRowProps {
  groups: readonly LedgerReportGroupField[];
  combos: readonly ReportGroupValueCombo[];
}

export interface GroupValueColSpan {
  group: LedgerReportGroupField;
  value: string | null;
  colSpan: number;
}

const ReportTableGroupedHeaderRows = ({ groups, combos }: ReportTableGroupedHeaderRowProps) => {
  const theme = useTheme();

  const buildGroupValueColSpans = (rowIndex: number): GroupValueColSpan[] => {
    const colSpans: GroupValueColSpan[] = [];

    for (let columnIndex = 0; columnIndex < combos.length; columnIndex += 1) {
      const groupValue = combos[columnIndex].groupValues[rowIndex];
      let colSpan = 1;

      combosLoop: while (columnIndex + 1 < combos.length) {
        for (let rowIndexCounter = 0; rowIndexCounter <= rowIndex; rowIndexCounter += 1) {
          if (
            combos[columnIndex + 1].groupValues[rowIndexCounter].value !==
            combos[columnIndex].groupValues[rowIndexCounter].value
          ) {
            break combosLoop;
          }
        }

        colSpan += 1;
        columnIndex += 1;
      }
      colSpans.push({ group: groupValue.group, value: buildGroupValueDisplay(groupValue.value) || 'Total', colSpan });
    }

    return colSpans;
  };

  return (
    <>
      {groups.map((group, rowIndex) => (
        <TableRow key={group}>
          {rowIndex === 0 ? (
            <HeaderColumnTableCell rowSpan={groups.length} front>
              <NorrioIcon height={theme.spacing(4)} />
            </HeaderColumnTableCell>
          ) : null}
          {buildGroupValueColSpans(rowIndex).map(({ group, value, colSpan }, index) => (
            <TableCell
              css={css`
                top: ${rowIndex * 57}px;
              `}
              key={index}
              align="center"
              colSpan={colSpan}
            >
              <strong>{value || 'Total'}</strong>
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};
export default ReportTableGroupedHeaderRows;
