import { DateTime } from 'luxon';
import { apiGet } from './auth';

export interface DshReportData {
  name: string;
  version: string;
  report: any;
  reportText?: string;
  startDate?: string;
  endDate?: string;
}

export const fetchDshReport = async (
  version: string,
  report: string,
  timeZone: string,
  params: { [name: string]: string } = {},
): Promise<DshReportData> => {
  console.debug('Fetching DSH Report: ', version, report, params);
  const results = await apiGet<DshReportData>(`/reports/dsh/realized-gains/text/${report}`, {
    queryStringParameters: { ...params, version, timeZone },
  });
  console.debug('Got DSH Report results:', results);
  return results;
};

export enum ReportType {
  Daily = 'daily',
  Monthly = 'monthly',
}

export const fetchDshCustomReport = async (
  version: string,
  reportType: ReportType,
  reportDate: DateTime,
  timeZone: string,
): Promise<DshReportData> =>
  fetchDshReport(version, 'custom', timeZone, { reportType, reportDate: reportDate.toISODate() });

export const fetchDshAssetReport = async (
  version: string,
  report: string,
  asset: string,
  timeZone: string,
): Promise<DshReportData> => fetchDshReport(version, report, timeZone, { ticker: asset });
