import * as React from 'react';
import PageContainer from '@paypr/mui5-common-components/dist/components/page/PageContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailText from '@paypr/mui5-common-components/dist/components/typography/DetailText';
import PageFooter from '../components/common/page/PageFooter';

export const BadRoute = () => (
  <PageContainer>
    <DetailField>
      <DetailText>We're sorry, but this page does not exist.</DetailText>
    </DetailField>
    <PageFooter />
  </PageContainer>
);
