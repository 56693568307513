import { gql } from '@apollo/client';
import NoteBlock from '@paypr/mui5-common-components/dist/components/typography/NoteBlock';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { InternalUserDeletedNote_InternalUser } from '../../generated/graphql';

interface InternalUserDeletedNoteProps {
  internalUser: InternalUserDeletedNote_InternalUser;
}

const InternalUserDeletedNote = ({ internalUser: { deletedAt } }: InternalUserDeletedNoteProps) => {
  const { formatDateTime } = useDateTime();

  if (!deletedAt) {
    return null;
  }

  return (
    <NoteBlock>
      <em>This admin user was deleted on {formatDateTime(deletedAt)}</em>
    </NoteBlock>
  );
};
export default InternalUserDeletedNote;

export const internalUserDeletedNoteFragment = gql`
  fragment InternalUserDeletedNote_InternalUser on InternalUser {
    deletedAt
  }
`;
