import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTimeZone } from '../../../data/dates';
import { getDshRealizedGainsReportState } from '../../../data/reports';
import { fetchDshReport } from '../../../services/dshReports';
import DshGainsReport from './DshGainsReport';

export interface DshRealizedGainsReportIntegrationProps {
  version: string;
  reportName: string;
}

const DshRealizedGainsReportIntegration = ({ version, reportName }: DshRealizedGainsReportIntegrationProps) => {
  const [{ report }, setDshRealizedGainsReportState] = useRecoilState(
    getDshRealizedGainsReportState(version, reportName),
  );
  const [loading, setLoading] = useState<boolean>(false);
  const timeZone = useTimeZone();

  useEffect(() => {
    if (report) {
      return;
    }

    // noinspection JSIgnoredPromiseFromCall
    loadReportData();
  }, [report, reportName]);

  const loadReportData = async () => {
    try {
      setLoading(true);
      const report = await fetchDshReport(version, reportName, timeZone);
      setDshRealizedGainsReportState((currentState) => ({ ...currentState, report }));
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DshGainsReport
      report={report}
      onRefresh={loadReportData}
      loading={loading}
      refreshing={Boolean(loading && report)}
      autoRefresh
    />
  );
};
export default DshRealizedGainsReportIntegration;
