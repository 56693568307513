import { gql, NetworkStatus } from '@apollo/client';
import React from 'react';
import { usePortfolioAssetsSectionQueryLazyQuery } from '../../generated/graphql';
import ContentError from '../common/content/ContentError';
import { ExplodingComponentProps } from '../common/icons/ExplodeIconButton';
import PortfolioAssetsSection from './PortfolioAssetsSection';
import { PortfolioAssetClickEventHandler, portfolioAssetsTableRowFragment } from './PortfolioAssetsTable';

export interface PortfolioAssetsSectionIntegrationProps extends ExplodingComponentProps {
  portfolioId: string;
  includeDeletedAssets?: boolean;
  onUpdateIncludeDeletedAssets?: (includeDeletedAssets: boolean) => void;
  onClickPortfolioAsset?: PortfolioAssetClickEventHandler;
  defaultExpanded?: boolean;
  autoRefresh?: boolean | number;
}

const PortfolioAssetsSectionIntegration = ({
  portfolioId,
  includeDeletedAssets,
  onUpdateIncludeDeletedAssets,
  onClickPortfolioAsset,
  defaultExpanded,
  exploded,
  onExplodeChanged,
  autoRefresh,
}: PortfolioAssetsSectionIntegrationProps) => {
  const [fetchPortfolioAssets, { data, error, refetch, networkStatus, loading }] =
    usePortfolioAssetsSectionQueryLazyQuery({
      fetchPolicy: 'cache-and-network',
      variables: { portfolioId, includeDeletedAssets: includeDeletedAssets || false },
    });

  if (error) {
    return <ContentError message="Error loading portfolio assets" error={error} />;
  }

  const portfolio = data?.portfolio;

  const handleRefresh = async () => {
    if (portfolio) {
      await refetch();
    } else {
      await fetchPortfolioAssets();
    }
  };

  return (
    <PortfolioAssetsSection
      portfolioAssets={portfolio?.assets}
      onClickPortfolioAsset={onClickPortfolioAsset}
      includeDeletedAssets={includeDeletedAssets}
      onUpdateIncludeDeletedAssets={onUpdateIncludeDeletedAssets}
      defaultExpanded={defaultExpanded}
      exploded={exploded}
      onExplodeChanged={onExplodeChanged}
      refreshing={networkStatus === NetworkStatus.refetch}
      loading={loading}
      onRefresh={handleRefresh}
      autoRefresh={autoRefresh}
    />
  );
};
export default PortfolioAssetsSectionIntegration;

gql`
  query PortfolioAssetsSectionQuery($portfolioId: ID!, $includeDeletedAssets: Boolean!) {
    portfolio(id: $portfolioId, includeDeletedAssets: $includeDeletedAssets) {
      id

      assets {
        id
        ...PortfolioAssetsTableRow_PortfolioAsset
      }
    }
  }

  ${portfolioAssetsTableRowFragment}
`;
