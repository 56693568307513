// workflows
import { WorkflowStatus } from '../../generated/graphql';
import { QueryParams } from '../common/routes/paths';

export const workflowsPath = '/workflows';

export interface WorkflowsPageQueryParams {
  inProgress: string;
  status: string;
  optionsExpanded: string;
}

export interface WorkflowFilters {
  inProgress: boolean | null;
  status: WorkflowStatus | null;
}

export const extractWorkflowFiltersFromUrl = (params: QueryParams<WorkflowsPageQueryParams>): WorkflowFilters => {
  const inProgressText = params.get('inProgress');
  const inProgress = inProgressText ? inProgressText === 'true' : inProgressText === 'false' ? false : null;

  const statusText = params.get('status');
  const status = statusText ? (statusText as WorkflowStatus) : null;

  return {
    inProgress,
    status,
  };
};

export const buildWorkflowsUrlWithFilters = (filters: WorkflowFilters, optionsExpanded: boolean): string => {
  const { inProgress, status } = filters;
  const params = {
    inProgress: inProgress !== null && inProgress !== undefined ? inProgress.toString() : '',
    status: status ? status : '',
    optionsExpanded: optionsExpanded ? 'true' : 'false',
  };
  return `${workflowsPath}?${new URLSearchParams(params).toString()}`;
};

export const buildWorkflowPath = (workflowId: string) => `${workflowsPath}/${workflowId}`;

export const parameterizedWorkflowPath = buildWorkflowPath(':workflowId');
export interface WorkflowPageParams {
  workflowId: string;
}
export interface WorkflowPageQueryParams {}
