import Grid from '@mui/material/Grid';
import NumberField from '@paypr/mui5-common-components/dist/components/forms/NumberField';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import DetailValue from '@paypr/mui5-common-components/dist/components/typography/DetailValue';
import React from 'react';
import { FormFieldChangeEventHandler } from '../../../hooks/forms';
import DetailGridItem from '../../common/containers/DetailGridItem';
import UsdFormat from '../../common/typography/UsdFormat';

export interface UpdatePortfolioManagedCurrencyEditorProps {
  managedCurrencyAmount: number;

  fields: UpdatePortfolioManagedCurrencyFields;
  handleFieldChange: FormFieldChangeEventHandler;
}

export interface UpdatePortfolioManagedCurrencyFields {
  managedCurrencyAmount: string;
}

const UpdatePortfolioManagedCurrencyEditor = ({
  managedCurrencyAmount,
  fields,
  handleFieldChange,
}: UpdatePortfolioManagedCurrencyEditorProps) => {
  return (
    <DetailContainer>
      <Grid container justifyContent="left" spacing={2}>
        <DetailGridItem size="half">
          <DetailField>
            <DetailTitle>Current Amount</DetailTitle>
            <DetailValue>
              <UsdFormat amount={managedCurrencyAmount} />
            </DetailValue>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem size="half">
          <DetailField>
            <DetailTitle>New Amount</DetailTitle>
            <NumberField
              autoFocus
              name="managedCurrencyAmount"
              label="New Amount"
              value={fields.managedCurrencyAmount}
              min={0}
              required
              onChange={handleFieldChange}
            />
          </DetailField>
        </DetailGridItem>
      </Grid>
    </DetailContainer>
  );
};
export default UpdatePortfolioManagedCurrencyEditor;
