import React, { HTMLAttributes } from 'react';

export interface BooleanFormatProps extends HTMLAttributes<HTMLSpanElement> {
  value?: boolean;
  type?: BooleanFormatType;
}

export const BooleanFormatType = {
  yesNo: 'yes-no',
  onOff: 'on-off',
} as const;
export type BooleanFormatType = (typeof BooleanFormatType)[keyof typeof BooleanFormatType];

const defaultType = BooleanFormatType.yesNo;

const BooleanFormat = ({ value, type, ...rest }: BooleanFormatProps) => {
  if (value === undefined) {
    return null;
  }

  return <span {...rest}>{formatBoolean(value, type)}</span>;
};
export default BooleanFormat;

const values = {
  [BooleanFormatType.yesNo]: ['Yes', 'No'],
  [BooleanFormatType.onOff]: ['On', 'Off'],
};

export const formatBoolean = (value?: boolean, type?: BooleanFormatType) =>
  value === undefined ? '' : value ? values[type || defaultType][0] : values[type || defaultType][1];
