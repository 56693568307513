import { css } from '@emotion/react';
import React from 'react';
import { useDateTime } from '../../data/dates';
import FloatFormat from '../common/typography/FloatFormat';
import IntegerFormat from '../common/typography/IntegerFormat';
import PercentFormat from '../common/typography/PercentFormat';
import UsdFormat from '../common/typography/UsdFormat';
import { ReportPropertyType } from './reportHelpers';

export interface ReportFormattedValueProps {
  propertyType: ReportPropertyType;
  value: number | string | null;
  asset?: string;
  color?: string;
}

const ReportFormattedValue = ({ propertyType, value, asset, color }: ReportFormattedValueProps) => {
  const { fromMillis } = useDateTime();

  if (value === null) {
    return <>&nbsp;</>;
  }

  const style = css`
    color: ${color};
  `;

  const commonProps = {
    css: style,
  };

  switch (propertyType) {
    case ReportPropertyType.Usd:
      return <UsdFormat {...commonProps} amount={value} decimalScale={2} />;

    case ReportPropertyType.Percent:
      return <PercentFormat {...commonProps} amount={value as number} decimalScale={2} />;

    case ReportPropertyType.Integer:
      return <IntegerFormat {...commonProps} amount={value} />;

    case ReportPropertyType.Number:
      return <FloatFormat {...commonProps} amount={value} asset={asset} />;

    case ReportPropertyType.Bips:
      return <IntegerFormat {...commonProps} amount={value} />;

    case ReportPropertyType.DateTime:
      return <span {...commonProps}>{value ? fromMillis(value).toFormat('M/d/yyyy h:mm:ssa') : ''}</span>;

    case ReportPropertyType.Date:
      return <span {...commonProps}>{value ? fromMillis(value).toFormat('M/d/yyyy') : ''}</span>;

    case ReportPropertyType.Unknown:
      if (typeof value == 'number') {
        return <FloatFormat {...commonProps} amount={value} />;
      }
      return <span {...commonProps}>{value}</span>;
  }

  return <span {...commonProps}>{value}</span>;
};
export default ReportFormattedValue;
