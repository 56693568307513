import { gql } from '@apollo/client';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import AlternatingTableRow from '@paypr/mui5-common-components/dist/components/tables/AlternatingTableRow';
import EmptyTableRow from '@paypr/mui5-common-components/dist/components/tables/EmptyTableRow';
import HeightLimitedTableContainer from '@paypr/mui5-common-components/dist/components/tables/HeightLimitedTableContainer';
import {
  SimpleSortingTableHeadCell,
  SimpleSortingTableHeadProps,
} from '@paypr/mui5-common-components/dist/components/tables/SortingTableHead';
import { getComparator, stableSort, useSorting } from '@paypr/mui5-common-components/dist/components/tables/sortUtils';
import * as React from 'react';
import { useDateTime } from '../../../data/dates';
import { ClientTransactionsTableRow_ClientTransaction, ClientTransactionType } from '../../../generated/graphql';
import { ErrorLike } from '../../../hooks/errors';
import { snakeOrKebabToDisplay } from '../../../utils/strings';
import ContentError from '../../common/content/ContentError';
import SimpleSortingTableHead from '../../common/tables/SortingTableHead';
import UsdFormat from '../../common/typography/UsdFormat';
import { buildFundTitle } from '../../funds/fundUtils';
import { buildClientTitle } from '../clientUtils';

interface ClientTransactionsTableProps {
  clientTransactions?: readonly ClientTransactionsTableRow_ClientTransaction[];
  hideClient?: boolean;
  hideFund?: boolean;
  onClickClientTransactionRow?: ClientTransactionClickEventHandler;
  error?: ErrorLike;
}

export type ClientTransactionClickEventHandler = (clientTransactionId: string) => void;

interface ClientTransactionsTableRowData extends ClientTransactionsTableRow_ClientTransaction {
  clientName: string;
  fundName: string;
}

const ClientTransactionsTable = ({
  hideClient,
  hideFund,
  clientTransactions,
  onClickClientTransactionRow,
  error,
}: ClientTransactionsTableProps) => {
  const { order, orderBy, handleRequestSort } = useSorting<ClientTransactionsTableRow_ClientTransaction>(
    'occurredAt',
    'desc',
  );

  const showClient = !hideClient;
  const showFund = !hideFund;

  const createClickHandler = (clientTransactionId: string) => {
    if (!onClickClientTransactionRow) {
      return undefined;
    }
    return () => onClickClientTransactionRow(clientTransactionId);
  };

  const clientTransactionData = clientTransactions?.map((clientTransaction) => ({
    ...clientTransaction,
    clientName: buildClientTitle(clientTransaction.client.fullName),
    fundName: buildFundTitle(clientTransaction.fund.name),
  }));

  return (
    <HeightLimitedTableContainer>
      <Table stickyHeader>
        <ClientTransactionsTableHead
          showClient={showClient}
          showFund={showFund}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {clientTransactionData && clientTransactionData.length > 0 ? (
            stableSort(clientTransactionData, getComparator(order, orderBy)).map((clientTransaction) => (
              <ClientTransactionTableRow
                key={clientTransaction.id}
                clientTransaction={clientTransaction}
                showClient={showClient}
                showFund={showFund}
                onRowClick={createClickHandler(clientTransaction.id)}
              />
            ))
          ) : (
            <EmptyTableRow columnCount={columnHeadings.length}>
              {error ? (
                <ContentError message={'Error loading LP transactions'} error={error} />
              ) : clientTransactions ? (
                'No LP transactions were found.'
              ) : (
                'Loading LP transactions...'
              )}
            </EmptyTableRow>
          )}
        </TableBody>
      </Table>
    </HeightLimitedTableContainer>
  );
};
export default ClientTransactionsTable;

interface ClientTransactionsTableHeadProps
  extends Omit<SimpleSortingTableHeadProps<ClientTransactionsTableRowData>, 'headings'> {
  showClient: boolean;
  showFund: boolean;
}

const columnHeadings: SimpleSortingTableHeadCell<ClientTransactionsTableRowData>[] = [
  { key: 'clientName', label: 'LP', align: 'left' },
  { key: 'fundName', label: 'Fund', align: 'left' },
  { key: 'type', label: 'Type', align: 'left' },
  { key: 'amount', label: 'Amount', align: 'right' },
  { key: 'deposits', label: 'Deposits', align: 'right' },
  { key: 'withdrawals', label: 'Withdrawals', align: 'right' },
  { key: 'investment', label: 'Investment', align: 'right' },
  { key: 'fees', label: 'Fees', align: 'right' },
  { key: 'value', label: 'Value', align: 'right' },
  { key: 'occurredAt', label: 'Occurred', align: 'right' },
];

const columnHeadingsWithoutFund = columnHeadings.filter(({ key }) => key !== 'fundName');
const columnHeadingsWithoutClient = columnHeadings.filter(({ key }) => key !== 'clientName');
const columnHeadingsWithoutFundOrClient = columnHeadings.filter(
  ({ key }) => key !== 'fundName' && key !== 'clientName',
);

const ClientTransactionsTableHead = ({ showClient, showFund, ...props }: ClientTransactionsTableHeadProps) => {
  const buildColumnHeadings = () => {
    if (showClient && showFund) {
      return columnHeadings;
    }
    if (showClient) {
      return columnHeadingsWithoutFund;
    }
    if (showFund) {
      return columnHeadingsWithoutClient;
    }
    return columnHeadingsWithoutFundOrClient;
  };

  return <SimpleSortingTableHead {...props} headings={buildColumnHeadings()} />;
};

type ClientTransactionTableRowProps = {
  clientTransaction: ClientTransactionsTableRowData;
  showClient: boolean;
  showFund: boolean;
  onRowClick?: React.MouseEventHandler;
};

export const ClientTransactionTableRow = ({
  clientTransaction,
  showClient,
  showFund,
  onRowClick,
}: ClientTransactionTableRowProps) => {
  const { formatShortDateTime } = useDateTime();

  return (
    <AlternatingTableRow hover={Boolean(onRowClick)} onClick={onRowClick}>
      {showClient && <TableCell>{clientTransaction.clientName}</TableCell>}
      {showFund && <TableCell>{clientTransaction.fundName}</TableCell>}
      <TableCell>{buildClientTransactionTypeDisplay(clientTransaction.type)}</TableCell>
      <TableCell align="right">
        <UsdFormat amount={clientTransaction.amount} />
      </TableCell>
      <TableCell align="right">
        <UsdFormat amount={clientTransaction.deposits} />
      </TableCell>
      <TableCell align="right">
        <UsdFormat amount={clientTransaction.withdrawals} />
      </TableCell>
      <TableCell align="right">
        <UsdFormat amount={clientTransaction.investment} />
      </TableCell>
      <TableCell align="right">
        <UsdFormat amount={clientTransaction.fees} />
      </TableCell>
      <TableCell align="right">
        <UsdFormat amount={clientTransaction.value} />
      </TableCell>
      <TableCell align="right">{formatShortDateTime(clientTransaction.occurredAt)}</TableCell>
    </AlternatingTableRow>
  );
};

export const buildClientTransactionTypeDisplay = (type: ClientTransactionType) => {
  return snakeOrKebabToDisplay(type);
};

export const clientTransactionsTableRowFragment = gql`
  fragment ClientTransactionsTableRow_ClientTransaction on ClientTransaction {
    id
    type
    client {
      id
      fullName
    }
    fund {
      id
      name
    }
    amount
    deposits
    withdrawals
    investment
    fees
    value
    occurredAt
  }
`;
