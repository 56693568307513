import { gql } from '@apollo/client';
import Grid from '@mui/material/Grid';
import RouteLink from '@paypr/mui5-common-components/dist/components/routes/RouteLink';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import DetailValue from '@paypr/mui5-common-components/dist/components/typography/DetailValue';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { TradeDetail_Trade, TradeStatus } from '../../generated/graphql';
import { snakeOrKebabToDisplay } from '../../utils/strings';
import DetailGridItem from '../common/containers/DetailGridItem';
import { buildExchangeDisplay } from '../common/model/exchange';
import { buildPriorityDisplay, buildTimeframeDisplay } from '../common/model/timeframe';
import FloatFormat from '../common/typography/FloatFormat';
import UsdFormat from '../common/typography/UsdFormat';
import { buildOrderPath } from '../orders/orderPaths';
import { buildPortfolioPath } from '../portfolios/portfolioPaths';
import {
  buildTransactionSideDisplay,
  buildTransactionStatusDisplay,
  buildTransactionTypeDisplay,
} from '../transactions/TransactionDetail';
import { buildTransactionPath } from '../transactions/transactionPaths';

export type TradeDetailData = TradeDetail_Trade;

interface TradeDetailProps {
  trade: TradeDetailData;
}

const TradeDetail = ({ trade }: TradeDetailProps) => {
  const { formatDateTime } = useDateTime();

  return (
    <>
      <DetailContainer>
        {trade.error ? (
          <DetailField>
            <DetailValue color="error">{trade.error}</DetailValue>
          </DetailField>
        ) : null}
        <Grid container justifyContent="left" spacing={2}>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Order</DetailTitle>
              <DetailValue>
                <RouteLink path={buildPortfolioPath(trade.order.portfolio.id)}>{trade.order.portfolio.name}</RouteLink>{' '}
                <RouteLink path={buildOrderPath(trade.order.id)}>Order</RouteLink>
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Status</DetailTitle>
              <DetailValue>{buildTradeStatusDisplay(trade.status)}</DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Exchange</DetailTitle>
              <DetailValue>
                {buildExchangeDisplay(trade.exchange)}{' '}
                {trade.transaction ? (
                  <RouteLink path={buildTransactionPath(trade.transaction.id)}>Transaction</RouteLink>
                ) : null}
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          {trade.transaction ? (
            <>
              <DetailGridItem>
                <DetailField>
                  <DetailTitle>Transaction Status</DetailTitle>
                  <DetailValue>{buildTransactionStatusDisplay(trade.transaction.status)}</DetailValue>
                </DetailField>
              </DetailGridItem>
              <DetailGridItem>
                <DetailField>
                  <DetailTitle>Transaction Type</DetailTitle>
                  <DetailValue>{buildTransactionTypeDisplay(trade.transaction.type)}</DetailValue>
                </DetailField>
              </DetailGridItem>
              <DetailGridItem>
                <DetailField>
                  <DetailTitle>Transaction Side</DetailTitle>
                  <DetailValue>{buildTransactionSideDisplay(trade.transaction.side)}</DetailValue>
                </DetailField>
              </DetailGridItem>
            </>
          ) : null}
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Asset</DetailTitle>
              <DetailValue>{trade.asset}</DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Desired Price</DetailTitle>
              <DetailValue>
                <UsdFormat amount={trade.desiredPrice} />
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Average Price</DetailTitle>
              <DetailValue>
                <UsdFormat amount={trade.averagePrice} />
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Desired Amount</DetailTitle>
              <DetailValue>
                <UsdFormat amount={trade.desiredCurrencyAmount} />
              </DetailValue>
              <DetailValue>
                <FloatFormat amount={trade.desiredAssetAmount} asset={trade.asset} />
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Filled Amount</DetailTitle>
              <DetailValue>
                <UsdFormat amount={trade.filledCurrencyAmount} />
              </DetailValue>
              <DetailValue>
                <FloatFormat amount={trade.filledAssetAmount} asset={trade.asset} />
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Unfilled Amount</DetailTitle>
              <DetailValue>
                <UsdFormat amount={trade.unfilledCurrencyAmount} />
              </DetailValue>
              <DetailValue>
                <FloatFormat amount={trade.unfilledAssetAmount} asset={trade.asset} />
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Fees Paid</DetailTitle>
              <DetailValue>
                <UsdFormat amount={trade.feesPaid} />
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Timeframe</DetailTitle>
              <DetailValue>{buildTimeframeDisplay(trade.timeframe)}</DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Priority</DetailTitle>
              <DetailValue>{buildPriorityDisplay(trade.priority)}</DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Due</DetailTitle>
              <DetailValue>{formatDateTime(trade.dueAt)}</DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Last Update</DetailTitle>
              <DetailValue>{formatDateTime(trade.updatedAt)}</DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Created</DetailTitle>
              <DetailValue>{formatDateTime(trade.createdAt)}</DetailValue>
            </DetailField>
          </DetailGridItem>
        </Grid>
      </DetailContainer>
    </>
  );
};
export default TradeDetail;

export const buildTradeTitle = (tradeId?: string, portfolioName?: string) =>
  portfolioName ? `${portfolioName} Trade` : tradeId ? `Trade ${tradeId}` : 'Trade';

export const buildTradeStatusDisplay = (status: TradeStatus) => snakeOrKebabToDisplay(status);

export const tradeDetailFragment = gql`
  fragment TradeDetail_Trade on Trade {
    id
    order {
      id
      portfolio {
        id
        name
      }
    }
    status
    asset
    exchange
    transaction {
      id
      status
      type
      side
    }
    desiredAssetAmount
    desiredCurrencyAmount
    filledAssetAmount
    filledCurrencyAmount
    unfilledAssetAmount
    unfilledCurrencyAmount
    desiredPrice
    averagePrice
    feesPaid
    timeframe
    priority
    error
    dueAt
    createdAt
    updatedAt
  }
`;
