import { css } from '@emotion/react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import TooltipIconButton from '@paypr/mui5-common-components/dist/components/actions/TooltipIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import React, { useEffect, useState } from 'react';
import {
  AddTransferNoticeDialog_Client,
  CreateTransferNoticeInput,
  TransferNoticesTableRow_TransferNotice,
} from '../../../generated/graphql';
import { ErrorLike } from '../../../hooks/errors';
import AddIcon from '../../common/icons/AddIcon';
import ExpandIcon from '../../common/icons/ExpandIcon';
import { ExplodingComponentProps } from '../../common/icons/ExplodeIconButton';
import AddTransferNoticeDialog, { SubmitCreateTransferNoticeEventHandler } from './add/AddTransferNoticeDialog';
import TransferNoticesTable, { TransferNoticeClickEventHandler } from './TransferNoticesTable';

export interface TransferNoticesAccordionProps extends ExplodingComponentProps, RefreshingComponentProps {
  client?: AddTransferNoticeDialog_Client;
  transferNotices?: readonly TransferNoticesTableRow_TransferNotice[];
  title?: string;
  hideClient?: boolean;
  hideFund?: boolean;
  defaultExpanded?: boolean;
  onClickTransferNotice?: TransferNoticeClickEventHandler;
  onSubmitCreateTransferNotice?: SubmitCreateTransferNoticeEventHandler;
  submittingCreateTransferNotice?: boolean;
  error?: ErrorLike;
}

const TransferNoticesAccordion = ({
  client,
  transferNotices,
  title,
  hideClient,
  hideFund,
  defaultExpanded,
  onClickTransferNotice,
  onSubmitCreateTransferNotice,
  submittingCreateTransferNotice,
  exploded,
  loading,
  refreshing,
  onRefresh,
  autoRefresh,
  error,
}: TransferNoticesAccordionProps) => {
  const [expanded, setExpanded] = useState(exploded ? true : defaultExpanded);
  const [addTransferNoticeDialogOpen, setAddTransferNoticeDialogOpen] = useState(false);

  useEffect(() => {
    if (!expanded) {
      return;
    }

    onRefresh?.();
  }, [expanded]);

  const openAddTransferNoticeDialog = () => {
    setAddTransferNoticeDialogOpen(true);
  };

  const closeAddTransferNoticeDialog = () => {
    setAddTransferNoticeDialogOpen(false);
  };

  const handleAddTransferNotice = async (event) => {
    event.stopPropagation();

    openAddTransferNoticeDialog();
  };

  const handleSubmitClientFundUpdate = async (input: CreateTransferNoticeInput) => {
    if (!onSubmitCreateTransferNotice) {
      return;
    }

    await onSubmitCreateTransferNotice(input);
    closeAddTransferNoticeDialog();
  };

  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      expanded={expanded}
      onChange={(event, expanded) => setExpanded(expanded)}
    >
      <AccordionSummary expandIcon={!exploded ? <ExpandIcon /> : undefined}>
        <Toolbar
          css={css`
            display: flex;
            flex-grow: 1;
          `}
        >
          <SectionTitle>{title || 'Transfer Notices'}</SectionTitle>
          <HorizontalAutoSpacer />
          {expanded && onSubmitCreateTransferNotice && client && (
            <TooltipIconButton title={`Submit Transfer Notice`} onClick={handleAddTransferNotice} disabled={loading}>
              <AddIcon />
            </TooltipIconButton>
          )}
          <RefreshIconButton
            refreshing={refreshing}
            loading={loading}
            onRefresh={onRefresh}
            autoRefresh={autoRefresh}
            includeRefreshText
          />
        </Toolbar>
      </AccordionSummary>
      <AccordionDetails>
        <TransferNoticesTable
          transferNotices={transferNotices}
          hideClient={hideClient}
          hideFund={hideFund}
          onClickTransferNoticeRow={onClickTransferNotice}
          error={error}
        />
        {onSubmitCreateTransferNotice && client && (
          <AddTransferNoticeDialog
            client={client}
            onSubmitCreateTransferNotice={handleSubmitClientFundUpdate}
            onClose={closeAddTransferNoticeDialog}
            open={addTransferNoticeDialogOpen}
            submitting={submittingCreateTransferNotice}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};
export default TransferNoticesAccordion;
