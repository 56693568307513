import { gql } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import AlternatingTableRow from '@paypr/mui5-common-components/dist/components/tables/AlternatingTableRow';
import EmptyTableRow from '@paypr/mui5-common-components/dist/components/tables/EmptyTableRow';
import HeightLimitedTableContainer from '@paypr/mui5-common-components/dist/components/tables/HeightLimitedTableContainer';
import {
  SimpleSortingTableHeadCell,
  SimpleSortingTableHeadProps,
} from '@paypr/mui5-common-components/dist/components/tables/SortingTableHead';
import { getComparator, stableSort, useSorting } from '@paypr/mui5-common-components/dist/components/tables/sortUtils';
import TooltipItem from '@paypr/mui5-common-components/dist/components/typography/TooltipItem';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { TransactionsTableData_TransactionConnection, TransactionsTableRow_Transaction } from '../../generated/graphql';
import ErrorIcon from '../common/icons/ErrorIcon';
import { buildExchangeDisplay } from '../common/model/exchange';
import SimpleSortingTableHead from '../common/tables/SortingTableHead';
import FloatFormat from '../common/typography/FloatFormat';
import IntegerFormat from '../common/typography/IntegerFormat';
import UsdFormat from '../common/typography/UsdFormat';
import {
  buildTransactionSideDisplay,
  buildTransactionStatusDisplay,
  buildTransactionTypeDisplay,
} from './TransactionDetail';

interface TransactionsTableProps {
  transactionConnection?: TransactionsTableData_TransactionConnection;
  transactions?: readonly TransactionsTableRow_Transaction[];
  onClickTransactionRow?: TransactionClickEventHandler;
}

export type TransactionClickEventHandler = (transactionId: string, newTab: boolean) => void;

interface TransactionsTableRowData extends TransactionsTableRow_Transaction {
  portfolioName: string;
}

const TransactionsTable = ({ transactions, transactionConnection, onClickTransactionRow }: TransactionsTableProps) => {
  const { order, orderBy, handleRequestSort } = useSorting<TransactionsTableRowData>('createdAt', 'desc');

  const createClickHandler = (transactionId: string) => {
    if (!onClickTransactionRow) {
      return undefined;
    }
    return (event) => onClickTransactionRow(transactionId, event.ctrlKey || event.metaKey);
  };

  const transactionList: TransactionsTableRowData[] = transactionConnection
    ? transactionConnection.edges
        .map((edge) => edge.node)
        .map((transaction) => ({
          ...transaction,
          portfolioName: transaction.trade.order.portfolio.name,
        }))
    : transactions
    ? transactions.map((transaction) => ({ ...transaction, portfolioName: transaction.trade.order.portfolio.name }))
    : [];

  return (
    <HeightLimitedTableContainer>
      <Table stickyHeader>
        <TransactionsTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
        <TableBody>
          {transactionList.length > 0 ? (
            stableSort(transactionList, getComparator(order, orderBy)).map((transaction) => (
              <TransactionTableRow
                key={transaction.id}
                transaction={transaction}
                onRowClick={createClickHandler(transaction.id)}
              />
            ))
          ) : (
            <EmptyTableRow columnCount={columnHeadings.length}>No transactions were found.</EmptyTableRow>
          )}
        </TableBody>
      </Table>
    </HeightLimitedTableContainer>
  );
};
export default TransactionsTable;

type TransactionsTableHeadProps = Omit<SimpleSortingTableHeadProps<TransactionsTableRowData>, 'headings'>;

const columnHeadings: SimpleSortingTableHeadCell<TransactionsTableRowData>[] = [
  { key: 'portfolioName', label: 'Portfolio' },
  { key: 'exchange', label: 'Exchange' },
  { key: 'status', label: 'Status' },
  { key: 'asset', label: 'Asset' },
  { key: 'type', label: 'Type' },
  { key: 'side', label: 'Side' },
  { key: 'filledCurrencyAmount', label: 'Filled', align: 'right' },
  { key: 'averagePrice', label: 'Price', align: 'right' },
  { key: 'feesPaid', label: 'Fees', align: 'right' },
  { key: 'numFills', label: '# Fills', align: 'right' },
  { key: 'updatedAt', label: 'Last Update', align: 'right' },
  { key: 'createdAt', label: 'Created', align: 'right' },
];

const TransactionsTableHead = (props: TransactionsTableHeadProps) => (
  <SimpleSortingTableHead {...props} headings={columnHeadings} />
);

type TransactionTableRowProps = {
  transaction: TransactionsTableRowData;
  onRowClick?: React.MouseEventHandler;
};

export const TransactionTableRow = ({ transaction, onRowClick }: TransactionTableRowProps) => {
  const theme = useTheme();
  const { formatShortDateTime } = useDateTime();

  return (
    <AlternatingTableRow hover={Boolean(onRowClick)} onClick={onRowClick}>
      <TableCell>{transaction.portfolioName}</TableCell>
      <TableCell>{buildExchangeDisplay(transaction.exchange)}</TableCell>
      <TableCell>
        {buildTransactionStatusDisplay(transaction.status)}{' '}
        {transaction.error ? (
          <TooltipItem title={transaction.error}>
            <ErrorIcon style={{ fontSize: theme.typography.body2.fontSize }} color="error" />
          </TooltipItem>
        ) : null}
      </TableCell>
      <TableCell>{transaction.asset}</TableCell>
      <TableCell>{buildTransactionTypeDisplay(transaction.type)}</TableCell>
      <TableCell>{buildTransactionSideDisplay(transaction.side)}</TableCell>
      <TableCell align="right">
        <UsdFormat amount={transaction.filledCurrencyAmount} />
        <br />
        <FloatFormat amount={transaction.filledAssetAmount} asset={transaction.asset} />
      </TableCell>
      <TableCell align="right">
        <UsdFormat amount={transaction.averagePrice} />
      </TableCell>
      <TableCell align="right">
        <UsdFormat amount={transaction.feesPaid} />
      </TableCell>
      <TableCell align="right">
        <IntegerFormat amount={transaction.numFills} />
      </TableCell>
      <TableCell align="right">{formatShortDateTime(transaction.updatedAt)}</TableCell>
      <TableCell align="right">{formatShortDateTime(transaction.createdAt)}</TableCell>
    </AlternatingTableRow>
  );
};

export const transactionsTableRowFragment = gql`
  fragment TransactionsTableData_TransactionConnection on TransactionConnection {
    edges {
      node {
        id
        ...TransactionsTableRow_Transaction
      }
    }
  }

  fragment TransactionsTableRow_Transaction on Transaction {
    id
    trade {
      order {
        id
        portfolio {
          id
          name
        }
      }
    }
    status
    asset
    exchange
    type
    side
    filledCurrencyAmount
    filledAssetAmount
    averagePrice
    numFills
    feesPaid
    error
    createdAt
    updatedAt
  }
`;
