import { gql, NetworkStatus } from '@apollo/client';
import { useSnackbar } from 'notistack';
import React from 'react';
import { LpFundPageParams } from '../../components/clientFunds/clientFundPaths';
import ClientFundSection, { clientFundSectionFragment } from '../../components/clientFunds/ClientFundSection';
import { buildClientFundTitle } from '../../components/clientFunds/clientFundUtils';
import AppPage from '../../components/common/page/AppPage';
import AppPageError from '../../components/common/page/AppPageError';
import AppPageMessage from '../../components/common/page/AppPageMessage';
import WiderPageContainer from '../../components/common/page/WiderPageContainer';
import {
  UpdateClientFundInput,
  useClientFundPageQuery,
  useClientFundPageUpdateClientFundMutation,
} from '../../generated/graphql';
import { useErrors } from '../../hooks/errors';
import { usePageParams, usePageTitle } from '../../hooks/page';

const refreshAfterSubmitDelay = 15 * 1000; // 15 seconds

const ClientFundPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { showError, clearError } = useErrors();

  const { clientId, fundId } = usePageParams<keyof LpFundPageParams>();

  const { data, error, refetch, networkStatus, loading } = useClientFundPageQuery({
    fetchPolicy: 'cache-and-network',
    variables: { clientId, fundId },
  });

  usePageTitle(`LP Fund - ${buildClientFundTitle(data?.clientFund?.client.fullName, data?.clientFund?.fund.name)}`);

  const [updateClientFund, { loading: updating }] = useClientFundPageUpdateClientFundMutation();

  if (error) {
    return <AppPageError error={error} />;
  }

  const clientFund = data?.clientFund;

  if (clientFund === null) {
    return <AppPageMessage text={`LP ${clientId} Fund ${fundId} does not exist`} />;
  }

  const handleUpdateClientFund = async (input: UpdateClientFundInput) => {
    clearError();

    try {
      await updateClientFund({
        variables: {
          input,
        },
      });

      setTimeout(async () => {
        await refetch();
      }, refreshAfterSubmitDelay);

      enqueueSnackbar('Submitted LP Fund updates successfully.', { variant: 'success' });
    } catch (e) {
      showError(e);
    }
  };

  return (
    <AppPage>
      <WiderPageContainer>
        <ClientFundSection
          clientFund={clientFund}
          onSubmitUpdateClientFund={handleUpdateClientFund}
          submittingClientFundUpdate={updating}
          loading={loading}
          refreshing={networkStatus === NetworkStatus.refetch}
          onRefresh={() => refetch()}
          autoRefresh
        />
      </WiderPageContainer>
    </AppPage>
  );
};
export default ClientFundPage;

gql`
  query ClientFundPageQuery($clientId: ID!, $fundId: ID!) {
    clientFund(clientId: $clientId, fundId: $fundId) {
      id
      client {
        id
        fullName
      }
      fund {
        id
        name
      }
      ...ClientFundSection_ClientFund
    }
  }

  mutation ClientFundPageUpdateClientFundMutation($input: UpdateClientFundInput!) {
    updateClientFund(input: $input)
  }

  ${clientFundSectionFragment}
`;
