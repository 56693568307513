import { gql, NetworkStatus } from '@apollo/client';
import React from 'react';
import AppPage from '../../components/common/page/AppPage';
import AppPageError from '../../components/common/page/AppPageError';
import WidePageContainer from '../../components/common/page/WidePageContainer';
import { useQuery } from '../../components/common/routes/paths';
import { buildOrderPath, OrdersPageQueryParams } from '../../components/orders/orderPaths';
import OrdersSection from '../../components/orders/OrdersSection';
import { ordersTableRowFragment } from '../../components/orders/OrdersTable';
import { useOrdersPageQuery } from '../../generated/graphql';
import { usePageTitle } from '../../hooks/page';
import { usePaths } from '../../hooks/paths';

const OrdersPage = () => {
  usePageTitle('Orders');
  const { navigateTo } = usePaths();

  const { params, updateParam } = useQuery<OrdersPageQueryParams>();

  const { data, error, refetch, networkStatus, loading } = useOrdersPageQuery({
    fetchPolicy: 'cache-and-network',
    variables: { inProgress: null, status: null },
  });

  if (error) {
    return <AppPageError error={error} />;
  }

  const handleOrderClicked = (orderId: string, newTab: boolean) => {
    navigateTo(buildOrderPath(orderId), newTab);
  };

  return (
    <AppPage>
      <WidePageContainer>
        <OrdersSection
          orders={data?.orders}
          loading={loading}
          refreshing={networkStatus === NetworkStatus.refetch}
          onRefresh={() => refetch()}
          autoRefresh
          onClickOrder={handleOrderClicked}
        />
      </WidePageContainer>
    </AppPage>
  );
};
export default OrdersPage;

gql`
  query OrdersPageQuery($inProgress: Boolean, $status: OrderStatus) {
    orders(inProgress: $inProgress, status: $status) {
      ...OrdersTableData_OrderConnection
    }
  }

  ${ordersTableRowFragment}
`;
