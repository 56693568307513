import BigNumberFormat, {
  BigNumberFormatProps,
} from '@paypr/mui5-common-components/dist/components/typography/BigNumberFormat';
import React from 'react';

export interface IntegerFormatProps
  extends Omit<BigNumberFormatProps, 'prefix' | 'suffix' | 'fixedDecimalScale' | 'decimalScale'> {}

const IntegerFormat = (props: IntegerFormatProps) => <BigNumberFormat {...props} decimalScale={0} fixedDecimalScale />;
export default IntegerFormat;
