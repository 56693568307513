import { gql } from '@apollo/client';
import NavItem, { NavItemProps } from '@paypr/mui5-common-components/dist/components/page/nav/NavItem';
import React from 'react';
import { useMatch } from 'react-router-dom';
import { usePortfolioNavItemQuery } from '../../generated/graphql';
import { buildPortfolioTitle } from './PortfolioDetail';
import { PortfolioPageParams } from './portfolioPaths';

export interface PortfolioNavItemProps extends Omit<NavItemProps, 'title'> {}

const PortfolioNavItem = ({ path, ...rest }: PortfolioNavItemProps) => {
  const match = useMatch<keyof PortfolioPageParams, typeof path>(path);

  const extractPortfolioId = (): string | null => {
    return match?.params?.portfolioId || null;
  };

  const portfolioId = extractPortfolioId();

  const { data } = usePortfolioNavItemQuery({
    variables: portfolioId ? { portfolioId } : undefined,
    skip: !portfolioId || !match,
  });

  const title = buildPortfolioTitle(data?.portfolio?.name);

  return <NavItem {...rest} title={title} path={path} />;
};
export default PortfolioNavItem;

gql`
  query PortfolioNavItemQuery($portfolioId: ID!) {
    portfolio(id: $portfolioId) {
      id
      name
    }
  }
`;
