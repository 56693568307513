import { gql } from '@apollo/client';
import NoteBlock from '@paypr/mui5-common-components/dist/components/typography/NoteBlock';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { StrategyDeletedNote_Strategy } from '../../generated/graphql';

interface StrategyDeletedNoteProps {
  strategy: StrategyDeletedNote_Strategy;
}

const StrategyDeletedNote = ({ strategy: { deletedAt } }: StrategyDeletedNoteProps) => {
  const { formatDateTime } = useDateTime();

  if (!deletedAt) {
    return null;
  }

  return (
    <NoteBlock>
      <em>This strategy was deleted on {formatDateTime(deletedAt)}</em>
    </NoteBlock>
  );
};
export default StrategyDeletedNote;

export const strategyDeletedNoteFragment = gql`
  fragment StrategyDeletedNote_Strategy on Strategy {
    deletedAt
  }
`;
