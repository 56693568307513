import { css } from '@emotion/react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import SubSectionTitle from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import React, { useEffect, useState } from 'react';
import { PortfolioAssetsTableRow_PortfolioAsset } from '../../generated/graphql';
import ExpandIcon from '../common/icons/ExpandIcon';
import ExplodeIconButton, { ExplodingComponentProps } from '../common/icons/ExplodeIconButton';
import NoDataError from '../common/page/NoDataError';
import PortfolioAssetsTable, {
  PortfolioAssetClickEventHandler,
  PortfolioAssetsTableSelection,
} from './PortfolioAssetsTable';

export interface PortfolioAssetsSectionProps extends ExplodingComponentProps, RefreshingComponentProps {
  portfolioAssets?: readonly PortfolioAssetsTableRow_PortfolioAsset[];
  onClickPortfolioAsset?: PortfolioAssetClickEventHandler;
  includeDeletedAssets?: boolean;
  onUpdateIncludeDeletedAssets?: (includeDeletedAssets: boolean) => void;
  defaultExpanded?: boolean;
}

const PortfolioAssetsSection = ({
  portfolioAssets,
  onClickPortfolioAsset,
  includeDeletedAssets,
  onUpdateIncludeDeletedAssets,
  defaultExpanded,
  exploded,
  onExplodeChanged,
  refreshing,
  loading,
  onRefresh,
  autoRefresh,
}: PortfolioAssetsSectionProps) => {
  const [expanded, setExpanded] = useState(exploded ? true : defaultExpanded);
  const [selection, setSelection] = useState<PortfolioAssetsTableSelection>(PortfolioAssetsTableSelection.Performance);

  useEffect(() => {
    if (!expanded) {
      return;
    }

    onRefresh?.();
  }, [expanded]);

  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      expanded={expanded}
      onChange={(event, expanded) => setExpanded(expanded)}
    >
      <AccordionSummary expandIcon={!exploded ? <ExpandIcon /> : undefined}>
        <Toolbar
          css={css`
            display: flex;
            flex-grow: 1;
          `}
        >
          <SubSectionTitle>Assets</SubSectionTitle>
          <HorizontalAutoSpacer />
          <RefreshIconButton
            refreshing={refreshing}
            loading={loading}
            onRefresh={expanded ? onRefresh : undefined}
            autoRefresh={autoRefresh}
            includeRefreshText
          />
          <ExplodeIconButton exploded={exploded} onExplodeChanged={onExplodeChanged} />
        </Toolbar>
      </AccordionSummary>
      <AccordionDetails>
        {onUpdateIncludeDeletedAssets ? (
          <DetailContainer>
            <FormControlLabel
              control={
                <Switch
                  defaultChecked={includeDeletedAssets}
                  onChange={(_, value) => onUpdateIncludeDeletedAssets(value)}
                />
              }
              label="Include Deleted"
            />
          </DetailContainer>
        ) : null}
        <Tabs value={selection} onChange={(_, selection) => setSelection(selection)}>
          <Tab label="Performance" value={PortfolioAssetsTableSelection.Performance} />
          <Tab label="Price" value={PortfolioAssetsTableSelection.Price} />
        </Tabs>
        {portfolioAssets !== undefined ? (
          <PortfolioAssetsTable
            selection={selection}
            portfolioAssets={portfolioAssets}
            onClickPortfolioAssetRow={onClickPortfolioAsset}
            fullHeight={exploded}
          />
        ) : !(loading || refreshing) ? (
          <NoDataError />
        ) : null}
      </AccordionDetails>
    </Accordion>
  );
};
export default PortfolioAssetsSection;
