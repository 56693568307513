import { gql } from '@apollo/client';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import LoaderButton from '@paypr/mui5-common-components/dist/components/forms/LoaderButton';
import RouteLink from '@paypr/mui5-common-components/dist/components/routes/RouteLink';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import DetailValue from '@paypr/mui5-common-components/dist/components/typography/DetailValue';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { ApprovalDecision_Approval, ApprovalStatus, ApprovalType } from '../../generated/graphql';
import { auditActionDetailFragment } from '../auditActions/AuditActionDetail';
import DetailGridItem from '../common/containers/DetailGridItem';
import { buildInternalUserPath } from '../internalUsers/internalUserPaths';
import { workflowAuditActionDetailFragment } from '../workflows/WorkflowAuditActionDetail';
import WorkflowAuditActionsAccordion from '../workflows/WorkflowAuditActionsAccordion';
import WorkflowUpdatesViewer, { workflowUpdatesViewerFragment } from '../workflows/WorkflowUpdatesViewer';
import { workflowReferenceFragment } from '../workflows/workflowUtils';
import { buildApprovalStatusDisplay } from './approvalUtils';

export type ApprovalDecisionData = ApprovalDecision_Approval;

interface ApprovalDecisionProps {
  approval: ApprovalDecisionData;
  onApproval?: (approvalId: string, approved: boolean) => void;
  approving?: boolean;
  submittedApproval?: boolean;
  disabled?: boolean;
}

const ApprovalDecision = ({ approval, onApproval, approving, submittedApproval, disabled }: ApprovalDecisionProps) => {
  const { formatDateTime } = useDateTime();

  return (
    <>
      <DetailContainer>
        <Grid container justifyContent="left" spacing={2}>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Initiator</DetailTitle>
              <DetailValue>
                <RouteLink path={buildInternalUserPath(approval.workflow.user.id)}>
                  {approval.workflow.user.fullName}
                </RouteLink>
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Status</DetailTitle>
              <DetailValue>
                {submittedApproval && approval.status === ApprovalStatus.Pending
                  ? 'Submitted'
                  : buildApprovalStatusDisplay(approval.status)}
              </DetailValue>
              {onApproval && approval.user.isMe && approval.status === ApprovalStatus.Pending && !submittedApproval && (
                <DetailValue>
                  <Stack direction="row" spacing={1}>
                    <LoaderButton
                      size="small"
                      onClick={() => onApproval(approval.id, true)}
                      disabled={disabled}
                      loading={approving}
                    >
                      Approve
                    </LoaderButton>
                    <LoaderButton
                      size="small"
                      color="error"
                      onClick={() => onApproval(approval.id, false)}
                      disabled={disabled}
                      loading={approving}
                    >
                      Reject
                    </LoaderButton>
                  </Stack>
                </DetailValue>
              )}
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Approvals</DetailTitle>
              <DetailValue>
                {approval.workflow.numApproved} approved of {approval.workflow.minimumApprovals} needed
              </DetailValue>
              {approval.type === ApprovalType.Required && <DetailValue>Your approval is required</DetailValue>}
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Sent</DetailTitle>
              <DetailValue>{formatDateTime(approval.createdAt)}</DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem size="full">
            <WorkflowUpdatesViewer details={approval.workflow.details} />
          </DetailGridItem>
        </Grid>
      </DetailContainer>
      <WorkflowAuditActionsAccordion actions={approval.workflow.actions} />
    </>
  );
};
export default ApprovalDecision;

export const approvalDecisionFragment = gql`
  fragment ApprovalDecision_Approval on Approval {
    id
    type
    status

    workflow {
      id
      ...WorkflowReference_Workflow

      minimumApprovals
      numApproved

      user {
        id
        fullName
      }

      details {
        ...WorkflowUpdatesViewer_WorkflowDetails
      }

      actions {
        id
        ...WorkflowAuditActionDetail_Action
      }
    }

    user {
      id
      isMe
    }

    createdAt
    updatedAt
  }

  ${auditActionDetailFragment}
  ${workflowReferenceFragment}
  ${workflowUpdatesViewerFragment}
  ${workflowAuditActionDetailFragment}
`;
