import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import SubSectionTitle from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import React, { useEffect } from 'react';
import { WorkflowStatus } from '../../generated/graphql';
import { useFormFields } from '../../hooks/forms';
import ExpandIcon from '../common/icons/ExpandIcon';
import { WorkflowFilters } from './workflowPaths';

export interface WorkflowFilterChoicesProps {
  defaultFilters: WorkflowFilters;
  defaultExpanded?: boolean;
  onOptionsExpandedChanged?: (expanded: boolean) => void;
  loading?: boolean;
  onChangeFilters: (filters: WorkflowFilters) => void;
}

interface WorkflowFilterFields {
  statusType: string;
  status: string;
}

const WorkflowFilterChoices = ({
  defaultFilters,
  defaultExpanded,
  onOptionsExpandedChanged,
  loading,
  onChangeFilters,
}: WorkflowFilterChoicesProps) => {
  const { fields, handleFieldChange, setFieldValue } = useFormFields<WorkflowFilterFields>({
    statusType:
      defaultFilters.inProgress !== null ? defaultFilters.inProgress.toString() : defaultFilters.status ? 'status' : '',
    status: defaultFilters.status || '',
  });

  useEffect(() => {
    onChangeFilters({
      inProgress: fields.statusType === 'true' ? true : fields.statusType === 'false' ? false : null,
      status: fields.statusType === 'status' && fields.status ? (fields.status as WorkflowStatus) : null,
    });
  }, [fields]);

  const handleOptionsExpandedChanged = (_event, expanded) => {
    onOptionsExpandedChanged?.(expanded);
  };

  return (
    <Accordion defaultExpanded={defaultExpanded} onChange={handleOptionsExpandedChanged}>
      <AccordionSummary expandIcon={<ExpandIcon />}>
        <SubSectionTitle>Options</SubSectionTitle>
      </AccordionSummary>
      <AccordionDetails>
        <DetailContainer>
          <DetailField>
            <DetailTitle>Status</DetailTitle>
            <RadioGroup name="statusType" row onChange={handleFieldChange} value={fields.statusType}>
              <FormControlLabel radioGroup="statusType" value="" control={<Radio />} label="All" disabled={loading} />
              <FormControlLabel
                radioGroup="statusType"
                value="true"
                control={<Radio />}
                label="In Progress"
                disabled={loading}
              />
              <FormControlLabel
                radioGroup="statusType"
                value="false"
                control={<Radio />}
                label="Not In Progress"
                disabled={loading}
              />
              <FormControlLabel
                radioGroup="statusType"
                value="status"
                control={<Radio />}
                label="Choose Status"
                disabled={loading}
              />
            </RadioGroup>
          </DetailField>
          {fields.statusType === 'status' && (
            <DetailField>
              <Select
                id="status"
                value={fields.status}
                label="Status"
                onChange={(event) => setFieldValue('status', event.target.value)}
              >
                <MenuItem value={WorkflowStatus.Initiated}>Initiated</MenuItem>
                <MenuItem value={WorkflowStatus.Processing}>Processing</MenuItem>
                <MenuItem value={WorkflowStatus.Done}>Done</MenuItem>
                <MenuItem value={WorkflowStatus.Expired}>Expired</MenuItem>
                <MenuItem value={WorkflowStatus.Error}>Error</MenuItem>
                <MenuItem value={WorkflowStatus.Canceled}>Canceled</MenuItem>
              </Select>
            </DetailField>
          )}
        </DetailContainer>
      </AccordionDetails>
    </Accordion>
  );
};
export default WorkflowFilterChoices;
