import { gql } from '@apollo/client';
import Grid from '@mui/material/Grid';
import LoaderButton from '@paypr/mui5-common-components/dist/components/forms/LoaderButton';
import RouteLink from '@paypr/mui5-common-components/dist/components/routes/RouteLink';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import DetailValue from '@paypr/mui5-common-components/dist/components/typography/DetailValue';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { ApprovalStatus, WorkflowDetail_Workflow, WorkflowStatus } from '../../generated/graphql';
import { buildApprovalDecisionPath } from '../approvals/approvalPaths';
import ApprovalsAccordion from '../approvals/ApprovalsAccordion';
import { ApprovalClickEventHandler, approvalsTableRowFragment } from '../approvals/ApprovalsTable';
import DetailGridItem from '../common/containers/DetailGridItem';
import { buildInternalUserPath } from '../internalUsers/internalUserPaths';
import { workflowAuditActionDetailFragment } from './WorkflowAuditActionDetail';
import WorkflowAuditActionsAccordion from './WorkflowAuditActionsAccordion';
import WorkflowUpdatesViewer, { workflowUpdatesViewerFragment } from './WorkflowUpdatesViewer';
import { buildWorkflowStatusDisplay, buildWorkflowTypeDisplay, workflowReferenceFragment } from './workflowUtils';

export type WorkflowDetailData = WorkflowDetail_Workflow;

interface WorkflowDetailProps {
  workflow: WorkflowDetailData;
  onClickApproval?: ApprovalClickEventHandler;
  onCancelWorkflow?: (workflowId: string) => void;
  cancelingWorkflow?: boolean;
  submittedCancelWorkflow?: boolean;
  disabled?: boolean;
}

const WorkflowDetail = ({
  workflow,
  onClickApproval,
  onCancelWorkflow,
  cancelingWorkflow,
  submittedCancelWorkflow,
  disabled,
}: WorkflowDetailProps) => {
  const { formatDateTime } = useDateTime();

  const myApproval = workflow.approvals.find(({ user }) => user.isMe);

  return (
    <>
      <DetailContainer>
        <Grid container justifyContent="left" spacing={2}>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Initiator</DetailTitle>
              <DetailValue>
                <RouteLink path={buildInternalUserPath(workflow.user.id)}>{workflow.user.fullName}</RouteLink>
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Workflow Type</DetailTitle>
              <DetailValue>{buildWorkflowTypeDisplay(workflow.type)}</DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Status</DetailTitle>
              <DetailValue>
                {submittedCancelWorkflow && workflow.status === WorkflowStatus.Processing
                  ? 'Submitted Cancel'
                  : buildWorkflowStatusDisplay(workflow.status)}
              </DetailValue>
              {onCancelWorkflow &&
                workflow.user.isMe &&
                workflow.status === WorkflowStatus.Processing &&
                !submittedCancelWorkflow && (
                  <DetailValue>
                    <LoaderButton
                      size="small"
                      color="error"
                      loading={cancelingWorkflow}
                      onClick={() => onCancelWorkflow(workflow.id)}
                      disabled={disabled}
                    >
                      Cancel
                    </LoaderButton>
                  </DetailValue>
                )}
              {myApproval && myApproval.status === ApprovalStatus.Pending && (
                <DetailValue>
                  <LoaderButton size="small" href={buildApprovalDecisionPath(myApproval.id)} disabled={disabled}>
                    Make Decision
                  </LoaderButton>
                </DetailValue>
              )}
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Num Approvals</DetailTitle>
              <DetailValue>
                {workflow.numApproved} of {workflow.minimumApprovals} needed
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Last Update</DetailTitle>
              <DetailValue>{formatDateTime(workflow.updatedAt)}</DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Started</DetailTitle>
              <DetailValue>{formatDateTime(workflow.createdAt)}</DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem size="full">
            <WorkflowUpdatesViewer details={workflow.details} />
          </DetailGridItem>
        </Grid>
      </DetailContainer>
      <WorkflowAuditActionsAccordion actions={workflow.actions} />
      <ApprovalsAccordion approvals={workflow.approvals} onClickApproval={onClickApproval} sortByApprover />
    </>
  );
};
export default WorkflowDetail;

export const workflowDetailFragment = gql`
  fragment WorkflowDetail_Workflow on Workflow {
    id
    ...WorkflowReference_Workflow

    title
    type
    status
    minimumApprovals
    numApproved

    user {
      id
      fullName
      isMe
    }

    details {
      ...WorkflowUpdatesViewer_WorkflowDetails
    }

    actions {
      id
      ...WorkflowAuditActionDetail_Action
    }

    approvals {
      id
      status
      user {
        id
        isMe
      }
      ...ApprovalsTableRow_Approval
    }

    createdAt
    updatedAt
  }

  ${workflowReferenceFragment}
  ${workflowUpdatesViewerFragment}
  ${workflowAuditActionDetailFragment}
  ${approvalsTableRowFragment}
`;
