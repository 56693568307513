import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import TooltipIconButton from '@paypr/mui5-common-components/dist/components/actions/TooltipIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import React from 'react';
import { SubsystemsTableRow_Subsystem } from '../../generated/graphql';
import AddIcon from '../common/icons/AddIcon';
import NoDataError from '../common/page/NoDataError';
import SubsystemsTable, { SubsystemClickEventHandler } from './SubsystemsTable';

export interface SubsystemsSectionProps extends RefreshingComponentProps {
  subsystems?: readonly SubsystemsTableRow_Subsystem[];
  onAddSubsystem?: () => void;
  onClickSubsystem?: SubsystemClickEventHandler;
  includeDeletedSubsystems?: boolean;
  onUpdateIncludeDeletedSubsystems?: (includeDeletedSubsystems: boolean) => void;
}

const SubsystemsSection = ({
  subsystems,
  onAddSubsystem,
  onClickSubsystem,
  includeDeletedSubsystems,
  onUpdateIncludeDeletedSubsystems,
  loading,
  refreshing,
  onRefresh,
  autoRefresh,
}: SubsystemsSectionProps) => (
  <Paper>
    <Toolbar>
      <SectionTitle>Subsystems</SectionTitle>
      <HorizontalAutoSpacer />
      {onAddSubsystem ? (
        <TooltipIconButton title="Add Subsystem" onClick={onAddSubsystem}>
          <AddIcon />
        </TooltipIconButton>
      ) : null}
      <RefreshIconButton
        refreshing={refreshing}
        loading={loading}
        onRefresh={onRefresh}
        autoRefresh={autoRefresh}
        includeRefreshText
      />
    </Toolbar>
    {onUpdateIncludeDeletedSubsystems ? (
      <DetailContainer>
        <FormControlLabel
          control={
            <Switch
              defaultChecked={includeDeletedSubsystems}
              onChange={(_, value) => onUpdateIncludeDeletedSubsystems(value)}
            />
          }
          label="Include Deleted"
        />
      </DetailContainer>
    ) : null}

    {subsystems ? (
      <SubsystemsTable subsystems={subsystems} onClickSubsystemRow={onClickSubsystem} />
    ) : !(loading || refreshing) ? (
      <NoDataError />
    ) : null}
  </Paper>
);
export default SubsystemsSection;
