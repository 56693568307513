import { gql } from '@apollo/client';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import AlternatingTableRow from '@paypr/mui5-common-components/dist/components/tables/AlternatingTableRow';
import EmptyTableRow from '@paypr/mui5-common-components/dist/components/tables/EmptyTableRow';
import HeightLimitedTableContainer from '@paypr/mui5-common-components/dist/components/tables/HeightLimitedTableContainer';
import {
  SimpleSortingTableHeadCell,
  SimpleSortingTableHeadProps,
} from '@paypr/mui5-common-components/dist/components/tables/SortingTableHead';
import { getComparator, stableSort, useSorting } from '@paypr/mui5-common-components/dist/components/tables/sortUtils';
import * as React from 'react';
import { OrderAssetsTableRow_OrderAsset, OrderAssetStatus, OrderAssetTriggerType } from '../../generated/graphql';
import { snakeOrKebabToDisplay } from '../../utils/strings';
import SimpleSortingTableHead from '../common/tables/SortingTableHead';
import FloatFormat from '../common/typography/FloatFormat';
import UsdFormat from '../common/typography/UsdFormat';

interface OrderAssetsTableProps {
  orderAssets: readonly OrderAssetsTableRow_OrderAsset[];
  onClickOrderAssetRow?: OrderAssetClickEventHandler;
}

export type OrderAssetClickEventHandler = (orderAssetId: string) => void;

const OrderAssetsTable = ({ orderAssets, onClickOrderAssetRow }: OrderAssetsTableProps) => {
  const { order, orderBy, handleRequestSort } = useSorting<OrderAssetsTableRow_OrderAsset>(
    'desiredCurrencyAmount',
    'desc',
  );

  const createClickHandler = (orderAssetId: string) => {
    if (!onClickOrderAssetRow) {
      return undefined;
    }
    return () => onClickOrderAssetRow(orderAssetId);
  };

  return (
    <HeightLimitedTableContainer>
      <Table stickyHeader>
        <OrderAssetsTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
        <TableBody>
          {orderAssets.length > 0 ? (
            stableSort(orderAssets, getComparator(order, orderBy)).map((orderAsset) => (
              <OrderAssetTableRow
                key={orderAsset.id}
                orderAsset={orderAsset}
                onRowClick={createClickHandler(orderAsset.id)}
              />
            ))
          ) : (
            <EmptyTableRow columnCount={columnHeadings.length}>No order assets were found.</EmptyTableRow>
          )}
        </TableBody>
      </Table>
    </HeightLimitedTableContainer>
  );
};
export default OrderAssetsTable;

type OrderAssetsTableHeadProps = Omit<SimpleSortingTableHeadProps<OrderAssetsTableRow_OrderAsset>, 'headings'>;

const columnHeadings: SimpleSortingTableHeadCell<OrderAssetsTableRow_OrderAsset>[] = [
  { key: 'asset', label: 'Asset' },
  { key: 'status', label: 'Status' },
  { key: 'desiredCurrencyAmount', label: 'Desired', align: 'right' },
  { key: 'filledCurrencyAmount', label: 'Filled', align: 'right' },
  { key: 'unfilledCurrencyAmount', label: 'Unfilled', align: 'right' },
  { key: 'desiredPrice', label: 'Desired Price', align: 'right' },
  { key: 'averagePrice', label: 'Average Price', align: 'right' },
  { key: 'feesPaid', label: 'Fees Paid', align: 'right' },
  { key: 'triggerType', label: 'Trigger', align: 'right' },
  { key: 'triggerPrice', label: 'Trigger Price', align: 'right' },
];

const OrderAssetsTableHead = (props: OrderAssetsTableHeadProps) => (
  <SimpleSortingTableHead {...props} headings={columnHeadings} />
);

type OrderAssetTableRowProps = {
  orderAsset: OrderAssetsTableRow_OrderAsset;
  onRowClick?: () => void;
};

export const OrderAssetTableRow = ({ orderAsset, onRowClick }: OrderAssetTableRowProps) => (
  <AlternatingTableRow hover={Boolean(onRowClick)} onClick={onRowClick}>
    <TableCell>{buildOrderAssetTitle(orderAsset.asset)}</TableCell>
    <TableCell>{buildOrderAssetStatusDisplay(orderAsset.status)}</TableCell>
    <TableCell align="right">
      <UsdFormat amount={orderAsset.desiredCurrencyAmount} />
      <br />
      <FloatFormat amount={orderAsset.desiredAssetAmount} asset={orderAsset.asset} />
    </TableCell>
    <TableCell align="right">
      <UsdFormat amount={orderAsset.filledCurrencyAmount} />
      <br />
      <FloatFormat amount={orderAsset.filledAssetAmount} asset={orderAsset.asset} />
    </TableCell>
    <TableCell align="right">
      <UsdFormat amount={orderAsset.unfilledCurrencyAmount} />
      <br />
      <FloatFormat amount={orderAsset.unfilledAssetAmount} asset={orderAsset.asset} />
    </TableCell>
    <TableCell align="right">
      <UsdFormat amount={orderAsset.desiredPrice} />
    </TableCell>
    <TableCell align="right">
      <UsdFormat amount={orderAsset.averagePrice} />
    </TableCell>
    <TableCell align="right">
      <UsdFormat amount={orderAsset.feesPaid} />
    </TableCell>
    <TableCell>{buildOrderAssetTriggerTypeDisplay(orderAsset.triggerType)}</TableCell>
    <TableCell align="right">
      <UsdFormat amount={orderAsset.triggerPrice} />
    </TableCell>
  </AlternatingTableRow>
);

const buildOrderAssetTitle = (asset: string) => asset;

const buildOrderAssetStatusDisplay = (status: OrderAssetStatus) => snakeOrKebabToDisplay(status);

const buildOrderAssetTriggerTypeDisplay = (triggerType: OrderAssetTriggerType | null) =>
  triggerType ? snakeOrKebabToDisplay(triggerType) : '';

export const orderAssetsTableRowFragment = gql`
  fragment OrderAssetsTableRow_OrderAsset on OrderAsset {
    id
    asset
    status
    desiredAssetAmount
    desiredCurrencyAmount
    filledAssetAmount
    filledCurrencyAmount
    unfilledAssetAmount
    unfilledCurrencyAmount
    desiredPrice
    averagePrice
    feesPaid
    triggerType
    triggerPrice
  }
`;
