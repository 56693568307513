import { css } from '@emotion/react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AlternatingTableRow from '@paypr/mui5-common-components/dist/components/tables/AlternatingTableRow';
import React from 'react';
import NorrioIcon from '../common/icons/NorrioIcon';
import { ReportPropertyType } from './reportHelpers';
import ReportTableCell from './ReportTableCell';

export interface GenericReportDataTableProps {
  report: any;
  timeSeries?: boolean;
}

const GenericReportDataTable = ({ report, timeSeries }: GenericReportDataTableProps) => (
  <Box
    css={css`
      max-height: 500px;
      overflow-y: scroll;
    `}
  >
    <Table stickyHeader>
      <TableHead>
        <ReportTableHeaderRow report={report} timeSeries={timeSeries} />
      </TableHead>
      <TableBody>
        {Object.keys(report[Object.keys(report)[0]]).map((property) => (
          <ReportTableRow key={property} report={report} propertyName={property} timeSeries={timeSeries} />
        ))}
      </TableBody>
    </Table>
  </Box>
);
export default GenericReportDataTable;

export interface ReportTableHeaderRowProps {
  report: any;
  timeSeries?: boolean;
}

export const ReportTableHeaderRow = ({ report, timeSeries }: ReportTableHeaderRowProps) => {
  const theme = useTheme();
  return (
    <TableRow>
      {!timeSeries ? (
        <TableCell>
          <NorrioIcon height={theme.spacing(4)} />
        </TableCell>
      ) : null}
      {Object.keys(report).map((series) => (
        <TableCell key={series} align="center">
          <strong>{extractPropertyName(series)}</strong>
        </TableCell>
      ))}
    </TableRow>
  );
};

export interface ReportTableRowProps {
  report: any;
  propertyName: string;
  timeSeries?: boolean;
  rowIndex?: number;
}

export const ReportTableRow = ({ report, propertyName, timeSeries, rowIndex }: ReportTableRowProps) => (
  <AlternatingTableRow rowIndex={rowIndex}>
    {!timeSeries ? (
      <TableCell>
        <strong>{extractPropertyName(propertyName)}</strong>
      </TableCell>
    ) : null}
    {Object.keys(report).map((series) => (
      <ReportTableCell
        key={series}
        propertyType={determinePropertyType(timeSeries ? series : propertyName)}
        value={report[series][propertyName]}
      />
    ))}
  </AlternatingTableRow>
);

const extractPropertyName = (propertyName: string) => {
  if (propertyName.indexOf('USD') >= 0) {
    return propertyName.replace(' USD', '');
  }
  return propertyName;
};

const determinePropertyType = (propertyName: string): ReportPropertyType => {
  if (propertyName.toLowerCase().indexOf('usd') >= 0) {
    return ReportPropertyType.Usd;
  }

  if (propertyName.indexOf('%') >= 0) {
    return ReportPropertyType.Percent;
  }

  if (propertyName.indexOf('#') >= 0) {
    return ReportPropertyType.Integer;
  }

  if (propertyName.toLowerCase().indexOf('bips') >= 0) {
    return ReportPropertyType.Bips;
  }

  if (propertyName.toLowerCase().indexOf('date/time') >= 0) {
    return ReportPropertyType.DateTime;
  }

  if (propertyName.toLowerCase().indexOf('date') >= 0) {
    return ReportPropertyType.Date;
  }

  if (propertyName.toLowerCase().indexOf('id') >= 0) {
    return ReportPropertyType.Id;
  }

  return ReportPropertyType.Unknown;
};
