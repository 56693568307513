import { gql } from '@apollo/client';
import NavItem from '@paypr/mui5-common-components/dist/components/page/nav/NavItem';
import React from 'react';
import { useFundNavItemsQuery } from '../../generated/graphql';
import FundIcon from './FundIcon';
import { buildFundPath } from './fundsPaths';

export interface FundNavItemsProps {
  disabled?: boolean;
}

const FundNavItems = ({ disabled }: FundNavItemsProps) => {
  const { data } = useFundNavItemsQuery();

  return (
    <>
      {data?.funds.map(({ id, name }) => (
        <NavItem key={id} title={name} icon={FundIcon} path={buildFundPath(id)} disabled={disabled} child />
      ))}
    </>
  );
};
export default FundNavItems;

gql`
  query FundNavItemsQuery {
    funds {
      id
      name
    }
  }
`;
