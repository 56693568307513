import { gql } from '@apollo/client';
import Grid from '@mui/material/Grid';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import DetailValue from '@paypr/mui5-common-components/dist/components/typography/DetailValue';
import * as React from 'react';
import { StrategyDetail_Strategy } from '../../generated/graphql';
import DetailGridItem from '../common/containers/DetailGridItem';
import ExplodingContainer from '../common/containers/ExplodingContainer';
import { buildTimeframeDisplay } from '../common/model/timeframe';
import PercentFormat from '../common/typography/PercentFormat';
import UsdFormat from '../common/typography/UsdFormat';
import { AddLedgerEventClickEventHandler } from '../ledgerEvents/LedgerEventsAccordion';
import { LedgerEventClickEventHandler } from '../ledgerEvents/LedgerEventsTable';
import PortfoliosSection from '../portfolios/PortfoliosSection';
import { PortfolioClickEventHandler, portfoliosTableRowFragment } from '../portfolios/PortfoliosTable';
import FinanceStatsDetail, { financeStatsDetailFragment } from '../stats/FinanceStatsDetail';
import StrategyDeletedNote, { strategyDeletedNoteFragment } from './StrategyDeletedNote';
import StrategyLedgerEventsSectionIntegration from './StrategyLedgerEventsSectionIntegration';
import StrategyReport from './StrategyReport';

export type StrategyDetailData = StrategyDetail_Strategy;

interface StrategyDetailProps {
  strategy: StrategyDetailData;
  onClickPortfolio?: PortfolioClickEventHandler;
  onClickLedgerEvent?: LedgerEventClickEventHandler;
  onClickAddLedgerEvent?: AddLedgerEventClickEventHandler;
  autoRefresh?: boolean | number;
}

const StrategyDetail = ({
  strategy,
  onClickPortfolio,
  onClickLedgerEvent,
  onClickAddLedgerEvent,
  autoRefresh,
}: StrategyDetailProps) => {
  return (
    <>
      <DetailContainer>
        <StrategyDeletedNote strategy={strategy} />

        <Grid container justifyContent="left" spacing={2}>
          <DetailGridItem size="full">
            <DetailField>
              <DetailTitle>Performance</DetailTitle>
              <StrategyReport strategyId={strategy.id} />
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Default Timeframe</DetailTitle>
              <DetailValue>{buildTimeframeDisplay(strategy.timeframe)}</DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Allocated Funds</DetailTitle>
              <DetailValue>
                <UsdFormat amount={strategy.stats.managedCurrency} />
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Net Exposure</DetailTitle>
              <DetailValue>
                <PercentFormat amount={strategy.stats.netExposure} />
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>All Time PnL</DetailTitle>
              <DetailValue>
                <UsdFormat amount={strategy.stats.pnlCumulativeValue} />
                <br />
                <PercentFormat amount={strategy.stats.pnlCumulativeRoi} />
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Realized Gains/Losses</DetailTitle>
              <DetailValue>
                <UsdFormat amount={strategy.stats.realizedGainsCumulativeValue} />
                <br />
                <PercentFormat amount={strategy.stats.realizedGainsCumulativeRoi} />
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Unrealized Gains/Losses</DetailTitle>
              <DetailValue>
                <UsdFormat amount={strategy.stats.unrealizedGainsCumulativeValue} />
                <br />
                <PercentFormat amount={strategy.stats.unrealizedGainsCumulativeRoi} />
              </DetailValue>
            </DetailField>
          </DetailGridItem>
          <DetailGridItem size="half">
            <FinanceStatsDetail stats={strategy.stats} />
          </DetailGridItem>
        </Grid>
      </DetailContainer>
      <br />
      <PortfoliosSection portfolios={strategy?.portfolios} onClickPortfolio={onClickPortfolio} hideStrategy />
      <br />

      <ExplodingContainer
        renderComponent={(exploded, onExplodeChanged) => (
          <StrategyLedgerEventsSectionIntegration
            strategyId={strategy.id}
            onClickLedgerEvent={onClickLedgerEvent}
            onClickAddLedgerEvent={onClickAddLedgerEvent}
            exploded={exploded}
            onExplodeChanged={onExplodeChanged}
            autoRefresh={autoRefresh}
          />
        )}
      />
    </>
  );
};
export default StrategyDetail;

export const buildStrategyTitle = (name?: string) => (name ? name : 'Strategy');

export const strategyDetailFragment = gql`
  fragment StrategyDetail_Strategy on Strategy {
    id
    name
    timeframe
    createdAt

    stats {
      managedCurrency
      netExposure
      pnlCumulativeRoi
      pnlCumulativeValue
      realizedGainsCumulativeRoi
      realizedGainsCumulativeValue
      unrealizedGainsCumulativeRoi
      unrealizedGainsCumulativeValue
      ...FinanceStatsDetail_Stats
    }

    portfolios {
      id
      ...PortfoliosTableRow_Portfolio
    }

    ...StrategyDeletedNote_Strategy
  }

  ${strategyDeletedNoteFragment}
  ${financeStatsDetailFragment}
  ${portfoliosTableRowFragment}
`;
