// audit actions
export const auditActionsPath = '/activity';

export interface AuditActionsPageQueryParams {
  includeDeleted: string;
}

export const buildAuditActionPath = (auditActionId: string) => `${auditActionsPath}/${auditActionId}`;

export const parameterizedAuditActionPath = buildAuditActionPath(':auditActionId');
export interface AuditActionPageParams {
  auditActionId: string;
}
