import { gql } from '@apollo/client';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import RouteLink from '@paypr/mui5-common-components/dist/components/routes/RouteLink';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import * as React from 'react';
import { ApprovalDecisionSection_Approval } from '../../generated/graphql';
import { buildWorkflowPath } from '../workflows/workflowPaths';
import { buildWorkflowReferenceTitle, workflowReferenceFragment } from '../workflows/workflowUtils';
import ApprovalDecision, { approvalDecisionFragment } from './ApprovalDecision';
import ApprovalIcon from './ApprovalIcon';
import { buildApprovalPath } from './approvalPaths';
import { buildApprovalTitle } from './approvalUtils';

interface ApprovalSectionProps extends RefreshingComponentProps {
  approval?: ApprovalDecisionSection_Approval;
  onApproval?: (approvalId: string, approved: boolean) => void;
  approving?: boolean;
  submittedApproval?: boolean;
}

const ApprovalDecisionSection = ({
  approval,
  onApproval,
  approving,
  submittedApproval,
  loading,
  refreshing,
  onRefresh,
  autoRefresh,
}: ApprovalSectionProps) => (
  <Paper>
    <Toolbar>
      <ApprovalIcon />
      &nbsp;
      <SectionTitle>
        {approval ? (
          <>
            <RouteLink path={buildWorkflowPath(approval.workflow.id)}>
              {buildWorkflowReferenceTitle(approval.workflow)}
            </RouteLink>{' '}
            - <RouteLink path={buildApprovalPath(approval.id)}>Approval</RouteLink>
          </>
        ) : (
          buildApprovalTitle()
        )}
      </SectionTitle>
      <HorizontalAutoSpacer />
      <RefreshIconButton
        refreshing={refreshing}
        loading={loading}
        onRefresh={onRefresh}
        autoRefresh={autoRefresh}
        includeRefreshText
      />
    </Toolbar>

    {approval ? (
      <ApprovalDecision
        approval={approval}
        onApproval={onApproval}
        approving={approving}
        submittedApproval={submittedApproval}
        disabled={loading}
      />
    ) : null}
  </Paper>
);
export default ApprovalDecisionSection;

export const approvalDecisionSectionFragment = gql`
  fragment ApprovalDecisionSection_Approval on Approval {
    id
    workflow {
      id
      ...WorkflowReference_Workflow
    }
    ...ApprovalDecision_Approval
  }
  ${approvalDecisionFragment}
  ${workflowReferenceFragment}
`;
