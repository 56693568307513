import { gql } from '@apollo/client';
import NoteBlock from '@paypr/mui5-common-components/dist/components/typography/NoteBlock';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { InternalUserDisabledNote_InternalUser } from '../../generated/graphql';

interface InternalUserDisabledNoteProps {
  internalUser: InternalUserDisabledNote_InternalUser;
}

const InternalUserDisabledNote = ({ internalUser: { disabledAt } }: InternalUserDisabledNoteProps) => {
  const { formatDateTime } = useDateTime();

  if (!disabledAt) {
    return null;
  }

  return (
    <NoteBlock>
      <em>The Admin Tools Login for this admin user was disabled on {formatDateTime(disabledAt)}</em>
    </NoteBlock>
  );
};
export default InternalUserDisabledNote;

export const internalUserDisabledNoteFragment = gql`
  fragment InternalUserDisabledNote_InternalUser on InternalUser {
    disabledAt
  }
`;
