import Grid from '@mui/material/Grid';
import React from 'react';

interface WidePageContainerProps {
  children: React.ReactNode;
}

const WidePageContainer = ({ children, ...rest }: WidePageContainerProps) => (
  <Grid {...rest} container justifyContent="center" spacing={2}>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} children={children} />
  </Grid>
);
export default WidePageContainer;
