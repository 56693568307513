import { gql } from '@apollo/client';

gql`
  query DshStrategyAllocationsDataEstimateQuery($input: DshAllocationsEstimateInput!) {
    dshAllocationsEstimate(input: $input) {
      ...UpdateDshStrategyAllocations_DshAllocationEstimate
    }
  }
`;

export const updateDshStrategyAllocationsEstimateFragment = gql`
  fragment UpdateDshStrategyAllocations_DshAllocationEstimate on DshAllocationsEstimate {
    desiredTotalAmount
    desiredTotalPercent
    estimatedTotalAmount
    estimatedTotalPercent
    varianceAmount
    variancePercent
    assets {
      ...UpdateDshStrategyAllocations_DshAllocationEstimateAsset
    }
  }

  fragment UpdateDshStrategyAllocations_DshAllocationEstimateAsset on DshAllocationsEstimateAsset {
    asset
    desiredPercent
    desiredMaxSlots
    desiredSlotInvestmentSize
    numCoreSlots
    hedgeSlotFactor
    dipSlotFactor
    slotFactor
    idealAmount
    idealSlotInvestmentSize
    estimatedAmount
    estimatedPercent
    varianceAmount
    variancePercent
  }
`;

export const updateDshStrategyAllocationsWorkflowDetailsFragment = gql`
  fragment UpdateDshStrategyAllocations_WorkflowDetails on UpdateDshAllocationsWorkflowDetails {
    oldTotalAmount
    oldTotalPercent
    desiredTotalAmount
    desiredTotalPercent
    estimatedTotalAmount
    estimatedTotalPercent
    varianceAmount
    variancePercent
    assets {
      ...UpdateDshStrategyAllocations_AssetWorkflowDetails
    }
  }

  fragment UpdateDshStrategyAllocations_AssetWorkflowDetails on UpdateDshAllocationsAssetWorkflowDetails {
    asset
    oldAmount
    oldPercent
    oldMaxSlots
    oldSlotInvestmentSize
    desiredPercent
    desiredMaxSlots
    desiredSlotInvestmentSize
    numCoreSlots
    hedgeSlotFactor
    dipSlotFactor
    slotFactor
    idealAmount
    idealSlotInvestmentSize
    estimatedAmount
    estimatedPercent
    varianceAmount
    variancePercent
  }
`;
