import { gql } from '@apollo/client';
import NavItem, { NavItemProps } from '@paypr/mui5-common-components/dist/components/page/nav/NavItem';
import * as React from 'react';
import { useMatch } from 'react-router-dom';
import { useAuditActionNavItemQuery } from '../../generated/graphql';
import { buildAuditActionTitle } from './AuditActionDetail';
import { AuditActionPageParams } from './auditActionPaths';

type AuditActionNavItemProps = Omit<NavItemProps, 'title'>;

const AuditActionNavItem = ({ path, ...rest }: AuditActionNavItemProps) => {
  const match = useMatch<keyof AuditActionPageParams, typeof path>(path);

  const extractAuditActionId = (): string | null => {
    return match?.params?.auditActionId || null;
  };

  const auditActionId = extractAuditActionId();

  const { data } = useAuditActionNavItemQuery({
    variables: auditActionId ? { auditActionId } : undefined,
    skip: !auditActionId || !match,
  });

  const title = buildAuditActionTitle(data?.auditAction?.subsystem?.label, data?.auditAction?.type);

  return <NavItem {...rest} title={title} path={path} />;
};
export default AuditActionNavItem;

gql`
  query AuditActionNavItemQuery($auditActionId: ID!) {
    auditAction(id: $auditActionId) {
      id
      subsystem {
        id
        label
      }
      type
    }
  }
`;
