import { gql } from '@apollo/client';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import * as React from 'react';
import { OrderSection_Order } from '../../generated/graphql';
import { TradeClickEventHandler } from '../trades/TradesTable';
import { OrderAssetClickEventHandler } from './OrderAssetsTable';
import OrderDetail, { buildOrderTitle, orderDetailFragment } from './OrderDetail';
import OrderIcon from './OrderIcon';

interface OrderSectionProps extends RefreshingComponentProps {
  order?: OrderSection_Order;
  onClickOrderAsset?: OrderAssetClickEventHandler;
  onClickTrade?: TradeClickEventHandler;
}

const OrderSection = ({
  order,
  loading,
  refreshing,
  onRefresh,
  autoRefresh,
  onClickOrderAsset,
  onClickTrade,
}: OrderSectionProps) => (
  <Paper>
    <Toolbar>
      <OrderIcon />
      &nbsp;
      <SectionTitle>{buildOrderTitle(order?.id, order?.portfolio.name)}</SectionTitle>
      <HorizontalAutoSpacer />
      <RefreshIconButton
        refreshing={refreshing}
        loading={loading}
        onRefresh={onRefresh}
        autoRefresh={autoRefresh}
        includeRefreshText
      />
    </Toolbar>

    {order ? <OrderDetail order={order} onClickOrderAsset={onClickOrderAsset} onClickTrade={onClickTrade} /> : null}
  </Paper>
);
export default OrderSection;

export const orderSectionFragment = gql`
  fragment OrderSection_Order on Order {
    id
    portfolio {
      id
      name
    }
    ...OrderDetail_Order
  }
  ${orderDetailFragment}
`;
