import { gql, NetworkStatus } from '@apollo/client';
import React from 'react';
import { usePortfolioLedgerEventsSectionQueryLazyQuery } from '../../generated/graphql';
import ContentError from '../common/content/ContentError';
import { ExplodingComponentProps } from '../common/icons/ExplodeIconButton';
import LedgerEventsAccordion, { AddLedgerEventClickEventHandler } from '../ledgerEvents/LedgerEventsAccordion';
import { LedgerEventClickEventHandler, ledgerEventsTableRowFragment } from '../ledgerEvents/LedgerEventsTable';

export interface PortfolioLedgerEventsSectionIntegrationProps extends ExplodingComponentProps {
  portfolioId: string;
  onClickLedgerEvent?: LedgerEventClickEventHandler;
  onClickAddLedgerEvent?: AddLedgerEventClickEventHandler;
  defaultExpanded?: boolean;
  autoRefresh?: boolean | number;
}

const PortfolioLedgerEventsSectionIntegration = ({
  portfolioId,
  onClickLedgerEvent,
  onClickAddLedgerEvent,
  defaultExpanded,
  autoRefresh,
  exploded,
  onExplodeChanged,
}: PortfolioLedgerEventsSectionIntegrationProps) => {
  const [fetchPortfolioLedgerEvents, { data, error, refetch, networkStatus, loading }] =
    usePortfolioLedgerEventsSectionQueryLazyQuery({
      fetchPolicy: 'cache-and-network',
      variables: { portfolioId },
    });

  if (error) {
    return <ContentError message="Error loading portfolio events" error={error} />;
  }

  const portfolio = data?.portfolio;

  const handleRefresh = async () => {
    if (portfolio) {
      await refetch();
    } else {
      await fetchPortfolioLedgerEvents();
    }
  };

  return (
    <LedgerEventsAccordion
      ledgerEvents={portfolio?.ledgerEvents}
      onClickLedgerEvent={onClickLedgerEvent}
      onClickAddLedgerEvent={onClickAddLedgerEvent}
      defaultExpanded={defaultExpanded}
      exploded={exploded}
      onExplodeChanged={onExplodeChanged}
      refreshing={networkStatus === NetworkStatus.refetch}
      loading={loading}
      onRefresh={handleRefresh}
      autoRefresh={autoRefresh}
    />
  );
};
export default PortfolioLedgerEventsSectionIntegration;

gql`
  query PortfolioLedgerEventsSectionQuery($portfolioId: ID!) {
    portfolio(id: $portfolioId) {
      id

      ledgerEvents {
        ...LedgerEventsTableRow_LedgerAccountEvent
      }
    }
  }

  ${ledgerEventsTableRowFragment}
`;
