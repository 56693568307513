import { gql, NetworkStatus } from '@apollo/client';
import React from 'react';
import { useClientTransactionsSectionIntegrationQueryLazyQuery } from '../../generated/graphql';
import { ExplodingComponentProps } from '../common/icons/ExplodeIconButton';
import ClientTransactionsAccordion from './transactions/ClientTransactionsAccordion';
import {
  ClientTransactionClickEventHandler,
  clientTransactionsTableRowFragment,
} from './transactions/ClientTransactionsTable';

export interface ClientTransactionsSectionIntegrationProps extends ExplodingComponentProps {
  clientId: string;
  onClickClientTransaction?: ClientTransactionClickEventHandler;
  defaultExpanded?: boolean;
  autoRefresh?: boolean | number;
}

const ClientTransactionsSectionIntegration = ({
  clientId,
  onClickClientTransaction,
  defaultExpanded,
  autoRefresh,
  exploded,
  onExplodeChanged,
}: ClientTransactionsSectionIntegrationProps) => {
  const [fetchTransactions, { data, error, refetch, networkStatus, loading }] =
    useClientTransactionsSectionIntegrationQueryLazyQuery({
      fetchPolicy: 'cache-and-network',
      variables: { clientId },
    });

  const client = data?.client;

  const handleRefresh = async () => {
    if (client) {
      await refetch();
    } else {
      await fetchTransactions();
    }
  };

  return (
    <ClientTransactionsAccordion
      clientTransactions={client?.transactions}
      hideClient
      onClickClientTransaction={onClickClientTransaction}
      defaultExpanded={defaultExpanded}
      exploded={exploded}
      onExplodeChanged={onExplodeChanged}
      refreshing={networkStatus === NetworkStatus.refetch}
      loading={loading}
      onRefresh={handleRefresh}
      autoRefresh={autoRefresh}
      error={error}
    />
  );
};
export default ClientTransactionsSectionIntegration;

gql`
  query ClientTransactionsSectionIntegrationQuery($clientId: ID!) {
    client(id: $clientId) {
      id

      transactions {
        id
        ...ClientTransactionsTableRow_ClientTransaction
      }
    }
  }

  ${clientTransactionsTableRowFragment}
`;
