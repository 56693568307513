import { gql } from '@apollo/client';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import NumberField from '@paypr/mui5-common-components/dist/components/forms/NumberField';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailError from '@paypr/mui5-common-components/dist/components/typography/DetailError';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailInlineTitle from '@paypr/mui5-common-components/dist/components/typography/DetailInlineTitle';
import DetailInlineValue from '@paypr/mui5-common-components/dist/components/typography/DetailInlineValue';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import DetailValue from '@paypr/mui5-common-components/dist/components/typography/DetailValue';
import SubSectionTitle from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import React, { useEffect } from 'react';
import {
  DshStrategyAllocationsEditor_DshAsset,
  DshStrategyAllocationsEditor_DshVersion,
  UpdateDshStrategyAllocations_DshAllocationEstimate,
  UpdateDshStrategyAllocations_DshAllocationEstimateAsset,
} from '../../../generated/graphql';
import { FormFieldChangeEventHandler, useFormFields } from '../../../hooks/forms';
import { isTextPositiveFloat, isTextPositiveFloatOrZero, isTextPositiveInt } from '../../../utils/strings';
import DetailGridItem from '../../common/containers/DetailGridItem';
import IntegerFormat from '../../common/typography/IntegerFormat';
import PercentFormat, { formatPercent } from '../../common/typography/PercentFormat';
import UsdFormat from '../../common/typography/UsdFormat';

export interface UpdateDshStrategyAllocationsEditorProps {
  dshVersion: DshStrategyAllocationsEditor_DshVersion;
  assetFields: UpdateDshStrategyAllocationsAssetFields[];
  estimate?: UpdateDshStrategyAllocations_DshAllocationEstimate;
  fields: UpdateDshStrategyAllocationsForm;
  handleFieldChange: FormFieldChangeEventHandler;
  onUpdateAssetFields: UpdateDshStrategyAllocationsAssetEventHandler;
}

export type UpdateDshStrategyAllocationsAssetEventHandler = (
  asset: string,
  fields: UpdateDshStrategyAllocationsAssetFields,
) => void;

export interface UpdateDshStrategyAllocationsForm {
  totalAmount: string;
}

const UpdateDshStrategyAllocationsEditor = ({
  dshVersion,
  assetFields,
  estimate,
  fields,
  handleFieldChange,
  onUpdateAssetFields,
}: UpdateDshStrategyAllocationsEditorProps) => {
  const totalAmount = dshVersion.assets.reduce(
    (total, asset) => total + (asset.config ? asset.config.allocationAmount : 0),
    0,
  );
  const totalPercent = Object.values(assetFields).reduce(
    (total, asset) =>
      total + (isTextPositiveFloatOrZero(asset.assetPercent) ? parseFloat(asset.assetPercent) / 100 : 0),
    0,
  );

  return (
    <DetailContainer>
      <Grid container justifyContent="left" spacing={2}>
        <DetailGridItem size="full">
          <DetailField>
            <SubSectionTitle>Allocated Funds</SubSectionTitle>

            <Box>
              <DetailInlineTitle>Current</DetailInlineTitle>
              <DetailInlineValue>
                <UsdFormat amount={dshVersion.portfolio.managedCurrencyAmount} />
              </DetailInlineValue>
            </Box>

            <DetailValue>
              <NumberField
                name="totalAmount"
                label="Desired $"
                autoComplete="off"
                value={fields.totalAmount}
                min={0}
                required
                autoFocus
                onChange={handleFieldChange}
              />
              {!isTextPositiveFloat(fields.totalAmount) ? (
                <DetailError>Desired Total Amount must be greater than 0</DetailError>
              ) : null}
            </DetailValue>

            {estimate && (
              <>
                <Box>
                  <DetailInlineTitle>Estimated</DetailInlineTitle>
                  <DetailInlineValue>
                    <UsdFormat amount={estimate.estimatedTotalAmount} />
                  </DetailInlineValue>
                </Box>
                <Box>
                  <DetailInlineTitle>Variance</DetailInlineTitle>
                  <DetailInlineValue>
                    <UsdFormat amount={estimate.varianceAmount} /> (
                    <PercentFormat amount={estimate.variancePercent} />)
                  </DetailInlineValue>
                </Box>
              </>
            )}

            {totalPercent < 0.99 || totalPercent > 1.01 ? (
              <DetailError>
                Desired Total Percent must be between 99% and 101% (currently {formatPercent(totalPercent)})
              </DetailError>
            ) : null}
          </DetailField>
        </DetailGridItem>
        {dshVersion.assets.map((asset) => (
          <AssetAllocationEditor
            key={asset.asset}
            asset={asset}
            assetFields={assetFields.find((a) => a.asset == asset.asset)!!}
            totalAmount={totalAmount}
            estimate={estimate?.assets?.find((a) => a.asset == asset.asset)}
            onUpdateAsset={onUpdateAssetFields}
          />
        ))}
        <DetailGridItem size="full">
          <code hidden>{JSON.stringify({ fields, assetFields })}</code>
        </DetailGridItem>
      </Grid>
    </DetailContainer>
  );
};
export default UpdateDshStrategyAllocationsEditor;

interface AssetAllocationEditorProps {
  asset: DshStrategyAllocationsEditor_DshAsset;
  assetFields: UpdateDshStrategyAllocationsAssetFields;
  totalAmount: number;
  onUpdateAsset: (asset: string, formFields: UpdateDshStrategyAllocationsAssetFields) => void;
  estimate?: UpdateDshStrategyAllocations_DshAllocationEstimateAsset;
}

export interface UpdateDshStrategyAllocationsAssetFields extends UpdateDshStrategyAllocationsAssetForm {
  asset: string;
}

export interface UpdateDshStrategyAllocationsAssetForm {
  assetPercent: string;
  maxSlots: string;
  slotInvestmentSize: string;
}

const AssetAllocationEditor = ({
  asset,
  assetFields,
  totalAmount,
  estimate,
  onUpdateAsset,
}: AssetAllocationEditorProps) => {
  const { fields, handleFieldChange } = useFormFields<UpdateDshStrategyAllocationsAssetForm>({
    assetPercent: assetFields.assetPercent,
    maxSlots: assetFields.maxSlots,
    slotInvestmentSize: assetFields.slotInvestmentSize,
  });

  useEffect(() => {
    onUpdateAsset(asset.asset, { asset: asset.asset, ...fields });
  }, [fields]);

  if (!asset.config) {
    return null;
  }

  return (
    <DetailGridItem>
      <SubSectionTitle>{asset.asset}</SubSectionTitle>
      <DetailField>
        <DetailTitle>Asset Percent</DetailTitle>
        <Box>
          <DetailInlineTitle>Current</DetailInlineTitle>
          <DetailInlineValue>
            {totalAmount > 0 ? <PercentFormat amount={asset.config.allocationAmount / totalAmount} /> : 'n/a'}
          </DetailInlineValue>
        </Box>
        <DetailField>
          <NumberField
            name="assetPercent"
            label="Desired %"
            autoComplete="off"
            value={fields.assetPercent}
            min={0}
            required
            onChange={handleFieldChange}
          />
          {!isTextPositiveFloat(fields.assetPercent) || parseFloat(fields.assetPercent) >= 100 ? (
            <DetailError>Desired Percent must be greater than 0 and less than 100</DetailError>
          ) : null}
        </DetailField>
      </DetailField>
      <DetailField>
        <DetailTitle>Max Slots</DetailTitle>
        <Box>
          <DetailInlineTitle>Current</DetailInlineTitle>
          <DetailInlineValue>
            <IntegerFormat amount={asset.config.maxSlots} />
          </DetailInlineValue>
        </Box>
        <DetailField>
          <NumberField
            name="maxSlots"
            label="Max Slots"
            autoComplete="off"
            value={fields.maxSlots}
            min={0}
            required
            onChange={handleFieldChange}
          />
          {!isTextPositiveInt(fields.maxSlots) || parseInt(fields.maxSlots, 10) <= 2 ? (
            <DetailError>Max Slots must be greater than 2</DetailError>
          ) : null}
        </DetailField>
      </DetailField>
      <DetailField>
        <DetailTitle>Slot Investment Size</DetailTitle>
        <Box>
          <DetailInlineTitle>Current</DetailInlineTitle>
          <DetailInlineValue>
            <UsdFormat amount={asset.config.slotInvestmentSize} />
          </DetailInlineValue>
        </Box>
        <DetailField>
          <NumberField
            name="slotInvestmentSize"
            label="Slot Investment Size"
            autoComplete="off"
            value={fields.slotInvestmentSize}
            min={0}
            required
            onChange={handleFieldChange}
          />
          {!isTextPositiveInt(fields.slotInvestmentSize) ? (
            <DetailError>Slot Investment Size must be greater than 0</DetailError>
          ) : null}
          {estimate && (
            <>
              <Box>
                <DetailInlineTitle>Ideal</DetailInlineTitle>
                <DetailInlineValue>
                  <UsdFormat amount={estimate.idealSlotInvestmentSize} />
                </DetailInlineValue>
              </Box>
            </>
          )}
        </DetailField>
      </DetailField>
      <DetailField>
        <DetailTitle>Allocation Amount</DetailTitle>
        <Box>
          <DetailInlineTitle>Current</DetailInlineTitle>
          <DetailInlineValue>
            <UsdFormat amount={asset.config.allocationAmount} />
          </DetailInlineValue>
        </Box>
        {estimate && (
          <>
            <Box>
              <DetailInlineTitle>Estimated</DetailInlineTitle>
              <DetailInlineValue>
                <UsdFormat amount={estimate.estimatedAmount} /> (
                <PercentFormat amount={estimate.estimatedPercent} />)
              </DetailInlineValue>
            </Box>
            <Box>
              <DetailInlineTitle>Ideal</DetailInlineTitle>
              <DetailInlineValue>
                <UsdFormat amount={estimate.idealAmount} />
              </DetailInlineValue>
            </Box>
            <Box>
              <DetailInlineTitle>Variance</DetailInlineTitle>
              <DetailInlineValue>
                <UsdFormat amount={estimate.varianceAmount} /> (
                <PercentFormat amount={estimate.variancePercent} />)
              </DetailInlineValue>
            </Box>
          </>
        )}
      </DetailField>
    </DetailGridItem>
  );
};

export const dshStrategyAllocationsEditorDshVersionFragment = gql`
  fragment DshStrategyAllocationsEditor_DshVersion on DshVersion {
    id
    portfolio {
      id
      name
      managedCurrencyAmount
    }
    assets {
      ...DshStrategyAllocationsEditor_DshAsset
    }
    active
  }

  fragment DshStrategyAllocationsEditor_DshAsset on DshAsset {
    asset
    config {
      maxSlots
      slotInvestmentSize
      allocationAmount
    }
  }
`;
