import { gql } from '@apollo/client';
import PageContainer from '@paypr/mui5-common-components/dist/components/page/PageContainer';
import { useSnackbar } from 'notistack';
import React from 'react';
import AppPage from '../../components/common/page/AppPage';
import AppPageError from '../../components/common/page/AppPageError';
import AppPageMessage from '../../components/common/page/AppPageMessage';
import AddStrategyLedgerEventForm from '../../components/strategies/ledgerEvents/AddStrategyLedgerEventForm';
import { buildStrategyTitle } from '../../components/strategies/StrategyDetail';
import { buildStrategyPath, StrategyLedgerEventPageParams } from '../../components/strategies/strategyPaths';
import {
  StrategyLedgerEventInput,
  useAddStrategyLedgerEventPageMutation,
  useAddStrategyLedgerEventPageQuery,
} from '../../generated/graphql';
import { useErrors } from '../../hooks/errors';
import { usePageParams, usePageTitle } from '../../hooks/page';
import { usePaths } from '../../hooks/paths';

const AddStrategyLedgerEventPage = () => {
  const { strategyId } = usePageParams<keyof StrategyLedgerEventPageParams>();
  const { navigateTo } = usePaths();
  const { enqueueSnackbar } = useSnackbar();
  const { showError } = useErrors();

  const { data, error, loading } = useAddStrategyLedgerEventPageQuery({
    fetchPolicy: 'cache-and-network',
    variables: { strategyId },
  });

  const [addStrategyLedgerEvent, { loading: updating }] = useAddStrategyLedgerEventPageMutation();

  usePageTitle(`Add Strategy Event - ${buildStrategyTitle(data?.strategy?.name)}`);

  if (error) {
    return <AppPageError error={error} />;
  }

  const strategy = data?.strategy;

  if (!strategy) {
    return <AppPageMessage text={`Strategy ${strategyId} does not exist`} />;
  }

  const handleAddStrategyLedgerEvent = async (newStrategyLedgerEvent: StrategyLedgerEventInput) => {
    if (!confirm(`Are you sure you want to add the event to ${buildStrategyTitle(strategy.name)}?`)) {
      return;
    }

    try {
      await addStrategyLedgerEvent({
        variables: {
          input: newStrategyLedgerEvent,
        },
      });
      enqueueSnackbar('Event added successfully.', { variant: 'success' });

      navigateTo(buildStrategyPath(strategyId));
    } catch (e) {
      showError(e);
    }
  };

  return (
    <AppPage>
      <PageContainer>
        <AddStrategyLedgerEventForm
          strategyId={strategy.id}
          strategyName={strategy.name}
          onAddStrategyLedgerEvent={handleAddStrategyLedgerEvent}
          loading={loading || updating}
        />
      </PageContainer>
    </AppPage>
  );
};
export default AddStrategyLedgerEventPage;

gql`
  query AddStrategyLedgerEventPageQuery($strategyId: ID!) {
    strategy(id: $strategyId) {
      id
      name
    }
  }

  mutation AddStrategyLedgerEventPageMutation($input: StrategyLedgerEventInput!) {
    createStrategyLedgerEvent(input: $input) {
      id
    }
  }
`;
