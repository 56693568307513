import { gql } from '@apollo/client';
import NoteBlock from '@paypr/mui5-common-components/dist/components/typography/NoteBlock';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { ClientDisabledNote_Client } from '../../generated/graphql';

interface ClientDisabledNoteProps {
  client: ClientDisabledNote_Client;
}

const ClientDisabledNote = ({ client }: ClientDisabledNoteProps) => {
  const { formatDateTime } = useDateTime();

  const disabledAt = client.user?.disabledAt;

  if (!disabledAt) {
    return null;
  }

  return (
    <NoteBlock>
      <em>The LP Portal Login for this LP was disabled on {formatDateTime(disabledAt)}</em>
    </NoteBlock>
  );
};
export default ClientDisabledNote;

export const clientDisabledNoteFragment = gql`
  fragment ClientDisabledNote_Client on Client {
    user {
      id
      disabledAt
    }
  }
`;
