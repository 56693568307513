import { css } from '@emotion/react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SubSectionTitle from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import React, { useEffect, useState } from 'react';
import { AuditActionsTableData_AuditActionConnection } from '../../generated/graphql';
import ExpandIcon from '../common/icons/ExpandIcon';
import ExplodeIconButton, { ExplodingComponentProps } from '../common/icons/ExplodeIconButton';
import NoDataError from '../common/page/NoDataError';
import AuditActionsTable, { AuditActionClickEventHandler } from './AuditActionsTable';

export interface AuditActionsAccordionProps extends ExplodingComponentProps, RefreshingComponentProps {
  auditActions?: AuditActionsTableData_AuditActionConnection;
  onClickAuditAction?: AuditActionClickEventHandler;
  defaultExpanded?: boolean;
}

const AuditActionsAccordion = ({
  auditActions,
  onClickAuditAction,
  defaultExpanded,
  exploded,
  onExplodeChanged,
  loading,
  refreshing,
  onRefresh,
  autoRefresh,
}: AuditActionsAccordionProps) => {
  const [expanded, setExpanded] = useState(exploded ? true : defaultExpanded);

  useEffect(() => {
    if (!expanded) {
      return;
    }

    onRefresh?.();
  }, [expanded]);

  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      expanded={expanded}
      onChange={(event, expanded) => setExpanded(expanded)}
    >
      <AccordionSummary expandIcon={!exploded ? <ExpandIcon /> : undefined}>
        <Toolbar
          css={css`
            display: flex;
            flex-grow: 1;
          `}
        >
          <SubSectionTitle>Activity</SubSectionTitle>
          <HorizontalAutoSpacer />
          <RefreshIconButton
            refreshing={refreshing}
            loading={loading}
            onRefresh={expanded ? onRefresh : undefined}
            includeRefreshText
            autoRefresh={autoRefresh}
          />
          <ExplodeIconButton exploded={exploded} onExplodeChanged={onExplodeChanged} />
        </Toolbar>
      </AccordionSummary>
      <AccordionDetails>
        {auditActions ? (
          <AuditActionsTable
            auditActionConnection={auditActions}
            onClickAuditActionRow={onClickAuditAction}
            fullHeight={exploded}
          />
        ) : !(loading || refreshing) ? (
          <NoDataError />
        ) : null}
      </AccordionDetails>
    </Accordion>
  );
};
export default AuditActionsAccordion;
