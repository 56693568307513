import { gql } from '@apollo/client';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import PauseIcon from '@paypr/mui5-common-components/dist/components/actions/PauseIcon';
import AlternatingTableRow from '@paypr/mui5-common-components/dist/components/tables/AlternatingTableRow';
import EmptyTableRow from '@paypr/mui5-common-components/dist/components/tables/EmptyTableRow';
import {
  SimpleSortingTableHeadCell,
  SimpleSortingTableHeadProps,
} from '@paypr/mui5-common-components/dist/components/tables/SortingTableHead';
import { getComparator, stableSort, useSorting } from '@paypr/mui5-common-components/dist/components/tables/sortUtils';
import TooltipItem from '@paypr/mui5-common-components/dist/components/typography/TooltipItem';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { PortfolioAssetsTableRow_PortfolioAsset, PortfolioAssetTable_AssetChange } from '../../generated/graphql';
import ArrowDownIcon from '../common/icons/ArrowDownIcon';
import ArrowUpIcon from '../common/icons/ArrowUpIcon';
import DeletedIcon from '../common/icons/DeletedIcon';
import HeightLimitedTableContainer from '../common/tables/HeightLimitedTableContainer';
import SimpleSortingTableHead from '../common/tables/SortingTableHead';
import FloatFormat from '../common/typography/FloatFormat';
import PercentFormat from '../common/typography/PercentFormat';
import UsdFormat from '../common/typography/UsdFormat';
import { useChartColors } from '../reports/chartHooks';

export interface PortfolioAssetsTableProps {
  selection: PortfolioAssetsTableSelection;
  portfolioAssets: readonly PortfolioAssetsTableRow_PortfolioAsset[];
  onClickPortfolioAssetRow?: PortfolioAssetClickEventHandler;
  fullHeight?: boolean;
}

export enum PortfolioAssetsTableSelection {
  Performance = 'performance',
  Price = 'price',
}

export type PortfolioAssetClickEventHandler = (portfolioAssetId: string) => void;

interface PortfolioAssetsTableRowData extends PortfolioAssetsTableRow_PortfolioAsset {
  asset: string;
  desiredDistance: number | null;
  filledSize: number | null;
  currentPrice: number;
  fifteenMinutePercentChange: number | null;
  hourPercentChange: number;
  dayPercentChange: number;
  weekPercentChange: number;
}

const PortfolioAssetsTable = ({
  selection,
  portfolioAssets,
  onClickPortfolioAssetRow,
  fullHeight,
}: PortfolioAssetsTableProps) => {
  const { order, orderBy, handleRequestSort } = useSorting<PortfolioAssetsTableRowData>('filledSize', 'desc');

  const createClickHandler = (portfolioAssetId: string) => {
    if (!onClickPortfolioAssetRow) {
      return undefined;
    }
    return () => onClickPortfolioAssetRow(portfolioAssetId);
  };

  const portfolioAssetList: PortfolioAssetsTableRowData[] = portfolioAssets.map((portfolioAsset) => {
    const changes = selection == PortfolioAssetsTableSelection.Performance ? portfolioAsset.pnl : portfolioAsset.prices;

    return {
      ...portfolioAsset,
      asset: portfolioAsset.asset,
      desiredDistance:
        portfolioAsset.desiredPercent !== null && portfolioAsset.filledPercent !== null
          ? Math.abs(portfolioAsset.desiredPercent - portfolioAsset.filledPercent)
          : null,
      filledSize: portfolioAsset.filledPercent !== null ? Math.abs(portfolioAsset.filledPercent) : null,
      currentPrice: portfolioAsset.prices.currentPrice,
      fifteenMinutePercentChange: 'fifteenMinutes' in changes ? changes.fifteenMinutes.percentChange : null,
      hourPercentChange: changes.hour.percentChange,
      dayPercentChange: changes.day.percentChange,
      weekPercentChange: changes.week.percentChange,
    };
  });

  return (
    <HeightLimitedTableContainer fullHeight={fullHeight}>
      <Table stickyHeader>
        <PortfolioAssetsTableHead
          selection={selection}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {portfolioAssetList.length > 0 ? (
            stableSort(portfolioAssetList, getComparator(order, orderBy)).map((portfolioAsset) => (
              <PortfolioAssetTableRow
                key={portfolioAsset.id}
                selection={selection}
                portfolioAsset={portfolioAsset}
                onRowClick={createClickHandler(portfolioAsset.id)}
              />
            ))
          ) : (
            <EmptyTableRow columnCount={allColumnHeadings.length}>No portfolio assets were found.</EmptyTableRow>
          )}
        </TableBody>
      </Table>
    </HeightLimitedTableContainer>
  );
};
export default PortfolioAssetsTable;

interface PortfolioAssetsTableHeadProps
  extends Omit<SimpleSortingTableHeadProps<PortfolioAssetsTableRowData>, 'headings'> {
  selection: PortfolioAssetsTableSelection;
}

const allColumnHeadings: SimpleSortingTableHeadCell<PortfolioAssetsTableRowData>[] = [
  { key: 'asset', label: 'Asset' },
  { key: 'filledSize', label: 'Position Size', align: 'right' },
  { key: 'filledPercent', label: 'Position %', align: 'right' },
  { key: 'fifteenMinutePercentChange', label: '15m', align: 'right' },
  { key: 'hourPercentChange', label: '1h', align: 'right' },
  { key: 'dayPercentChange', label: '24h', align: 'right' },
  { key: 'weekPercentChange', label: '7d', align: 'right' },
  { key: 'currentPrice', label: 'Current Price', align: 'right' },
  { key: 'desiredDistance', label: '% from Desired', align: 'right' },
  { key: 'filledAssetAmount', label: 'Position Asset', align: 'right' },
  { key: 'filledCurrencyAmount', label: 'Position USD', align: 'right' },
];

const columnHeadingsForPerformance = allColumnHeadings.filter(({ key }) => key != 'fifteenMinutePercentChange');

const PortfolioAssetsTableHead = ({ selection, ...props }: PortfolioAssetsTableHeadProps) => {
  const columnHeadings =
    selection === PortfolioAssetsTableSelection.Performance ? columnHeadingsForPerformance : allColumnHeadings;

  return <SimpleSortingTableHead {...props} headings={columnHeadings} />;
};

type PortfolioAssetTableRowProps = {
  selection: PortfolioAssetsTableSelection;
  portfolioAsset: PortfolioAssetsTableRowData;
  onRowClick?: () => void;
};

export const PortfolioAssetTableRow = ({ selection, portfolioAsset, onRowClick }: PortfolioAssetTableRowProps) => {
  const theme = useTheme();
  const { formatDateTime } = useDateTime();
  const { getPercentChangeColor } = useChartColors();

  const changes = selection == PortfolioAssetsTableSelection.Performance ? portfolioAsset.pnl : portfolioAsset.prices;

  return (
    <AlternatingTableRow hover={Boolean(onRowClick)} onClick={onRowClick}>
      <TableCell>
        {buildPortfolioAssetTitle(portfolioAsset.asset)}&nbsp;
        {portfolioAsset.pausedAt ? (
          <TooltipItem title={`Paused on ${formatDateTime(portfolioAsset.pausedAt)}`}>
            <PauseIcon style={{ fontSize: theme.typography.body2.fontSize }} />
          </TooltipItem>
        ) : null}
        {portfolioAsset.deletedAt ? (
          <TooltipItem title={`Deleted on ${formatDateTime(portfolioAsset.deletedAt)}`}>
            <DeletedIcon style={{ fontSize: theme.typography.body2.fontSize }} />
          </TooltipItem>
        ) : null}
      </TableCell>
      <TableCell align="right">
        <PercentFormat amount={portfolioAsset.filledSize} />
      </TableCell>
      <TableCell align="right">
        <PercentFormat amount={portfolioAsset.filledPercent} />
      </TableCell>
      {'fifteenMinutes' in changes && <AssetChangeTableCell assetChange={changes.fifteenMinutes} />}
      <AssetChangeTableCell assetChange={changes.hour} />
      <AssetChangeTableCell assetChange={changes.day} />
      <AssetChangeTableCell assetChange={changes.week} />
      <TableCell align="right">
        <UsdFormat amount={portfolioAsset.currentPrice} />
      </TableCell>
      <TableCell align="right">
        <PercentFormat
          css={css`
            color: ${getPercentChangeColor(portfolioAsset.desiredDistance || 0)};
          `}
          amount={portfolioAsset.desiredDistance}
        />
      </TableCell>
      <TableCell align="right">
        <FloatFormat amount={portfolioAsset.filledAssetAmount} asset={portfolioAsset.asset} />
      </TableCell>
      <TableCell align="right">
        <UsdFormat amount={portfolioAsset.filledCurrencyAmount} />
      </TableCell>
    </AlternatingTableRow>
  );
};

interface AssetChangeTableCellProps {
  assetChange: PortfolioAssetTable_AssetChange;
}

const AssetChangeTableCell = ({ assetChange }: AssetChangeTableCellProps) => {
  const { getChangeAmountColor } = useChartColors();

  const color = getChangeAmountColor(assetChange.percentChange);

  return (
    <TableCell
      css={css`
        white-space: nowrap;
      `}
      align="right"
    >
      <TooltipItem
        title={
          <UsdFormat
            css={css`
              color: ${color};
            `}
            amount={assetChange.valueChange}
          />
        }
      >
        <>
          <PercentArrow percentChange={assetChange.percentChange} />
          &nbsp;
          <PercentFormat
            css={css`
              color: ${color};
            `}
            amount={assetChange.percentChange}
          />
        </>
      </TooltipItem>
    </TableCell>
  );
};

const PercentArrow = ({ percentChange }: { percentChange: number }) => {
  const { redColor, greenColor } = useChartColors();

  if (percentChange > 0) {
    return (
      <ArrowUpIcon
        css={css`
          color: ${greenColor};
          vertical-align: middle;
        `}
      />
    );
  }

  if (percentChange < 0) {
    return (
      <ArrowDownIcon
        css={css`
          color: ${redColor};
          vertical-align: middle;
        `}
      />
    );
  }

  return null;
};

export const buildPortfolioAssetTitle = (asset?: string) => asset || 'Unknown portfolio asset';

export const portfolioAssetsTableRowFragment = gql`
  fragment PortfolioAssetsTableRow_PortfolioAsset on PortfolioAsset {
    id
    asset
    prices {
      id
      currentPrice
      fifteenMinutes {
        ...PortfolioAssetTable_AssetChange
      }
      hour {
        ...PortfolioAssetTable_AssetChange
      }
      day {
        ...PortfolioAssetTable_AssetChange
      }
      week {
        ...PortfolioAssetTable_AssetChange
      }
    }
    pnl {
      id
      hour {
        ...PortfolioAssetTable_AssetChange
      }
      day {
        ...PortfolioAssetTable_AssetChange
      }
      week {
        ...PortfolioAssetTable_AssetChange
      }
    }
    desiredAssetAmount
    desiredCurrencyAmount
    desiredPercent
    desiredSizePercent
    filledAssetAmount
    filledCurrencyAmount
    filledPercent
    createdAt
    pausedAt
    deletedAt
  }

  fragment PortfolioAssetTable_AssetChange on AssetChange {
    percentChange
    valueChange
  }
`;
