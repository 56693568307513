import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SecondaryButton from '@paypr/mui5-common-components/dist/components/forms/SecondaryButton';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailText from '@paypr/mui5-common-components/dist/components/typography/DetailText';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import React from 'react';
import { FavoriteLedgerReport } from './favorites';

export interface RemoveFavoriteReportDialogProps extends Omit<DialogProps, 'onClose'> {
  favoriteReport: FavoriteLedgerReport;
  onRemoveFavoriteReport: () => void;
  onClose: (
    event: React.MouseEvent<HTMLButtonElement> | {},
    reason: 'backdropClick' | 'escapeKeyDown' | 'cancelClicked',
  ) => void;
}

const RemoveFavoriteReportDialog = ({
  favoriteReport,
  onRemoveFavoriteReport,
  onClose,
  ...props
}: RemoveFavoriteReportDialogProps) => (
  <Dialog {...props} onClose={onClose}>
    <DialogTitle>Remove Favorite Report</DialogTitle>
    <DialogContent>
      <DetailField>
        <DetailTitle>Confirm</DetailTitle>
        <DetailText>
          Are you sure you want to remove the <strong>{favoriteReport.name}</strong> favorite?
        </DetailText>
      </DetailField>
    </DialogContent>
    <DialogActions>
      <SecondaryButton onClick={(event) => onClose(event, 'cancelClicked')} variant="text" color="inherit">
        Cancel
      </SecondaryButton>
      <Button onClick={onRemoveFavoriteReport}>Remove</Button>
    </DialogActions>
  </Dialog>
);
export default RemoveFavoriteReportDialog;
