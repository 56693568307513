import { gql } from '@apollo/client';
import { DateTime } from 'luxon';
import {
  LedgerAccountEventType,
  LedgerReportGroupField,
  LedgerReportTimeResolution,
  LedgerReportValueField,
} from '../../generated/graphql';

export interface LedgerReportSettings {
  definition: LedgerReportAugmentedDefinition;
  view: ReportView;
  chartSettings: ReportChartSettings;
  tableSettings: ReportTableSettings;
}

export enum ReportView {
  Chart = 'chart',
  Table = 'table',
}

export interface LedgerReportAugmentedDefinition extends LedgerReportDefinition {
  standardTimeframe?: LedgerReportStandardTimeframe;
}

export interface LedgerReportDefinition {
  startAt: DateTime;
  endAt?: DateTime;
  timeResolution: LedgerReportTimeResolution;
  groupBy: LedgerReportGroupField[];
  groupFilters?: Partial<Record<LedgerReportGroupField, string[]>>;
  includeTotals?: boolean;
  values: LedgerReportValueField[];
  marketAssets?: string[];
  includeAccountEvents?: boolean;
  accountEventTypes?: LedgerAccountEventType[];
}

export enum LedgerReportStandardTimeframe {
  AllTime = 'all-time',
  Year = 'year',
  YearToDate = 'year-to-date',
  PreviousYear = 'previous-year',
  Quarter = 'quarter',
  QuarterToDate = 'quarter-to-date',
  PreviousQuarter = 'previous-quarter',
  Month = 'month',
  MonthToDate = 'month-to-date',
  PreviousMonth = 'previous-month',
  Week = 'week',
  Day = 'day',
}

export interface ReportChartSettings {}

export interface ReportTableSettings {
  orientation: ReportTableOrientation;
}

export enum ReportTableOrientation {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export const ledgerReportDetailsFragment = gql`
  fragment LedgerReportDetailsDetails on LedgerReportDetails {
    timeResolution
    groupBy
    groupFilters {
      group
      values
    }
    values

    cryptoMarketAssets
    equityMarketAssets

    results {
      ...LedgerReportResultDetails
    }

    includeAccountEvents
    accountEventTypes
    accountEvents {
      id
      ...LedgerAccountEventDetails
    }
  }

  fragment LedgerReportResultDetails on LedgerReportResult {
    groups {
      ...LedgerReportResultGroupValueDetails
    }
    values {
      ...LedgerReportValueDetails
    }
  }

  fragment LedgerReportResultGroupValueDetails on LedgerReportResultGroupValue {
    id
    name
    field
  }

  fragment LedgerReportValueDetails on LedgerReportResultFieldNumberValueLedgerReportResultFieldTextValue {
    ...LedgerReportNumberValueDetails
    ...LedgerReportTextValueDetails
  }

  fragment LedgerReportNumberValueDetails on LedgerReportResultFieldNumberValue {
    field
    numberValue: value
  }

  fragment LedgerReportTextValueDetails on LedgerReportResultFieldTextValue {
    field
    textValue: value
  }

  fragment LedgerAccountEventDetails on LedgerAugmentedAccountEvent {
    id
    accountName
    occurredAt
    type
    value
    notes
  }
`;
