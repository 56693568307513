import { gql } from '@apollo/client';
import NoteBlock from '@paypr/mui5-common-components/dist/components/typography/NoteBlock';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { SubsystemDeletedNote_Subsystem } from '../../generated/graphql';

interface SubsystemDeletedNoteProps {
  subsystem: SubsystemDeletedNote_Subsystem;
}

const SubsystemDeletedNote = ({ subsystem: { deletedAt } }: SubsystemDeletedNoteProps) => {
  const { formatDateTime } = useDateTime();

  if (!deletedAt) {
    return null;
  }

  return (
    <NoteBlock>
      <em>This subsystem was deleted on {formatDateTime(deletedAt)}</em>
    </NoteBlock>
  );
};
export default SubsystemDeletedNote;

export const subsystemDeletedNoteFragment = gql`
  fragment SubsystemDeletedNote_Subsystem on Subsystem {
    deletedAt
  }
`;
