import { gql, NetworkStatus } from '@apollo/client';
import React from 'react';
import AppPage from '../../components/common/page/AppPage';
import AppPageError from '../../components/common/page/AppPageError';
import WidePageContainer from '../../components/common/page/WidePageContainer';
import { useQuery } from '../../components/common/routes/paths';
import StrategiesSection from '../../components/strategies/StrategiesSection';
import { strategiesTableRowFragment } from '../../components/strategies/StrategiesTable';
import { buildStrategyPath, StrategiesPageQueryParams } from '../../components/strategies/strategyPaths';
import { useStrategiesPageQuery } from '../../generated/graphql';
import { usePageTitle } from '../../hooks/page';
import { usePaths } from '../../hooks/paths';

const StrategiesPage = () => {
  usePageTitle('Strategies');
  const { navigateTo } = usePaths();

  const { params, updateParam } = useQuery<StrategiesPageQueryParams>();

  const includeDeletedFlag = params.get('includeDeleted');
  const includeDeleted = includeDeletedFlag === 'true';

  const { data, error, refetch, networkStatus, loading } = useStrategiesPageQuery({
    fetchPolicy: 'cache-and-network',
    variables: { includeDeleted: includeDeleted || false },
  });

  if (error) {
    return <AppPageError error={error} />;
  }

  const handleStrategyClicked = (strategyId: string, newTab: boolean) => {
    navigateTo(buildStrategyPath(strategyId), newTab);
  };

  const handleIncludeDeletedStrategiesChanged = (includeDeletedStrategies: boolean) => {
    updateParam('includeDeleted', includeDeletedStrategies ? 'true' : 'false');
  };

  return (
    <AppPage>
      <WidePageContainer>
        <StrategiesSection
          strategies={data?.strategies}
          loading={loading}
          refreshing={networkStatus === NetworkStatus.refetch}
          onRefresh={() => refetch()}
          autoRefresh
          onClickStrategy={handleStrategyClicked}
          includeDeletedStrategies={includeDeleted}
          onUpdateIncludeDeletedStrategies={
            includeDeletedFlag !== null ? handleIncludeDeletedStrategiesChanged : undefined
          }
        />
      </WidePageContainer>
    </AppPage>
  );
};
export default StrategiesPage;

gql`
  query StrategiesPageQuery($includeDeleted: Boolean!) {
    strategies(includeDeleted: $includeDeleted) {
      id
      ...StrategiesTableRow_Strategy
    }
  }

  ${strategiesTableRowFragment}
`;
