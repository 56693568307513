import { gql, NetworkStatus } from '@apollo/client';
import React from 'react';
import AppPage from '../../components/common/page/AppPage';
import AppPageError from '../../components/common/page/AppPageError';
import AppPageMessage from '../../components/common/page/AppPageMessage';
import WiderPageContainer from '../../components/common/page/WiderPageContainer';
import { useQuery } from '../../components/common/routes/paths';
import { buildTradeTitle } from '../../components/trades/TradeDetail';
import { TradePageParams, TradePageQueryParams } from '../../components/trades/tradePaths';
import TradeSection, { tradeSectionFragment } from '../../components/trades/TradeSection';
import { useTradePageQuery } from '../../generated/graphql';
import { usePageParams, usePageTitle } from '../../hooks/page';

const TradePage = () => {
  const { tradeId } = usePageParams<keyof TradePageParams>();

  const { params, updateParam } = useQuery<TradePageQueryParams>();

  const { data, error, refetch, networkStatus, loading } = useTradePageQuery({
    fetchPolicy: 'cache-and-network',
    variables: { tradeId },
  });

  usePageTitle(`${buildTradeTitle(tradeId, data?.trade?.order.portfolio.name)}`);

  if (error) {
    return <AppPageError error={error} />;
  }

  const trade = data?.trade;

  if (trade === null) {
    return <AppPageMessage text={`Trade ${tradeId} does not exist`} />;
  }

  return (
    <AppPage>
      <WiderPageContainer>
        <TradeSection
          trade={trade}
          loading={loading}
          refreshing={networkStatus === NetworkStatus.refetch}
          onRefresh={() => refetch()}
          autoRefresh
        />
      </WiderPageContainer>
    </AppPage>
  );
};
export default TradePage;

gql`
  query TradePageQuery($tradeId: ID!) {
    trade(id: $tradeId) {
      id
      order {
        portfolio {
          id
          name
        }
      }
      ...TradeSection_Trade
    }
  }

  ${tradeSectionFragment}
`;
