import { gql } from '@apollo/client';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import * as React from 'react';
import { StrategySection_Strategy } from '../../generated/graphql';
import { AddLedgerEventClickEventHandler } from '../ledgerEvents/LedgerEventsAccordion';
import { LedgerEventClickEventHandler } from '../ledgerEvents/LedgerEventsTable';
import { PortfolioClickEventHandler } from '../portfolios/PortfoliosTable';
import StrategyDetail, { buildStrategyTitle, strategyDetailFragment } from './StrategyDetail';
import StrategyIcon from './StrategyIcon';

interface StrategySectionProps extends RefreshingComponentProps {
  strategy?: StrategySection_Strategy;
  onClickPortfolio?: PortfolioClickEventHandler;
  onClickLedgerEvent?: LedgerEventClickEventHandler;
  onClickAddLedgerEvent?: AddLedgerEventClickEventHandler;
}

const StrategySection = ({
  strategy,
  onClickPortfolio,
  onClickLedgerEvent,
  onClickAddLedgerEvent,
  loading,
  refreshing,
  onRefresh,
  autoRefresh,
}: StrategySectionProps) => (
  <Paper>
    <Toolbar>
      <StrategyIcon />
      &nbsp;
      <SectionTitle>{buildStrategyTitle(strategy?.name)}</SectionTitle>
      <HorizontalAutoSpacer />
      <RefreshIconButton
        refreshing={refreshing}
        loading={loading}
        onRefresh={onRefresh}
        includeRefreshText
        autoRefresh={autoRefresh}
      />
    </Toolbar>

    {strategy ? (
      <StrategyDetail
        strategy={strategy}
        onClickPortfolio={onClickPortfolio}
        onClickLedgerEvent={onClickLedgerEvent}
        onClickAddLedgerEvent={onClickAddLedgerEvent}
        autoRefresh={autoRefresh}
      />
    ) : null}
  </Paper>
);
export default StrategySection;

export const strategySectionFragment = gql`
  fragment StrategySection_Strategy on Strategy {
    id
    name
    ...StrategyDetail_Strategy
  }
  ${strategyDetailFragment}
`;
