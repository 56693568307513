import { gql } from '@apollo/client';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import * as React from 'react';
import { TransactionSection_Transaction } from '../../generated/graphql';
import TransactionDetail, { buildTransactionTitle, transactionDetailFragment } from './TransactionDetail';
import TransactionIcon from './TransactionIcon';

interface TransactionSectionProps extends RefreshingComponentProps {
  transaction?: TransactionSection_Transaction;
}

const TransactionSection = ({ transaction, loading, refreshing, onRefresh, autoRefresh }: TransactionSectionProps) => (
  <Paper>
    <Toolbar>
      <TransactionIcon />
      &nbsp;
      <SectionTitle>{buildTransactionTitle(transaction?.id, transaction?.trade.order.portfolio.name)}</SectionTitle>
      <HorizontalAutoSpacer />
      <RefreshIconButton refreshing={refreshing} loading={loading} onRefresh={onRefresh} autoRefresh={autoRefresh} />
    </Toolbar>

    {transaction ? <TransactionDetail transaction={transaction} /> : null}
  </Paper>
);
export default TransactionSection;

export const transactionSectionFragment = gql`
  fragment TransactionSection_Transaction on Transaction {
    id
    trade {
      order {
        portfolio {
          id
          name
        }
      }
    }
    ...TransactionDetail_Transaction
  }
  ${transactionDetailFragment}
`;
