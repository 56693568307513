import { css } from '@emotion/react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import React from 'react';
import { useDateTime } from '../../../data/dates';
import { DshReportData } from '../../../services/dshReports';
import { ReportTableHeaderRow, ReportTableRow } from '../../reports/GenericReportDataTable';

export interface DshGainsOverTimeReportProps extends RefreshingComponentProps {
  reports: DshReportData[];
}

const DshGainsOverTimeReport = ({
  reports,
  onRefresh,
  loading,
  refreshing,
  autoRefresh,
}: DshGainsOverTimeReportProps) => {
  const theme = useTheme();
  const { formatShortDate } = useDateTime();

  const propertyNames = [
    'Invested USD',
    'Allocation USD',
    'Allocation %',
    'Net Realized Gains/Losses USD',
    'Net Realized Gains/Losses Invested % ROI',
    'Net Realized Gains/Losses Portfolio % ROI',
    '# Trades',
    '# Days With Trades Up',
  ];

  return (
    <>
      <Box
        css={css`
          text-align: right;
        `}
      >
        <RefreshIconButton
          refreshing={refreshing}
          loading={loading}
          onRefresh={onRefresh}
          autoRefresh={autoRefresh}
          includeRefreshText
        />
      </Box>

      {reports.length > 0 ? (
        <Box
          css={css`
            max-height: 500px;
            overflow-y: scroll;
          `}
        >
          <Table stickyHeader>
            <TableHead>
              <ReportTableHeaderRow report={reports[0].report} />
            </TableHead>
            <TableBody>
              {reports.map(({ report, startDate }) => (
                <>
                  <TableRow>
                    <TableCell
                      css={css`
                        color: ${theme.palette.primary.contrastText};
                        background-color: ${theme.palette.primary.main};
                      `}
                      colSpan={Object.keys(report).length + 1}
                    >
                      <strong>{formatShortDate(startDate)}</strong>
                    </TableCell>
                  </TableRow>
                  {propertyNames.map((property, index) => (
                    <ReportTableRow key={property} report={report} propertyName={property} rowIndex={index} />
                  ))}
                </>
              ))}
            </TableBody>
          </Table>
        </Box>
      ) : null}
    </>
  );
};
export default DshGainsOverTimeReport;
