import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import * as React from 'react';

interface PageFooterProps {}

const PageFooter = ({}: PageFooterProps) => (
  <>
    <br />
    <Divider />
    <DialogContent>
      <DetailField textAlign="center">
        <Typography variant="caption">Copyright &copy; 2022-2024 Norrio Capital Partners, LLC</Typography>
      </DetailField>
    </DialogContent>
  </>
);
export default PageFooter;
