import { gql, NetworkStatus } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import {
  DshStrategyAssetLogEventDetail,
  useDshStrategyAssetLogsIntegrationLogsQueryLazyQuery,
  useDshStrategyAssetLogsIntegrationQuery,
} from '../../generated/graphql';
import DshStrategyAssetLogs, { dshStrategyAssetLogEventDetailFragment } from './DshStrategyAssetLogs';

export interface DshStrategyAssetLogsIntegrationProps {
  version: string;
  asset: string;
}

const DshStrategyAssetLogsIntegration = ({ version, asset }: DshStrategyAssetLogsIntegrationProps) => {
  const [nextCursor, setNextCursor] = useState<string | null>(null);
  const { data, loading } = useDshStrategyAssetLogsIntegrationQuery({
    fetchPolicy: 'cache-and-network',
    variables: { version, asset },
  });
  const [fetch, { loading: logsLoading, networkStatus }] = useDshStrategyAssetLogsIntegrationLogsQueryLazyQuery({
    fetchPolicy: 'cache-and-network',
    variables: { version, asset, input: { nextCursor } },
  });
  const [allLogEvents, setAllLogEvents] = useState<Record<string, DshStrategyAssetLogEventDetail>>();
  const [logStreamUrl, setLogStreamUrl] = useState<string>();

  useEffect(() => {
    if (!nextCursor) {
      return;
    }

    // noinspection JSIgnoredPromiseFromCall
    handleRefresh();
  }, [nextCursor]);

  const handleRefresh = async () => {
    try {
      const { data: newData } = await fetch();

      const events = newData?.dshVersion?.asset?.logGroup?.latestStream?.events;
      if (!events) {
        return;
      }

      const { nodes: newEvents, nextCursor: newNextCursor } = events;

      setAllLogEvents((oldLogEvents) => {
        const newLogEvents = newEvents.reduce(
          (acc, cur) => {
            acc[cur.id] = cur;
            return acc;
          },
          {} as Record<string, DshStrategyAssetLogEventDetail>,
        );
        return { ...oldLogEvents, ...newLogEvents };
      });

      if (newNextCursor && newNextCursor !== nextCursor) {
        setNextCursor(newNextCursor);
      }

      const logGroup = newData.dshVersion.asset.logGroup;
      const newLogStreamUrl = logGroup.latestStream?.url;

      if (newLogStreamUrl && newLogStreamUrl !== logStreamUrl) {
        setLogStreamUrl(newLogStreamUrl);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const buildLogEventsList = () => {
    if (!allLogEvents) {
      return undefined;
    }

    const logEvents = Object.values(allLogEvents);
    logEvents.sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    return logEvents;
  };

  const logEvents = buildLogEventsList();

  const dshVersion = data?.dshVersion;

  return (
    <DshStrategyAssetLogs
      asset={asset}
      logs={logEvents}
      logGroupUrl={dshVersion?.asset?.logGroup?.url}
      logStreamUrl={logStreamUrl}
      active={dshVersion?.active}
      loading={loading || logsLoading}
      refreshing={networkStatus == NetworkStatus.refetch}
      onRefresh={handleRefresh}
      autoRefresh={2000}
    />
  );
};
export default DshStrategyAssetLogsIntegration;

gql`
  query DshStrategyAssetLogsIntegrationQuery($version: ID!, $asset: String!) {
    dshVersion(id: $version) {
      id
      asset(asset: $asset) {
        id
        logGroup {
          id
          name
          url
        }
      }
      active
    }
  }

  query DshStrategyAssetLogsIntegrationLogsQuery($version: ID!, $asset: String!, $input: LogEventsFilterInput) {
    dshVersion(id: $version) {
      id
      asset(asset: $asset) {
        id
        logGroup {
          id
          name
          url
          latestStream {
            id
            url
            events(input: $input) {
              nodes {
                id
                ...DshStrategyAssetLogEventDetail
              }
              nextCursor
            }
          }
        }
      }
    }
  }

  ${dshStrategyAssetLogEventDetailFragment}
`;
