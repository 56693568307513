export const subsystemsPath = '/subsystems';

export interface SubsystemsPageQueryParams {
  includeDeleted: string;
}

export const buildSubsystemPath = (subsystemId: string) => `${subsystemsPath}/${subsystemId}`;

export const addSubsystemPath = `${subsystemsPath}/add`;

export const parameterizedSubsystemPath = buildSubsystemPath(':subsystemId');
export interface SubsystemPageParams {
  subsystemId: string;
}
