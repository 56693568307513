import { css } from '@emotion/react';
import Box from '@mui/material/Box';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import React from 'react';
import { DshReportData } from '../../../services/dshReports';
import GenericReportDataTable from '../../reports/GenericReportDataTable';

export interface DshGainsReportProps extends RefreshingComponentProps {
  report: DshReportData | null;
}

const DshGainsReport = ({ report, onRefresh, loading, refreshing, autoRefresh }: DshGainsReportProps) => {
  return (
    <>
      <Box
        css={css`
          text-align: right;
        `}
      >
        <RefreshIconButton
          refreshing={refreshing}
          loading={loading}
          onRefresh={onRefresh}
          autoRefresh={autoRefresh}
          includeRefreshText
        />
      </Box>

      {report ? <GenericReportDataTable report={report.report} /> : null}
    </>
  );
};
export default DshGainsReport;
