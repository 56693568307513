import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Toolbar from '@mui/material/Toolbar';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SubSectionTitle from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import React from 'react';
import { OrderAssetsTableRow_OrderAsset } from '../../generated/graphql';
import ExpandIcon from '../common/icons/ExpandIcon';
import OrderAssetsTable, { OrderAssetClickEventHandler } from './OrderAssetsTable';

export interface OrderAssetsSectionProps {
  orderAssets: readonly OrderAssetsTableRow_OrderAsset[];
  onClickOrderAsset?: OrderAssetClickEventHandler;
}

const OrderAssetsSection = ({ orderAssets, onClickOrderAsset }: OrderAssetsSectionProps) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandIcon />}>
      <Toolbar>
        <SubSectionTitle>Assets</SubSectionTitle>
        <HorizontalAutoSpacer />
      </Toolbar>
    </AccordionSummary>
    <AccordionDetails>
      <OrderAssetsTable orderAssets={orderAssets} onClickOrderAssetRow={onClickOrderAsset} />
    </AccordionDetails>
  </Accordion>
);
export default OrderAssetsSection;
