import { gql } from '@apollo/client';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import TooltipIconButton from '@paypr/mui5-common-components/dist/components/actions/TooltipIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import React, { useState } from 'react';
import { DshAllocationsUpdateInput, DshStrategyAllocationsSectionDshVersionDetails } from '../../../generated/graphql';
import EditIcon from '../../common/icons/EditIcon';
import StrategyIcon from '../../strategies/StrategyIcon';
import DshStrategyVersionTitle, { useDshStrategyVersionTitle } from '../DshStrategyVersionTitle';
import DshStrategyAllocationsDetail, {
  dshStrategyAllocationsDetailDshVersionDetailsFragment,
} from './DshStrategyAllocationsDetail';
import UpdateDshStrategyAllocationsDialog, {
  SubmitDshAllocationsUpdateEventHandler,
  updateDshStrategyAllocationsDialogFragment,
} from './UpdateDshStrategyAllocationsDialog';

export interface DshStrategyAllocationsSectionProps extends RefreshingComponentProps {
  version: string;
  dshVersion?: DshStrategyAllocationsSectionDshVersionDetails | null;
  onSubmitDshAllocations?: SubmitDshAllocationsUpdateEventHandler;
  submittingDshAllocations?: boolean;
}

const DshStrategyAllocationsSection = ({
  version,
  dshVersion,
  onSubmitDshAllocations,
  submittingDshAllocations,
  refreshing,
  loading,
  onRefresh,
  autoRefresh,
}: DshStrategyAllocationsSectionProps) => {
  const [updateDshConfigDialogOpen, setUpdateDshConfigDialogOpen] = useState(false);
  const dshVersionTitle = useDshStrategyVersionTitle(version);

  const openUpdateDshConfigDialog = () => {
    setUpdateDshConfigDialogOpen(true);
  };

  const closeUpdateDshConfigDialog = () => {
    setUpdateDshConfigDialogOpen(false);
  };

  const handleEditDshConfig = async (event) => {
    event.stopPropagation();

    openUpdateDshConfigDialog();
  };

  const handleSubmitDshAllocationsUpdate = async (dshAllocationsUpdateInput: DshAllocationsUpdateInput) => {
    if (!onSubmitDshAllocations) {
      return;
    }

    await onSubmitDshAllocations(dshAllocationsUpdateInput);
    closeUpdateDshConfigDialog();
  };

  if (dshVersion === null) {
    return null;
  }

  return (
    <Paper>
      <Toolbar>
        <StrategyIcon />
        &nbsp;
        <SectionTitle>
          <DshStrategyVersionTitle version={version} pageName="Allocations" />
        </SectionTitle>
        <HorizontalAutoSpacer />
        {onSubmitDshAllocations && (
          <TooltipIconButton
            title={`Edit ${dshVersionTitle} Allocations`}
            onClick={handleEditDshConfig}
            disabled={loading}
          >
            <EditIcon />
          </TooltipIconButton>
        )}
        <RefreshIconButton
          refreshing={refreshing}
          loading={loading}
          onRefresh={onRefresh}
          includeRefreshText
          autoRefresh={autoRefresh}
        />
      </Toolbar>
      {dshVersion && <DshStrategyAllocationsDetail dshVersion={dshVersion} />}
      {dshVersion && onSubmitDshAllocations && (
        <UpdateDshStrategyAllocationsDialog
          open={submittingDshAllocations || updateDshConfigDialogOpen}
          dshVersion={dshVersion}
          onSubmitDshAllocations={handleSubmitDshAllocationsUpdate}
          submitting={submittingDshAllocations}
          onClose={closeUpdateDshConfigDialog}
        />
      )}
    </Paper>
  );
};
export default DshStrategyAllocationsSection;

export const dshStrategyAllocationsSectionDshVersionDetailsFragment = gql`
  fragment DshStrategyAllocationsSectionDshVersionDetails on DshVersion {
    ...DshStrategyAllocationsDetailDshVersionDetails
    ...UpdateDshStrategyAllocationsDialog_DshVersion
  }

  ${dshStrategyAllocationsDetailDshVersionDetailsFragment}
  ${updateDshStrategyAllocationsDialogFragment}
`;
