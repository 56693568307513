// approvals
import { ApprovalStatus } from '../../generated/graphql';
import { QueryParams } from '../common/routes/paths';

export const approvalsPath = '/approvals';

export interface ApprovalsPageQueryParams {
  inProgress: string;
  status: string;
  optionsExpanded: string;
}

export interface ApprovalFilters {
  inProgress: boolean | null;
  status: ApprovalStatus | null;
}

export const extractApprovalFiltersFromUrl = (params: QueryParams<ApprovalsPageQueryParams>): ApprovalFilters => {
  const inProgressText = params.get('inProgress');
  const inProgress = inProgressText ? inProgressText === 'true' : inProgressText === 'false' ? false : null;

  const statusText = params.get('status');
  const status = statusText ? (statusText as ApprovalStatus) : null;

  return {
    inProgress,
    status,
  };
};

export const buildApprovalsUrlWithFilters = (filters: ApprovalFilters, optionsExpanded: boolean): string => {
  const { inProgress, status } = filters;
  const params = {
    inProgress: inProgress !== null && inProgress !== undefined ? inProgress.toString() : '',
    status: status ? status : '',
    optionsExpanded: optionsExpanded ? 'true' : 'false',
  };
  return `${approvalsPath}?${new URLSearchParams(params).toString()}`;
};

export const buildApprovalPath = (approvalId: string) => `${approvalsPath}/${approvalId}`;

export const approvalsInProgressPath = buildApprovalPath('in-progress');

export const parameterizedApprovalPath = buildApprovalPath(':approvalId');
export interface ApprovalPageParams {
  approvalId: string;
}
export interface ApprovalPageQueryParams {}

export const buildApprovalDecisionPath = (approvalId: string) => `${buildApprovalPath(approvalId)}/approve-or-reject`;

export const parameterizedApprovalDecisionPath = buildApprovalDecisionPath(':approvalId');

export interface ApprovalDecisionPageParams extends ApprovalPageParams {}
export interface ApprovalDecisionPageQueryParams {}
