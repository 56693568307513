import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import SubSectionTitle from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import React, { useState } from 'react';
import DshRealizedGainsCustomReportIntegration from './reports/DshRealizedGainsCustomReportIntegration';
import DshRealizedGainsOverTimeReportIntegration from './reports/DshRealizedGainsOverTimeReportIntegration';
import DshRealizedGainsReportIntegration from './reports/DshRealizedGainsReportIntegration';
import { ReportType } from '../../services/dshReports';

export interface DshStrategyReportsSectionProps {
  version: string;
}

const DshStrategyReportsSection = ({ version }: DshStrategyReportsSectionProps) => {
  const [reportName, setReportName] = useState<string>('min-sale-price');

  const handleSelectReport = (event, newReportName: string) => {
    setReportName(newReportName);
  };

  return (
    <>
      <SubSectionTitle>Reports</SubSectionTitle>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={reportName}
          onChange={handleSelectReport}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          {/*
          <Tab label="Net Asset Value" value="current-stats" />
          <Tab label="All Time" value="all-time" />
          <Tab label="Day-by-Day" value="day-by-day" />
          <Tab label="Month-by-Month" value="month-by-month" />
          */}
          <Tab label="Min Sale Price" value="min-sale-price" />
          {/*
          <Tab label="Today" value="today" />
          <Tab label="Yesterday" value="yesterday" />
          <Tab label="Current Month-to-Date" value="current-month-to-date" />
          <Tab label="Last Month" value="last-month" />
          <Tab label="Custom" value="custom" />
          */}
        </Tabs>
      </Box>
      <ReportDetail version={version} reportName={reportName} />
    </>
  );
};
export default DshStrategyReportsSection;

interface ReportDetailProps {
  version: string;
  reportName: string;
}

const ReportDetail = ({ version, reportName }: ReportDetailProps) => {
  switch (reportName) {
    case 'custom':
      return <DshRealizedGainsCustomReportIntegration version={version} />;

    case 'day-by-day':
      return (
        <DshRealizedGainsOverTimeReportIntegration version={version} reportType={ReportType.Daily} numPeriods={7} />
      );

    case 'month-by-month':
      return (
        <DshRealizedGainsOverTimeReportIntegration version={version} reportType={ReportType.Monthly} numPeriods={6} />
      );

    default:
      return <DshRealizedGainsReportIntegration version={version} reportName={reportName} />;
  }
};
