import TableCell from '@mui/material/TableCell';
import React from 'react';
import ReportFormattedValue, { ReportFormattedValueProps } from './ReportFormattedValue';

export interface ReportTableCellProps extends ReportFormattedValueProps {}

const ReportTableCell = (props: ReportTableCellProps) => (
  <TableCell align="right">
    <ReportFormattedValue {...props} />
  </TableCell>
);
export default ReportTableCell;
