
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "BasicStats": [
      "ClientStats",
      "FundStats",
      "PortfolioStats",
      "StrategyStats"
    ],
    "FinanceStats": [
      "FundStats",
      "PortfolioStats",
      "StrategyStats"
    ],
    "LedgerReportResultFieldNumberValueLedgerReportResultFieldTextValue": [
      "LedgerReportResultFieldNumberValue",
      "LedgerReportResultFieldTextValue"
    ]
  }
};
      export default result;
    