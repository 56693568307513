import { css } from '@emotion/react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import React, { useEffect, useState } from 'react';
import { ClientTransactionsTableRow_ClientTransaction } from '../../../generated/graphql';
import { ErrorLike } from '../../../hooks/errors';
import ExpandIcon from '../../common/icons/ExpandIcon';
import { ExplodingComponentProps } from '../../common/icons/ExplodeIconButton';
import ClientTransactionsTable, { ClientTransactionClickEventHandler } from './ClientTransactionsTable';

export interface ClientTransactionsAccordionProps extends ExplodingComponentProps, RefreshingComponentProps {
  clientTransactions?: readonly ClientTransactionsTableRow_ClientTransaction[];
  hideClient?: boolean;
  hideFund?: boolean;
  defaultExpanded?: boolean;
  onClickClientTransaction?: ClientTransactionClickEventHandler;
  error?: ErrorLike;
}

const ClientTransactionsAccordion = ({
  clientTransactions,
  hideClient,
  hideFund,
  defaultExpanded,
  exploded,
  loading,
  refreshing,
  onRefresh,
  autoRefresh,
  onClickClientTransaction,
  error,
}: ClientTransactionsAccordionProps) => {
  const [expanded, setExpanded] = useState(exploded ? true : defaultExpanded);

  useEffect(() => {
    if (!expanded) {
      return;
    }

    onRefresh?.();
  }, [expanded]);

  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      expanded={expanded}
      onChange={(event, expanded) => setExpanded(expanded)}
    >
      <AccordionSummary expandIcon={!exploded ? <ExpandIcon /> : undefined}>
        <Toolbar
          css={css`
            display: flex;
            flex-grow: 1;
          `}
        >
          <SectionTitle>LP Transactions</SectionTitle>
          <HorizontalAutoSpacer />
          <RefreshIconButton
            refreshing={refreshing}
            loading={loading}
            onRefresh={onRefresh}
            autoRefresh={autoRefresh}
            includeRefreshText
          />
        </Toolbar>
      </AccordionSummary>
      <AccordionDetails>
        <ClientTransactionsTable
          clientTransactions={clientTransactions}
          hideClient={hideClient}
          hideFund={hideFund}
          onClickClientTransactionRow={onClickClientTransaction}
          error={error}
        />
      </AccordionDetails>
    </Accordion>
  );
};
export default ClientTransactionsAccordion;
