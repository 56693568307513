import { css } from '@emotion/react';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import ClippingAppBar from '@paypr/mui5-common-components/dist/components/page/bar/ClippingAppBar';
import PageBarDivider from '@paypr/mui5-common-components/dist/components/page/bar/PageBarDivider';
import React from 'react';
import { useDateTime } from '../../../../data/dates';
import UserMenu from '../../../user/UserMenu';
import NorrioIcon from '../../icons/NorrioIcon';

import PageBarTitle from './PageBarTitle';

const PageBar = () => {
  const theme = useTheme();
  const { now, isLocalTimeZone } = useDateTime();
  return (
    <ClippingAppBar data-qa="page-bar">
      <Toolbar
        css={css`
          height: ${theme.spacing(4)};
          overflow-y: clip;
        `}
      >
        <NorrioIcon height={theme.spacing(3)} />
        &nbsp;
        <PageBarTitle />
        {!isLocalTimeZone ? (
          <div
            css={css`
              margin-left: ${theme.spacing(1)};
              margin-right: ${theme.spacing(1)};
            `}
          >
            {now().toFormat('ZZZZZ')}
          </div>
        ) : null}
        <PageBarDivider />
        <UserMenu />
      </Toolbar>
    </ClippingAppBar>
  );
};
export default PageBar;
