import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import React from 'react';
import { StrategiesTableRow_Strategy } from '../../generated/graphql';
import NoDataError from '../common/page/NoDataError';
import StrategiesTable, { StrategyClickEventHandler } from './StrategiesTable';

export interface StrategiesSectionProps extends RefreshingComponentProps {
  strategies?: readonly StrategiesTableRow_Strategy[];
  onClickStrategy?: StrategyClickEventHandler;
  includeDeletedStrategies?: boolean;
  onUpdateIncludeDeletedStrategies?: (includeDeletedStrategies: boolean) => void;
}

const StrategiesSection = ({
  strategies,
  loading,
  refreshing,
  onRefresh,
  autoRefresh,
  onClickStrategy,
  includeDeletedStrategies,
  onUpdateIncludeDeletedStrategies,
}: StrategiesSectionProps) => (
  <Paper>
    <Toolbar>
      <SectionTitle>Strategies</SectionTitle>
      <HorizontalAutoSpacer />
      <RefreshIconButton
        refreshing={refreshing}
        loading={loading}
        onRefresh={onRefresh}
        autoRefresh={autoRefresh}
        includeRefreshText
      />
    </Toolbar>
    {onUpdateIncludeDeletedStrategies ? (
      <DetailContainer>
        <FormControlLabel
          control={
            <Switch
              defaultChecked={includeDeletedStrategies}
              onChange={(_, value) => onUpdateIncludeDeletedStrategies(value)}
            />
          }
          label="Include Deleted"
        />
      </DetailContainer>
    ) : null}

    {strategies ? (
      <StrategiesTable strategies={strategies} onClickStrategyRow={onClickStrategy} />
    ) : !(loading || refreshing) ? (
      <NoDataError />
    ) : null}
  </Paper>
);
export default StrategiesSection;
