import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import React from 'react';
import { OrdersTableData_OrderConnection } from '../../generated/graphql';
import NoDataError from '../common/page/NoDataError';
import OrdersTable, { OrderClickEventHandler } from './OrdersTable';

export interface OrdersSectionProps extends RefreshingComponentProps {
  orders?: OrdersTableData_OrderConnection;
  onClickOrder?: OrderClickEventHandler;
}

const OrdersSection = ({ orders, loading, refreshing, onRefresh, autoRefresh, onClickOrder }: OrdersSectionProps) => (
  <Paper>
    <Toolbar>
      <SectionTitle>Orders</SectionTitle>
      <HorizontalAutoSpacer />
      <RefreshIconButton
        refreshing={refreshing}
        loading={loading}
        onRefresh={onRefresh}
        autoRefresh={autoRefresh}
        includeRefreshText
      />
    </Toolbar>

    {orders ? (
      <OrdersTable orderConnection={orders} onClickOrderRow={onClickOrder} />
    ) : !(loading || refreshing) ? (
      <NoDataError />
    ) : null}
  </Paper>
);
export default OrdersSection;
