import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import FullPageContainer from '@paypr/mui5-common-components/dist/components/page/FullPageContainer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useState } from 'react';
import UserIcon from '../../components/common/icons/UserIcon';
import { homePath } from '../../components/common/routes/paths';
import { useAuthentication } from '../../hooks/auth';
import { ErrorKey, useErrors } from '../../hooks/errors';
import { usePageTitle } from '../../hooks/page';
import { usePaths } from '../../hooks/paths';
import ChangePasswordForm from './ChangePasswordForm';

const LoginPage = () => {
  const [updateErrorKey, setUpdateErrorKey] = useState<ErrorKey>();
  const { user, loading, changePassword } = useAuthentication();
  const { showError, clearError } = useErrors();
  const { enqueueSnackbar } = useSnackbar();
  const { navigateTo } = usePaths();

  usePageTitle('Change Password');

  const goHome = () => {
    navigateTo(homePath);
  };

  const handleUpdatePassword = async (oldPassword: string, newPassword: string) => {
    try {
      if (updateErrorKey) {
        clearError(updateErrorKey);
        setUpdateErrorKey(undefined);
      }

      await changePassword(oldPassword, newPassword);
      enqueueSnackbar('Password updated successfully.', { variant: 'success' });
      goHome();
    } catch (e) {
      setUpdateErrorKey(showError(e));
    }
  };

  const handleCancel = () => {
    goHome();
  };

  if (!user) {
    return null;
  }

  return (
    <FullPageContainer>
      <Paper>
        <Toolbar>
          <UserIcon />
          &nbsp;
          <SectionTitle>My Account</SectionTitle>
        </Toolbar>

        <ChangePasswordForm
          email={user.email}
          loading={loading}
          onUpdatePassword={handleUpdatePassword}
          onCancel={handleCancel}
        />
      </Paper>
    </FullPageContainer>
  );
};
export default LoginPage;
