import { gql } from '@apollo/client';
import NavItem, { NavItemProps } from '@paypr/mui5-common-components/dist/components/page/nav/NavItem';
import * as React from 'react';
import { useMatch } from 'react-router-dom';
import { useClientFundNavItemQuery } from '../../generated/graphql';
import { LpFundPageParams } from './clientFundPaths';
import { buildClientFundTitle } from './clientFundUtils';

type ClientFundNavItemProps = Omit<NavItemProps, 'title'>;

const ClientFundNavItem = ({ path, ...rest }: ClientFundNavItemProps) => {
  const match = useMatch<keyof LpFundPageParams, typeof path>(path);

  const extractClientId = (): string | null => {
    return match?.params?.clientId || null;
  };

  const extractFundId = (): string | null => {
    return match?.params?.fundId || null;
  };

  const clientId = extractClientId();
  const fundId = extractFundId();

  const { data } = useClientFundNavItemQuery({
    variables: clientId && fundId ? { clientId, fundId } : undefined,
    skip: !clientId || !fundId || !match,
  });

  const title = buildClientFundTitle(data?.clientFund?.client.fullName, data?.clientFund?.fund.name);

  return <NavItem {...rest} title={title} path={path} />;
};
export default ClientFundNavItem;

gql`
  query ClientFundNavItemQuery($clientId: ID!, $fundId: ID!) {
    clientFund(clientId: $clientId, fundId: $fundId) {
      id
      client {
        id
        fullName
      }
      fund {
        id
        name
      }
    }
  }
`;
