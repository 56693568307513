import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import React from 'react';
import { WorkflowsTableData_WorkflowConnection } from '../../generated/graphql';
import NoDataError from '../common/page/NoDataError';
import WorkflowFilterChoices from './WorkflowFilterChoices';
import { WorkflowFilters } from './workflowPaths';
import WorkflowsTable, { WorkflowClickEventHandler } from './WorkflowsTable';

export interface WorkflowsSectionProps extends RefreshingComponentProps {
  workflows?: WorkflowsTableData_WorkflowConnection;
  onClickWorkflow?: WorkflowClickEventHandler;
  defaultOptionsExpanded?: boolean;
  onOptionsExpandedChanged?: (expanded: boolean) => void;
  defaultFilters?: WorkflowFilters;
  onChangeFilters?: (filters: WorkflowFilters) => void;
}

const WorkflowsSection = ({
  workflows,
  autoRefresh,
  loading,
  refreshing,
  onRefresh,
  onClickWorkflow,
  defaultOptionsExpanded,
  onOptionsExpandedChanged,
  defaultFilters,
  onChangeFilters,
}: WorkflowsSectionProps) => (
  <Paper>
    <Toolbar>
      <SectionTitle>Workflows</SectionTitle>
      <HorizontalAutoSpacer />
      <RefreshIconButton
        autoRefresh={autoRefresh}
        loading={loading}
        refreshing={refreshing}
        onRefresh={onRefresh}
        includeRefreshText
      />
    </Toolbar>
    {defaultFilters && onChangeFilters && (
      <WorkflowFilterChoices
        defaultExpanded={defaultOptionsExpanded}
        onOptionsExpandedChanged={onOptionsExpandedChanged}
        defaultFilters={defaultFilters}
        onChangeFilters={onChangeFilters}
        loading={loading}
      />
    )}
    {workflows ? (
      <WorkflowsTable workflowConnection={workflows} onClickWorkflowRow={onClickWorkflow} />
    ) : !(loading || refreshing) ? (
      <NoDataError />
    ) : null}
  </Paper>
);
export default WorkflowsSection;
