import { gql } from '@apollo/client';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import LoaderButton from '@paypr/mui5-common-components/dist/components/forms/LoaderButton';
import SecondaryButton from '@paypr/mui5-common-components/dist/components/forms/SecondaryButton';
import SubSectionTitle from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import React, { useEffect } from 'react';
import { AddTransferNoticeDialog_Client, CreateTransferNoticeInput } from '../../../../generated/graphql';
import { useFormFields } from '../../../../hooks/forms';
import { isTextPositiveFloat, isTextPositiveFloatOrZero } from '../../../../utils/strings';
import { buildClientTitle } from '../../clientUtils';
import AddTransferNoticeChanges from './AddTransferNoticeChanges';
import AddTransferNoticeEditor, { AddTransferNoticeFields } from './AddTransferNoticeEditor';

export interface AddTransferNoticeDialogProps extends Omit<DialogProps, 'onClose' | 'maxWidth'> {
  client: AddTransferNoticeDialog_Client;
  onSubmitCreateTransferNotice: SubmitCreateTransferNoticeEventHandler;
  submitting?: boolean;
  onClose: (
    event: React.MouseEvent<HTMLButtonElement> | {},
    reason: 'backdropClick' | 'escapeKeyDown' | 'cancelClicked',
  ) => void;
}

export type SubmitCreateTransferNoticeEventHandler = (input: CreateTransferNoticeInput) => void | Promise<void>;

const AddTransferNoticeDialog = ({
  client,
  onSubmitCreateTransferNotice,
  onClose,
  submitting,
  open,
  ...props
}: AddTransferNoticeDialogProps) => {
  const startingPage = 1;
  const maxPages = 2;
  const [page, setPage] = React.useState(startingPage);

  const initialState: AddTransferNoticeFields = {
    fundId: client.funds[0]?.fund.id || '',
    originatingAccountNumber: '',
    amount: '',
    markProcessed: 'true',
  };
  const { fields, handleFieldChange, resetFields } = useFormFields<AddTransferNoticeFields>(initialState);

  useEffect(() => {
    if (!open) {
      return;
    }

    resetFields();
    setPage(startingPage);
  }, [open]);

  const hasChanges = JSON.stringify(initialState) !== JSON.stringify(fields);

  const formValid = hasChanges && isTextPositiveFloatOrZero(fields.amount);

  const input: CreateTransferNoticeInput = {
    clientId: client.id,
    fundId: fields.fundId,
    originatingAccountNumber: fields.originatingAccountNumber.trim(),
    amount: (isTextPositiveFloat(fields.amount) && parseFloat(fields.amount)) || 0,
    markProcessed: fields.markProcessed === 'true',
  };

  const handleSubmitChange = async () => await onSubmitCreateTransferNotice(input);

  const nextPage = () => {
    setPage((page) => page + 1);
  };

  const prevPage = () => {
    setPage((page) => page - 1);
  };

  return (
    <Dialog {...props} open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>Submit Transfer Notice on behalf of {buildClientTitle(client.fullName)}</DialogTitle>
      <DialogContent>
        {page == 1 && <AddTransferNoticeEditor client={client} fields={fields} handleFieldChange={handleFieldChange} />}
        {page == 2 && (
          <>
            <SubSectionTitle>Verify Changes</SubSectionTitle>
            <AddTransferNoticeChanges client={client} input={input} />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={1}>
          <SecondaryButton onClick={(event) => onClose(event, 'cancelClicked')} disabled={submitting}>
            Cancel
          </SecondaryButton>

          {page < maxPages && (
            <SecondaryButton onClick={() => resetFields()} disabled={submitting}>
              Reset
            </SecondaryButton>
          )}

          {page > startingPage && (
            <SecondaryButton onClick={prevPage} disabled={submitting}>
              Back
            </SecondaryButton>
          )}

          {page < maxPages && (
            <Button color="inherit" onClick={nextPage} disabled={!formValid || submitting}>
              Next
            </Button>
          )}

          {page == maxPages && (
            <LoaderButton loading={submitting} disabled={!formValid} onClick={handleSubmitChange}>
              Submit Add
            </LoaderButton>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
export default AddTransferNoticeDialog;

export const addTransferNoticeDialogFragment = gql`
  fragment AddTransferNoticeDialog_Client on Client {
    id
    fullName
    funds {
      id
      fund {
        id
        name
      }
    }
  }
`;
