import { gql } from '@apollo/client';
import React from 'react';
import { useDshStrategyVersionTitleQuery } from '../../generated/graphql';
import { usePageTitle } from '../../hooks/page';

export interface DshStrategyVersionTitleProps {
  version: string;
  pageName?: string;
  asset?: string;
}

const DshStrategyVersionTitle = ({ version, pageName, asset }: DshStrategyVersionTitleProps) => {
  const { data } = useDshStrategyVersionTitleQuery({ variables: { id: version } });
  return <>{buildTitle(data?.dshVersion?.name, pageName, asset, data?.dshVersion?.active)}</>;
};
export default DshStrategyVersionTitle;

gql`
  query DshStrategyVersionTitleQuery($id: ID!) {
    dshVersion(id: $id) {
      id
      name
      active
    }
  }
`;

export const useDshStrategyVersionPageTitle = (version: string, pageName?: string, asset?: string) => {
  const { data } = useDshStrategyVersionTitleQuery({ variables: { id: version } });

  usePageTitle(buildTitle(data?.dshVersion?.name, pageName, asset, data?.dshVersion?.active));
};

export const useDshStrategyVersionTitle = (version: string, asset?: string) => {
  const { data } = useDshStrategyVersionTitleQuery({ variables: { id: version }, skip: !version });

  return buildTitle(data?.dshVersion?.name, undefined, asset, data?.dshVersion?.active);
};

const buildTitle = (versionName: string | undefined, pageName?: string, asset?: string, active?: boolean) => {
  const inactive = active === false ? ' (inactive)' : '';

  return versionName
    ? `DSH Strategy${pageName ? ` ${pageName}` : ''} - ${versionName}${asset ? ` - ${asset}` : ''}${inactive}`
    : `DSH Strategy${inactive}`;
};
