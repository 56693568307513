import { gql } from '@apollo/client';
import NavItem from '@paypr/mui5-common-components/dist/components/page/nav/NavItem';
import React from 'react';
import { useStrategyNavItemsQuery } from '../../generated/graphql';
import StrategyIcon from './StrategyIcon';
import { buildStrategyPath } from './strategyPaths';

export interface StrategyNavItemsProps {
  disabled?: boolean;
}

const StrategyNavItems = ({ disabled }: StrategyNavItemsProps) => {
  const { data } = useStrategyNavItemsQuery();

  return (
    <>
      {data?.strategies.map(({ id, name }) => (
        <NavItem key={id} title={name} icon={StrategyIcon} path={buildStrategyPath(id)} disabled={disabled} child />
      ))}
    </>
  );
};
export default StrategyNavItems;

gql`
  query StrategyNavItemsQuery {
    strategies {
      id
      name
    }
  }
`;
