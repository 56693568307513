import { gql } from '@apollo/client';
import { css } from '@emotion/react';
import Grid from '@mui/material/Grid';
import RouteLink from '@paypr/mui5-common-components/dist/components/routes/RouteLink';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import DetailValue from '@paypr/mui5-common-components/dist/components/typography/DetailValue';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { AuditActionDetail_AuditAction, AuditActionWorkflowDetail_AuditAction } from '../../generated/graphql';
import { snakeOrKebabToDisplay } from '../../utils/strings';
import DetailGridItem from '../common/containers/DetailGridItem';
import { buildInternalUserPath } from '../internalUsers/internalUserPaths';
import { buildSubsystemPath } from '../subsystems/subsystemPaths';
import { buildWorkflowPath } from '../workflows/workflowPaths';
import { buildWorkflowStatusDisplay } from '../workflows/workflowUtils';

export type AuditActionDetailData = AuditActionDetail_AuditAction | AuditActionWorkflowDetail_AuditAction;

interface AuditActionDetailProps {
  auditAction: AuditActionDetailData;
}

const AuditActionDetail = ({ auditAction }: AuditActionDetailProps) => {
  const { formatDateTime } = useDateTime();

  return (
    <DetailContainer>
      <Grid container justifyContent="left" spacing={2}>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Subsystem</DetailTitle>
            <DetailValue>
              <RouteLink path={buildSubsystemPath(auditAction.subsystem.id)}>{auditAction.subsystem.label}</RouteLink>{' '}
            </DetailValue>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Type</DetailTitle>
            <DetailValue>{buildAuditActionTypeDisplay(auditAction.type)}</DetailValue>
          </DetailField>
        </DetailGridItem>
        <DetailGridItem>
          <DetailField>
            <DetailTitle>User</DetailTitle>
            <DetailValue>
              <RouteLink path={buildInternalUserPath(auditAction.user.id)}>{auditAction.user.fullName}</RouteLink>{' '}
            </DetailValue>
          </DetailField>
        </DetailGridItem>
        {'workflow' in auditAction && auditAction.workflow && (
          <DetailGridItem>
            <DetailField>
              <DetailTitle>Status</DetailTitle>
              <DetailValue>
                <RouteLink path={buildWorkflowPath(auditAction.workflow.id)}>
                  {buildWorkflowStatusDisplay(auditAction.workflow.status)}
                </RouteLink>
              </DetailValue>
            </DetailField>
          </DetailGridItem>
        )}
        <DetailGridItem>
          <DetailField>
            <DetailTitle>Created</DetailTitle>
            <DetailValue>{formatDateTime(auditAction.createdAt)}</DetailValue>
          </DetailField>
        </DetailGridItem>
      </Grid>
      <DetailField>
        <DetailTitle>Changes</DetailTitle>
        <DetailValue
          css={css`
            white-space: pre;
          `}
        >
          {JSON.stringify(JSON.parse(auditAction.changes), null, 2)}
        </DetailValue>
      </DetailField>
    </DetailContainer>
  );
};
export default AuditActionDetail;

export const buildAuditActionTypeDisplay = (actionType?: string) => {
  switch (actionType) {
    case 'create-lp':
      return 'Create LP';

    case 'enable-disable-admin-user':
      return 'Enable/Disable Admin User';

    case 'enable-disable-lp':
      return 'Enable/Disable LP';
  }

  return actionType ? snakeOrKebabToDisplay(actionType) : 'Update';
};

export const buildAuditActionTitle = (subsystemLabel?: string, actionType?: string) =>
  subsystemLabel || actionType ? `${subsystemLabel} ${buildAuditActionTypeDisplay(actionType)}` : 'Activity';

export const auditActionDetailFragment = gql`
  fragment AuditActionDetail_AuditAction on AuditAction {
    id
    subsystem {
      id
      label
    }
    user {
      id
      fullName
    }
    type
    changes
    createdAt
  }

  fragment AuditActionWorkflowDetail_AuditAction on AuditAction {
    ...AuditActionDetail_AuditAction

    workflow {
      id
      status
    }
  }
`;
