import { gql } from '@apollo/client';
import { WorkflowReference_Workflow, WorkflowStatus, WorkflowType } from '../../generated/graphql';
import { snakeOrKebabToDisplay } from '../../utils/strings';

export const buildWorkflowTitle = (workflowId?: string, title?: string) =>
  title || (workflowId ? `Workflow ${workflowId}` : 'Workflow');

export const buildWorkflowTypeDisplay = (type: WorkflowType) => snakeOrKebabToDisplay(type);

export const buildWorkflowStatusDisplay = (status: WorkflowStatus) => snakeOrKebabToDisplay(status);

export const buildWorkflowReferenceTitle = (workflow: WorkflowReference_Workflow) =>
  buildWorkflowTitle(workflow.id, workflow.title);

export const workflowReferenceFragment = gql`
  fragment WorkflowReference_Workflow on Workflow {
    id
    title
  }
`;
