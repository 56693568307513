import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import React from 'react';
import { BooleanFormatType, formatBoolean } from '../typography/BooleanFormat';

export interface BooleanFieldProps extends Omit<RadioGroupProps, 'children'> {
  booleanType?: BooleanFormatType;
  trueValue?: string;
  falseValue?: string;
}

const BooleanField = ({ booleanType, trueValue, falseValue, ...rest }: BooleanFieldProps) => (
  <RadioGroup {...rest}>
    <FormControlLabel
      radioGroup="stopWhenLedgerIsEmpty"
      value={trueValue || 'true'}
      control={<Radio />}
      label={formatBoolean(true, booleanType)}
    />
    <FormControlLabel
      radioGroup="stopWhenLedgerIsEmpty"
      value={falseValue || 'false'}
      control={<Radio />}
      label={formatBoolean(false, booleanType)}
    />
  </RadioGroup>
);
export default BooleanField;
