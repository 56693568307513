// trades
import { TradeStatus } from '../../generated/graphql';
import { QueryParams } from '../common/routes/paths';

export const tradesPath = '/trades';

export interface TradesPageQueryParams {
  assets: string;
  inProgress: string;
  status: string;
  optionsExpanded: string;
}

export interface TradeFilters {
  assets: string[] | null;
  inProgress: boolean | null;
  status: TradeStatus | null;
}

export const extractTradeFiltersFromUrl = (params: QueryParams<TradesPageQueryParams>): TradeFilters => {
  const assetsText = params.get('assets');
  const assets = assetsText ? assetsText.split(',') : null;

  const inProgressText = params.get('inProgress');
  const inProgress = inProgressText
    ? inProgressText === 'true'
      ? true
      : inProgressText === 'false'
        ? false
        : null
    : null;

  const statusText = params.get('status');
  const status = statusText ? (statusText as TradeStatus) : null;

  return {
    assets,
    inProgress,
    status,
  };
};

export const buildTradesUrlWithFilters = (filters: TradeFilters, optionsExpanded: boolean): string => {
  const { assets, inProgress, status } = filters;
  const params = {
    assets: assets ? assets.join(',') : '',
    inProgress: inProgress !== null && inProgress !== undefined ? inProgress.toString() : '',
    status: status ? status : '',
    optionsExpanded: optionsExpanded ? 'true' : 'false',
  };
  return `${tradesPath}?${new URLSearchParams(params).toString()}`;
};

export const buildTradePath = (tradeId: string) => `${tradesPath}/${tradeId}`;

export const parameterizedTradePath = buildTradePath(':tradeId');
export interface TradePageParams {
  tradeId: string;
}
export interface TradePageQueryParams {}
