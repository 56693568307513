import { gql } from '@apollo/client';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { DatePicker } from '@mui/x-date-pickers';
import TooltipIconButton from '@paypr/mui5-common-components/dist/components/actions/TooltipIconButton';
import DefaultTextField from '@paypr/mui5-common-components/dist/components/forms/DefaultTextField';
import DetailError from '@paypr/mui5-common-components/dist/components/typography/DetailError';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailInlineValue from '@paypr/mui5-common-components/dist/components/typography/DetailInlineValue';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import SubSectionTitle from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import TooltipItem from '@paypr/mui5-common-components/dist/components/typography/TooltipItem';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { useDateTime } from '../../data/dates';
import {
  LedgerReportChoiceClient,
  LedgerReportChoiceFund,
  LedgerReportChoicePortfolio,
  LedgerReportChoiceStrategy,
  LedgerReportGroupField,
  LedgerReportTimeResolution,
  LedgerReportValueField,
} from '../../generated/graphql';
import { useFormFields } from '../../hooks/forms';
import RemoveIcon from '../common/icons/RemoveIcon';
import { AutocompleteOption } from '../common/model/autocomplete';
import {
  determineEndAtFromStandardTimeframe,
  determineFieldDescription,
  determineFieldName,
  determineStartAtFromStandardTimeframe,
  determineTimeFromTimeResolution,
  determineTimeResolutionFromDates,
  findLedgerReportGroupsDataByGroups,
  findLedgerReportGroupsDataByName,
  formatDateTime,
  getGroupsValues,
  ledgerReportGroupsData,
  reportFieldNotDeprecated,
  sortReportValueFields,
  timeResolutionValues,
} from './ledgerReportHelpers';
import { LedgerReportAugmentedDefinition, LedgerReportStandardTimeframe } from './ledgerReportModel';

export interface LedgerReportDataChoicesProps {
  defaultReportDefinition: LedgerReportAugmentedDefinition;
  assets?: readonly string[];
  clients?: readonly LedgerReportChoiceClient[];
  funds?: readonly LedgerReportChoiceFund[];
  portfolios?: readonly LedgerReportChoicePortfolio[];
  strategies?: readonly LedgerReportChoiceStrategy[];
  onChangeReportDefinition: (reportDefinition: LedgerReportAugmentedDefinition) => void;
  loading?: boolean;
}

interface LedgerReportDataChoiceFields {
  standardTimeframe: LedgerReportStandardTimeframe;
  useStandardTimeframe: string;
  startAt: DateTime;
  endAt: DateTime;
  endAtNow: string;
  includeTotals: string;
  timeResolution: LedgerReportTimeResolution;
  groupByName: string;
  addAssetFilterText: string;
  addClientFilterText: string;
  addFundFilterText: string;
  addPortfolioFilterText: string;
  addStrategyFilterText: string;
  addValueFieldText: string;
  includeEvents: string;
  addMarketAssetText: string;
}

const LedgerReportDataChoices = ({
  defaultReportDefinition,
  assets,
  clients,
  funds,
  portfolios,
  strategies,
  onChangeReportDefinition,
  loading,
}: LedgerReportDataChoicesProps) => {
  const { now, toDateTime } = useDateTime();

  const [reportGroupBy, setReportGroupBy] = React.useState<LedgerReportGroupField[]>(defaultReportDefinition.groupBy);

  const [reportGroupFilters, setReportGroupFilters] = React.useState<Partial<Record<LedgerReportGroupField, string[]>>>(
    defaultReportDefinition.groupFilters || {},
  );

  const [reportValues, setReportValues] = React.useState<LedgerReportValueField[]>(defaultReportDefinition.values);

  const [reportMarketAssets, setReportMarketAssets] = React.useState<string[]>(
    defaultReportDefinition.marketAssets || [],
  );

  const { fields, handleFieldChange, setFieldValue } = useFormFields<LedgerReportDataChoiceFields>({
    standardTimeframe: defaultReportDefinition.standardTimeframe || LedgerReportStandardTimeframe.AllTime,
    useStandardTimeframe: defaultReportDefinition.standardTimeframe ? 'true' : 'false',
    startAt: defaultReportDefinition.startAt,
    endAt: defaultReportDefinition.endAt || now(),
    endAtNow: defaultReportDefinition.endAt ? 'false' : 'true',
    includeTotals: defaultReportDefinition.includeTotals ? 'true' : 'false',
    timeResolution: defaultReportDefinition.timeResolution,
    groupByName: findLedgerReportGroupsDataByGroups(defaultReportDefinition.groupBy).name,
    includeEvents: defaultReportDefinition.includeAccountEvents ? 'true' : 'false',
    addAssetFilterText: '',
    addClientFilterText: '',
    addFundFilterText: '',
    addPortfolioFilterText: '',
    addStrategyFilterText: '',
    addValueFieldText: '',
    addMarketAssetText: '',
  });

  const bestTimeResolution = determineTimeResolutionFromDates(
    fields.startAt,
    fields.endAt,
    LedgerReportTimeResolution.Hour,
    { now },
  );

  useEffect(() => {
    updateReportDefinition();
  }, [reportValues, fields, reportGroupFilters, reportMarketAssets]);

  const handleStandardTimeframeChange = (
    newStandardTimeframe: LedgerReportStandardTimeframe,
    timeResolution?: LedgerReportTimeResolution,
  ) => {
    setFieldValue('standardTimeframe', newStandardTimeframe);
    setFieldValue('useStandardTimeframe', 'true');

    const startAt = determineStartAtFromStandardTimeframe(newStandardTimeframe, { now, toDateTime });
    const endAt = determineEndAtFromStandardTimeframe(newStandardTimeframe, { now });
    const newTimeResolution =
      timeResolution ||
      determineTimeResolutionFromDates(startAt, endAt, LedgerReportTimeResolution.Hour, {
        now,
      });
    const newStartAt = determineTimeFromTimeResolution(startAt, newTimeResolution);
    const newEndAt = endAt ? determineTimeFromTimeResolution(endAt, newTimeResolution) : null;

    handleStartAndEndAtChange(newStartAt, newEndAt, newTimeResolution);
  };

  const handleUseStandardTimeframeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newUseStandardTimeframe = event.target.value === 'true';
    setFieldValue('useStandardTimeframe', newUseStandardTimeframe ? 'true' : 'false');
    if (!newUseStandardTimeframe) {
      return;
    }

    handleStandardTimeframeChange(fields.standardTimeframe);
  };

  const handleStandardTimeframeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newStandardTimeframe = event.target.value as LedgerReportStandardTimeframe;
    handleStandardTimeframeChange(newStandardTimeframe);
  };

  const handleStartAtChange = (newStartAt: DateTime) => {
    handleStartAndEndAtChange(newStartAt, fields.endAt, fields.timeResolution);
  };

  const handleEndAtChange = (newEndAt: DateTime | null) => {
    handleStartAndEndAtChange(fields.startAt, newEndAt, fields.timeResolution);
  };

  const handleTimeResolutionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const newTimeResolution = value as LedgerReportTimeResolution;
    setFieldValue('timeResolution', newTimeResolution);

    if (fields.useStandardTimeframe === 'true') {
      handleStandardTimeframeChange(fields.standardTimeframe, newTimeResolution);
      return;
    }

    const newStartAt = determineTimeFromTimeResolution(fields.startAt, newTimeResolution);
    const newEndAt =
      fields.endAtNow === 'true' ? undefined : determineTimeFromTimeResolution(fields.endAt, newTimeResolution);

    handleStartAndEndAtChange(newStartAt, newEndAt, newTimeResolution);
  };

  const handleStartAndEndAtChange = (
    newStartAt: DateTime,
    newEndAt: DateTime | null,
    timeResolution: LedgerReportTimeResolution,
  ) => {
    if (!newStartAt.isValid || (newEndAt && !newEndAt.isValid)) {
      return;
    }

    const newTimeResolution = determineTimeResolutionFromDates(newStartAt, newEndAt, timeResolution, { now });

    const timeNow = now();

    const newStartAtTime = determineTimeFromTimeResolution(
      newStartAt > timeNow ? defaultReportDefinition.startAt : newStartAt,
      newTimeResolution,
    );
    const newEndAtTime = determineTimeFromTimeResolution(
      !newEndAt || newEndAt > timeNow ? timeNow : newEndAt,
      newTimeResolution,
    );

    setFieldValue('startAt', newStartAtTime);
    setFieldValue('endAt', newEndAtTime);
    setFieldValue('endAtNow', !newEndAt || newEndAtTime >= timeNow ? 'true' : 'false');
    setFieldValue('timeResolution', newTimeResolution);
  };

  const updateReportDefinition = () => {
    if (reportValues.length == 0) {
      return;
    }

    const standardTimeframe = fields.useStandardTimeframe === 'true' ? fields.standardTimeframe : undefined;
    const startAt = standardTimeframe
      ? determineStartAtFromStandardTimeframe(standardTimeframe, { now, toDateTime })
      : determineTimeFromTimeResolution(fields.startAt, fields.timeResolution);

    const endAt = standardTimeframe
      ? determineEndAtFromStandardTimeframe(standardTimeframe, { now })
      : fields.endAtNow === 'true'
        ? undefined
        : determineTimeFromTimeResolution(fields.endAt, fields.timeResolution);

    const newStartAt = determineTimeFromTimeResolution(startAt, fields.timeResolution);
    const newEndAt = endAt ? determineTimeFromTimeResolution(endAt, fields.timeResolution) : null;

    const newReportDefinition = {
      standardTimeframe: fields.useStandardTimeframe === 'true' ? fields.standardTimeframe : undefined,
      startAt: newStartAt,
      endAt: newEndAt,
      timeResolution: fields.timeResolution,

      groupBy: reportGroupBy,
      groupFilters: reportGroupFilters,
      includeTotals: fields.includeTotals === 'true',
      values: reportValues,
      includeAccountEvents: fields.includeEvents === 'true',
      marketAssets: reportMarketAssets,
    };
    console.debug('newReportDefinition', newReportDefinition);
    onChangeReportDefinition(newReportDefinition);
  };

  const handleUpdateGroupBy = (event) => {
    const { value } = event.target;
    const groupsData = findLedgerReportGroupsDataByName(value);

    setFieldValue('groupByName', value);

    setReportGroupFilters((currentFilters) =>
      Object.keys(currentFilters).reduce((filters, key) => {
        const group = key as LedgerReportGroupField;
        if (groupsData.groups.includes(group)) {
          filters[group] = currentFilters[group];
        }

        return filters;
      }, {}),
    );

    setReportGroupBy(groupsData.groups);
    setReportValues((currentValues) => currentValues.filter((valueField) => groupsData.values.includes(valueField)));
  };

  // assets
  const addAssetFilterOptions: string[] = (assets || [])
    .filter((asset) => !reportGroupFilters[LedgerReportGroupField.Asset]?.includes(asset))
    .sort((a, b) => a.localeCompare(b));

  const handleAddAssetFilter = (event, newAsset: string) => {
    event.preventDefault();
    if (!newAsset) {
      return;
    }
    setReportGroupFilters((currentFilters) => ({
      ...currentFilters,
      [LedgerReportGroupField.Asset]: [...(currentFilters[LedgerReportGroupField.Asset] || []), newAsset],
    }));
    setFieldValue('addAssetFilterText', '');
  };

  const handleRemoveAssetFilter = (removeAssetFilter: string) =>
    setReportGroupFilters((currentFilters) => ({
      ...currentFilters,
      [LedgerReportGroupField.Asset]: (currentFilters[LedgerReportGroupField.Asset] || []).filter(
        (asset) => asset !== removeAssetFilter,
      ),
    }));

  // clients
  const buildClientName = (client: LedgerReportChoiceClient) => client.fullName;

  const addClientFilterOptions: AutocompleteOption[] = (clients || [])
    .filter((client) => !reportGroupFilters[LedgerReportGroupField.Client]?.includes(client.id))
    .map((client) => ({
      id: client.id,
      label: buildClientName(client),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const getClientName = (clientId: string) => {
    const client = clients?.find((option) => option.id === clientId);
    if (client) {
      return buildClientName(client);
    }
    return `Unknown Client ${clientId}`;
  };

  const handleAddClientFilter = (event, newClient: AutocompleteOption | '') => {
    event.preventDefault();
    if (!newClient) {
      return;
    }
    setReportGroupFilters((currentFilters) => ({
      ...currentFilters,
      [LedgerReportGroupField.Client]: [...(currentFilters[LedgerReportGroupField.Client] || []), newClient.id],
    }));
    setFieldValue('addClientFilterText', '');
  };

  const handleRemoveClientFilter = (removeClient: string) =>
    setReportGroupFilters((currentFilters) => ({
      ...currentFilters,
      [LedgerReportGroupField.Client]: (currentFilters[LedgerReportGroupField.Client] || []).filter(
        (client) => client !== removeClient,
      ),
    }));

  // funds
  const addFundFilterOptions: AutocompleteOption[] = (funds || [])
    .filter((fund) => !reportGroupFilters[LedgerReportGroupField.Fund]?.includes(fund.id))
    .map((fund) => ({
      id: fund.id,
      label: fund.name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const getFundName = (fundId: string) =>
    funds?.find((option) => option.id === fundId)?.name || `Unknown Fund ${fundId}`;

  const handleAddFundFilter = (event, newFund: AutocompleteOption | '') => {
    event.preventDefault();
    if (!newFund) {
      return;
    }
    setReportGroupFilters((currentFilters) => ({
      ...currentFilters,
      [LedgerReportGroupField.Fund]: [...(currentFilters[LedgerReportGroupField.Fund] || []), newFund.id],
    }));
    setFieldValue('addFundFilterText', '');
  };

  const handleRemoveFundFilter = (removeFund: string) =>
    setReportGroupFilters((currentFilters) => ({
      ...currentFilters,
      [LedgerReportGroupField.Fund]: (currentFilters[LedgerReportGroupField.Fund] || []).filter(
        (fund) => fund !== removeFund,
      ),
    }));

  // portfolios
  const addPortfolioFilterOptions: AutocompleteOption[] = (portfolios || [])
    .filter((portfolio) => !reportGroupFilters[LedgerReportGroupField.Portfolio]?.includes(portfolio.id))
    .map((portfolio) => ({
      id: portfolio.id,
      label: portfolio.name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const getPortfolioName = (portfolioId: string) =>
    portfolios?.find((option) => option.id === portfolioId)?.name || `Unknown Portfolio ${portfolioId}`;

  const handleAddPortfolioFilter = (event, newPortfolio: AutocompleteOption | '') => {
    event.preventDefault();
    if (!newPortfolio) {
      return;
    }
    setReportGroupFilters((currentFilters) => ({
      ...currentFilters,
      [LedgerReportGroupField.Portfolio]: [
        ...(currentFilters[LedgerReportGroupField.Portfolio] || []),
        newPortfolio.id,
      ],
    }));
    setFieldValue('addPortfolioFilterText', '');
  };

  const handleRemovePortfolioFilter = (removePortfolio: string) =>
    setReportGroupFilters((currentFilters) => ({
      ...currentFilters,
      [LedgerReportGroupField.Portfolio]: (currentFilters[LedgerReportGroupField.Portfolio] || []).filter(
        (portfolio) => portfolio !== removePortfolio,
      ),
    }));

  // strategies
  const addStrategyFilterOptions: AutocompleteOption[] = (strategies || [])
    .filter((strategy) => !reportGroupFilters[LedgerReportGroupField.Strategy]?.includes(strategy.id))
    .map((strategy) => ({
      id: strategy.id,
      label: strategy.name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const getStrategyName = (strategyId: string) =>
    strategies?.find((option) => option.id === strategyId)?.name || `Unknown Strategy ${strategyId}`;

  const handleAddStrategyFilter = (event, newStrategy: AutocompleteOption | '') => {
    event.preventDefault();
    if (!newStrategy) {
      return;
    }
    setReportGroupFilters((currentFilters) => ({
      ...currentFilters,
      [LedgerReportGroupField.Strategy]: [...(currentFilters[LedgerReportGroupField.Strategy] || []), newStrategy.id],
    }));
    setFieldValue('addStrategyFilterText', '');
  };

  const handleRemoveStrategyFilter = (removeStrategy: string) =>
    setReportGroupFilters((currentFilters) => ({
      ...currentFilters,
      [LedgerReportGroupField.Strategy]: (currentFilters[LedgerReportGroupField.Strategy] || []).filter(
        (strategy) => strategy !== removeStrategy,
      ),
    }));

  // values
  const addValueFieldOptions = getGroupsValues(reportGroupBy)
    .filter(reportFieldNotDeprecated)
    .filter((valueField) => !reportValues.includes(valueField))
    .map((valueField) => ({
      id: valueField,
      label: determineFieldName(valueField),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const handleAddValueField = (event, newValue: AutocompleteOption<LedgerReportValueField> | '') => {
    event.preventDefault();
    if (!newValue) {
      return;
    }
    setReportValues((currentValues) => [...currentValues, newValue.id]);
    setFieldValue('addValueFieldText', '');
  };

  const handleRemoveValueField = (removeValueField: string) =>
    setReportValues((currentValues) => currentValues.filter((valueField) => valueField !== removeValueField));

  // market assets
  const addMarketAssetOptions: string[] = (assets || [])
    .filter((asset) => !reportMarketAssets.includes(asset))
    .sort((a, b) => a.localeCompare(b));

  const handleAddMarketAsset = (event, newAsset: string) => {
    event.preventDefault();
    if (!newAsset) {
      return;
    }
    setReportMarketAssets((currentAssets) => [...currentAssets, newAsset]);
    setFieldValue('addMarketAssetText', '');
  };

  const handleRemoveMarketAsset = (removeMarketAsset: string) =>
    setReportMarketAssets((currentAssets) => currentAssets.filter((asset) => asset !== removeMarketAsset));

  // time resolution
  interface TimeResolutionControlProps {
    label: string;
    timeResolution: LedgerReportTimeResolution;
  }

  const cryptoMarketValueFields = new Set([
    LedgerReportValueField.CryptoMarketPnlCumulativeRoi,
    LedgerReportValueField.CryptoMarketPnlCumulativeValue,
    LedgerReportValueField.CryptoMarketPnlRoiChange,
    LedgerReportValueField.CryptoMarketPnlValueChange,
    LedgerReportValueField.MarketPnlCumulativeRoi,
    LedgerReportValueField.MarketPnlCumulativeValue,
    LedgerReportValueField.MarketPnlRoiChange,
    LedgerReportValueField.MarketPnlValueChange,
  ]);
  const marketValueFieldsShown =
    reportValues.filter((valueField) => cryptoMarketValueFields.has(valueField)).length > 0;

  const TimeResolutionControl = ({ label, timeResolution }: TimeResolutionControlProps) => (
    <FormControlLabel
      radioGroup="timeResolution"
      value={timeResolution}
      control={<Radio />}
      label={label}
      disabled={loading || timeResolutionValues[bestTimeResolution] > timeResolutionValues[timeResolution]}
    />
  );

  return (
    <>
      <SubSectionTitle>Report Data</SubSectionTitle>
      <DetailField>
        <DetailTitle>Dates</DetailTitle>
        <RadioGroup
          name="useStandardTimeframe"
          row
          onChange={handleUseStandardTimeframeChange}
          value={fields.useStandardTimeframe}
        >
          <FormControlLabel
            radioGroup="useStandardTimeframe"
            value="true"
            control={<Radio />}
            label="Use Standard Timeframe"
            disabled={loading}
          />
          <FormControlLabel
            radioGroup="useStandardTimeframe"
            value="false"
            control={<Radio />}
            label="Choose Start and End Dates"
            disabled={loading}
          />
        </RadioGroup>
      </DetailField>
      {fields.useStandardTimeframe === 'true' ? (
        <DetailField>
          <DefaultTextField
            id="standardTimeframe"
            value={fields.standardTimeframe}
            label="Timeframe"
            select
            onChange={handleStandardTimeframeEvent}
          >
            <MenuItem value={LedgerReportStandardTimeframe.AllTime}>All Time</MenuItem>
            <MenuItem value={LedgerReportStandardTimeframe.Year}>Last 365 Days</MenuItem>
            <MenuItem value={LedgerReportStandardTimeframe.Quarter}>Last 90 Days</MenuItem>
            <MenuItem value={LedgerReportStandardTimeframe.Month}>Last 30 Days</MenuItem>
            <MenuItem value={LedgerReportStandardTimeframe.Week}>Last 7 Days</MenuItem>
            <MenuItem value={LedgerReportStandardTimeframe.Day}>Last 24 Hours</MenuItem>
            <MenuItem value={LedgerReportStandardTimeframe.YearToDate}>Year-to-Date</MenuItem>
            <MenuItem value={LedgerReportStandardTimeframe.PreviousYear}>Previous Year</MenuItem>
            <MenuItem value={LedgerReportStandardTimeframe.QuarterToDate}>Quarter-to-Date</MenuItem>
            <MenuItem value={LedgerReportStandardTimeframe.PreviousQuarter}>Previous Quarter</MenuItem>
            <MenuItem value={LedgerReportStandardTimeframe.MonthToDate}>Month-to-Date</MenuItem>
            <MenuItem value={LedgerReportStandardTimeframe.PreviousMonth}>Previous Month</MenuItem>
          </DefaultTextField>
          {formatDateTime(fields.startAt, LedgerReportTimeResolution.Day)}&nbsp;to&nbsp;
          {fields.endAtNow === 'true' ? 'now' : formatDateTime(fields.endAt, LedgerReportTimeResolution.Day)}
        </DetailField>
      ) : (
        <>
          <DetailField>
            <DatePicker label="Start Date" value={fields.startAt} onChange={handleStartAtChange} disabled={loading} />
          </DetailField>
          <DetailField>
            <RadioGroup name="endAtNow" row onChange={handleFieldChange} value={fields.endAtNow}>
              <FormControlLabel
                radioGroup="endAtNow"
                value="true"
                control={<Radio />}
                label="End Now"
                disabled={loading}
              />
              <FormControlLabel
                radioGroup="endAtNow"
                value="false"
                control={<Radio />}
                label="Choose End Date"
                disabled={loading}
              />
            </RadioGroup>
          </DetailField>
          {fields.endAtNow !== 'true' ? (
            <DetailField>
              <DatePicker label="End Date" value={fields.endAt} onChange={handleEndAtChange} disabled={loading} />
            </DetailField>
          ) : null}
        </>
      )}
      <DetailField>
        <DetailTitle>Time Resolution</DetailTitle>
        <RadioGroup name="timeResolution" row onChange={handleTimeResolutionChange} value={fields.timeResolution}>
          <TimeResolutionControl label="Year" timeResolution={LedgerReportTimeResolution.Year} />
          <TimeResolutionControl label="Quarter" timeResolution={LedgerReportTimeResolution.Quarter} />
          <TimeResolutionControl label="Month" timeResolution={LedgerReportTimeResolution.Month} />
          <TimeResolutionControl label="Week" timeResolution={LedgerReportTimeResolution.Week} />
          <TimeResolutionControl label="Day" timeResolution={LedgerReportTimeResolution.Day} />
          <TimeResolutionControl label="Hour" timeResolution={LedgerReportTimeResolution.Hour} />
        </RadioGroup>
      </DetailField>
      <DetailField>
        <DefaultTextField
          id="groupByName"
          value={fields.groupByName}
          label="Group By"
          select
          onChange={handleUpdateGroupBy}
        >
          {ledgerReportGroupsData
            .filter(({ deprecated }) => !deprecated)
            .map((groupsData) => (
              <MenuItem key={groupsData.name} value={groupsData.name}>
                {groupsData.name}
              </MenuItem>
            ))}
        </DefaultTextField>
      </DetailField>
      {clients && reportGroupBy.includes(LedgerReportGroupField.Client) ? (
        <DetailField>
          <DetailTitle>LP Filter</DetailTitle>
          <FormGroup>
            <Autocomplete
              id="addClientFilter"
              inputValue={fields.addClientFilterText}
              onInputChange={(event, newInputValue) => setFieldValue('addClientFilterText', newInputValue)}
              options={addClientFilterOptions}
              onChange={handleAddClientFilter}
              disabled={loading || addClientFilterOptions.length === 0}
              renderInput={(params) => <DefaultTextField {...params} label="Add LP" placeholder="Pick a client" />}
            />
            {reportGroupFilters[LedgerReportGroupField.Client]?.map((client) => (
              <div key={client}>
                <TooltipIconButton
                  title={`Remove ${getClientName(client)}`}
                  onClick={() => handleRemoveClientFilter(client)}
                  disabled={loading}
                >
                  <RemoveIcon fontSize="small" />
                </TooltipIconButton>
                <DetailInlineValue>{getClientName(client)}</DetailInlineValue>
              </div>
            ))}
          </FormGroup>
        </DetailField>
      ) : null}
      {funds && reportGroupBy.includes(LedgerReportGroupField.Fund) ? (
        <DetailField>
          <DetailTitle>Fund Filter</DetailTitle>
          <FormGroup>
            <Autocomplete
              id="addFundFilter"
              inputValue={fields.addFundFilterText}
              onInputChange={(event, newInputValue) => setFieldValue('addFundFilterText', newInputValue)}
              options={addFundFilterOptions}
              onChange={handleAddFundFilter}
              disabled={loading || addFundFilterOptions.length === 0}
              renderInput={(params) => <DefaultTextField {...params} label="Add Fund" placeholder="Pick a fund" />}
            />
            {reportGroupFilters[LedgerReportGroupField.Fund]?.map((fund) => (
              <div key={fund}>
                <TooltipIconButton
                  title={`Remove ${getFundName(fund)}`}
                  onClick={() => handleRemoveFundFilter(fund)}
                  disabled={loading}
                >
                  <RemoveIcon fontSize="small" />
                </TooltipIconButton>
                <DetailInlineValue>{getFundName(fund)}</DetailInlineValue>
              </div>
            ))}
          </FormGroup>
        </DetailField>
      ) : null}
      {reportGroupBy.includes(LedgerReportGroupField.Portfolio) ? (
        <DetailField>
          <DetailTitle>Portfolio Filter</DetailTitle>
          <FormGroup>
            <Autocomplete
              id="addPortfolioFilter"
              inputValue={fields.addPortfolioFilterText}
              onInputChange={(event, newInputValue) => setFieldValue('addPortfolioFilterText', newInputValue)}
              options={addPortfolioFilterOptions}
              onChange={handleAddPortfolioFilter}
              disabled={loading || addPortfolioFilterOptions.length === 0}
              renderInput={(params) => (
                <DefaultTextField {...params} label="Add Portfolio" placeholder="Pick a portfolio" />
              )}
            />
            {reportGroupFilters[LedgerReportGroupField.Portfolio]?.map((portfolio) => (
              <div key={portfolio}>
                <TooltipIconButton
                  title={`Remove ${getPortfolioName(portfolio)}`}
                  onClick={() => handleRemovePortfolioFilter(portfolio)}
                  disabled={loading}
                >
                  <RemoveIcon fontSize="small" />
                </TooltipIconButton>
                <DetailInlineValue>{getPortfolioName(portfolio)}</DetailInlineValue>
              </div>
            ))}
          </FormGroup>
        </DetailField>
      ) : null}
      {reportGroupBy.includes(LedgerReportGroupField.Strategy) ? (
        <DetailField>
          <DetailTitle>Strategy Filter</DetailTitle>
          <FormGroup>
            <Autocomplete
              id="addStrategyFilter"
              inputValue={fields.addStrategyFilterText}
              onInputChange={(event, newInputValue) => setFieldValue('addStrategyFilterText', newInputValue)}
              options={addStrategyFilterOptions}
              onChange={handleAddStrategyFilter}
              disabled={loading || addStrategyFilterOptions.length === 0}
              renderInput={(params) => (
                <DefaultTextField {...params} label="Add Strategy" placeholder="Pick a strategy" />
              )}
            />
            {reportGroupFilters[LedgerReportGroupField.Strategy]?.map((strategy) => (
              <div key={strategy}>
                <TooltipIconButton
                  title={`Remove ${getStrategyName(strategy)}`}
                  onClick={() => handleRemoveStrategyFilter(strategy)}
                  disabled={loading}
                >
                  <RemoveIcon fontSize="small" />
                </TooltipIconButton>
                <DetailInlineValue>{getStrategyName(strategy)}</DetailInlineValue>
              </div>
            ))}
          </FormGroup>
        </DetailField>
      ) : null}
      {reportGroupBy.includes(LedgerReportGroupField.Asset) ? (
        <DetailField>
          <DetailTitle>Asset Filter</DetailTitle>
          <FormGroup>
            <Autocomplete
              id="addAssetFilter"
              inputValue={fields.addAssetFilterText}
              onInputChange={(event, newInputValue) => setFieldValue('addAssetFilterText', newInputValue)}
              options={addAssetFilterOptions}
              onChange={handleAddAssetFilter}
              disabled={loading || addAssetFilterOptions.length === 0}
              renderInput={(params) => <DefaultTextField {...params} label="Add Asset" placeholder="Pick a asset" />}
            />
            {reportGroupFilters[LedgerReportGroupField.Asset]?.map((asset) => (
              <div key={asset}>
                <TooltipIconButton
                  title={`Remove ${asset}`}
                  onClick={() => handleRemoveAssetFilter(asset)}
                  disabled={loading}
                >
                  <RemoveIcon fontSize="small" />
                </TooltipIconButton>
                <DetailInlineValue>{asset}</DetailInlineValue>
              </div>
            ))}
          </FormGroup>
        </DetailField>
      ) : null}
      {fields.includeTotals == 'true' ? (
        <DetailField>
          <DetailTitle>Include Totals</DetailTitle>
          <DetailError>Include Totals is not currently supported</DetailError>
          <RadioGroup name="includeTotals" row onChange={handleFieldChange} value={fields.includeTotals}>
            <FormControlLabel
              radioGroup="includeTotals"
              value="true"
              control={<Radio />}
              label="Yes"
              disabled={loading}
            />
            <FormControlLabel
              radioGroup="includeTotals"
              value="false"
              control={<Radio />}
              label="No"
              disabled={loading}
            />
          </RadioGroup>
        </DetailField>
      ) : null}
      <DetailField>
        <DetailTitle>Fields</DetailTitle>
        <Autocomplete
          id="addValueField"
          inputValue={fields.addValueFieldText}
          onInputChange={(event, newInputValue) => setFieldValue('addValueFieldText', newInputValue)}
          options={addValueFieldOptions}
          renderOption={(props, option) =>
            typeof option !== 'string' ? (
              <TooltipItem title={option ? determineFieldDescription(option.id) : null}>
                <MenuItem {...props}>{option.label}</MenuItem>
              </TooltipItem>
            ) : (
              <MenuItem {...props}>{option}</MenuItem>
            )
          }
          onChange={handleAddValueField}
          disabled={loading || addValueFieldOptions.length === 0}
          renderInput={(params) => <DefaultTextField {...params} label="Add Field" placeholder="Pick a field" />}
        />
        {sortReportValueFields([...reportValues]).map((valueField) => (
          <div key={valueField}>
            <TooltipIconButton
              title={`Remove ${determineFieldName(valueField)}`}
              onClick={() => handleRemoveValueField(valueField)}
              disabled={loading}
            >
              <RemoveIcon fontSize="small" />
            </TooltipIconButton>
            <TooltipItem title={determineFieldDescription(valueField)}>
              <DetailInlineValue>{determineFieldName(valueField)}</DetailInlineValue>
            </TooltipItem>
          </div>
        ))}
      </DetailField>
      <DetailField>
        <DetailTitle>Include Events</DetailTitle>
        <RadioGroup name="includeEvents" row onChange={handleFieldChange} value={fields.includeEvents}>
          <FormControlLabel
            radioGroup="includeEvents"
            value="true"
            control={<Radio />}
            label="Yes"
            disabled={loading}
          />
          <FormControlLabel
            radioGroup="includeEvents"
            value="false"
            control={<Radio />}
            label="No"
            disabled={loading}
          />
        </RadioGroup>
      </DetailField>
      {marketValueFieldsShown ? (
        <DetailField>
          <DetailTitle>Market Assets</DetailTitle>
          <Autocomplete
            id="addMarketAssetField"
            inputValue={fields.addMarketAssetText}
            onInputChange={(event, newInputValue) => setFieldValue('addMarketAssetText', newInputValue)}
            options={addMarketAssetOptions}
            onChange={handleAddMarketAsset}
            disabled={loading || addMarketAssetOptions.length === 0}
            renderInput={(params) => <DefaultTextField {...params} label="Add Asset" placeholder="Pick an asset" />}
          />
          {reportMarketAssets.map((marketAsset) => (
            <div key={marketAsset}>
              <TooltipIconButton
                title={`Remove ${marketAsset}`}
                onClick={() => handleRemoveMarketAsset(marketAsset)}
                disabled={loading}
              >
                <RemoveIcon fontSize="small" />
              </TooltipIconButton>
              <DetailInlineValue>{marketAsset}</DetailInlineValue>
            </div>
          ))}
        </DetailField>
      ) : null}
    </>
  );
};
export default LedgerReportDataChoices;

export const ledgerReportChoiceClientFragment = gql`
  fragment LedgerReportChoiceClient on Client {
    id
    fullName
  }
`;

export const ledgerReportChoiceFundFragment = gql`
  fragment LedgerReportChoiceFund on Fund {
    id
    name
  }
`;

export const ledgerReportChoicePortfolioFragment = gql`
  fragment LedgerReportChoicePortfolio on Portfolio {
    id
    name
  }
`;

export const ledgerReportChoiceStrategyFragment = gql`
  fragment LedgerReportChoiceStrategy on Strategy {
    id
    name
  }
`;
