import NavItem, { NavItemProps } from '@paypr/mui5-common-components/dist/components/page/nav/NavItem';
import * as React from 'react';
import { useMatch } from 'react-router-dom';
import { useDshStrategyVersionTitleQuery } from '../../generated/graphql';
import { DshStrategyAssetPageParams } from './dshPaths';

type DshStrategyAssetNavItemProps = Omit<NavItemProps, 'title'>;

const DshStrategyAssetNavItem = ({ path, ...rest }: DshStrategyAssetNavItemProps) => {
  const match = useMatch<keyof DshStrategyAssetPageParams, typeof path>(path);

  const extractVersion = (): string | null => {
    return match?.params?.version || null;
  };

  const extractAsset = (): string | null => {
    return match?.params?.asset || null;
  };

  const version = extractVersion();
  const asset = extractAsset();

  const { data } = useDshStrategyVersionTitleQuery({
    variables: version ? { id: version } : undefined,
    skip: !version || !match,
  });

  const versionName = data?.dshVersion?.name || version;

  const title = `${versionName} ${asset}`;

  return <NavItem {...rest} title={title} path={path} />;
};
export default DshStrategyAssetNavItem;
