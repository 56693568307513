import { css } from '@emotion/react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import SubSectionTitle from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import React from 'react';
import { WorkflowAuditActionDetail_Action } from '../../generated/graphql';
import DetailGridItem from '../common/containers/DetailGridItem';
import ExpandIcon from '../common/icons/ExpandIcon';
import WorkflowAuditActionDetail from './WorkflowAuditActionDetail';

export interface WorkflowAuditActionsAccordionProps {
  actions: readonly WorkflowAuditActionDetail_Action[];
}

const WorkflowAuditActionsAccordion = ({ actions }: WorkflowAuditActionsAccordionProps) => {
  const startExpanded = false;

  return (
    <Accordion defaultExpanded={startExpanded}>
      <AccordionSummary expandIcon={<ExpandIcon />}>
        <Toolbar
          css={css`
            display: flex;
            flex-grow: 1;
          `}
        >
          <SubSectionTitle>Activities</SubSectionTitle>
        </Toolbar>
      </AccordionSummary>
      <AccordionDetails>
        <DetailContainer>
          <Grid container justifyContent="left" spacing={2}>
            {actions.map((action) => (
              <DetailGridItem key={action.id} size="full">
                <WorkflowAuditActionDetail action={action} />
              </DetailGridItem>
            ))}
          </Grid>
        </DetailContainer>
      </AccordionDetails>
    </Accordion>
  );
};
export default WorkflowAuditActionsAccordion;
