import { gql } from '@apollo/client';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import LoaderButton from '@paypr/mui5-common-components/dist/components/forms/LoaderButton';
import SecondaryButton from '@paypr/mui5-common-components/dist/components/forms/SecondaryButton';
import SubSectionTitle from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import React, { useEffect } from 'react';
import { UpdatePortfolioManagedCurrencyDialogPortfolioDetails } from '../../../generated/graphql';
import { useFormFields } from '../../../hooks/forms';
import { isTextPositiveFloatOrZero } from '../../../utils/strings';
import { buildPortfolioTitle } from '../PortfolioDetail';
import UpdatePortfolioManagedCurrencyChanges from './UpdatePortfolioManagedCurrencyChanges';
import UpdatePortfolioManagedCurrencyEditor, {
  UpdatePortfolioManagedCurrencyFields,
} from './UpdatePortfolioManagedCurrencyEditor';

export interface UpdatePortfolioManagedCurrencyDialogProps extends Omit<DialogProps, 'onClose' | 'maxWidth'> {
  portfolio: UpdatePortfolioManagedCurrencyDialogPortfolioDetails;
  onSubmitPortfolioManagedCurrencyUpdate: SubmitPortfolioManagedCurrencyUpdateEventHandler;
  submitting?: boolean;
  onClose: (
    event: React.MouseEvent<HTMLButtonElement> | {},
    reason: 'backdropClick' | 'escapeKeyDown' | 'cancelClicked',
  ) => void;
}

export type SubmitPortfolioManagedCurrencyUpdateEventHandler = (
  portfolioId: string,
  managedCurrencyAmount: number,
) => void | Promise<void>;

const UpdatePortfolioManagedCurrencyDialog = ({
  portfolio,
  onSubmitPortfolioManagedCurrencyUpdate,
  onClose,
  submitting,
  open,
  ...props
}: UpdatePortfolioManagedCurrencyDialogProps) => {
  const startingPage = 1;
  const maxPages = 2;
  const [page, setPage] = React.useState(startingPage);

  const { fields, handleFieldChange, resetFields } = useFormFields<UpdatePortfolioManagedCurrencyFields>({
    managedCurrencyAmount: portfolio.managedCurrencyAmount.toString(),
  });

  useEffect(() => {
    if (!open) {
      return;
    }

    resetFields();
    setPage(startingPage);
  }, [open]);

  const newManagedCurrencyAmount = isTextPositiveFloatOrZero(fields.managedCurrencyAmount)
    ? parseFloat(fields.managedCurrencyAmount)
    : portfolio.managedCurrencyAmount;

  const formValid =
    isTextPositiveFloatOrZero(fields.managedCurrencyAmount) &&
    parseFloat(fields.managedCurrencyAmount) != portfolio.managedCurrencyAmount;

  const handleSubmitChange = async () =>
    await onSubmitPortfolioManagedCurrencyUpdate(portfolio.id, newManagedCurrencyAmount);

  const nextPage = () => {
    setPage((page) => page + 1);
  };

  const prevPage = () => {
    setPage((page) => page - 1);
  };

  return (
    <Dialog {...props} open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>Update {buildPortfolioTitle(portfolio.name)} Allocated Funds</DialogTitle>
      <DialogContent>
        {page == 1 && (
          <UpdatePortfolioManagedCurrencyEditor
            managedCurrencyAmount={portfolio.managedCurrencyAmount}
            fields={fields}
            handleFieldChange={handleFieldChange}
          />
        )}
        {page == 2 && (
          <>
            <SubSectionTitle>Verify Changes</SubSectionTitle>
            <UpdatePortfolioManagedCurrencyChanges
              oldManagedCurrencyAmount={portfolio.managedCurrencyAmount}
              newManagedCurrencyAmount={newManagedCurrencyAmount}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={1}>
          <SecondaryButton onClick={(event) => onClose(event, 'cancelClicked')} disabled={submitting}>
            Cancel
          </SecondaryButton>

          {page < maxPages && (
            <SecondaryButton onClick={() => resetFields()} disabled={submitting}>
              Reset
            </SecondaryButton>
          )}

          {page > startingPage && (
            <SecondaryButton onClick={prevPage} disabled={submitting}>
              Back
            </SecondaryButton>
          )}

          {page < maxPages && (
            <Button color="inherit" onClick={nextPage} disabled={!formValid || submitting}>
              Next
            </Button>
          )}

          {page == maxPages && (
            <LoaderButton loading={submitting} disabled={!formValid} onClick={handleSubmitChange}>
              Submit Update
            </LoaderButton>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
export default UpdatePortfolioManagedCurrencyDialog;

export const updatePortfolioManagedCurrencyDialogFragment = gql`
  fragment UpdatePortfolioManagedCurrencyDialogPortfolioDetails on Portfolio {
    id
    name
    managedCurrencyAmount
    availableCurrencyAmount
  }
`;
