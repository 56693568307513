export enum ReportPropertyType {
  Id = 'Id',
  Usd = 'usd',
  Percent = 'percent',
  Integer = 'integer',
  Number = 'number',
  Bips = 'bips',
  Date = 'date',
  DateTime = 'datetime',
  Unknown = 'unknown',
}
