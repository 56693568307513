import { gql } from '@apollo/client';
import NavItem, { NavItemProps } from '@paypr/mui5-common-components/dist/components/page/nav/NavItem';
import * as React from 'react';
import { useMatch } from 'react-router-dom';
import { useClientNavItemQuery } from '../../generated/graphql';
import { ClientPageParams } from './clientPaths';
import { buildClientTitle } from './clientUtils';

type ClientNavItemProps = Omit<NavItemProps, 'title'>;

const ClientNavItem = ({ path, ...rest }: ClientNavItemProps) => {
  const match = useMatch<keyof ClientPageParams, typeof path>(path);

  const extractClientId = (): string | null => {
    return match?.params?.clientId || null;
  };

  const clientId = extractClientId();

  const { data } = useClientNavItemQuery({
    variables: clientId ? { clientId } : undefined,
    skip: !clientId || !match,
  });

  const title = buildClientTitle(data?.client?.fullName);

  return <NavItem {...rest} title={title} path={path} />;
};
export default ClientNavItem;

gql`
  query ClientNavItemQuery($clientId: ID!) {
    client(id: $clientId) {
      id
      fullName
    }
  }
`;
