import Button from '@mui/material/Button';
import Papa from 'papaparse';
import React from 'react';
import { useDateTime } from '../../../data/dates';

export interface DownloadCsvButtonProps {
  filename?: string;
  filenamePrefix?: string;
  dataProvider?: () => (string | number | null)[][];
  disabled?: boolean;
}

const DownloadCsvButton = ({ filename, filenamePrefix, dataProvider, disabled }: DownloadCsvButtonProps) => {
  const { now } = useDateTime();

  const buildEffectiveFilename = () => {
    if (filename) {
      return filename;
    }
    const date = now();

    return `${filenamePrefix || 'data'}-${date.toISODate()}.csv`;
  };

  const effectiveFilename = buildEffectiveFilename();

  const handleDownload = (event) => {
    event.stopPropagation();

    if (!dataProvider) {
      return;
    }

    const csv = Papa.unparse(dataProvider());
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', effectiveFilename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Button onClick={handleDownload} variant="text" disabled={disabled || !dataProvider}>
      Download&nbsp;CSV
    </Button>
  );
};
export default DownloadCsvButton;
