import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import RefreshIconButton, {
  RefreshingComponentProps,
} from '@paypr/mui5-common-components/dist/components/actions/RefreshIconButton';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import React from 'react';
import { useDateTime } from '../../data/dates';
import { TradesTableData_TradeConnection } from '../../generated/graphql';
import NoDataError from '../common/page/NoDataError';
import DownloadCsvButton from '../common/tables/DownloadCsvButton';
import TradeFilterChoices from './TradeFilterChoices';
import { TradeFilters } from './tradePaths';
import TradesTable, { buildTradeCsvData, buildTrades, TradeClickEventHandler } from './TradesTable';

export interface TradesSectionProps extends RefreshingComponentProps {
  trades?: TradesTableData_TradeConnection;
  onClickTrade?: TradeClickEventHandler;
  defaultOptionsExpanded?: boolean;
  onOptionsExpandedChanged?: (expanded: boolean) => void;
  defaultFilters?: TradeFilters;
  assets?: string[];
  onChangeFilters?: (filters: TradeFilters) => void;
}

const TradesSection = ({
  trades,
  autoRefresh,
  loading,
  refreshing,
  onRefresh,
  onClickTrade,
  defaultOptionsExpanded,
  onOptionsExpandedChanged,
  defaultFilters,
  assets,
  onChangeFilters,
}: TradesSectionProps) => {
  const { formatShortDateTime } = useDateTime();

  return (
    <Paper>
      <Toolbar>
        <SectionTitle>Trades</SectionTitle>
        <HorizontalAutoSpacer />
        <RefreshIconButton
          autoRefresh={autoRefresh}
          loading={loading}
          refreshing={refreshing}
          onRefresh={onRefresh}
          includeRefreshText
        />
        <DownloadCsvButton
          filenamePrefix="trades"
          dataProvider={
            trades ? () => buildTradeCsvData(buildTrades({ tradeConnection: trades }), formatShortDateTime) : undefined
          }
        />
      </Toolbar>
      {defaultFilters && onChangeFilters && (
        <TradeFilterChoices
          defaultExpanded={defaultOptionsExpanded}
          onOptionsExpandedChanged={onOptionsExpandedChanged}
          defaultFilters={defaultFilters}
          assets={assets}
          onChangeFilters={onChangeFilters}
          loading={loading}
        />
      )}
      {trades ? (
        <TradesTable tradeConnection={trades} onClickTradeRow={onClickTrade} />
      ) : !(loading || refreshing) ? (
        <NoDataError />
      ) : null}
    </Paper>
  );
};
export default TradesSection;
