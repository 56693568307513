import { gql, NetworkStatus } from '@apollo/client';
import React from 'react';
import AppPage from '../../components/common/page/AppPage';
import AppPageError from '../../components/common/page/AppPageError';
import WidePageContainer from '../../components/common/page/WidePageContainer';
import { useQuery } from '../../components/common/routes/paths';
import { buildTransactionPath, TransactionsPageQueryParams } from '../../components/transactions/transactionPaths';
import TransactionsSection from '../../components/transactions/TransactionsSection';
import { transactionsTableRowFragment } from '../../components/transactions/TransactionsTable';
import { useTransactionsPageQuery } from '../../generated/graphql';
import { usePageTitle } from '../../hooks/page';
import { usePaths } from '../../hooks/paths';

const TransactionsPage = () => {
  usePageTitle('Transactions');
  const { navigateTo } = usePaths();

  const { params, updateParam } = useQuery<TransactionsPageQueryParams>();

  const { data, error, refetch, networkStatus, loading } = useTransactionsPageQuery({
    fetchPolicy: 'cache-and-network',
    variables: { inProgress: null, status: null },
  });

  if (error) {
    return <AppPageError error={error} />;
  }

  const handleTransactionClicked = (transactionId: string, newTab: boolean) => {
    navigateTo(buildTransactionPath(transactionId), newTab);
  };

  return (
    <AppPage>
      <WidePageContainer>
        <TransactionsSection
          transactions={data?.transactions}
          loading={loading}
          refreshing={networkStatus === NetworkStatus.refetch}
          onRefresh={() => refetch()}
          onClickTransaction={handleTransactionClicked}
        />
      </WidePageContainer>
    </AppPage>
  );
};
export default TransactionsPage;

gql`
  query TransactionsPageQuery($inProgress: Boolean, $status: TransactionStatus) {
    transactions(inProgress: $inProgress, status: $status) {
      ...TransactionsTableData_TransactionConnection
    }
  }

  ${transactionsTableRowFragment}
`;
