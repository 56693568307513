import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import NumberField from '@paypr/mui5-common-components/dist/components/forms/NumberField';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailError from '@paypr/mui5-common-components/dist/components/typography/DetailError';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailInlineTitle from '@paypr/mui5-common-components/dist/components/typography/DetailInlineTitle';
import DetailInlineValue from '@paypr/mui5-common-components/dist/components/typography/DetailInlineValue';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import React from 'react';
import { DshForceAggressiveMode, DshStartMode, DshStrategyAssetConfigUpdateDetails } from '../../../generated/graphql';
import { FormFieldChangeEventHandler } from '../../../hooks/forms';
import { isTextNumber } from '../../../utils/strings';
import DetailGridItem from '../../common/containers/DetailGridItem';
import BooleanField from '../../common/forms/BooleanField';
import BooleanFormat from '../../common/typography/BooleanFormat';
import UsdFormat from '../../common/typography/UsdFormat';
import { buildDshForceAggressiveModeDisplay, buildDshStartModeDisplay } from '../dshUtils';

export interface UpdateDshStrategyAssetConfigEditorProps {
  dshConfig: DshStrategyAssetConfigUpdateDetails;

  fields: UpdateDshStrategyForm;
  handleFieldChange: FormFieldChangeEventHandler;
}

export interface UpdateDshStrategyForm {
  startFromSavedState: string;
  startMode: DshStartMode;
  forceAggressive: DshForceAggressiveMode;
  stopWhenLedgerIsEmpty: string;
  maxInvestmentRisk: string;
  riskManagement: string;
}

const UpdateDshStrategyAssetConfigEditor = ({
  dshConfig,
  fields,
  handleFieldChange,
}: UpdateDshStrategyAssetConfigEditorProps) => (
  <DetailContainer>
    <Grid container justifyContent="left" spacing={2}>
      <DetailGridItem>
        <DetailField>
          <DetailTitle>Start from Saved State</DetailTitle>
          <Box>
            <DetailInlineTitle>Current</DetailInlineTitle>
            <DetailInlineValue>
              <BooleanFormat value={dshConfig.startFromSavedState} type="on-off" />
            </DetailInlineValue>
          </Box>
          <BooleanField
            name="startFromSavedState"
            row
            onChange={handleFieldChange}
            value={fields.startFromSavedState}
            booleanType="on-off"
          />
        </DetailField>
      </DetailGridItem>
      <DetailGridItem>
        <DetailField>
          <DetailTitle>Start Mode</DetailTitle>
          <Box>
            <DetailInlineTitle>Current</DetailInlineTitle>
            <DetailInlineValue>{buildDshStartModeDisplay(dshConfig.startMode)}</DetailInlineValue>
          </Box>
          <RadioGroup name="startMode" row onChange={handleFieldChange} value={fields.startMode}>
            <FormControlLabel radioGroup="startMode" value={DshStartMode.Buy} control={<Radio />} label="Buy" />
            <FormControlLabel radioGroup="startMode" value={DshStartMode.Sell} control={<Radio />} label="Sell" />
          </RadioGroup>
        </DetailField>
      </DetailGridItem>
      <DetailGridItem>
        <DetailField>
          <DetailTitle>Stop When Ledger is Empty</DetailTitle>
          <Box>
            <DetailInlineTitle>Current</DetailInlineTitle>
            <DetailInlineValue>
              <BooleanFormat value={dshConfig.stopWhenLedgerIsEmpty} />
            </DetailInlineValue>
          </Box>
          <BooleanField
            name="stopWhenLedgerIsEmpty"
            row
            onChange={handleFieldChange}
            value={fields.stopWhenLedgerIsEmpty}
          />
        </DetailField>
      </DetailGridItem>
      <DetailGridItem>
        <DetailField>
          <DetailTitle>Force Aggressive</DetailTitle>
          <Box>
            <DetailInlineTitle>Current</DetailInlineTitle>
            <DetailInlineValue>{buildDshForceAggressiveModeDisplay(dshConfig.forceAggressive)}</DetailInlineValue>
          </Box>
          <RadioGroup name="forceAggressive" row onChange={handleFieldChange} value={fields.forceAggressive}>
            <FormControlLabel
              radioGroup="forceAggressive"
              value={DshForceAggressiveMode.Override}
              control={<Radio />}
              label="Yes"
            />
            <FormControlLabel
              radioGroup="forceAggressive"
              value={DshForceAggressiveMode.None}
              control={<Radio />}
              label="No"
            />
          </RadioGroup>
        </DetailField>
      </DetailGridItem>
      <DetailGridItem>
        <DetailField>
          <DetailTitle>Max Investment Risk</DetailTitle>
          <Box>
            <DetailInlineTitle>Current</DetailInlineTitle>
            <DetailInlineValue>
              <UsdFormat amount={dshConfig.maxInvestmentRisk} />
            </DetailInlineValue>
          </Box>
          <NumberField
            name="maxInvestmentRisk"
            label="Max Investment Risk"
            autoComplete="off"
            value={fields.maxInvestmentRisk}
            min={0}
            required
            onChange={handleFieldChange}
          />
          {!isTextNumber(fields.maxInvestmentRisk) ? (
            <DetailError>Max Investment Risk must be a number</DetailError>
          ) : null}
        </DetailField>
      </DetailGridItem>
      <DetailGridItem>
        <DetailField>
          <DetailTitle>Risk Management</DetailTitle>
          <Box>
            <DetailInlineTitle>Current</DetailInlineTitle>
            <DetailInlineValue>
              <BooleanFormat value={dshConfig.riskManagement} type="on-off" />
            </DetailInlineValue>
          </Box>
          <BooleanField
            name="riskManagement"
            row
            onChange={handleFieldChange}
            value={fields.riskManagement}
            booleanType="on-off"
          />
        </DetailField>
      </DetailGridItem>
    </Grid>
    <code hidden>{JSON.stringify(fields)}</code>
  </DetailContainer>
);
export default UpdateDshStrategyAssetConfigEditor;
