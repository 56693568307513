import { gql, NetworkStatus } from '@apollo/client';
import React from 'react';
import AppPage from '../../components/common/page/AppPage';
import AppPageError from '../../components/common/page/AppPageError';
import AppPageMessage from '../../components/common/page/AppPageMessage';
import WiderPageContainer from '../../components/common/page/WiderPageContainer';
import { useQuery } from '../../components/common/routes/paths';
import { buildTransactionTitle } from '../../components/transactions/TransactionDetail';
import { TransactionPageParams, TransactionPageQueryParams } from '../../components/transactions/transactionPaths';
import TransactionSection, { transactionSectionFragment } from '../../components/transactions/TransactionSection';
import { useTransactionPageQuery } from '../../generated/graphql';
import { usePageParams, usePageTitle } from '../../hooks/page';

const TransactionPage = () => {
  const { transactionId } = usePageParams<keyof TransactionPageParams>();

  const { params, updateParam } = useQuery<TransactionPageQueryParams>();

  const { data, error, refetch, networkStatus, loading } = useTransactionPageQuery({
    fetchPolicy: 'cache-and-network',
    variables: { transactionId },
  });

  usePageTitle(`${buildTransactionTitle(transactionId, data?.transaction?.trade.order.portfolio.name)}`);

  if (error) {
    return <AppPageError error={error} />;
  }

  const transaction = data?.transaction;

  if (transaction === null) {
    return <AppPageMessage text={`Transaction ${transactionId} does not exist`} />;
  }

  return (
    <AppPage>
      <WiderPageContainer>
        <TransactionSection
          transaction={transaction}
          loading={loading}
          refreshing={networkStatus === NetworkStatus.refetch}
          onRefresh={() => refetch()}
        />
      </WiderPageContainer>
    </AppPage>
  );
};
export default TransactionPage;

gql`
  query TransactionPageQuery($transactionId: ID!) {
    transaction(id: $transactionId) {
      id
      trade {
        order {
          portfolio {
            id
            name
          }
        }
      }
      ...TransactionSection_Transaction
    }
  }

  ${transactionSectionFragment}
`;
