import { gql } from '@apollo/client';
import SubSectionTitle from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import React from 'react';
import { WorkflowUpdatesViewer_WorkflowDetails } from '../../generated/graphql';
import { updateDshStrategyAllocationsWorkflowDetailsFragment } from '../dsh/allocations/allocationsData';
import UpdateDshStrategyAllocationsChanges from '../dsh/allocations/UpdateDshStrategyAllocationsChanges';
import { dshStrategyAssetConfigDetailsFragment } from '../dsh/config/DshStrategyAssetConfig';
import UpdateDshStrategyAssetConfigChanges from '../dsh/config/UpdateDshStrategyAssetConfigChanges';
import DshStrategyVersionTitle from '../dsh/DshStrategyVersionTitle';
import UpdatePortfolioManagedCurrencyChanges from '../portfolios/managedCurrency/UpdatePortfolioManagedCurrencyChanges';
import { buildPortfolioTitle } from '../portfolios/PortfolioDetail';

export interface WorkflowUpdatesViewerProps {
  details: WorkflowUpdatesViewer_WorkflowDetails;
}

const WorkflowUpdatesViewer = ({ details }: WorkflowUpdatesViewerProps) => (
  <>
    {details.updatePortfolioManagedCurrency && (
      <>
        <SubSectionTitle>
          {buildPortfolioTitle(details.updatePortfolioManagedCurrency.portfolio.name)} Allocated Funds Changes
        </SubSectionTitle>
        <UpdatePortfolioManagedCurrencyChanges
          oldManagedCurrencyAmount={details.updatePortfolioManagedCurrency.oldManagedCurrencyAmount}
          newManagedCurrencyAmount={details.updatePortfolioManagedCurrency.newManagedCurrencyAmount}
        />
      </>
    )}
    {details.updateDshAllocations && (
      <>
        <SubSectionTitle>
          <DshStrategyVersionTitle version={details.updateDshAllocations.version} pageName="Allocations Changes" />
        </SubSectionTitle>
        <UpdateDshStrategyAllocationsChanges allocations={details.updateDshAllocations} />
      </>
    )}
    {details.updateDshConfig && (
      <>
        <SubSectionTitle>
          <DshStrategyVersionTitle
            version={details.updateDshConfig.version}
            asset={details.updateDshConfig.asset}
            pageName="Execution Config Changes"
          />
        </SubSectionTitle>
        <UpdateDshStrategyAssetConfigChanges
          oldDshConfig={details.updateDshConfig.oldConfig}
          newDshConfig={details.updateDshConfig.newConfig}
        />
      </>
    )}
  </>
);
export default WorkflowUpdatesViewer;

export const workflowUpdatesViewerFragment = gql`
  fragment WorkflowUpdatesViewer_WorkflowDetails on WorkflowDetails {
    updatePortfolioManagedCurrency {
      portfolio {
        id
        name
      }
      oldManagedCurrencyAmount
      newManagedCurrencyAmount
    }

    updateDshAllocations {
      version
      ...UpdateDshStrategyAllocations_WorkflowDetails
    }

    updateDshConfig {
      version
      asset
      oldConfig {
        ...DshStrategyAssetConfigUpdateDetails
      }
      newConfig {
        ...DshStrategyAssetConfigUpdateDetails
      }
    }
  }

  ${dshStrategyAssetConfigDetailsFragment}
  ${updateDshStrategyAllocationsWorkflowDetailsFragment}
`;
