import { gql, NetworkStatus } from '@apollo/client';
import React, { useState } from 'react';
import { usePortfolioTradesSectionQueryLazyQuery } from '../../generated/graphql';
import ContentError from '../common/content/ContentError';
import { ExplodingComponentProps } from '../common/icons/ExplodeIconButton';
import { TradeFilters } from '../trades/tradePaths';
import TradesAccordion from '../trades/TradesAccordion';
import { TradeClickEventHandler, tradesTableRowFragment } from '../trades/TradesTable';

export interface PortfolioTradesSectionIntegrationProps extends ExplodingComponentProps {
  portfolioId: string;
  assets?: string[];
  onClickTrade?: TradeClickEventHandler;
  defaultExpanded?: boolean;
  autoRefresh?: boolean | number;
}

const PortfolioTradesSectionIntegration = ({
  portfolioId,
  assets,
  onClickTrade,
  defaultExpanded,
  autoRefresh,
  exploded,
  onExplodeChanged,
}: PortfolioTradesSectionIntegrationProps) => {
  const [filters, setFilters] = useState<TradeFilters>({
    assets: null,
    inProgress: null,
    status: null,
  });

  const [fetchPortfolioTrades, { data, error, refetch, networkStatus, loading }] =
    usePortfolioTradesSectionQueryLazyQuery({
      fetchPolicy: 'cache-and-network',
      variables: { portfolioId, ...filters },
    });

  if (error) {
    return <ContentError message="Error loading portfolio trades" error={error} />;
  }

  const portfolio = data?.portfolio;

  const handleRefresh = async () => {
    if (portfolio) {
      await refetch();
    } else {
      await fetchPortfolioTrades();
    }
  };

  return (
    <TradesAccordion
      tradeConnection={portfolio?.trades}
      onClickTrade={onClickTrade}
      defaultExpanded={defaultExpanded}
      defaultFilters={filters}
      assets={assets}
      onChangeFilters={setFilters}
      exploded={exploded}
      onExplodeChanged={onExplodeChanged}
      refreshing={networkStatus === NetworkStatus.refetch}
      loading={loading}
      onRefresh={handleRefresh}
      autoRefresh={autoRefresh}
    />
  );
};
export default PortfolioTradesSectionIntegration;

gql`
  query PortfolioTradesSectionQuery($portfolioId: ID!, $assets: [String!], $inProgress: Boolean, $status: TradeStatus) {
    portfolio(id: $portfolioId) {
      id

      trades(assets: $assets, inProgress: $inProgress, status: $status) {
        ...TradesTableData_TradeConnection
      }
    }
  }

  ${tradesTableRowFragment}
`;
