import { gql, NetworkStatus } from '@apollo/client';
import React from 'react';
import AppPage from '../../components/common/page/AppPage';
import AppPageError from '../../components/common/page/AppPageError';
import AppPageMessage from '../../components/common/page/AppPageMessage';
import WiderPageContainer from '../../components/common/page/WiderPageContainer';
import { useQuery } from '../../components/common/routes/paths';
import { buildOrderTitle } from '../../components/orders/OrderDetail';
import { OrderPageParams, OrderPageQueryParams } from '../../components/orders/orderPaths';
import OrderSection, { orderSectionFragment } from '../../components/orders/OrderSection';
import { buildTradePath } from '../../components/trades/tradePaths';
import { useOrderPageQuery } from '../../generated/graphql';
import { usePageParams, usePageTitle } from '../../hooks/page';
import { usePaths } from '../../hooks/paths';

const OrderPage = () => {
  const { orderId } = usePageParams<keyof OrderPageParams>();
  const { navigateTo } = usePaths();

  const { params, updateParam } = useQuery<OrderPageQueryParams>();

  const { data, error, refetch, networkStatus, loading } = useOrderPageQuery({
    fetchPolicy: 'cache-and-network',
    variables: { orderId },
  });

  usePageTitle(`${buildOrderTitle(orderId, data?.order?.portfolio.name)}`);

  if (error) {
    return <AppPageError error={error} />;
  }

  const handleClickTrade = (tradeId: string, newTab: boolean) => {
    navigateTo(buildTradePath(tradeId), newTab);
  };

  const order = data?.order;

  if (order === null) {
    return <AppPageMessage text={`Order ${orderId} does not exist`} />;
  }

  return (
    <AppPage>
      <WiderPageContainer>
        <OrderSection
          order={order}
          loading={loading}
          refreshing={networkStatus === NetworkStatus.refetch}
          onRefresh={() => refetch()}
          autoRefresh
          onClickTrade={handleClickTrade}
        />
      </WiderPageContainer>
    </AppPage>
  );
};
export default OrderPage;

gql`
  query OrderPageQuery($orderId: ID!) {
    order(id: $orderId) {
      id
      portfolio {
        id
        name
      }
      ...OrderSection_Order
    }
  }

  ${orderSectionFragment}
`;
