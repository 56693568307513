import { gql } from '@apollo/client';
import NoteBlock from '@paypr/mui5-common-components/dist/components/typography/NoteBlock';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { PortfolioDeletedNote_Portfolio } from '../../generated/graphql';

interface PortfolioDeletedNoteProps {
  portfolio: PortfolioDeletedNote_Portfolio;
}

const PortfolioDeletedNote = ({ portfolio: { deletedAt } }: PortfolioDeletedNoteProps) => {
  const { formatDateTime } = useDateTime();

  if (!deletedAt) {
    return null;
  }

  return (
    <NoteBlock>
      <em>This portfolio was deleted on {formatDateTime(deletedAt)}</em>
    </NoteBlock>
  );
};
export default PortfolioDeletedNote;

export const portfolioDeletedNoteFragment = gql`
  fragment PortfolioDeletedNote_Portfolio on Portfolio {
    deletedAt
  }
`;
